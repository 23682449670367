<content class="program-modal">
  <div *ngIf="program.isArchived" class="statusBar text-center text-light bg-primary">
    <span>You are currently viewing an Archived program</span>
  </div>
  <div class="d-flex m-0 position-relative h-100">
    <div class="p-3 primary">
      <div class="text-center">
        <i class="fal fa-hard-hat fa-3x"></i>
        <H5>Program</H5>
      </div>
      <div class="py-4 modal-nav">
        <button *ngFor="let item of nav" mat-button class="btn py-2 d-block w-100"
          [ngClass]="{ 'border-p': current.page == item.page }" (click)="changeSection(item)" [disabled]="item.disabled"
          [hidden]="item.hidden">
          {{ item.name }}
        </button>
      </div>
    </div>
    <div class="corner-close" (click)="close(form)">
      <i class="fal fa-times fa-2x"></i>
    </div>
    <form class="bg-modal px-4 d-flex w-100" [formGroup]="form" data-lpignore="true" autocomplete="off"
      *ngIf="loaded && form; else loading" (input)="check(form)">
      <div class="d-flex flex-column justify-content-between w-100">
        <!-- #region Header -->
        <header class="d-flex primary pt-3">
          <div>
            <h2 class="m-0">
              {{ isNew ? "Add Program" : program.name }}
            </h2>
            {{ current.name }}
          </div>
        </header>
        <!-- #endregion -->
        <!-- #region Main -->
        <main class="h-100" [ngSwitch]="current.page">
          <!-- #region Details -->
          <div *ngSwitchCase="'details'">
            <div class="card p-2 d-block">
              <mat-form-field class="w-100">
                <mat-label class="primary">Name</mat-label>
                <input matInput #name formControlName="name" />
                <mat-error *ngIf="
                    form.get('name').hasError('required') &&
                    form.get('name').touched
                  ">
                  Name Required.
                </mat-error>
              </mat-form-field>
              <mat-form-field class="w-100">
                <mat-label class="primary"> Description </mat-label>
                <textarea matInput class="h-100" rows="12" formControlName="description"></textarea>
              </mat-form-field>
              <mat-form-field class="w-50">
                <mat-label class="primary">Teams</mat-label>
                <mat-select multiple formControlName="teams" [compareWith]="compareOverride">
                  <mat-option *ngFor="
                      let team of metaData.teams | sort: 'name' | archivedFilter
                    " [value]="team">
                    {{ team.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field class="w-50">
                <mat-label class="primary">Contractor</mat-label>
                <mat-select formControlName="contractor" [compareWith]="compareOverride">
                  <mat-option [value]="null"></mat-option>
                  <mat-option *ngFor="
                      let contractor of metaData.contractors | sort: 'name' | archivedFilter
                    " [value]="contractor">
                    {{ contractor.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <!-- #endregion -->
          <div *ngSwitchCase="'customFields'">
            <app-custom-fields [parentForm]="form" [fields]="customFields" [entity]="program"></app-custom-fields>
          </div>
          <!-- #region sites -->
          <div *ngSwitchCase="'sites'" class="h-100">
            <div class="card p-2 d-flex h-100">
              <mat-label class="primary">Add/Remove Sites</mat-label>
              <button mat-flat-button class="border-p m-1 programs-add-sites-btn">
                <mat-select multiple #matSelect class="multiDrag" formControlName="sites"
                  [compareWith]="compareOverride" (selectionChange)="
                    changeSelected(form.controls.sites, matSelect.selected)
                  " (ngInit)="
                    matSelectLoad(form.controls.sites, matSelect.selected)
                  ">
                  <mat-option #matOption *ngFor="
                      let site of metaData.sites | sort: 'name' | archivedFilter
                    " (ngOnInit)="setSelected(site.id, matSelect.selected)" [value]="site">
                    {{ site.name }}
                  </mat-option>
                </mat-select>
              </button>
              <div cdkDropList (cdkDropListDropped)="
                  handleListDrop($event, form.controls.sites.value)
                " class="d-block boundarySites overflow-y-auto">
                <div class="d-flex border rounded p-2 pe-3 justify-content-between"
                  *ngFor="let site of form.controls.sites.value; let i = index" cdkDrag cdkDragLockAxis="y"
                  cdkDragBoundary=".boundarySites" [cdkDragData]="form.controls.sites.value" [id]="'site-' + i">
                  {{ i + 1 }}) {{ site.name }}
                  <div class="delete_hold_row">
                    <div holdable class="holdable" matTooltip="Press and hold to remove" (holdTime)="removeItem($event, site, form.controls.sites)"
                      [id]="'site-' + i + '-close'">
                      <i class="fas fa-times-circle"></i>
                    </div>
                    <mat-progress-spinner [diameter]="25" [value]="site.progress ?? 0" max="100"
                      class="delete-hold-spin"></mat-progress-spinner>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- #endregion -->
          <!-- #region tasks -->
          <div *ngSwitchCase="'siteTasks'" class="h-100">
            <div class="card p-2 d-flex h-100">
              <mat-label class="primary">Edit/Order Tasks</mat-label>

              <mat-accordion class="example-headers-align" multi>
                <mat-expansion-panel *ngFor="let site of siteTasksMetaData; let siteId = index">
                  <mat-expansion-panel-header>
                    <mat-panel-title>{{siteId + 1}}) {{ site.name }} </mat-panel-title>
                  </mat-expansion-panel-header>
                  <button mat-flat-button class="border-p task-select">
                    <mat-select multiple #matSelect class="multiDrag" [compareWith]="compareOverride" (ngInit)="
                        matSelectLoad(
                          form.controls.sites.value[siteId],
                          matSelect.selected
                        )
                      ">
                      <mat-option (click)="
                          selectAll(
                            form.controls.sites.value[siteId].tasks,
                            site.tasks,
                            matSelect,
                            siteId
                          )
                        ">Select All</mat-option>
                      <mat-option #matOption *ngFor="
                          let task of site.tasks | sort: 'name' | archivedFilter
                        " [value]="task" (onSelectionChange)="
                          changeSelectedTask(
                            $event,
                            form.controls.sites.value[siteId].tasks,
                            siteId
                          )
                        " (ngInit)="
                          initOption(
                            form.controls.sites.value[siteId],
                            matOption
                          )
                        ">
                        {{ task.name }}
                      </mat-option>
                    </mat-select>
                  </button>
                  <div cdkDropList (cdkDropListDropped)="
                      handleListDrop(
                        $event,
                        form.controls.sites.value[siteId].tasks
                      )
                    " [ngClass]="'d-block overflow-y-auto boundaryTasks-' + siteId">
                    <div *ngFor="
                        let task of form.controls.sites.value[siteId].tasks;
                        let taskId = index
                      " class="d-flex border rounded p-2 pe-3 justify-content-between" cdkDrag cdkDragLockAxis="y"
                      [cdkDragBoundary]="'.boundaryTasks-' + siteId"
                      [cdkDragData]="form.controls.sites.value[siteId].tasks" [id]="'task-' + taskId">
                      {{ taskId + 1 }}) {{ task.name }}
                      <div class="delete_hold_row_tasks">
                        <div holdable class="holdable" matTooltip="Press and hold to remove"
                          (holdTime)="removeTask($event,task,form.controls.sites.value[siteId],siteId)"
                          [id]="'task-' + taskId + '-close'">
                          <i class=" fas fa-times-circle"></i>
                        </div>
                        <mat-progress-spinner [diameter]="25" [value]="task.progress ?? 0" max="100"
                          class="delete-hold-spin"></mat-progress-spinner>
                      </div>


                      <!-- <div (click)="
                          removeTask(
                            $event,
                            task,
                            form.controls.sites.value[siteId],
                            siteId
                          )
                        " [id]="'task-' + taskId + '-close'">
                        <i class="fas fa-times"></i>
                      </div> -->
                    </div>
                  </div>
                </mat-expansion-panel>
              </mat-accordion>
            </div>
          </div>
          <!-- #endregion -->
          <!-- #region tasks -->
          <div *ngSwitchCase="'taskRecords'" class="h-100">
            <app-grid #taskRecordsGridComponent [gridData]="program.taskRecords" [columns]="taskRecordColumns"
              [small]="true" (onClick)="click($event)" (onDoubleClick)="doubleClick($event)" [selection]="'none'"
              [groupable]="false"></app-grid>
          </div>
          <!-- #endregion -->
          <!-- #region History / AuditTrail -->
          <div *ngSwitchCase="'auditTrail'">
            <app-audit-trail id="auditTrail" [auditTrail]="program.auditTrail"></app-audit-trail>
          </div>
          <!-- #endregion -->
        </main>
        <!-- #endregion -->
        <!-- #region Footer -->
        <footer class="flex justify-content-end my-3">
          <div>
            <button mat-flat-button name="detailSaveButton" class="ms-2 px-4"
              [disabled]="form.invalid || !form.dirty || !hasClaim || saving" (click)="save()">
              <span *ngIf="saving">
                <i class="fad fa-spinner-third fa-spin"> </i>
              </span>
              <span *ngIf="!saving">Save</span>
            </button>
            <button mat-flat-button class="ms-2 px-4" (click)="close(form)">
              Close
            </button>
          </div>
        </footer>
        <!-- #endregion -->
      </div>
    </form>
    <ng-template #loading>
      <app-loading class="w-100 d-flex align-items-center justify-content-center bg-modal"></app-loading>
    </ng-template>
  </div>
</content>
