import {
  Component,
  EventEmitter,
  Inject,
  Output,
  ViewChild
} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {BaseModalComponent} from "src/app/shared/base/base-modal.component";
import {UntypedFormControl, Validators} from "@angular/forms";
import {debounceTime, take} from "rxjs/operators";
import {
  attendanceColumns,
  trainingRecordColumns,
  trainingRequirementColumns
} from "./person-modal.columns";
import {Regex} from "../../shared/helpers/regex-patterns";
import {GridComponent} from "../../shared/grid/grid.component";
import {ModalRequest} from "../../shared/classes/modal.request";
import {ModalType} from "../../shared/enums/modal-fields.enum";
import {ConfirmModalComponent} from "../../shared/components/modals/confirm/confirm-modal.component";
import {Claims} from "src/app/auth/models/claims";
import {ViewCategories} from "../../shared/enums/view-categories.enum";

@Component({
  selector: "app-person-modal",
  templateUrl: "./person-modal.component.html"
})
export class PersonModalComponent extends BaseModalComponent {
  @Output()
  onSave = new EventEmitter();

  claims = [];
  person = <any>{};
  hasClaim = false;
  max = 2;
  allNames = ["Photos"];
  trainingRecordColumns = trainingRecordColumns;
  trainingRequirementColumns = trainingRequirementColumns;
  attendanceColumns = attendanceColumns;

  addTrainingRecordButtons = this.authService.hasClaim(Claims.peopleEdit) && [
    {name: "Add Training Rec.", action: this.addTrainingRecord}
  ];

  addTrainingRequirementButtons = this.authService.hasClaim(
    Claims.peopleEdit
  ) && [{name: "Add Course", action: this.addTrainingRequirement}];

  addAttendanceButtons = this.authService.hasClaim(Claims.peopleEdit) && [
    {name: "Add Attendance", action: this.addAttendance}
  ];

  getNav = () => [
    {name: "Person Details", page: "details"},
    {name: "Custom Fields", page: "customFields", hidden: !this.showCustomFields},
    {name: "Web Section Access", page: "claims"},
    {name: "Photos", page: "photos"},
    {name: "Training Requirements", page: "trainingRequirements"},
    {name: "Training Records", page: "trainingRecords"},
    // {name: "Attendance", page: "attendance"},
    {name: "Links & Files", page: "linksAndFiles"},
    {name: "History", page: "auditTrail", hidden: this.isNew}
  ];

  @ViewChild(GridComponent, {static: false})
  private trainingReqGridComponent: GridComponent;

  @ViewChild(GridComponent, {static: false})
  private trainingRecGridComponent: GridComponent;

  @ViewChild(GridComponent, {static: false})
  private attendanceGridComponent: GridComponent;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<Component>
  ) {
    super();
    this.isNew = !data.data.id;

    this.metaData = data.metaData;
    this.fields = data.fields;
    this.person = data.data;

    this.remoteService.getFields(ViewCategories.People)
      .pipe(take(1))
      .subscribe((response) => {
        this.customFields = response;
        this.showCustomFields = this.customFields.length > 0;
        this.nav = this.getNav();
        this.current = this.nav[0];

        this.metaData.roleClaims.sort(
          (a, b) => {
            if (a < b)
              return 1;
            if (a > b)
              return -1;
            return 0;
          }
        );

        const process = function (context) {

          if (context.person.webRole) {
            context.mapUserClaims(context.person.webRole, context.form);
          }

          context.form.controls["email"].setValidators(Validators.email);

          if (!context.form.controls["hasMobileAccess"].value) {
            context.form.controls["mobileRoles"].disable();
          }
          if (!context.form.controls["hasWebAccess"].value) {
            context.form.controls["hasZohoAccess"].disable();
            context.form.controls["webRole"].disable();
            context.form.controls["twoFactorEnabled"].disable();
          }

          context.form.controls["email"].valueChanges
            .pipe(debounceTime(500))
            .subscribe((v) => {
              if (v) {
                context.checkValidEmail("email").then(() =>
                  context.form.controls["newPassword"].enable());
              } else {
                //MP -> I know this is stupid - but this was actually tripping it out in odd scenarios
                if (!context.form.controls["email"].value) {
                  context.form.controls["newPassword"].disable();
                  context.form.controls["newPassword"].removeValidators(
                    Validators.required
                  );
                }
              }
            });

          context.form.controls["shortUsername"].valueChanges
            .pipe(debounceTime(500))
            .subscribe((v) => {
              if (v) {
                context.checkValidShortUsername("shortUsername").then(() =>
                  context.form.controls["shortUsername"].enable());
              } else {
                //MP -> I know this is stupid - but this was actually tripping it out in odd scenarios
                if (!context.form.controls["email"].value) {
                  context.form.controls["shortUsername"].disable();
                  context.form.controls["shortUsername"].removeValidators(
                    Validators.required
                  );
                }
              }
            });


          if (!context.person.mobile) {
            context.disableNotify(context);
          }

          context.form.controls["mobile"].setValidators([
            Validators.minLength(11),
            Validators.pattern(Regex.phoneNumber)
          ]);

          context.form.controls["mobile"].valueChanges.subscribe((v) => {
            if (v && v.length >= 11) {
              context.form.controls["notifyTask"].enable();
              context.form.controls["notifyStock"].enable();
              context.form.controls["notifyIssue"].enable();
            } else {
              context.disableNotify(context);
            }
          });

          context.form.controls["hasWebAccess"].valueChanges.subscribe((v) => {
            if (v) {
              context.nav[2].disabled = false;
              context.form.controls["hasZohoAccess"].enable();
              context.form.controls["webRole"].enable();
              context.form.controls["twoFactorEnabled"].enable();
              context.form.controls["webRole"].addValidators(Validators.required);
              if (!context.person.hasPassword) {
                context.form.controls["newPassword"].addValidators(Validators.required);
                context.form.controls["newPassword"].setErrors({'required': true});
              }
              if (!context.form.controls["webRole"].value || (context.form.controls["webRole"].value
                && context.form.controls["webRole"].value.length == 0)) {
                context.form.controls['webRole'].setErrors({'required': true});
              }
            } else {
              context.nav[2].disabled = true;
              context.form.controls["webRole"].setValue(null);
              context.form.controls["hasZohoAccess"].setValue(false);
              context.form.controls["hasZohoAccess"].disable();
              context.form.controls["webRole"].disable();
              context.form.controls["twoFactorEnabled"].disable();
              context.form.controls["twoFactorEnabled"].setValue(false);
              context.form.controls["webRole"].removeValidators(
                Validators.required
              );
            }

            context.handleValidators();
            context.handleUsername();
          });

          context.form.controls["webRole"].valueChanges.subscribe((v) => {
            if (v) {
              context.mapUserClaims(v, context.form);
              context.max = v.name == "Read Only" ? 1 : 2;
              context.person.role = v;

              if (v.name == "Contractor") {
                context.form.controls["contractor"].setValidators(
                  Validators.required
                );
                context.form.controls["contractor"].setErrors({required: true});

              } else {
                context.form.controls["contractor"].clearValidators();
              }
            }
          });

          context.form.controls["hasMobileAccess"].valueChanges.subscribe((v) => {
            if (v) {
              context.form.controls["mobileRoles"].enable();
              context.form.controls["mobileRoles"].setValidators(
                Validators.required
              );
              if (
                context.form.controls["mobileRoles"].value &&
                context.form.controls["mobileRoles"].value.length == 0
              ) {
                context.form.controls["mobileRoles"].setErrors({required: true});
              }
            } else {
              context.form.controls["mobileRoles"].disable();
            }
            context.handleValidators();
            context.handleUsername();
          });
          setTimeout(() => context.form.markAllAsTouched(), 5000);
          //Check Claims
          !context.authService.hasClaim(Claims.peopleEdit)
            ? context.form.disable()
            : context.hasClaim = true;
        };

        if (this.isNew) {
          // Default to off for a new Person
          this.person.hasWebAccess = false;
          this.person.hasZohoAccess = false;
          this.person.hasMobileAccess = false;
          this.person.notifyIssue = false;
          this.person.notifyTask = false;
          this.person.notifyStock = false;
        }

        this.nav[2].disabled = !this.person.hasWebAccess;

        this.createForm(this.fields, this.person)
          .then((form) => {
            this.form = form;
            if (this.isNew) {
              this.form.controls["shortUsername"].disable();
              this.form.controls["newShortPassword"].disable();
              this.form.controls["newPassword"].disable();
            }

            this.form.controls["newPassword"].setValidators([
              Validators.minLength(8),
              Validators.maxLength(20),
              Validators.pattern(Regex.password)
            ]);

            this.form.controls["newShortPassword"].setValidators([
              Validators.minLength(4),
              Validators.maxLength(20)
            ]);

          })
          .then(() => {
            process(this);
          });
        this.max =
          this.person.webRole && this.person.webRole.name == "Read Only" ? 1 : 2;
      });


  }

  //
  onSliderChange($event, valueType) {
    if ($event && $event.value != null && this.form.controls[`claim-${valueType}`]) {
      this.form.controls[`claim-${valueType}`].setValue($event.value);
    }
  }

  formatLabel(value: number): string {
    switch (value) {
      case 0:
        return "None";
      case 1:
        return "Read Only";
      case 2:
        return "Edit";
    }
    return "";
  }

  mapUserClaims(role, form) {
    return new Promise((resolve) => {
      this.claims = this.metaData.roleClaims.filter((c) => c.type.id == role.id);
      for (let i = 0; i < this.claims.length; i++) {
        const match = this.person.claims
          ? this.person.claims.find((f) => f.valueType == this.claims[i].valueType)
          : null;
        form.addControl(
          `claim-${this.claims[i].valueType}`,
          new UntypedFormControl(
            match ? match.value : this.person.isNew ? this.max : 0
          )
        );
      }
      resolve(form);
    });
  }

  // Helper function to enforce Required logic - Seperated for readability.
  private handleValidators() {
    if (
      this.form.controls["hasWebAccess"].value ||
      this.form.controls["hasMobileAccess"].value
    ) {
      if (this.form.controls["hasWebAccess"].value) {
        this.form.controls["webRole"].setValidators(Validators.required);
      }
      this.form.controls["email"].setValidators(Validators.required);
      if (!this.person.hasPassword) {
        this.form.controls["newPassword"].setValidators(Validators.required);
      }
    } else {
      this.form.controls["email"].clearValidators();
      this.form.controls["newPassword"].removeValidators(Validators.required);
      this.form.controls["mobileRoles"].clearValidators();
    }
  }

  private disableNotify(context) {
    context.form.controls["notifyTask"].setValue(false);
    context.form.controls["notifyTask"].disable();
    context.form.controls["notifyIssue"].setValue(false);
    context.form.controls["notifyIssue"].disable();
    context.form.controls["notifyStock"].setValue(false);
    context.form.controls["notifyStock"].disable();
  }

  private handleUsername() {
    if (
      this.form.controls["hasMobileAccess"].value
    ) {
      this.form.controls["shortUsername"].enable({emitEvent: false});
      this.form.controls["newShortPassword"].enable();
    } else {
      this.form.controls["shortUsername"].disable();
      this.form.controls["newShortPassword"].disable();
    }
  }

  // Validation against the API to see if the Email / Username has already been used.
  checkValidEmail(control): Promise<void> {
    return new Promise((resolve) => {
      let avail = false;
      this.remoteService
        .getBy(
          "people",
          "CheckUserNameAvailable",
          this.form.controls[control].value
        )
        .subscribe((response) => {
          avail = response;
          if (
            !avail &&
            this.form.controls[control].value != this.person[control]
          ) {
            this.form.controls[control].setErrors({inUse: true});
          }
          resolve();
        });
    });
  }

  // Validation against the API to see if the Email / Username has already been used.
  checkValidShortUsername(control): Promise<void> {
    return new Promise((resolve) => {
      let avail = false;
      this.remoteService
        .getBy(
          "people",
          "CheckShortUserNameAvailable",
          this.form.controls[control].value
        )
        .subscribe((response) => {
          avail = response;
          if (
            !avail &&
            this.form.controls[control].value != this.person[control]
          ) {
            this.form.controls[control].setErrors({inUse: true});
          }
          resolve();
        });
    });
  }

  changeSection(item) {
    if (
      item.page === "trainingRequirements" ||
      item.page === "trainingRecords" ||
      item.page === "attendance" ||
      item.page === "auditTrail"
    ) {
      let parameter = item.page !== "auditTrail" ? "id" : null;
      // We only want to load each section once.
      if (!this.person[item.page]) {
        this.loaded = false;
        this.remoteService
          .getBy(`people/${item.page}`, parameter, this.data.data.id)
          .subscribe(
            (res) => {
              this.loaded = true;
              this.person[item.page] = res;
            },
            () => {
              this.loaded = true;
            }
          );
      }
    }
    this.current = item;
  }

  addTrainingRecord(context) {
    context.openModal.next(
      new ModalRequest({
        requestContext: context,
        modalType: ModalType.PersonTrainingRecord,
        afterSave: context.saveTrainingRecord,
        payload: {person: {id: context.person.id}}
      })
    );
  }

  addTrainingRequirement(context) {
    context.openModal.next(
      new ModalRequest({
        requestContext: context,
        modalType: ModalType.PersonTrainingRequirement,
        afterSave: context.saveTrainingRequirement,
        payload: {person: {id: context.person.id}}
      })
    );
  }

  addAttendance(context) {
    context.openModal.next(
      new ModalRequest({
        requestContext: context,
        modalType: ModalType.Attendee,
        afterSave: context.saveAttendance,
        payload: {person: {id: context.person.id}}
      })
    );
  }

  saveTrainingRecord(context, entity, dialogRef) {
    if (entity.course.name) {
      entity.courseName = entity.course?.name;
    }
    if (entity.trainer.name) {
      entity.trainerName = entity.trainer?.name;
    }
    context.addOrReplace(context.person.trainingRecords, entity);
    context.trainingRecGridComponent.grid.data = [
      ...context.person.trainingRecords
    ];
    dialogRef.close();
    context.form.markAsDirty();
  }

  saveTrainingRequirement(context, entity, dialogRef) {
    if (entity.course.name) {
      entity.courseName = entity.course?.name;
    }
    context.addOrReplace(context.person.trainingRequirements, entity);
    context.trainingReqGridComponent.grid.data = [
      ...context.person.trainingRequirements
    ];
    dialogRef.close();
    context.form.markAsDirty();
  }

  saveAttendance(entity, context, dialogRef) {
    if (entity.course.name) {
      entity.courseName = entity.course?.name;
    }
    context.addOrReplace(context.person.attendance, entity);
    context.attendanceGridComponent.grid.data = [...context.person.attendance];
    dialogRef.close();
    context.form.markAsDirty();
  }

  trackImage($event) {
    this.person.images.push({
      id: $event
    });
  }

  checkImages($event) {
    if (!this.person.images) {
      this.person.images = [];
    }
    this.person.images.push($event);
    this.form.markAsDirty();
  }

  checkDocuments($event) {
    if (!this.person.documents) {
      this.person.documents = [];
    }
    this.person.documents.push($event);
    this.form.markAsDirty();
  }

  doubleClick(cell) {
    this.openModal.next(
      new ModalRequest({
        id: cell.id.rowID,
        requestContext: this,
        modalType:
          this.current.name == "Training Records"
            ? ModalType.PersonTrainingRecord
            : ModalType.PersonTrainingRequirement,
        autoSave: false,
        afterSave:
          this.current.name == "Training Records"
            ? this.saveTrainingRecord
            : this.saveTrainingRequirement,
        payload: {person: {id: this.person.id}}
      })
    );
  }

  save() {
    this.person = {...this.person, ...this.form.getRawValue()};
    if (this.person.email) {
      this.person.username = this.person.email;
    }
    
    // iterate through the claims and build them in the API accepted format
    this.person.claims = [];

    if (this.person.webRole) {
      Object.keys(this.person).forEach((key) => {
        if (key.includes("claim-")) {
          if (this.person.webRole.name == "Readonly" && this.person[key] > 1) {
            // also checked on API
            this.person[key] = 1;
          }
          this.person.claims.push({
            valueType: key.substr(6),
            value: this.person[key] ? this.person[key] : 0
          });
          delete this.person[key];
        }
      });
    }
    if (this.person.hasWebAccess
      && (!this.person.claims
        || this.person.claims.length == 0
        || !this.person.claims.find(f => f.value > 0))) {
      this.matDialog.open(ConfirmModalComponent, {
        data: {
          type: "no-btn",
          body: [
            "You must add Access to at least one Web Section (See Web Section Access)"
          ]
        },
        panelClass: "confirm-dialog-container"
      });
    } else {
      this.onSave.emit(this.person);
    }
  }

  close(form) {
    this.closeModal(form, this.dialogRef);
  }
}
