<content class="task-modal">
  <div
    *ngIf="task.isArchived"
    class="statusBar text-center text-light bg-primary"
  >
    <span>You are currently viewing an Archived Task</span>
  </div>
  <div class="d-flex m-0 position-relative h-100">
    <div class="p-3 primary">
      <div class="text-center">
        <i class="fal fa-thumbtack fa-3x"></i>
        <H5>Task</H5>
      </div>
      <div class="py-5 modal-nav">
        <button
          *ngFor="let item of nav"
          mat-button
          class="btn py-2 d-block w-100"
          [ngClass]="{ 'border-p': current.page == item.page }"
          (click)="changeSection(item)"
          [disabled]="item.disabled"
          [hidden]="item.hidden"
        >
          {{ item.name }}
        </button>
      </div>
    </div>
    <div class="corner-close" (click)="close(form)">
      <i class="fal fa-times fa-2x"></i>
    </div>
    <form
      class="bg-modal px-4 d-flex w-100"
      [formGroup]="form"
      data-lpignore="true"
      autocomplete="off"
      *ngIf="loaded && form; else loading"
      (input)="check(form)"
    >
      <div class="d-flex flex-column justify-content-between w-100">
        <!-- #region Header -->
        <header class="d-flex primary pt-3">
          <div>
            <h2 class="m-0">
              {{ isNew ? "Add Task" : task.name }}
            </h2>
            {{ current.name }}
          </div>
        </header>
        <!-- #endregion -->
        <!-- #region Main -->
        <main class="h-100" [ngSwitch]="current.page">
          <!-- #region Details -->
          <div *ngSwitchCase="'details'">
            <div class="row">
              <div class="col-12 col-md-6">
                <div class="card p-2 d-block">
                  <mat-form-field class="w-100">
                    <mat-label class="primary">Name</mat-label>
                    <input matInput #name formControlName="name"/>
                    <mat-error
                      *ngIf="
                        form.get('name').hasError('required') &&
                        form.get('name').touched
                      "
                    >
                      Name Required.
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field class="w-100">
                    <mat-label class="primary">Task Category</mat-label>
                    <mat-select
                      formControlName="taskCategory"
                      [compareWith]="compareOverride"
                    >
                      <mat-option
                        *ngFor="
                          let category of metaData.taskCategories
                          | sort: 'name'
                          | archivedFilter : task.taskCategory
                        "
                        [value]="category"
                      >
                        {{ category.name }}
                      </mat-option>
                    </mat-select>
                    <mat-error
                      *ngIf="
                        form.get('taskCategory').hasError('required') &&
                        form.get('taskCategory').touched
                      "
                    >
                      Task Category Required.
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field class="w-100">
                    <mat-label class="primary">Task Type</mat-label>
                    <mat-select
                      formControlName="taskType"
                      [compareWith]="compareOverride"
                    >
                      <mat-option
                        *ngFor="
                          let type of metaData.taskTypes
                            | sort: 'name'
                            | typeFilter: form.controls['taskCategory'].value
                            | archivedFilter : task.taskType
                        "
                        [value]="type"
                      >
                        {{ type.name }}
                      </mat-option>
                    </mat-select>
                    <mat-error
                      *ngIf="
                        form.get('taskType').hasError('required') &&
                        form.get('taskType').touched
                      "
                    >
                      <span>Task Type Required.</span>
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field *ngIf="isNew && !task.site" class="w-100">
                    <mat-label class="primary">Sites</mat-label>
                    <mat-select
                      formControlName="sites"
                      multiple
                      [compareWith]="compareOverride"
                    >
                      <mat-option
                        *ngFor="let site of metaData.sites
                        | sort: 'name'
                        | archivedFilter
                        "
                        [value]="site"
                      >
                        {{ site.name }}
                      </mat-option>
                    </mat-select>
                    <mat-error
                      *ngIf="
                        form.get('sites').hasError('required') &&
                        form.get('sites').touched
                      "
                    >
                      <span>At least 1 Site Required.</span>
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field *ngIf="!isNew || task.site" class="w-100">
                    <mat-label class="primary">Site</mat-label>
                    <mat-select
                      formControlName="site"
                      [compareWith]="compareOverride"
                    >
                      <mat-option [value]="null"
                      >
                        N/A
                      </mat-option>
                      <mat-option
                        *ngFor="let site of metaData.sites
                        | sort: 'name'
                        | archivedFilter: task.site"
                        [value]="site"
                      >
                        {{ site.name }}
                      </mat-option>
                    </mat-select>
                    <mat-error
                      *ngIf="
                        form.get('site').hasError('required') &&
                        form.get('site').touched
                      "
                    >
                      <span>Site Required.</span>
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field class="w-100">
                    <mat-label class="primary">Programs</mat-label>
                    <mat-select
                      formControlName="programs"
                      multiple
                      [compareWith]="compareOverride"
                    >
                      <mat-option
                        *ngFor="let program of metaData.programs
                        | sort: 'name'
                        | archivedFilter"
                        [value]="program"
                      >
                        {{ program.name }}
                      </mat-option>
                    </mat-select>
                    <mat-error
                      *ngIf="
                        form.get('programs').hasError('programs') &&
                        form.get('programs').touched
                      "
                    >
                      <span>At least one Program Required.</span>
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field *ngIf="task.taskStatus == 'Awaiting Review'" class="w-100">
                    <mat-label class="primary">Process Review Request</mat-label>
                    <mat-select
                      formControlName="updateStatus"
                      [compareWith]="compareOverride"
                    >
                      <mat-option
                        *ngFor="let reviewStatus of metaData.reviewStatuses
                        | sort: 'name'
                        | archivedFilter"
                        [value]="reviewStatus"
                      >
                        {{ reviewStatus.name }}
                      </mat-option>
                    </mat-select>
                    <mat-error
                      *ngIf="
                        form.get('updateStatus').hasError('required') &&
                        form.get('updateStatus').touched
                      "
                    >
                      <span>Review Status Required.</span>
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="card mt-3 p-2 d-block">
                  <mat-form-field class="w-100">
                    <mat-label class="primary"> Description</mat-label>
                    <textarea
                      matInput
                      class="h-100"
                      rows="{{task.taskStatus == 'Awaiting Review' ? 5 : 8}}"
                      formControlName="description"
                    ></textarea>
                  </mat-form-field>
                </div>
              </div>
              <div class="col-12 col-md-6 d-flex flex-column">
                <div
                  class="card d-block flex-grow-1 text-center"
                >
                  <app-image-carousel
                    [images]="task.images"
                    (goToPhotos)="current = { name: 'Photos', page: 'photos' }"
                  ></app-image-carousel>
                </div>
                <div class="card mt-3 p-2 d-block">
                  <mat-form-field
                    class="w-100"
                    *ngIf="
                      task.isAdhoc || (
                      form.controls.taskCategory.value &&
                      form.controls.taskCategory.value.name === 'Adhoc Work')
                    "
                  >
                    <mat-label class="primary">Task Priority</mat-label>
                    <mat-select
                      formControlName="taskPriority"
                      [compareWith]="compareOverride"
                    >
                      <mat-option
                        *ngFor="
                          let priority of metaData.taskPriorities | sort: 'name'
                        "
                        [value]="priority"
                      >
                        {{ priority.name }}
                      </mat-option>
                    </mat-select>
                    <mat-error
                      *ngIf="
                        form.get('taskPriority').hasError('required') &&
                        form.get('taskPriority').touched
                      "
                    >
                      <span>Priority Required.</span>
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field class="w-100">
                    <mat-label class="primary"> Chargeable</mat-label>
                    <mat-select
                      formControlName="isChargeable"
                    >
                      <mat-option
                        *ngFor="let bool of bools"
                        [value]="bool.id"
                      >
                        {{ bool.name }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <mat-form-field class="w-100 pb-1">
                    <mat-label class="primary"> Total Cost</mat-label>
                    <div class="d-flex">
                      <span class="me-1">£</span>
                      <input
                        matInput
                        type="number"
                        min="0"
                        formControlName="cost"
                      />
                    </div>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
          <!-- #endregion -->
          <div *ngSwitchCase="'customFields'">
            <app-custom-fields
              [parentForm]="form"
              [fields]="customFields"
              [entity]="task"
            ></app-custom-fields>
          </div>
          <!-- #region Tasks -->
          <div *ngSwitchCase="'taskRecords'" class="h-100">
            <app-grid
              #taskGridComponent
              [gridData]="task.taskRecords"
              (onClick)="click($event)"
              (onDoubleClick)="doubleClick($event)"
              [buttons]="addTaskRecordButtons"
              [columns]="taskModalColumns"
              [small]="true"
              [selection]="'none'"
              [groupable]="false"
            ></app-grid>
          </div>
          <!-- #endregion -->
          <!-- #region Map -->
          <div *ngSwitchCase="'map'" class="h-100">
            <div class="card p-2">
              <div class="col-sm-12 text-center">
                <div class="row m-0">
                  <div class="w-50 row m-0">
                    <mat-form-field class="w-50">
                      <mat-label>Latitude</mat-label>
                      <input
                        matInput
                        formControlName="latitude"
                      />
                      <mat-error
                        *ngIf="
                                  form.get('latitude').status == 'INVALID' &&
                                  form.get('latitude').touched
                                "
                      >
                        [-90 to 90] and 15 decimals max.
                      </mat-error>
                    </mat-form-field>
                    <mat-form-field class="w-50">
                      <mat-label>Longitude</mat-label>
                      <input
                        matInput
                        formControlName="longitude"
                      />
                      <mat-error
                        *ngIf="
                                  form.get('longitude').status == 'INVALID' &&
                                  form.get('longitude').touched
                                "
                      >
                        [-180 to 180] and 15 decimals max.
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="w-50 row m-0">
                    <mat-form-field class="w-50">
                      <mat-label>What3Words</mat-label>
                      <input
                        matInput
                        disabled
                        formControlName="what3Words"
                      />
                    </mat-form-field>
                    <div class="w-50" *hasClaim="'map.edit'">
                      <button
                        class="plot-button border ms-3"
                        *ngIf="!task.isNew && !form.dirty"
                        mat-flat-button
                        (click)="navigateToMap()"
                      >
                        Plot Task on Map
                      </button>
                    </div>
                  </div>
                  <mat-form-field class="w-100">
                    <mat-label>Measured Distances</mat-label>
                    <input
                      matInput
                      disabled
                      formControlName="measuredDistances"
                    />
                  </mat-form-field>
                </div>

                <div
                  class="col-sm-12 p-0"
                  *ngIf="
                          task.parsedLatitude && task.parsedLongitude
                          else explain
                        "
                >
                  <div class="map-container">
                    <app-map
                      class="h-100"
                      [latitude]="
                              task.parsedLatitude
                                ? task.parsedLatitude
                                : form.controls['latitude'].value
                            "
                      [longitude]="
                              task.parsedLongitude
                                ? task.parsedLongitude
                                : form.controls['longitude'].value
                            "
                      [isStatic]="true"
                      [zoom]="15"
                      [id]="task.id"
                    >
                    </app-map>
                  </div>
                </div>
                <ng-template #explain>
                        <span class="explain">
                          Add a valid Latitude & Longitude to view the Map.
                        </span>
                </ng-template>
              </div>
            </div>
          </div>
          <!-- #endregion -->
          <!-- #region Issue -->
          <div *ngSwitchCase="'issue'">
            <div class="row">
              <div class="col-12 col-md-6">
                <div class="card p-2 d-block">
                  <mat-form-field class="w-100">
                    <mat-label class="primary">Issue Number</mat-label>
                    <input matInput #issueNumber formControlName="issueNumber"/>
                  </mat-form-field>
                  <mat-form-field class="w-100">
                    <mat-label class="primary">Issue Category</mat-label>
                    <input matInput #issueCategoryName formControlName="issueCategoryName"/>
                  </mat-form-field>
                  <mat-form-field class="w-100">
                    <mat-label class="primary">Issue Type</mat-label>
                    <input matInput #issueTypeName formControlName="issueTypeName"/>
                  </mat-form-field>
                  <mat-form-field class="w-100">
                    <mat-label class="primary">Site</mat-label>
                    <input matInput #siteName formControlName="siteName"/>
                  </mat-form-field>
                  <mat-form-field class="w-100">
                    <mat-label class="primary"> Description</mat-label>
                    <textarea
                      matInput
                      class="h-100"
                      rows="13"
                      formControlName="issueDescription"
                    ></textarea>
                  </mat-form-field>
                </div>
              </div>
              <div class="col-12 col-md-6 d-flex flex-column">
                <div
                  class="card d-block flex-grow-1 max-photo-height overflow-hidden text-center"
                >
                  <app-image-carousel
                    [images]="task.issueImages"
                    [showButton]="false"
                  ></app-image-carousel>
                </div>
                <div class="card mt-3 p-2 d-block">
                  <mat-form-field class="w-50">
                    <mat-label class="primary"> Latitude</mat-label>
                    <input matInput type="number" formControlName="issueLatitude"/>
                  </mat-form-field>
                  <mat-form-field class="w-50">
                    <mat-label class="primary"> Longitude</mat-label>
                    <input matInput type="number" formControlName="issueLongitude"/>
                  </mat-form-field>
                  <mat-form-field class="w-50">
                    <mat-label class="primary"> Risk</mat-label>
                    <input matInput #riskName formControlName="riskName"/>
                  </mat-form-field>
                  <mat-form-field class="w-50">
                    <mat-label class="primary"> Escalated</mat-label>
                    <mat-select formControlName="escalated">
                      <mat-option
                        *ngFor="let bool of bools"
                        [value]="bool.id"
                      >
                        {{ bool.name }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <mat-form-field class="w-100">
                    <mat-label class="primary"> Issue Status</mat-label>
                    <mat-select formControlName="isClosed">
                      <mat-option [value]="true"> Resolved</mat-option>
                      <mat-option [value]="false"> Open</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
          <!-- #endregion -->
          <!-- #region Photos -->
          <div *ngSwitchCase="'photos'" class="h-100">
            <div class="row h-100">
              <app-photos
                *ngIf="isNew"
                [imageArrays]="[task.images || []]"
                class="col-12 pb-1 h-100"
                [arrayNames]="allNames"
                [isNew]="true"
                [addPhotos]="hasClaim"
                (addedImage)="checkImages($event)"
                (removedImage)="form.markAsDirty()"
              >
              </app-photos>
              <app-photos
                *ngIf="!isNew"
                [addPhotos]="hasClaim"
                class="col-12 pb-1 h-100"
                [imageArrays]="allImages"
                [arrayNames]="allNames"
                [entity]="{ type: 'task', id: task.id }"
                (addedImage)="checkImages($event)"
                (removedImage)="form.markAsDirty()"
              >
              </app-photos>
            </div>
          </div>
          <!-- #endregion -->
          <!-- #region Links and Files -->
          <div *ngSwitchCase="'linksAndFiles'" class="h-100">
            <app-documents
              class="documents"
              [canAdd]="hasClaim && !task.isNew"
              [documents]="task.documents || []"
              [taskId]="task.id"
              (addedDocument)="checkDocuments($event)"
              [isNew]="isNew"
              [form]="form"
            >
            </app-documents>
          </div>
          <!-- #endregion -->
          <!-- #region History / AuditTrail -->
          <div *ngSwitchCase="'auditTrail'">
            <app-audit-trail
              id="auditTrail"
              [auditTrail]="task.auditTrail"
            ></app-audit-trail>
          </div>
          <!-- #endregion -->
        </main>
        <!-- #endregion -->
        <!-- #region Footer -->
        <footer
          class="flex justify-content-end my-3"
          [ngClass]="task.isArchived ? 'pb-4' : ''"
        >
          <div>
            <button
              mat-flat-button
              name="detailSaveButton"
              class="ms-2 px-4"
              [disabled]="form.invalid || !form.dirty || !hasClaim || saving"
              (click)="save()"
            >
              <span *ngIf="saving">
                <i class="fad fa-spinner-third fa-spin">
                </i>
              </span>
              <span *ngIf="!saving">Save</span>
            </button>
            <button mat-flat-button class="ms-2 px-4" (click)="close(form)">
              Close
            </button>
          </div>
        </footer>
        <!-- #endregion -->
      </div>
    </form>
    <ng-template #loading>
      <app-loading
        class="w-100 d-flex align-items-center justify-content-center bg-modal"
      ></app-loading>
    </ng-template>
  </div>
</content>
