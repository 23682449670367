<content class="equip-modal">
  <div
    *ngIf="equipment.isArchived"
    class="statusBar text-center text-light bg-primary"
  >
    <span>You are currently viewing an Archived Equipment</span>
  </div>
  <div
    *ngIf="equipment.flaggedForReview"
    class="statusBar text-center text-light bg-primary"
  >
    <span>The Equipment you are viewing is in Review, please update the Status</span>
  </div>
  <div class="d-flex m-0 position-relative h-100">
    <div class="p-3 primary">
      <div class="text-center">
        <i class="fal fa-thumbtack fa-3x"></i>
        <H5>Equipment</H5>
      </div>
      <div class="py-5 modal-nav">
        <button
          *ngFor="let item of nav"
          mat-button
          class="btn py-2 d-block w-100"
          [ngClass]="{ 'border-p': current.page == item.page }"
          (click)="changeSection(item)"
          [disabled]="item.disabled"
          [hidden]="item.hidden"
        >
          {{ item.name }}
        </button>
      </div>
    </div>
    <div class="corner-close" (click)="close(form)">
      <i class="fal fa-times fa-2x"></i>
    </div>
    <form
      class="bg-modal px-4 d-flex w-100"
      [formGroup]="form"
      data-lpignore="true"
      autocomplete="off"
      *ngIf="loaded && form; else loading"
      (input)="check(form)"
    >
      <div class="d-flex flex-column justify-content-between w-100">
        <!-- #region Header -->
        <header class="d-flex primary pt-3">
          <div>
            <h2 class="m-0">
              {{ isNew ? "Add Equipment" : equipment.name }}
            </h2>
            {{ current.name }}
          </div>
        </header>
        <!-- #endregion -->
        <!-- #region Main -->
        <main class="h-100" [ngSwitch]="current.page">
          <!-- #region Details -->
          <div *ngSwitchCase="'details'">
            <div class="row">
              <div class="col-12 col-md-6">
                <div class="card p-2 d-block">
                  <mat-form-field class="w-100">
                    <mat-label class="primary"> Equipment Name </mat-label>
                    <input matInput #name formControlName="name" />
                    <mat-error
                      *ngIf="
                        form.get('name').hasError('required') &&
                        form.get('name').touched
                      "
                    >
                      Equipment Name Required.
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field class="w-100">
                    <mat-label class="primary">Category</mat-label>
                    <mat-select
                      formControlName="equipmentCategory"
                      [compareWith]="compareOverride"
                    >
                      <mat-option
                        *ngFor="
                          let category of metaData.equipmentCategories
                            | sort: 'name'
                            | archivedFilter : equipment.equipmentCategory
                        "
                        [value]="category"
                      >
                        {{ category.name }}
                      </mat-option>
                    </mat-select>
                    <mat-error
                      *ngIf="
                        form.get('equipmentCategory').hasError('required') &&
                        form.get('equipmentCategory').touched
                      "
                    >
                      Equipment Category Required.
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field class="w-100">
                    <mat-label class="primary">Equipment Type</mat-label>
                    <mat-select
                      formControlName="equipmentType"
                      [compareWith]="compareOverride"
                    >
                      <mat-option
                        *ngFor="
                          let type of metaData.equipmentTypes
                            | sort: 'name'
                            | typeFilter
                              : form.controls['equipmentCategory'].value
                            | archivedFilter : equipment.equipmentType
                        "
                        [value]="type"
                      >
                        {{ type.name }}
                      </mat-option>
                    </mat-select>
                    <mat-error
                      *ngIf="
                        form.get('equipmentType').hasError('required') &&
                        form.get('equipmentType').touched
                      "
                    >
                      <span>Equipment Type Required.</span>
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field class="w-100">
                    <mat-label class="primary"> Description </mat-label>
                    <textarea
                      matInput
                      class="h-100"
                      rows="4"
                      formControlName="description"
                    ></textarea>
                  </mat-form-field>
                  <mat-form-field class="w-100">
                    <mat-label class="primary"> Location </mat-label>
                    <input matInput formControlName="storedLocation" />
                  </mat-form-field>
                </div>
                <div class="card p-2 mt-3 d-block">
                  <mat-form-field class="w-50">
                    <mat-label class="primary"> Make </mat-label>
                    <input matInput formControlName="make" />
                  </mat-form-field>
                  <mat-form-field class="w-50">
                    <mat-label class="primary"> Model </mat-label>
                    <input matInput formControlName="model" />
                  </mat-form-field>
                  <mat-form-field class="w-50">
                    <mat-label class="primary"> Serial No. </mat-label>
                    <input matInput formControlName="serialNo" />
                  </mat-form-field>
                  <mat-form-field class="w-50">
                    <mat-label class="primary"> Equipment No. </mat-label>
                    <input matInput formControlName="equipmentNo" />
                  </mat-form-field>
                  <mat-form-field class="w-50">
                    <mat-label class="primary"> Fleet No. </mat-label>
                    <input matInput formControlName="fleetNo" />
                  </mat-form-field>
                  <mat-form-field class="w-50">
                    <mat-label class="primary"> Year </mat-label>
                    <input matInput formControlName="year" />
                  </mat-form-field>
                  <mat-form-field class="w-50">
                    <mat-label class="primary"> Value </mat-label>
                    <input matInput formControlName="value" type="number" />
                  </mat-form-field>
                  <mat-form-field class="w-50">
                    <mat-label class="primary"> Reference </mat-label>
                    <input matInput formControlName="reference" />
                  </mat-form-field>
                </div>
              </div>
              <div class="col-12 col-md-6 d-flex flex-column">
                <div class="card d-block flex-grow-1 text-center">
                  <app-image-carousel
                    [images]="equipment.images"
                    (goToPhotos)="current = { name: 'Photos', page: 'photos' }"
                  ></app-image-carousel>
                </div>
                <div class="card mt-3 p-2 d-block">
                  <mat-form-field
                    class="w-50"
                  >
                    <mat-label class="primary">Hours Used</mat-label>
                    <input
                      type="number"
                      matInput
                      formControlName="hoursUsed"
                    />
                  </mat-form-field>
                  <mat-form-field
                    class="w-50"
                  >
                    <mat-label class="primary">Mileage</mat-label>
                    <input
                      type="number"
                      matInput
                      formControlName="mileage"
                    />
                  </mat-form-field>
                  <mat-form-field
                    class="w-50"
                  >
                    <mat-label class="primary">HAV Reading</mat-label>
                    <input
                      type="number"
                      matInput
                      formControlName="havReading"
                    />
                  </mat-form-field>
                    <mat-form-field
                      class="w-50"
                    >
                      <mat-label class="primary">Noise Level</mat-label>
                      <input
                        type="number"
                        matInput
                        step="2"
                        formControlName="noiseLevel"
                      />
                    </mat-form-field>
                  <mat-form-field class="w-50">
                    <mat-label class="primary">Last Serviced Date</mat-label>
                    <input
                      matInput
                      [matDatepicker]="pickerLastServiceDate"
                      formControlName="lastServiceDate"
                    />
                    <mat-datepicker-toggle
                      matSuffix
                      id="lastServiceDateTrigger"
                      [for]="pickerLastServiceDate"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #pickerLastServiceDate></mat-datepicker>
                  </mat-form-field>
                  <mat-form-field class="w-50">
                    <mat-label class="primary">Next Service Due Date</mat-label>
                    <input
                      matInput
                      [matDatepicker]="pickerNextServiceDueDate"
                      formControlName="nextServiceDueDate"
                    />
                    <mat-datepicker-toggle
                      matSuffix
                      id="nextServiceDateTrigger"
                      [for]="pickerNextServiceDueDate"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #pickerNextServiceDueDate></mat-datepicker>
                  </mat-form-field>
                  <mat-form-field class="w-100">
                    <mat-label class="primary">Current Status</mat-label>
                    <mat-select
                      formControlName="equipmentStatus"
                      [compareWith]="compareOverride"
                    >
                      <mat-option
                        *ngFor="
                          let status of metaData.equipmentStatus | sort: 'name'
                        "
                        [value]="status"
                      >
                        {{ status.name }}
                      </mat-option>
                    </mat-select>
                    <mat-error
                      *ngIf="
                        form.get('equipmentStatus').hasError('required') &&
                        form.get('equipmentStatus').touched
                      "
                    >
                      <span>Equipment Status Required.</span>
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
          <!-- #endregion -->
          <div *ngSwitchCase="'customFields'">
            <app-custom-fields
              [parentForm]="form"
              [fields]="customFields"
              [entity]="equipment"
            ></app-custom-fields>
          </div>
          <!-- #region Maintenance -->
          <div *ngSwitchCase="'maintenanceRecords'" class="h-100">
            <app-grid
              #maintenanceRecordsGridComponent
              [gridData]="equipment.maintenanceRecords"
              [columns]="maintenanceColumns"
              [small]="true"
              [selection]="'none'"
              (onDoubleClick)="doubleClick($event)"
              [groupable]="false"
            ></app-grid>
          </div>
          <!-- #endregion -->
          <!-- #region Checks -->
          <div *ngSwitchCase="'checkRecords'" class="h-100">
            <app-grid
              #checkRecordsGridComponent
              [gridData]="equipment.checkRecords"
              [columns]="checksColumns"
              [small]="true"
              [selection]="'none'"
              (onDoubleClick)="doubleClick($event)"
              [groupable]="false"
            ></app-grid>
          </div>
          <!-- #endregion -->
          <!-- #region inspections -->
          <div *ngSwitchCase="'inspectionRecords'" class="h-100">
            <app-grid
              #inspectionRecordsGridComponent
              [gridData]="equipment.inspectionRecords"
              [columns]="inspectionsColumns"
              [small]="true"
              [selection]="'none'"
              (onDoubleClick)="doubleClick($event)"
              [groupable]="false"
            ></app-grid>
          </div>
          <!-- #endregion -->
          <!-- #region Service Requirements -->
          <div *ngSwitchCase="'serviceRequirements'" class="h-100">
            <app-grid
              #serviceRequirementsGridComponent
              [gridData]="equipment.serviceRequirements"
              [columns]="serviceRequirementColumns"
              [buttons]="addEquipmentServiceRequirementButtons"
              [small]="true"
              [selection]="'none'"
              (onClick)="click($event)"
              (onDoubleClick)="doubleClick($event)"
              [groupable]="false"
            ></app-grid>
          </div>
          <!-- #endregion -->
          <!-- #region Notes -->
          <div *ngSwitchCase="'notes'" class="h-100">
            <div class="card p-2">
              <mat-form-field class="w-100">
                <mat-label class="primary">Equipment Notes</mat-label>
                <textarea
                  matInput
                  class="h-100"
                  rows="10"
                  formControlName="notes"
                ></textarea>
              </mat-form-field>
            </div>
          </div>
          <!-- #endregion -->
          <!-- #region Photos -->
          <div *ngSwitchCase="'photos'" class="h-100">
            <div class="row h-100">
              <app-photos
                *ngIf="isNew"
                [imageArrays]="[equipment.images || []]"
                class="col-12 pb-1 h-100"
                [arrayNames]="allNames"
                [isNew]="true"
                [addPhotos]="hasClaim"
                (addedImage)="checkImages($event)"
                (removedImage)="form.markAsDirty()"
              >
              </app-photos>
              <app-photos
                *ngIf="!isNew"
                [addPhotos]="hasClaim"
                class="col-12 pb-1 h-100"
                [imageArrays]="[equipment.images || []]"
                [arrayNames]="allNames"
                [entity]="{ type: 'equipment', id: equipment.id }"
                (addedImage)="checkImages($event)"
                (removedImage)="form.markAsDirty()"
              >
              </app-photos>
            </div>
          </div>
          <!-- #endregion -->
          <!-- #region Links and Files -->
          <div *ngSwitchCase="'linksAndFiles'" class="h-100">
            <app-documents
              class="documents"
              [isNew]="isNew"
              [canAdd]="hasClaim && !equipment.isNew"
              [documents]="equipment.documents || []"
              [equipmentId]="equipment.id"
              [form]="form"
              (addedDocument)="checkDocuments($event)"
            >
            </app-documents>
          </div>
          <!-- #endregion -->
          <!-- #region History / AuditTrail -->
          <div *ngSwitchCase="'auditTrail'">
            <app-audit-trail
              id="auditTrail"
              [auditTrail]="equipment.auditTrail"
            ></app-audit-trail>
          </div>
          <!-- #endregion -->
        </main>
        <!-- #endregion -->
        <!-- #region Footer -->
        <footer
          class="flex justify-content-end my-3"
          [ngClass]="equipment.isArchived ? 'pb-4' : ''"
        >
          <div>
            <button
              mat-flat-button
              name="detailSaveButton"
              class="ms-2 px-4"
              [disabled]="form.invalid || !form.dirty || !hasClaim || saving"
              (click)="save()"
            >
              <span *ngIf="saving">
                <i class="fad fa-spinner-third fa-spin">
                </i>
              </span>
              <span *ngIf="!saving">Save</span>
            </button>
            <button mat-flat-button class="ms-2 px-4" (click)="close(form)">
              Close
            </button>
          </div>
        </footer>
        <!-- #endregion -->
      </div>
    </form>
    <ng-template #loading>
      <app-loading
        class="w-100 d-flex align-items-center justify-content-center bg-modal"
      ></app-loading>
    </ng-template>
  </div>
</content>
