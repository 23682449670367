<content class="bg-modal new-program-site-check-record-modal position-relative">
  <header class="text-center pt-2">
    <h2>{{ title }}</h2>
  </header>
  <main>
    <div class="d-flex flex-column justify-content-between w-100">
      <div class="corner-close" (click)="close(form)">
        <i class="fal fa-times fa-2x"></i>
      </div>
      <div class="h-100 p-2">
        <div class="h-100 w-100">
          <programSiteCheck
            [checks]="programSiteCheckRecord.checks"
            [grades]="grades"
            [completeBtnText]="'Complete Program Site Check Record'"
            [allowIncomplete]="allowIncomplete"
            (complete)="programSiteRecordComplete($event)"
          ></programSiteCheck>
        </div>

      </div>
    </div>
  </main>
</content>
