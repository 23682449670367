import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-image-carousel',
  templateUrl: './image-carousel.component.html'
})
export class ImageCarouselComponent {
  @Input()
  showButton = true;



  @Input()
  images = [];
  @Output() goToPhotos = new EventEmitter();

  activeId = 0;

  prev() {
    if (this.activeId > 0) {
      this.activeId--;
    }
  }
  next() {
    if (this.activeId < (this.images.length - 1)) {
      this.activeId++;
    }
  }
}
