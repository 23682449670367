<div class="d-flex flex-column justify-content-between w-100">
  <div *ngIf="checks; else loading">
    <div *ngFor="let check of checks | sort: 'order'; let i = index">
      <div *ngIf="check" class="w-100 card rounded p-2 mb-4 m-0 row border">
        <div class="row w-100 m-0">
          <div class="w-25 px-2 border-right-dotted">
            <mat-label class="primary position-absolute tr-image-label">Task & Task Record Images</mat-label>
            <small-image-carousel [images]="check.images"></small-image-carousel>
          </div>
          <div class="w-25 px-2 border-right-dotted">
            <mat-form-field class="w-100">
              <mat-label class="primary">Check</mat-label>
              <input matInput disabled [(ngModel)]="check.name"/>
            </mat-form-field>
            <mat-form-field class="w-100">
              <mat-label class="primary"> Description</mat-label>
              <textarea
                matInput
                class="h-100"
                rows="4"
                disabled
                [(ngModel)]="check.description"
              ></textarea>
            </mat-form-field>
          </div>
          <div class="w-50 row p-0">
            <div class="w-75">
              <mat-form-field *ngIf="check.taskRecordStatusName" class="w-100 px-2 border-right-dotted">
                <mat-label class="primary">Task Record Status</mat-label>
                <input matInput disabled [(ngModel)]="check.taskRecordStatusName"/>
              </mat-form-field>
              <mat-form-field class="w-100 px-2 border-right-dotted">
                <mat-label>Notes</mat-label>
                <textarea [(ngModel)]="check.note" matInput class="h-100"
                          rows="{{check.taskRecordStatusName ? 4 : 7}}"></textarea>
              </mat-form-field>
            </div>
            <div class="w-25 p-0">
              <mat-form-field class="w-100 px-2">
                <mat-label>Grade</mat-label>
                <mat-select (ngModelChange)="checkFinished()" [(ngModel)]="check.grade">
                  <mat-option [value]="grade" *ngFor="let grade of grades">
                    {{grade.name}}
                  </mat-option>
                  <mat-option [value]="{ id : 2 }" *ngIf="check.allowNa">
                    N/A
                  </mat-option>
                  <mat-option [value]="{ id : 3 }" *ngIf="check.allowDefer">
                    Deferred
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <div class="w-100 text-center border rounded pt-3 photo-box h-50">
                <small-photos [index]="i" (addedImage)="checkImages($event, check)"></small-photos>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer class="pb-3 text-center">
      <button
        mat-flat-button
        name="detailSaveButton"
        [disabled]="inComplete"
        (click)="submit()"
        class="ml-2 px-4 border">
        {{completeBtnText ? completeBtnText : 'Complete Inspection'}}
      </button>
    </footer>
  </div>
  <ng-template #loading>
    <app-loading
      class="w-100 d-flex align-items-center justify-content-center bg-modal"
    ></app-loading>
  </ng-template>
</div>
