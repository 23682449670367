<div class="h-100 basic-table basic-edit-table" *ngIf="loaded">
  <mat-form-field
    appearance="fill"
    class="w-100"
    *ngIf="dataSource?.data?.length > 6"
  >
    <mat-label>Filter</mat-label>
    <input
      matInput
      #filter
      placeholder="Start typing to filter..."
      (keyup)="applyFilter(filter.value)"
    />
    <span class="mat-form-field-label-wrapper"></span>
  </mat-form-field>
  <table
    #table
    mat-table
    class="w-100 table-striped"
    matSort
    [dataSource]="dataSource"
  >
    <ng-container
      *ngFor="let column of columns"
      matColumnDef="{{ column.name }}"
    >
      <span *ngIf="column.type !== 'save'">
        <th
          mat-header-cell
          width="{{ column.large ? width * 2 : width }}%"
          *matHeaderCellDef
          mat-sort-header
        >
          {{ column.label }}<span *ngIf="column.required">*</span>
        </th>
      </span>
      <span *ngIf="column.type === 'save'">
        <th
          mat-header-cell
          width="{{ column.large ? width * 2 : width }}%"
          *matHeaderCellDef
          mat-header
        >
          <div
            class="text-end save-column"
            *ngIf="column.type === 'save' && canAdd"
          >
            <button class="add-button border" (click)="add()" mat-flat-button>
              Add {{ column.label }}
            </button>
          </div>
        </th>
      </span>

      <td
        mat-cell
        width="{{ column.large ? width * 2 : width }}%"
        *matCellDef="let element"
      >
        <form *ngIf="forms[element.index]" [formGroup]="forms[element.index]">
          <mat-form-field
            floatLabel="never"
            *ngIf="column.type !== 'save'"
            [ngClass]="column.type === 'bool' ? 'small-field' : 'w-100'"
          >
            <input
              matInput
              type="text"
              formControlName="{{ column.name + 'FormControl' }}"
              *ngIf="column.type === 'text'"
            />
            <input
              matInput
              type="number"
              formControlName="{{ column.name + 'FormControl' }}"
              *ngIf="column.type === 'int'"
            />
            <input
              matInput
              type="number"
              step="any"
              min="0.00"
              max="9999999"
              formControlName="{{ column.name + 'FormControl' }}"
              *ngIf="column.type === 'money'"
            />
            <input
              matInput
              type="number"
              step="0.1"
              min="0.00"
              max="100"
              formControlName="{{ column.name + 'FormControl' }}"
              *ngIf="column.type === 'percentage'"
            />
            <span matSuffix *ngIf="column.type === 'percentage'">%</span>
            <mat-select
              matInput
              formControlName="{{ column.name + 'FormControl' }}"
              *ngIf="column.type === 'bool'"
            >
              <mat-option *ngFor="let bool of bools" [value]="bool.id">
                {{ bool.name }}
              </mat-option>
            </mat-select>
            <mat-select
              matInput
              formControlName="{{ column.name + 'FormControl' }}"
              *ngIf="column.type === 'select'"
              [compareWith]="compareOverride"
            >
              <mat-option
                *ngFor="let m of metaData[column.metaDataType]"
                [value]="m"
              >
                {{ m.name }}
              </mat-option>
            </mat-select>
            <mat-select
            matInput
            formControlName="{{ column.name + 'FormControl' }}"
            *ngIf="column.type === 'filterSelect'"
            [compareWith]="compareOverride"
          >
          <mat-select-filter
          *ngIf="metaData[column.metaDataType]"
          [displayMember]="'name'"
          [array]="metaData[column.metaDataType]"
          (filteredReturn)="
            setFilteredMetaData(column.metaDataType, $event)
          "
        >
        </mat-select-filter>
            <mat-option
              *ngFor="let m of filteredMetaData[column.metaDataType]"
              [value]="m"
            >
              {{ m.name }}
            </mat-option>
          </mat-select>
            <mat-select
              multiple
              formControlName="{{ column.name + 'FormControl' }}"
              *ngIf="column.type === 'multipleSelect'"
              [compareWith]="compareOverride"
            >
              <mat-option
                *ngFor="let m of metaData[column.metaDataType] | sort: 'name'"
                [value]="m"
              >
                {{ m.name }}
              </mat-option>
            </mat-select>
            <input
              matInput
              *ngIf="column.type === 'date'"
              [matDatepicker]="datePicker"
              formControlName="{{ column.name + 'FormControl' }}"
            />
            <mat-datepicker-toggle
              *ngIf="column.type === 'date'"
              matSuffix
              [for]="datePicker"
            ></mat-datepicker-toggle>
            <mat-datepicker #datePicker></mat-datepicker>

            <mat-error
              *ngIf="
                forms[element.index]
                  .get(column.name + 'FormControl')
                  .hasError('required') &&
                forms[element.index].get(column.name + 'FormControl').touched
              "
            >
              {{ column.label }} Required.
            </mat-error>
            <mat-hint class="blue" *ngIf="forms[element.index].dirty">
              Save required
            </mat-hint>
          </mat-form-field>
          <div class="text-end" *ngIf="column.type === 'save'">
            <button
              *ngIf="!element.pending"
              mat-flat-button
              [disabled]="
                forms[element.index].invalid || !forms[element.index].dirty
              "
              (click)="save(element, forms[element.index])"
            >
              Save
            </button>
            <span *ngIf="element.pending">
              <i class="fas fa-2x blue fa-circle-notch fa-spin"></i>
            </span>
            <button
              class="delete-button"
              *ngIf="canArchive"
              mat-flat-button
              (click)="archive(element, element.index)"
            >
              {{ archiveTitle }}
            </button>
            <button
              class="delete-button"
              *ngIf="canDelete"
              mat-flat-button
              [disabled]="!element.canDelete"
              (click)="delete(element, element.index)"
            >
              Delete
            </button>
          </div>
        </form>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <div *ngIf="dataSource?.data?.length > 15">
    <mat-paginator [pageSizeOptions]="[15, 30, 45]"></mat-paginator>
  </div>
</div>
