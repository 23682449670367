import { Component, Inject, } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";


@Component({
  selector: 'app-video-modal',
  templateUrl: './video-modal.component.html',
})
export class VideoModalComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<VideoModalComponent>,
    private modal: MatDialog) {

    this.videoUrl = data;
  }



  videoUrl: string;
}
