import { GridColumnDataType } from "@infragistics/igniteui-angular";
import {IGridColumn} from "../../shared/grid/grid-model";

export const checksColumns: IGridColumn[] = [
  {field: "mobileCreated", header: "Checked Date", dataType: GridColumnDataType.DateTime},
  {field: "equipmentChecklistName", header: "Equipment Checklist", dataType: GridColumnDataType.String},
  {field: "checkResultName", header: "Check Result", dataType: GridColumnDataType.String},
  {field: "createdByName", header: "Checked By", dataType: GridColumnDataType.String},
  {field: "reportedMileage", header: "Reported Mileage", dataType: GridColumnDataType.Number},
  {field: "reportedHours", header: "Reported Hours Used", dataType: GridColumnDataType.Number}
];

export const inspectionsColumns: IGridColumn[] = [
  {field: "mobileCreated", header: "Recorded Date", dataType: GridColumnDataType.DateTime},
  {field: "inspectionName", header: "Inspection Name", dataType: GridColumnDataType.String},
  {field: "inspectionCategoryName", header: "Inspection Category", dataType: GridColumnDataType.String},
  {field: "inspectionTypeName", header: "Inspection Type", dataType: GridColumnDataType.String},
  {field: "numberOfPassedChecks", header: "Passed Checks", dataType: GridColumnDataType.Number},
  {field: "numberOfFailedChecks", header: "Failed Checks", dataType: GridColumnDataType.Number},
  {field: "taskRaised", header: "Task Raised", dataType: GridColumnDataType.Boolean}
];

export const maintenanceColumns: IGridColumn[] = [
  {field: "mobileCreated", header: "Created Date", dataType: GridColumnDataType.DateTime},
  {field: "createdByName", header: "Mechanic", dataType: GridColumnDataType.String},
  {field: "recordTypeName", header: "Record Type", dataType: GridColumnDataType.String},
  {field: "equipmentStatusName", header: "Equipment Status", dataType: GridColumnDataType.String}
];

export const serviceRequirementColumns: IGridColumn[] = [
  {field: "equipmentServiceTypeName", header: "Service Type", dataType: GridColumnDataType.String},
  {field: "equipmentServiceTypeDescription", header: "Service Type Desc.", dataType: GridColumnDataType.String},
  {field: "lastServicedDate", header: "Last Serviced Date", dataType: GridColumnDataType.Date},
  {field: "requiredDate", header: "Required By Date", dataType: GridColumnDataType.Date}
];

