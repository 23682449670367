<content class="bg-modal position-relative">

  <header class="text-center pt-2"><h2>{{title}}</h2></header>
  <main>
    <div class="d-flex flex-column justify-content-between w-100">
      <div class="corner-close" (click)="close(form)">
        <i class="fal fa-times fa-2x"></i>
      </div>
      <div class="h-100 p-2" style="width: 850px;" [ngSwitch]="page">
        <div *ngSwitchCase="'start'">
          <div class="offset-2 col-8 row">
            <div class="col-12 pb-4">
              <form
                [formGroup]="form"
                data-lpignore="true"
                autocomplete="off"
                *ngIf="loaded"
              >
                <div class="card p-2 mb-2  d-block">

                  <mat-form-field class="w-100">
                    <mat-label class="primary">Site</mat-label>
                    <mat-select
                      formControlName="site"
                      [compareWith]="compareOverride"
                    >
                      <mat-option
                        *ngFor="
                          let s of metaData.sites
                            | sort: 'name'
                            | archivedFilter
                        "
                        [value]="s"
                      >
                        {{ s.name }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <mat-form-field class="w-100">
                    <mat-label class="primary">Equipment</mat-label>
                    <mat-select
                      formControlName="equipment"
                      [compareWith]="compareOverride"
                    >
                      <mat-option
                        *ngFor="
                          let e of metaData.equipment
                            | sort: 'name'
                            | archivedFilter
                        "
                        [value]="e"
                      >
                        {{ e.name }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <mat-form-field class="w-50">
                    <mat-label class="primary">Recorded Date</mat-label>
                    <input
                      matInput
                      [matDatepicker]="pickerMobileCreatedDate"
                      formControlName="mobileCreatedDate"
                    />
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="pickerMobileCreatedDate"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #pickerMobileCreatedDate></mat-datepicker>
                  </mat-form-field>
                  <mat-form-field class="w-50">
                    <mat-label class="primary">Recorded Time</mat-label>
                    <input
                      matInput
                      type="time"
                      formControlName="mobileCreatedTime"
                    />
                  </mat-form-field>
                </div>
                <div class="card p-2 mb-2 d-block">
                  <mat-form-field class="w-100">
                    <mat-label class="primary">Inspection</mat-label>
                    <mat-select
                      [disabled]="!form.controls['site'].value && !form.controls['equipment'].value"
                      formControlName="inspection"
                      [compareWith]="compareOverride"
                    >
                      <mat-option
                        *ngFor="
                          let s of metaData.inspections
                            | sort: 'name'
                            | archivedFilter
                        "
                        [value]="s"
                      >
                        {{ s.name }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="card p-2 text-center d-block">
                  <button
                    mat-flat-button
                    [disabled]="!form.controls['inspection'].value"
                    name="detailSaveButton"
                    (click)="startInspection()"
                    class="ms-2 px-4 border">
                    Start Inspection
                  </button>
                </div>
              </form>

            </div>
          </div>
        </div>
        <div class="h-100 w-100" *ngSwitchCase="'inspection'">
          <inspection [checks]="inspection.checks" [saving]="saving" (complete)="inspectionComplete($event)"></inspection>
        </div>
        <div class="h-100 w-100" *ngSwitchCase="'failedChecks'">
          <h5 class="text-center">
            The Inspection you have carried out has a mandatory Risk Policy for failed Checks.
          </h5>
          <div class="card row m-4 p-5">
            <div *ngFor="let check of failedChecks | sort : 'order'; let i = index;">
              <mat-form-field class="w-50">
                <mat-label class="primary">Check</mat-label>
                <input matInput disabled [(ngModel)]="check.name"/>
              </mat-form-field>
              <mat-form-field class="w-50">
                <mat-label>Risk</mat-label>
                <mat-select (ngModelChange)="riskFinished()" [(ngModel)]="check.risk">
                  <mat-option [value]="risk" *ngFor="let risk of metaData.risks">
                    {{risk.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <footer class="pb-3 text-center">
            <button
              mat-flat-button
              name="detailSaveButton"
              [disabled]="inComplete || saving"
              (click)="saveAfterRisk()"
              class="mt-2 px-4 m-4 border">
                <span *ngIf="saving">
                <i class="fad fa-spinner-third fa-spin">
                </i>
              </span>
              <span *ngIf="!saving">Submit Inspection</span>
            </button>
          </footer>
        </div>
      </div>
    </div>
  </main>
</content>
