import { GridColumnDataType } from "@infragistics/igniteui-angular";
import {IGridColumn} from "../../shared/grid/grid-model";

export const issuesColumns: IGridColumn[] = [
  {field: "issueNumber", header: "Issue Number", dataType: GridColumnDataType.Number},
  {field: "created", header: "Reported Date", dataType: GridColumnDataType.DateTime},
  {field: "issueCategoryName", header: "Category", dataType: GridColumnDataType.String},
  {field: "issueTypeName", header: "Type", dataType: GridColumnDataType.String},
  {field: "riskName", header: "Risk", dataType: GridColumnDataType.String},
  {field: "issueStatusName", header: "Issue Status", dataType: GridColumnDataType.String},
  {field: "closedDate", header: "Resolved Date", dataType: GridColumnDataType.DateTime},
  {field: "hasTask", header: "Has Task(s)", dataType: GridColumnDataType.Boolean},
];

export const inspectionColumns: IGridColumn[] = [
  {
    field: "name",
    header: "Inspection Name",
    dataType: GridColumnDataType.String,
    pinned: true
  },
  {
    field: "inspectionTypeName",
    header: "Inspection Type",
    dataType: GridColumnDataType.String
  },
  {
    field: "lastInspected",
    header: "Last Inspected",
    dataType: GridColumnDataType.DateTime
  },
  {
    field: "lastInspectedBy",
    header: "Last Inspected By",
    dataType: GridColumnDataType.String
  },
  {
    field: "timesInspected",
    header: "Times Inspected",
    dataType: GridColumnDataType.Number
  }
];

export const tasksColumns: IGridColumn[] = [
  {field: "name", header: "Task", dataType: GridColumnDataType.String},
  {
    field: "taskCategoryName",
    header: "Task Category",
    dataType: GridColumnDataType.String
  },
  {field: "taskTypeName", header: "Task Type", dataType: GridColumnDataType.String},
  {field: "taskStatus", header: "Status", dataType: GridColumnDataType.String},
  {
    field: "lastCompleted",
    header: "Last Completed",
    dataType: GridColumnDataType.DateTime
  },
  {
    field: "lastCompletedProgram",
    header: "Last Completed Program",
    dataType: GridColumnDataType.String
  }
];

export const taskRecordsColumns: IGridColumn[] = [
  {
    field: "mobileCreated",
    header: "Recorded Date",
    dataType: GridColumnDataType.DateTime
  },
  {
    field: "name",
    header: "Task Name",
    dataType: GridColumnDataType.String
  },
  {
    field: "programName",
    header: "Program",
    dataType: GridColumnDataType.String
  },
  {
    field: "taskCategoryName",
    header: "Task Category",
    dataType: GridColumnDataType.String
  },
  {
    field: "taskTypeName",
    header: "Task Type",
    dataType: GridColumnDataType.String
  },
  {
    field: "taskPriorityName",
    header: "Task Priority",
    dataType: GridColumnDataType.String
  },
  {
    field: "taskRecordStatusName",
    header: "Task Record Status",
    dataType: GridColumnDataType.String
  }
];

export const taskCheckRecordsColumns: IGridColumn[] = [
  {
    field: "mobileCreated",
    header: "Checked Date",
    dataType: GridColumnDataType.DateTime
  },
  {
    field: "createdByName",
    header: "Checked By",
    dataType: GridColumnDataType.String,
  },
  {
    field: "name",
    header: "Task Name",
    dataType: GridColumnDataType.String
  },
  {
    field: "programName",
    header: "Program",
    dataType: GridColumnDataType.String
  },
  {
    field: "taskCategoryName",
    header: "Task Category",
    dataType: GridColumnDataType.String
  },
  {
    field: "taskTypeName",
    header: "Task Type",
    dataType: GridColumnDataType.String
  },
  {
    field: "taskPriorityName",
    header: "Task Priority",
    dataType: GridColumnDataType.String
  },
  {
    field: "resultName",
    header: "Task Check Record Status",
    dataType: GridColumnDataType.String
  },
  {
    field: "adhocTaskAdded",
    header: "Has Adhoc Task(s)",
    dataType: GridColumnDataType.Boolean
  }
];

export const attendanceColumns: IGridColumn[] = [
  {field: "name", header: "Name", dataType: GridColumnDataType.String}
];

export const siteQualityCheckRecordsColumns: IGridColumn[] = [
  {field: "mobileCreated", header: "Checked Date", dataType: GridColumnDataType.DateTime},
  {
    field: "createdByName",
    header: "Checked By",
    dataType: GridColumnDataType.String
  },
  {
    field: "isClientCheck",
    header: "Client Check",
    dataType: GridColumnDataType.Boolean
  },
  {
    field: "adhocRaised",
    header: "Adhoc Raised",
    dataType: GridColumnDataType.Boolean
  },
  {field: "note", header: "Note", dataType: GridColumnDataType.String}
];
