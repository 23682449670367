import {Component, EventEmitter, Inject, Output, ViewChild} from "@angular/core";
import {MatDialogRef, MAT_DIALOG_DATA} from "@angular/material/dialog";
import {BaseModalComponent} from "src/app/shared/base/base-modal.component";
import {GridComponent} from "../../shared/grid/grid.component";
import {Router} from "@angular/router"
import {ConfirmModalComponent} from "../../shared/components/modals/confirm/confirm-modal.component";

@Component({
  templateUrl: "./inspection-type-modal.component.html"
})
export class InspectionTypeModalComponent extends BaseModalComponent {
  @Output()
  onSave = new EventEmitter();
  inspectionType = <any>{};
  isNew = false;

  getNav = () => [
    {name: "Inspection Type Details", page: "details", disabled: false},
    {name: "History", page: "auditTrail", disabled: false, hidden: this.isNew}
  ];

  @ViewChild(GridComponent, {static: false})
  private inspectionRecordGridComponent: GridComponent;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<InspectionTypeModalComponent>,
    private router: Router
  ) {
    super();
    this.isNew = !data.data.id;
    this.fields = data.fields;
    this.metaData = data.metaData;
    this.inspectionType = data.data;
    this.nav = this.getNav();
    this.current = this.nav[0];

    if (!this.inspectionType.periods) {
      this.inspectionType.periods = [];
    }

    this.createForm(this.fields, this.inspectionType)
      .then((form) => {
        this.form = form;

        this.disableSchedules(form);

        if (this.inspectionType.isScheduled) {
          this.enableSchedules(this.form);
        }

        if (this.form.controls["timeFrameDays"].value) {
          this.handleTimeFrameParam(this.form.controls["timeFrameDays"].value, this.form);
        }

        if (this.form.controls["timeFrameDays"].value == 1) {
          if (this.form.controls["timeFrameParam"].value == 1) {
            this.form.controls["bufferDays"].setValue(0);
            this.form.controls["bufferDays"].disable();
          }
        }

        this.form.controls["timeFrameDays"].valueChanges.subscribe((v) => {
          if (v > 1) {
            this.form.controls["bufferDays"].enable();
          } else {
            if (this.form.controls["timeFrameParam"].value == 1) {
              this.form.controls["bufferDays"].disable();
            }
          }
        });

        this.form.controls["timeFrameParam"].valueChanges.subscribe((v) => {
          if (v > 1) {
            this.form.controls["bufferDays"].enable();
          } else {
            if (this.form.controls["timeFrameDays"].value == 1) {
              this.form.controls["bufferDays"].disable();
            }
          }
        });

        this.form.controls["isScheduled"].valueChanges.subscribe((v) => {
          if (v) {
            this.enableSchedules(this.form);
          } else {
            this.disableSchedules(this.form);
          }
        });

        this.form.controls["periods"].valueChanges.subscribe((v) => {
          if (v && v.length > 0) {
            this.form.controls["startDate"].setValue(null, {emitEvent: false});
            this.form.controls["endDate"].setValue(null, {emitEvent: false});
            this.form.controls["startDate"].disable();
            this.form.controls["endDate"].disable();
          } else if (this.form.controls["startDate"].disabled) {
            this.form.controls["startDate"].enable();
            this.form.controls["endDate"].enable();
          }
        })

        this.form.controls["startDate"].valueChanges.subscribe((v) => {
          if (v) {
            this.form.controls["periods"].setValue(null, {emitEvent: false});
            this.form.controls["periods"].disable();
          } else if (this.form.controls["periods"].disabled) {
            this.form.controls["periods"].enable();
          }
        })

        this.form.controls["endDate"].valueChanges.subscribe((v) => {
          if (v) {
            if (v < this.form.controls["startDate"].value) {
              this.form.controls["endDate"].setErrors({'invalid': true});
            } else {
              this.form.controls["endDate"].setErrors(null);
            }
          }
        });

        this.loaded = true;
        setTimeout(() => this.form.markAllAsTouched(), 5000);
      });
  }

  changeSection(item) {
    if (!this.inspectionType[item.page]) {
      this.loaded = false;
      this.remoteService
        .getBy(`inspectionType/${item.page}`, null, this.data.data.id)
        .subscribe((res) => {
          this.inspectionType[item.page] = res;
          this.loaded = true;
        }, () => {
          this.loaded = true;
        });
    }
    this.current = item;
  }

  disableSchedules(form) {
    form.controls["periods"].disable();
    form.controls["days"].disable();
    form.controls["startDate"].disable();
    form.controls["endDate"].disable();
    form.controls["timeFrameDays"].disable();
    form.controls["bufferDays"].disable();
  }

  enableSchedules(form) {

    if (!form.controls["periods"].value || form.controls["periods"].value.length == 0) {
      form.controls["startDate"].enable();
      form.controls["endDate"].enable();
      if (!form.controls["startDate"].value) {
        this.defaultStartDate(form);
      }
    }

    if (!form.controls["startDate"].value) {
      form.controls["periods"].enable();
    }

    form.controls["days"].enable();

    this.form.controls["timeFrameParam"].setValue(1);

    form.controls["timeFrameDays"].enable();
    if (!form.controls["timeFrameDays"].value) {
      form.controls["timeFrameDays"].setValue(1);
    }
    form.controls["bufferDays"].enable();
    if (!form.controls["days"].value || form.controls["days"].value.length == 0) {
      this.defaultDays(form);
    }
  }

  defaultStartDate(form) {
    let d = new Date();
    d.setDate(d.getDate() + (((1 + 7 - d.getDay()) % 7) || 7));
    form.controls["startDate"].setValue(d);
  }

  defaultDays(form) {
    this.form.controls["days"].setValue(JSON.parse(JSON.stringify(this.metaData.days)));
  }

  save() {
    this.saving = true;
    this.inspectionType = {...this.inspectionType, ...this.form.getRawValue()};
    if (this.inspectionType.isScheduled) {

      if (!this.inspectionType.startDate && (!this.inspectionType.periods || this.inspectionType.periods.length == 0)) {
        this.matDialog.open(ConfirmModalComponent, {
          data: {
            type: "no-btn",
            body: [
              "Please select a Start Date or at least one Period for your Scheduled Inspection Type"
            ]
          },
          panelClass: "confirm-dialog-container"
        });
        return;
      }

      if (this.inspectionType.timeFrameDays && this.inspectionType.timeFrameDays > 0) {
        this.inspectionType.timeFrameDays = this.inspectionType.timeFrameDays * this.inspectionType.timeFrameParam;
      }
      this.baseService.openSnackBar(
        "Creating/Updating your Inspection Schedule Records...");
      this.onSave.emit(this.inspectionType);
      setTimeout(() => this.saving = false, 50000);

    } else {
      this.onSave.emit(this.inspectionType);
      setTimeout(() => this.saving = false, 10000);
    }
  }

  close(form) {
    this.closeModal(form, this.dialogRef);
  }

  openEntityProperties(cell, context) {
    context.doubleClick(cell);
  }

  initOption(field, option) {
    if (field.selected) {
      let found = field.selected.find((o) => o.value.id === option.value.id);
      if (!found) {
        field.selected.push(option);
      }
    }
  }

  //Order on Selection or Drag & Drop
  matSelectLoad(items) {
    if (items) {
      items.sort((a, b) => a.order - b.order);
    }
  }

  removeItem(id, value, formField) {
    if (value.selected) {
      value.selected.find((e) => e.value.id === id).deselect();
    } else {
      let foundIndex = value.findIndex((e) => e.id === id);
      if (foundIndex !== -1) {
        value.splice(foundIndex, 1);
      }
    }
    formField.setValue(value);
    formField.markAsDirty();
  }

  handleTimeFrameParam(val, form) {
    if (Number.isInteger(val / 365)) {
      form.controls["timeFrameParam"].setValue(365);
      form.controls["timeFrameDays"].setValue(val / 365);
    } else if (Number.isInteger(val / 28)) {
      form.controls["timeFrameParam"].setValue(28);
      form.controls["timeFrameDays"].setValue(val / 28);
    } else if (Number.isInteger(val / 7)) {
      form.controls["timeFrameParam"].setValue(7);
      form.controls["timeFrameDays"].setValue(val / 7);
    } else {
      form.controls["timeFrameParam"].setValue(1);
    }
  }
}
