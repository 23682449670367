import { Component, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-menu",
  templateUrl: "./menu.component.html",
  styleUrls: ["./menu.component.css"]
})
export class MenuComponent {
  searchInput = "";
  @Input()
  activePage: boolean;

  @Input()
  menu: any[];
  @Output()
  navigate = new EventEmitter();
  @Output()
  onAction = new EventEmitter();
  @Output()
  onSidenavChange = new EventEmitter();

  applyFilter(e: { target: { value: string } }) {
    this.searchInput = e.target.value.trim().toLowerCase();
  }

  action(item) {
    if (item.path) {
      this.navigate.emit("./" + item.path);
    } else if (item.action) {
      this.onAction.emit(item.action);
    }
    if (item.sidenav) {
     this.onSidenavChange.emit(item.name) ;
    }
  }
}
