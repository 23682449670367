<content class="stock-modal">
  <div
    *ngIf="stock.isArchived"
    class="statusBar text-center text-light bg-primary"
  >
    <span>You are currently viewing an Archived Stock</span>
  </div>
  <div class="d-flex m-0 position-relative h-100">
    <div class="p-3 primary">
      <div class="text-center">
        <i class="fal fa-thumbtack fa-3x"></i>
        <H5>Stock</H5>
      </div>
      <div class="py-5 modal-nav">
        <button
          *ngFor="let item of nav"
          mat-button
          class="btn py-2 d-block w-100"
          [ngClass]="{ 'border-p': current.page == item.page }"
          (click)="changeSection(item)"
          [disabled]="item.disabled"
          [hidden]="item.hidden"
        >
          {{ item.name }}
        </button>
      </div>
    </div>
    <div class="corner-close" (click)="close(form)">
      <i class="fal fa-times fa-2x"></i>
    </div>
    <form
      class="bg-modal px-4 d-flex w-100"
      [formGroup]="form"
      data-lpignore="true"
      autocomplete="off"
      *ngIf="loaded && form; else loading"
      (input)="check(form)"
    >
      <div class="d-flex flex-column justify-content-between w-100">
        <!-- #region Header -->
        <header class="d-flex justify-content-between primary pt-3">
          <div>
            <h2 class="m-0">
              {{ isNew ? "Add Stock" : stock.name }}
            </h2>
            {{ current.name }}
          </div>
        </header>
        <!-- #endregion -->
        <!-- #region Main -->
        <main class="flex-grow-1">
          <div class="h-100" [ngSwitch]="current.page">
            <!-- #region Details -->
            <div *ngSwitchCase="'details'">
              <div class="row">
                <div class="col-12 col-md-6">
                  <div class="card p-2 d-block">
                    <mat-form-field class="w-100">
                      <mat-label class="primary"> Stock Name </mat-label>
                      <input matInput #name formControlName="name" />
                      <mat-error
                        *ngIf="
                          form.get('name').hasError('required') &&
                          form.get('name').touched
                        "
                      >
                        Stock Name Required.
                      </mat-error>
                    </mat-form-field>
                    <mat-form-field class="w-100">
                      <mat-label class="primary">Category</mat-label>
                      <mat-select
                        formControlName="stockCategory"
                        [compareWith]="compareOverride"
                      >
                        <mat-option
                          *ngFor="
                            let category of metaData.stockCategories
                              | sort: 'name'
                              | archivedFilter: stock.stockCategory
                          "
                          [value]="category"
                        >
                          {{ category.name }}
                        </mat-option>
                      </mat-select>
                      <mat-error
                        *ngIf="
                          form.get('stockCategory').hasError('required') &&
                          form.get('stockCategory').touched
                        "
                      >
                        Stock Category Required.
                      </mat-error>
                    </mat-form-field>
                    <mat-form-field class="w-100">
                      <mat-label class="primary">Stock Type</mat-label>
                      <mat-select
                        formControlName="stockType"
                        [compareWith]="compareOverride"
                      >
                        <mat-option
                          *ngFor="
                            let type of metaData.stockTypes
                              | sort: 'name'
                              | typeFilter: form.controls['stockCategory'].value
                              | archivedFilter: stock.stockType
                          "
                          [value]="type"
                        >
                          {{ type.name }}
                        </mat-option>
                      </mat-select>
                      <mat-error
                        *ngIf="
                          form.get('stockType').hasError('required') &&
                          form.get('stockType').touched
                        "
                      >
                        <span>Stock Type Required.</span>
                      </mat-error>
                    </mat-form-field>
                    <mat-form-field class="w-100">
                      <mat-label class="primary"> Description </mat-label>
                      <textarea
                        matInput
                        class="h-100"
                        rows="4"
                        formControlName="description"
                      ></textarea>
                    </mat-form-field>
                    <mat-form-field class="w-100">
                      <mat-label class="primary"> Location </mat-label>
                      <input matInput formControlName="storedLocation" />
                    </mat-form-field>
                  </div>
                  <div class="card p-2 mt-3 d-block">
                    <mat-form-field class="w-50">
                      <mat-label class="primary"> Reference </mat-label>
                      <input matInput formControlName="reference" />
                    </mat-form-field>
                    <mat-form-field class="w-50">
                      <mat-label class="primary"> Quantity in Stock </mat-label>
                      <input
                        matInput
                        formControlName="quantity"
                        type="number"
                      />
                      <mat-error
                        *ngIf="
                          form.get('quantity').status == 'INVALID' &&
                          form.get('quantity').touched
                        "
                      >
                        Quantity required.
                      </mat-error>
                    </mat-form-field>
                    <mat-form-field class="w-50">
                      <mat-label class="primary"> Minimum Stock </mat-label>
                      <input
                        matInput
                        formControlName="minimumStockQuantity"
                        type="number"
                        min="0"
                      />
                      <mat-error
                        *ngIf="
                          form.get('quantity').status == 'INVALID' &&
                          form.get('quantity').touched
                        "
                      >
                        Minimum Stock must be a whole number.
                      </mat-error>
                    </mat-form-field>
                    <mat-form-field class="w-50">
                      <mat-label class="primary"> Maximum Order </mat-label>
                      <input
                        matInput
                        formControlName="maximumOrder"
                        type="number"
                        min="0.00"
                      />
                      <mat-error
                        *ngIf="
                          form.get('quantity').status == 'INVALID' &&
                          form.get('quantity').touched
                        "
                      >
                        Maximum Order must be a whole number.
                      </mat-error>
                    </mat-form-field>
                    <mat-form-field class="w-50">
                      <mat-label class="primary"> Cost </mat-label>
                      <span class="pt-1" matPrefix>£</span>
                      <input
                        matInput
                        formControlName="cost"
                        type="number"
                        step="0.01"
                        min="0"
                        currencyFormatter
                      />
                    </mat-form-field>
                    <mat-form-field class="w-50">
                      <mat-label class="primary"> Barcode </mat-label>
                      <input matInput formControlName="barcode" />
                    </mat-form-field>
                    <mat-form-field class="w-100">
                      <mat-label class="primary"> Expiry Date </mat-label>
                      <input
                        matInput
                        [matDatepicker]="pickerExpiryDate"
                        formControlName="expiryDate"
                      />
                      <mat-datepicker-toggle
                        matSuffix
                        id="expiryDateTrigger"
                        [for]="pickerExpiryDate"
                      ></mat-datepicker-toggle>
                      <mat-datepicker #pickerExpiryDate></mat-datepicker>
                    </mat-form-field>
                  </div>
                </div>
                <div class="col-12 col-md-6 d-flex max-photo-height flex-column">
                  <div class="card d-block flex-grow-1 text-center">
                    <app-image-carousel
                      [images]="stock.images"
                      (goToPhotos)="
                        current = { name: 'Photos', page: 'photos' }
                      "
                    ></app-image-carousel>
                  </div>
                </div>
              </div>
            </div>
            <!-- #endregion -->
            <div *ngSwitchCase="'customFields'">
              <app-custom-fields
                [parentForm]="form"
                [fields]="customFields"
                [entity]="stock"
              ></app-custom-fields>
            </div>
            <div *ngSwitchCase="'requests'">
              <app-grid
                #requestGridComponent
                [gridData]="stock.requests"
                [columns]="requestsColumns"
                [small]="true"
                [selection]="'none'"
                [groupable]="false"
              >
              </app-grid>
            </div>
            <!-- #region Photos -->
            <div *ngSwitchCase="'photos'" class="h-100">
              <div class="row h-100">
                <app-photos
                  *ngIf="isNew"
                  [imageArrays]="[stock.images || []]"
                  class="col-12 pb-1 h-100"
                  [arrayNames]="allNames"
                  [isNew]="true"
                  [addPhotos]="hasClaim"
                  (addedImage)="checkImages($event)"
                  (removedImage)="form.markAsDirty()"
                >
                </app-photos>
                <app-photos
                  *ngIf="!stock.isNew"
                  [addPhotos]="hasClaim"
                  class="col-12 pb-1 h-100"
                  [imageArrays]="[stock.images || []]"
                  [arrayNames]="allNames"
                  [entity]="{ type: 'stock', id: stock.id }"
                  (addedImage)="checkImages($event)"
                  (removedImage)="form.markAsDirty()"
                >
                </app-photos>
              </div>
            </div>
            <!-- #endregion -->
            <!-- #region Links and Files -->
            <div *ngSwitchCase="'linksAndFiles'" class="h-100">
              <app-documents
                class="documents"
                [isNew]="isNew"
                [canAdd]="hasClaim && !stock.isNew"
                [documents]="stock.documents || []"
                [stockId]="stock.id"
                [form]="form"
                (addedDocument)="checkDocuments($event)"
              >
              </app-documents>
            </div>
            <!-- #endregion -->
            <!-- #region History / AuditTrail -->
            <div *ngSwitchCase="'auditTrail'" class="h-100">
              <app-audit-trail
                id="auditTrail"
                [auditTrail]="stock.auditTrail"
              ></app-audit-trail>
            </div>
            <!-- #endregion -->
          </div>
        </main>
        <!-- #endregion -->
        <!-- #region Footer -->
        <footer
          class="flex justify-content-end my-3"
          [ngClass]="stock.isArchived ? 'pb-4' : ''"
        >
          <div>
            <button
              mat-flat-button
              name="detailSaveButton"
              class="ms-2 px-4"
              [disabled]="form.invalid || !form.dirty || !hasClaim || saving"
              (click)="save()"
            >
              <span *ngIf="saving">
                <i class="fad fa-spinner-third fa-spin"> </i>
              </span>
              <span *ngIf="!saving">Save</span>
            </button>
            <button mat-flat-button class="ms-2 px-4" (click)="close(form)">
              Close
            </button>
          </div>
        </footer>
        <!-- #endregion -->
      </div>
    </form>
    <ng-template #loading>
      <app-loading
        class="w-100 d-flex align-items-center justify-content-center bg-modal"
      ></app-loading>
    </ng-template>
  </div>
</content>
