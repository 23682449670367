import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Lightbox, LightboxConfig} from "ngx-lightbox";
import {ImageService} from "../../services/image/image.service";

@Component({
  selector: 'small-image-carousel',
  templateUrl: './small-image-carousel.component.html'
})
export class SmallImageCarouselComponent {
  @Input()
  images = [];

  activeId = 0;
  constructor(private lightBox: Lightbox,
              private imageService: ImageService,
              private _lightboxConfig: LightboxConfig) {
    _lightboxConfig.showZoom = true;
    _lightboxConfig.showDownloadButton = true;
  }


  open(index: number): void {
    this.lightBox.open(this.images, index);
  }

  close(): void {
    this.lightBox.close();
  }


  prev() {
    if (this.activeId > 0) {
      this.activeId--;
    }
  }

  next() {
    if (this.activeId < (this.images.length - 1)) {
      this.activeId++;
    }
  }
}
