<div class="task-quality-check-record-modal">
  <div
    *ngIf="taskQualityCheckRecord.isArchived"
    class="statusBar text-center text-light bg-primary"
  >
    <span>You are currently viewing an Archived Task Quality Check Record</span>
  </div>
  <div class="d-flex m-0 position-relative h-100">
    <div class="p-3 primary">
      <div class="text-center">
        <i class="fal fa-thumbtack fa-3x"></i>
        <H5>Task Quality Check Record</H5>
      </div>
      <div class="py-5 modal-nav">
        <button
          *ngFor="let item of nav"
          mat-button
          class="btn py-2 d-block w-100"
          [ngClass]="{ 'border-p': current.page == item.page }"
          (click)="changeSection(item)"
          [disabled]="item.disabled"
          [hidden]="item.hidden"
        >
          {{ item.name }}
        </button>
      </div>
    </div>
    <div class="corner-close" (click)="close(form)">
      <i class="fal fa-times fa-2x"></i>
    </div>
    <form
      class="bg-modal px-4 d-flex w-100"
      [formGroup]="form"
      data-lpignore="true"
      autocomplete="off"
      *ngIf="loaded; else loading"
      (input)="check(form)"
    >
      <content class="d-flex flex-column justify-content-between w-100">
        <!-- #region Header -->
        <header class="d-flex justify-content-between primary pt-3">
          <div>
            <h2 class="m-0">
              {{ isNew ? "Add Task Quality Check Record" : "Update Task Quality Check Record" }}
            </h2>
            {{ current.name }}
          </div>
        </header>
        <!-- #endregion -->
        <!-- #region Main -->
        <main class="flex-grow-1">
          <div class="h-100" [ngSwitch]="current.page">
            <!-- #region Details -->
            <div *ngSwitchCase="'details'">
              <div class="row">
                <div class="col-12 col-md-6">
                  <div class="card min-photo-height p-2 d-block">
                    <mat-form-field class="w-100">
                      <mat-label class="primary">Task</mat-label>
                      <input matInput formControlName="taskName" />
                    </mat-form-field>
                    <mat-form-field class="w-100">
                      <mat-label class="primary">Site</mat-label>
                      <input matInput formControlName="siteName" />
                    </mat-form-field>
                    <mat-form-field class="w-50">
                      <mat-label class="primary">Checked Date</mat-label>
                      <input
                        matInput
                        [matDatepicker]="pickerMobileCreatedDate"
                        formControlName="mobileCreatedDate"
                      />
                      <mat-datepicker-toggle
                        matSuffix
                        [for]="pickerMobileCreatedDate"
                      ></mat-datepicker-toggle>
                      <mat-datepicker #pickerMobileCreatedDate></mat-datepicker>
                    </mat-form-field>
                    <mat-form-field class="w-50">
                      <mat-label class="primary">Checked Time</mat-label>
                      <input
                        matInput
                        type="time"
                        formControlName="mobileCreatedTime"
                      />
                    </mat-form-field>
                    <mat-form-field class="w-100">
                      <mat-label class="primary">Recorded By</mat-label>
                      <input matInput formControlName="createdByName" />
                    </mat-form-field>
                    <mat-form-field class="w-100">
                      <mat-label class="primary"
                        >Task Quality Rating</mat-label
                      >
                      <mat-select
                        formControlName="rating"
                        [compareWith]="compareOverride"
                      >
                        <mat-option
                          *ngFor="
                            let type of metaData.ratings
                              | sort: 'name'
                          "
                          [value]="type"
                        >
                          {{ type.name }}
                        </mat-option>
                      </mat-select>
                      <mat-error
                        *ngIf="
                          form.get('rating').hasError('required') &&
                          form.get('rating').touched
                        "
                      >
                        <span>Task Quality Rating Required.</span>
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>
                <div class="col-12 col-md-6 d-flex flex-column">
                  <div
                    class="
                      card
                      min-photo-height
                      d-block
                      flex-grow-1
                      text-center
                    "
                  >
                    <app-image-carousel
                      [images]="taskQualityCheckRecord.images"
                      (goToPhotos)="
                        current = { name: 'Photos', page: 'photos' }
                      "
                    ></app-image-carousel>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 mt-4 col-md-12">
                  <div class="card p-2 d-block">
                    <mat-form-field class="w-100">
                      <mat-label class="primary">Notes</mat-label>
                      <textarea
                        matInput
                        class="h-100"
                        rows="7"
                        formControlName="note"
                      ></textarea>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
            <!-- #endregion -->
            <!-- #region Tasks -->
            <div *ngSwitchCase="'adhocTasks'" class="h-100">
              <app-grid
                #taskGridComponent
                [gridData]="taskQualityCheckRecord.adhocTasks"
                (onClick)="click($event)"
                (onDoubleClick)="doubleClick($event)"
                [buttons]="addTaskButtons"
                [columns]="taskQualityCheckRecordModalColumns"
                [small]="true"
                [selection]="'none'"
                [groupable]="false"
              ></app-grid>
            </div>
            <!-- #endregion -->
            <!-- #region Photos -->
            <div *ngSwitchCase="'photos'" class="h-100">
              <div class="row h-100">
                  <app-photos
                    *ngIf="isNew"
                    [imageArrays]="[taskQualityCheckRecord.images || []]"
                    class="col-12 pb-1 h-100"
                    [arrayNames]="allNames"
                    [isNew]="true"
                    [addPhotos]="hasClaim"
                    (addedImage)="checkImages($event)"
                    (removedImage)="form.markAsDirty()"
                  >
                  </app-photos>
                  <app-photos
                    *ngIf="!isNew"
                    [addPhotos]="hasClaim"
                    class="col-12 pb-1 h-100"
                    [imageArrays]="[taskQualityCheckRecord.images || []]"
                    [arrayNames]="allNames"
                    [entity]="{ type: 'taskQualityCheckRecord', id: taskQualityCheckRecord.id }"
                    (addedImage)="checkImages($event)"
                    (removedImage)="form.markAsDirty()"
                  >
                  </app-photos>
              </div>
            </div>
            <!-- #endregion -->
<!--            &lt;!&ndash; #region Map &ndash;&gt;-->
<!--            <div *ngSwitchCase="'map'" class="h-100">-->
<!--              <div class="col-sm-12 pt-3 text-center">-->
<!--                <div class="card">-->
<!--                  <div-->
<!--                    class="col-sm-12 p-0"-->
<!--                    *ngIf="-->
<!--                          taskQualityCheckRecord.parsedLatitude;-->
<!--                          else explain-->
<!--                        "-->
<!--                  >-->
<!--                    <div class="map-container">-->
<!--                      <app-map-->
<!--                        class="h-100"-->
<!--                        [latitude]="-->
<!--                              taskQualityCheckRecord.parsedLatitude-->
<!--                            "-->
<!--                        [longitude]="-->
<!--                              taskQualityCheckRecord.parsedLongitude-->
<!--                            "-->
<!--                        [staticType]="3"-->
<!--                        [isStatic]="true"-->
<!--                        [zoom]="12"-->
<!--                      >-->
<!--                      </app-map>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <ng-template #explain>-->
<!--                        <span class="explain">-->
<!--                          Task Quality Check Record Co-ordinates not found.-->
<!--                        </span>-->
<!--                  </ng-template>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--            &lt;!&ndash; #endregion &ndash;&gt;-->
            <!-- #region History / AuditTrail -->
            <div *ngSwitchCase="'auditTrail'">
              <app-audit-trail
                id="auditTrail"
                [auditTrail]="taskQualityCheckRecord.auditTrail"
              ></app-audit-trail>
            </div>
            <!-- #endregion -->
          </div>
        </main>
        <!-- #endregion -->
        <!-- #region Footer -->
        <footer
          class="flex justify-content-end my-3"
          [ngClass]="taskQualityCheckRecord.isArchived ? 'pb-4' : ''"
        >
          <div>
            <button
              mat-flat-button
              name="detailSaveButton"
              class="ms-2 px-4"
              [disabled]="form.invalid || !form.dirty || !hasClaim || saving"
              (click)="save()"
            >
              <span *ngIf="saving">
                <i class="fad fa-spinner-third fa-spin">
                </i>
              </span>
              <span *ngIf="!saving">Save</span>
            </button>
            <button mat-flat-button class="ms-2 px-4" (click)="close(form)">
              Close
            </button>
          </div>
        </footer>
        <!-- #endregion -->
      </content>
    </form>
    <ng-template #loading>
      <app-loading
        class="w-100 d-flex align-items-center justify-content-center bg-modal"
      ></app-loading>
    </ng-template>
  </div>
</div>
