import {IGridColumn} from "../../shared/grid/grid-model";
import { GridColumnDataType } from "@infragistics/igniteui-angular";

export const taskQualityCheckRecordsColumns: IGridColumn[] = [
  {
    field: "taskName",
    header: "Task",
    dataType: GridColumnDataType.String
  },
  {
    field: "mobileCreated",
    header: "Checked Date",
    dataType: GridColumnDataType.DateTime
  },
  {
    field: "taskCategoryName",
    header: "Task Category",
    dataType: GridColumnDataType.String
  },
  {
    field: "taskTypeName",
    header: "Task Type",
    dataType: GridColumnDataType.String
  },
  {
    field: "ratingName",
    header: "Rating",
    dataType: GridColumnDataType.String
  },
  {
    field: "hasImage",
    header: "Has Photo(s)",
    dataType: GridColumnDataType.Boolean
  },
];
