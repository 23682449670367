import { Component } from "@angular/core";
import { BaseBoolFilterComponent } from "./base-bool-filter.component";
import { boolFilterOperations } from "./bool-filter.operations";

@Component({
  selector: "bool-filter",
  templateUrl: "./base-bool-filter.component.html"
})
export class StandardBoolFilterComponent extends BaseBoolFilterComponent {
  constructor() {
    super();
    this.operations = boolFilterOperations.filter((f) => f.name !== "N/A");
  }
}
