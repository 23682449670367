/// Simple class to help prevent typos when defining claims.
/// The API prevents any circumventing or malicious attempts.
export class Claims {
  static dashboardView = "dashboard.view";
  static dashboardEdit = "dashboard.edit";
  static mapView = "map.view";
  static mapEdit = "map.edit";
  static reportView = "reports.view";
  static reportEdit = "reports.edit";
  static issueView = "issues.view";
  static issueEdit = "issues.edit";
  static siteView = "sites.view";
  static siteEdit = "sites.edit";
  static programView = "programs.view";
  static programEdit = "programs.edit";
  static taskView = "tasks.view";
  static taskEdit = "tasks.edit";
  static equipmentView = "equipment.view";
  static equipmentEdit = "equipment.edit";
  static peopleView = "people.view";
  static peopleEdit = "people.edit";
  static inspectionView = "inspections.view";
  static inspectionEdit = "inspections.edit";
  static siteQualityChecksView = "site quality checks.view";
  static siteQualityChecksEdit = "site quality checks.edit";
  static stockView = "stock.view";
  static stockEdit = "stock.edit";
  static attendanceView = "attendance.view";
  static attendanceEdit = "attendance.edit";
  static configuration = "configuration.edit";
  static supportView = "support.view";
  static supportEdit = "support.edit";
  static readOnly = "readonly.true"

}

