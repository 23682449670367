import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {ActivatedRoute} from "@angular/router";
import {MetaDataTypes} from "../shared/enums/meta-data-types.enum";
import MarkerClusterer from "@googlemaps/markerclustererplus";
import {take} from "rxjs/operators";
import {BaseComponent} from "../shared/base/base.component";
import {MAT_CHECKBOX_DEFAULT_OPTIONS, MatCheckboxDefaultOptions} from "@angular/material/checkbox";
import {ConfirmModalComponent} from "../shared/components/modals/confirm/confirm-modal.component";
import {ModalRequest} from "../shared/classes/modal.request";
import {ModalType} from "../shared/enums/modal-fields.enum";
import html2canvas from "html2canvas";
import {Subscription} from "rxjs";
import DrawingManagerOptions = google.maps.drawing.DrawingManagerOptions;
import OverlayType = google.maps.drawing.OverlayType;
// @ts-ignore
import * as MeasureTool from "measuretool-googlemaps-v3";
import {MapService} from "./map.service";

@Component({
  selector: "app-map",
  templateUrl: "./map.component.html",
  // styleUrls: ["./map.component.css"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: "main_component",
  },
  providers: [
    //Fixes Mat checkbox
    {
      provide: MAT_CHECKBOX_DEFAULT_OPTIONS,
      useValue: {
        clickAction: "noop",
        color: "accent",
      } as MatCheckboxDefaultOptions,
    },
  ],
})
export class MapComponent extends BaseComponent implements OnInit, OnDestroy {

  @Input()
  zoom = 13;
  @Input()
  isStatic: boolean;
  @Input()
  staticType: number;

  // Defaulted to Center of UK
  @Input()
  latitude: any = 52.9;
  @Input()
  longitude: any = -1.39;

  @Input()
  data: any = {};


  @Input()
  id: string;

  map: google.maps.Map;
  parsedLatitude: number;
  parsedLongitude: number;

  searchString: string = "";
  searchResults: any = [];

  siteMarker = "../assets/map/site-marker.svg";
  taskMarker = "../assets/map/task-marker.svg";
  issueMarker = "../assets/map/issue-marker.svg";
  siteCluster = "/assets/map/marker-clusters/site/m";
  taskCluster = "/assets/map/marker-clusters/task/m";
  issueCluster = "/assets/map/marker-clusters/issue/m";

  metaData: any;
  address: string;
  geoCoder: {
    geocode: (
      arg0: { location: { lat: any; lng: any } },
      arg1: (results: any, status: any) => void
    ) => void;
  };


  openWindow: { close: () => void };

  showingAllLabels = false;
  plotTool = {
    tool: "",
    color: "",
    issue: false,
    openPropModal: {type: "", id: ""},
    plotting: false,
  };

  showMap = true;
  loadingText = "Loading...";
  showLayers = false;
  showFilters = false;
  showMeasurements = false;
  showPlot = false;

  contextmenu = false;
  contextmenuX = 0;
  contextmenuY = 0;
  contextMenuMarker = {};
  contextMenuOptions = [];

  mapCluster = {};

  siteShapes: any = [];
  taskShapes: any = [];

  filtering = false;
  filters: any = [];

  filterTypes = [
    {
      name: "Tasks",
      id: 1,
    },
    {
      name: "Issues",
      id: 2,
    },
    {
      name: "Sites",
      id: 3,
    }
  ];

  display = {
    siteMarkers: true,
    issueMarkers: true,
    taskMarkers: false,
    taskRecordMarkers: false,
    siteShapes: false,
    taskShapes: false,
  };

  clonedData: any = [];

  drawingManager: any;
  isDrawing = false;
  ruler = {
    selected: false,
    measuringDistance: false,
    length,
  };
  measureTool: any;
  oms: any;
  measureCard = {
    show: false,
    firstText: "",
    secondText: "",
  };
  readOnly = false;
  shape: any = {};
  plotToDelete = {
    show: false,
    shape: this.shape,
    marker: false,
  };
  drawingEvent: any;
  siteTreeChangedOnce = false;

  @ViewChild("mapWindow", {static: true})
  mapWindow: ElementRef;
  @ViewChild("downloadLink")
  downloadLink: ElementRef;

  constructor(
    private readonly mapService: MapService,
    private readonly activatedRoute: ActivatedRoute,
    public modal: MatDialog,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    super();
    this.getMetaData();

    //Ease of access
    this.readOnly = this.authService.securityObject.readOnly;
    // Need this set before we plot the markers
    this.getSessionLayers();
    this.geoCoder = new google.maps.Geocoder();
  }

  //#region NG Implemented Methods
  ngOnInit() {
    this.initMap();
    if (!this.isStatic) {
      this.getData().then(() => {
        setTimeout(() => this.plotFromProp(), 500)
      });
    } else {
      if (!this.id) {
        if (this.latitude !== 52.9) {
          new google.maps.Marker({
            position: {lat: +this.latitude, lng: +this.longitude},
            map: this.map,
            zIndex: 0,
            icon: this.getMarkerIcon({markerDtoType: this.staticType}),
          });
        }
      } else {
        this.getData(null, this.id);
      }
    }
  }

  ngOnDestroy() {
    this.subscriptions = new Subscription();
    sessionStorage.setItem(
      "layerFilters",
      `${this.display.siteMarkers},${this.display.taskMarkers},${this.display.issueMarkers},
      ${this.display.siteShapes},${this.display.taskShapes}`
    );
    sessionStorage.setItem("showingAllLabels", `${this.showingAllLabels}`);
  }

  search() {
    this.searchResults = [];
    if (!this.searchString) {
      if (this.display.siteMarkers) {
        for (let i = 0; i < this.mapCluster["site"].markers_.length; i++) {
          this.mapCluster["site"].markers_[i]
            .setVisible(true)
        }
        this.mapCluster["site"].repaint();
      }
      if (this.display.issueMarkers) {
        for (let i = 0; i < this.mapCluster["issue"].markers_.length; i++) {
          this.mapCluster["issue"].markers_[i]
            .setVisible(true)
        }
        this.mapCluster["issue"].repaint();
      }
      if (this.display.taskMarkers) {
        for (let i = 0; i < this.mapCluster["task"].markers_.length; i++) {
          this.mapCluster["task"].markers_[i]
            .setVisible(true)
        }
        this.mapCluster["task"].repaint();
      }
    } else {
      if (this.display.siteMarkers) {
        for (let i = 0; i < this.mapCluster["site"].markers_.length; i++) {
          if (this.mapCluster["site"].markers_[i].title.toLowerCase().includes(this.searchString.toLowerCase())) {
            this.searchResults.push(this.mapCluster["site"].markers_[i]);
            this.mapCluster["site"].markers_[i]
              .setVisible(true);
          } else {
            this.mapCluster["site"].markers_[i]
              .setVisible(false);
          }
        }
        this.mapCluster["site"].repaint();
      }
      if (this.display.issueMarkers) {
        for (let i = 0; i < this.mapCluster["issue"].markers_.length; i++) {
          if (this.mapCluster["issue"].markers_[i].title.toLowerCase().includes(this.searchString.toLowerCase())) {
            this.searchResults.push(this.mapCluster["issue"].markers_[i]);
            this.mapCluster["issue"].markers_[i]
              .setVisible(true);
          } else {
            this.mapCluster["issue"].markers_[i]
              .setVisible(false);
          }
        }
        this.mapCluster["issue"].repaint();
      }
      if (this.display.taskMarkers) {
        for (let i = 0; i < this.mapCluster["task"].markers_.length; i++) {
          if (this.mapCluster["task"].markers_[i].title.toLowerCase().includes(this.searchString.toLowerCase())) {
            this.searchResults.push(this.mapCluster["task"].markers_[i]);
            this.mapCluster["task"].markers_[i]
              .setVisible(true);
          } else {
            this.mapCluster["task"].markers_[i]
              .setVisible(false);
          }
        }
        this.mapCluster["task"].repaint();
      }
    }
  }

  //#endregion

  private plotFromProp(): void {
    // Used RXJS Take 1 as we only use once.
    this.activatedRoute.paramMap.pipe(take(1))
      .subscribe((s) => {
        let id = "";
        let type = "";
        let parentId = "";

        if (s.get("type")) {
          type = s.get("type");
          id = s.get("id");
          parentId = s.get("parentId");
          if (id) {
            if (parentId) {
              this.plotById(type, id, parentId);
            } else {
              this.plotById(type, id);
            }
            this.plotTool.openPropModal.id = id;
            this.plotTool.openPropModal.type = type;
          }
        }
      });
  }

  private initMap(): void {
    this.map = new google.maps.Map(this.mapWindow.nativeElement, {
      center: {
        lat: parseFloat(this.latitude),
        lng: parseFloat(this.longitude),
      },
      mapTypeId: "roadmap",
      mapId: "1ad4231ecf55963f",
      mapTypeControl: true,
      rotateControl: true,
      streetViewControl: !this.isStatic,
      zoom: this.zoom,
      zoomControl: true,
      fullscreenControl: false,
    });
    this.initDrawingManager(this.map);
    this.measureTool = new MeasureTool(this.map, {contextMenu: false});
    this.map.addListener("click", (e) => this.mapClick(e, null, false));
  }

  private addMarkersToMap(markers: string | any[]) {
    let groupMarkers = [];
    for (let i = 0; i < markers.length; i++) {
      const newMarker = this.createMarker(markers[i]);
      groupMarkers.push(newMarker);
    }

    if (markers.length > 0) {
      this.mapCluster[MapComponent.getMarkerType(markers[0])] =
        new MarkerClusterer(this.map, groupMarkers, {
          imagePath: this.getClusterIcon(markers[0]),
          ignoreHidden: true,
          maxZoom: 20,
        });
    }
    return groupMarkers;
  }

  private createMarker(marker): google.maps.Marker {
    const newMarker = new google.maps.Marker({
      position: {lat: +marker.latitude, lng: +marker.longitude},
      title: marker.label,
      icon: this.getMarkerIcon(marker),
      zIndex: 0,
      visible: this.getVisibleStatus(marker),
    });

    const infoWindow = new google.maps.InfoWindow({
      disableAutoPan: true,
      zIndex: 5,
      content: `<b class='text-dark'>${marker.label}</b>`
    });
    newMarker.addListener("click", (e: any) => {
      this.mapClick(e, marker, true);
      infoWindow.open(this.map, newMarker);
    });
    newMarker.addListener("showAllLabels", () =>
      infoWindow.open(this.map, newMarker)
    );
    newMarker.addListener("closeAllLabels", () =>
      infoWindow.close()
    );
    newMarker.addListener("dblclick", () =>
      this.openRespectiveModal(marker.markerDtoType, marker.id)
    );
    newMarker.addListener("rightclick", (e: any) => {
        console.log("right - click");
        this.rightClickedMarker(marker, e);
      }
    );
    return newMarker;
  }

  private addShapesToMap(shapes: any[], color: string): any[] {
    return shapes.map((shape: any) => {
      return this.createShape(shape, color);
    });
  }

  private createShape(shape: any, color: string) {
    switch (shape.plottedAreaType) {
      case 1:
      case 2:
        const newPolyline = new google.maps.Polyline({
          path: shape.plottedArea,
          strokeColor: color,
          strokeOpacity: 1.0,
          strokeWeight: 5,
          map: this.map,
          visible:
            color === "yellow"
              ? this.display.siteShapes
              : this.display.taskShapes,
        });
        newPolyline.addListener("click", (e: any) =>
          this.mapClick(e, shape, false)
        );
        newPolyline.set("id", shape.id);
        return newPolyline;
      case 3:
        const newPolygon = new google.maps.Polygon({
          paths: shape.plottedArea,
          strokeColor: color,
          strokeOpacity: 1.0,
          strokeWeight: 5,
          fillColor: color,
          fillOpacity: 0.1,
          map: this.map,
          visible:
            color === "yellow"
              ? this.display.siteShapes
              : this.display.taskShapes,
        });
        newPolygon.addListener("click", (e: any) =>
          this.mapClick(e, shape, false)
        );
        newPolygon.set("id", shape.id);
        return newPolygon;
      case 4:
        const newCircle = new google.maps.Circle({
          radius: shape.radius,
          strokeColor: color,
          center: shape.centerPoint.lat
            ? new google.maps.LatLng(
              parseFloat(shape.centerPoint.lat),
              parseFloat(shape.centerPoint.lng)
            )
            : new google.maps.LatLng(
              shape.plottedArea[0].lat,
              shape.plottedArea[0].lng
            ),
          strokeOpacity: 1.0,
          strokeWeight: 5,
          map: this.map,
          fillColor: color,
          fillOpacity: 0.1,
          visible:
            color === "yellow"
              ? this.display.siteShapes
              : this.display.taskShapes,
        });
        newCircle.addListener("click", (e: any) =>
          this.mapClick(e, shape, false)
        );
        newCircle.set("id", shape.id);
        return newCircle;
    }
  }

  private getSessionLayers(): void {
    const sessionLayers = sessionStorage.getItem("layerFilters")
      ? sessionStorage.getItem("layerFilters").split(",")
      : [];

    this.display.siteMarkers = sessionLayers[0]
      ? sessionLayers[0] == "true"
      : true;
    this.display.taskMarkers = sessionLayers[1]
      ? sessionLayers[1] == "true"
      : true;
    this.display.issueMarkers = sessionLayers[2]
      ? sessionLayers[2] == "true"
      : false;
    this.display.siteShapes = sessionLayers[3]
      ? sessionLayers[3] == "true"
      : false;
    this.display.taskShapes = sessionLayers[4]
      ? sessionLayers[4] == "true"
      : false;
    this.showingAllLabels =
      sessionStorage.getItem("showingAllLabels") == "true";
    this.showingAllLabels
      ? (this.loadingText = "Loading Map and Labels...")
      : "";
  }

  private getData(center?, id?): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (id) {
        this.remoteService
          .getBy("map", "id", id)
          .subscribe((response) => {
            if (response) {
              this.data = response;
              this.clonedData = JSON.parse(JSON.stringify(response));
              this.display.siteShapes = true;
              this.display.taskShapes = true;
              this.display.issueMarkers = true;
              this.display.taskMarkers = true;
              this.display.siteMarkers = true;
              this.actionPlotMap(center);
              setTimeout(() => {
                this.showMap = true;

                this.loadingText = "Loading...";
                resolve();
              }, 500);
            } else reject(() => console.log("Getting data failed"));
          });
        resolve();
      } else {
        this.remoteService
          .getData("map")
          .subscribe((response) => {
            if (response) {
              this.data = response;
              this.clonedData = JSON.parse(JSON.stringify(response));
              this.actionPlotMap(center);
              setTimeout(() => {
                this.showMap = true;
                this.loadingText = "Loading...";
                resolve();
              }, 500);
            } else reject(() => console.log("Getting data failed"));
          });
        resolve();
      }
    });
  }


  private actionPlotMap(center?) {
    if (this.mapCluster["site"]) {
      this.mapCluster["site"].clearMarkers();
    }
    if (this.data.siteMarkers) {
      this.addMarkersToMap(this.data.siteMarkers);
    }
    if (this.mapCluster["task"]) {
      this.mapCluster["task"].clearMarkers();
    }
    if (this.data.taskMarkers) {
      this.addMarkersToMap(this.data.taskMarkers);
    }
    if (this.mapCluster["issue"]) {
      this.mapCluster["issue"].clearMarkers();
    }
    if (this.data.issueMarkers) {
      this.addMarkersToMap(this.data.issueMarkers);
    }
    if (this.siteShapes) {
      for (let i = 0; i < this.siteShapes.length; i++) {
        if (this.siteShapes[i]) {
          this.siteShapes[i].setMap(null);
        }
      }
    }
    if (this.taskShapes) {
      for (let i = 0; i < this.taskShapes.length; i++) {
        if (this.taskShapes[i]) {
          this.taskShapes[i].setMap(null);
        }
      }
    }

    this.fixShapesData().then(() => {
      this.siteShapes = this.addShapesToMap(this.siteShapes, "yellow");
      this.taskShapes = this.addShapesToMap(this.taskShapes, "blue");
    });

    if (center == undefined) {
      this.getCenterPoint();
    }
  }

  private fixShapesData(): Promise<void> {
    return new Promise<void>((resolve) => {
      const processMarker = function (
        context: any,
        marker: { plottedArea: any; plottedAreaType: number; radius: any }
      ) {
        if (marker.plottedArea) {
          if (marker.plottedAreaType == 4) {
            const circle = MapComponent.parseCircle(marker);
            marker.plottedArea = circle.plottedArea;
            marker.radius = circle.radius;
          } else {
            marker.plottedArea = context.parsePlottedArea(
              marker.plottedArea
            );
          }
          return marker;
        }
      };

      if (this.data.siteMarkers && this.data.siteMarkers.length > 0) {
        this.siteShapes = this.data.siteMarkers.filter((m: any) =>
          processMarker(this, m)
        );
      } else {
        for (let i = 0; i < this.siteShapes.length; i++) {
          this.siteShapes[i].setMap(null);
        }
      }

      if (this.data.taskMarkers && this.data.taskMarkers.length > 0) {
        this.taskShapes = this.data.taskMarkers.filter((m: any) =>
          processMarker(this, m)
        );
      } else {
        for (let i = 0; i < this.taskShapes.length; i++) {
          this.taskShapes[i].setMap(null);
        }
      }
      resolve();
    });
  }

  private getCoords(): Promise<any[]> {
    return new Promise((resolve => {
      let coords = [];
      const addToCoords = function (coords: any[], arr: string | any[]): void {
        for (let i = 0; i < arr.length; i++) {
          coords.push({
            latitude: arr[i].latitude,
            longitude: arr[i].longitude,
          });
        }
        resolve(coords);
      };
      if (this.data.siteMarkers && this.data.siteMarkers.length > 0) {
        addToCoords(coords, this.data.siteMarkers);
      } else if (this.data.taskMarkers && this.data.taskMarkers.length > 0) {
        addToCoords(coords, this.data.taskMarkers);
      } else if (this.data.issueMarkers && this.data.issueMarkers.length > 0) {
        addToCoords(coords, this.data.issueMarkers);
      } else {
        resolve(coords);
      }
    }))
  }

  private getCenterPoint(): void {
    this.getCoords().then((coords) => {
      if (coords && coords.length > 0) {
        if (!this.isStatic) {
          const averageGeolocation = this.averageGeolocation(coords);
          this.parsedLatitude = parseFloat(averageGeolocation.latitude);
          this.parsedLongitude = parseFloat(averageGeolocation.longitude);
        }
        if (this.parsedLongitude) {
          this.map.setCenter(new google.maps.LatLng(this.parsedLatitude, this.parsedLongitude));
        }
        this.changeDetectorRef.markForCheck();
      } else {
        this.setCurrentLocation();
      }
      if (this.isStatic) {
        this.zoom = 19;
      }
    })
  }

  toggleLayers(type: any): void {
    let resetLabels = false;

    if (this.showingAllLabels) {
      this.toggleLabels();
      resetLabels = true;
      this.changeDetectorRef.markForCheck();
    }

    switch (type) {
      case 1:
        if (this.data.siteMarkers) {
          this.display.siteMarkers = !this.display.siteMarkers;
          this.toggleCluster("site", this.display.siteMarkers).then(() => {
            this.toggleLabel("site", resetLabels && this.display.siteMarkers);
          });
        }
        break;
      case 2:
        if (this.data.taskMarkers) {
          this.display.taskMarkers = !this.display.taskMarkers;
          this.toggleCluster("task", this.display.taskMarkers).then(() => {
            this.toggleLabel("task", resetLabels && this.display.taskMarkers);
          });
        }
        break;
      case 3:
        if (this.data.issueMarkers) {
          this.display.issueMarkers = !this.display.issueMarkers;
          this.toggleCluster("issue", this.display.issueMarkers).then(() => {
            this.toggleLabel(
              "issue",
              resetLabels && this.display.issueMarkers
            );
          });
        }
        break;
      case 4:
        this.display.siteShapes = !this.display.siteShapes;
        this.siteShapes.forEach((m) => m.setVisible(this.display.siteShapes));
        break;
      case 5:
        this.display.taskShapes = !this.display.taskShapes;
        this.taskShapes.forEach((m) => m.setVisible(this.display.taskShapes));
        break;
    }

    if (resetLabels) {
      this.toggleLabels();
      this.changeDetectorRef.markForCheck();
    }
  }

  private toggleCluster(name: string, value: boolean): Promise<void> {
    return new Promise((resolve) => {
      if (this.mapCluster[name] && this.mapCluster[name].markers_) {
        for (let i = 0; i < this.mapCluster[name].markers_.length; i++) {
          this.mapCluster[name].markers_[i].setVisible(value);
        }
        this.mapCluster[name].repaint();
        resolve();
      }
    });
  }

  // FILTER
  addFilter() {
    this.filters.push({
      filterType: 0,
      filterMethods: [],
      filterMethod: "",
      filters: [],
      filter: "",
    });
  }

  removeFilter(index: any) {
    this.filters.splice(index, 1);
    for (let i = 0; i < this.filters.length; i++) {
      this.updateFilterMethods(this.filters[i].filterType, i);
      this.updateFilterOptions(this.filters[i].filterMethod, i);
    }
  }

  updateFilterMethods(filterType: any, index: number) {
    this.filters[index].filterMethods = [];
    this.filters[index].filterMethod = "";
    this.filters[index].filters = [];
    this.filters[index].filter = "";

    if (filterType) {
      const methods = this.mapService.getFilterMethods(filterType);
      var selectedMethods = this.filters.map(
        (f: { filterMethod: any }) => f.filterMethod
      );

      methods.map((m) => {
        if (!selectedMethods.includes(m.filter)) {
          this.filters[index].filterMethods.push(m);
        }
      });
    }
    this.filterData();
  }

  updateFilterOptions(filterMethod: string, index: number) {
    this.filters[index].filters = [];
    this.filters[index].filter = "";
    // this.data = JSON.parse(JSON.stringify(this.clonedData));

    let data: any = [];
    var result = [];
    switch (this.filters[index].filterType) {
      case 1:
        data = this.data.taskMarkers;
        break;
      case 2:
        data = this.data.issueMarkers;
        break;
      case 3:
        data = this.data.siteMarkers;
        break;
    }

    data.filter((c: { [x: string]: any }) => {
      Object.keys(c).forEach((e) => {
        if (e === filterMethod && !result.includes(c[e])) {
          result.push(c[e]);
        }
      });
    });
    this.filters[index].filters = result;
    this.filterData();
  }

  filterData() {
    this.filtering = true;
    const filterClusterArr = function (context, arr, name) {
      if (context.mapCluster[name].markers_) {
        for (let a = 0; a < context.mapCluster[name].markers_.length; a++) {
          const itemIndex = arr.findIndex(
            (m) => m.label == context.mapCluster[name].markers_[a].title
          );
          // && m.latitude == context.mapCluster[name].markers_[a].position.lat().toString());
          context.mapCluster[name].markers_[a].setVisible(itemIndex !== -1);
        }
        context.mapCluster[name].repaint();
      }
    };

    this.data = JSON.parse(JSON.stringify(this.clonedData));
    // this.taskShapes = JSON.parse(JSON.stringify(this.clonedTaskShapes));
    // this.siteShapes = JSON.parse(JSON.stringify(this.clonedSiteShapes));
    var markerData = [];

    // resets the clusters
    if (this.display.taskMarkers) {
      filterClusterArr(this, this.data.taskMarkers, "task");
    }

    if (this.display.siteMarkers) {
      filterClusterArr(this, this.data.siteMarkers, "site");
    }

    if (this.display.issueMarkers) {
      filterClusterArr(this, this.data.issueMarkers, "issue");
    }

    for (var i = 0; i < this.filters.length; i++) {
      switch (this.filters[i].filterType) {
        case 1:
          markerData = this.data.taskMarkers;
          break;
        case 2:
          markerData = this.data.issueMarkers;
          break;
        case 3:
          markerData = this.data.siteMarkers;
          break;
      }

      var result = [];

      markerData = markerData.filter((c) => {
        Object.keys(c).forEach((e) => {
          if (
            c[e] != null &&
            this.filters[i].filter &&
            c[e].toString().includes(this.filters[i].filter) &&
            !result.includes(c[e])
          ) {
            result.push(c);
          }
        });
      });

      if (
        this.filters[i].filter &&
        this.filters[i].filterType == 1 &&
        this.display.taskMarkers
      ) {
        this.data.taskMarkers = result;
        filterClusterArr(this, this.data.taskMarkers, "task");
      } else if (
        this.filters[i].filter &&
        this.filters[i].filterType == 3 &&
        this.display.siteMarkers
      ) {
        this.data.siteMarkers = result;
        filterClusterArr(this, this.data.siteMarkers, "site");
      } else if (this.filters[i].filter && this.display.issueMarkers) {
        this.data.issueMarkers = result;
        filterClusterArr(this, this.data.issueMarkers, "issue");
      }
      this.filters[i].count = result.length;
    }
  }

  //PLOTTING
  //Get plot info by id
  plotById(type: string, id: string, parentId?: string) {
    const getEntity = function (context, name, id) {
      if (parentId) {
        context.remoteService
          .getBy("site", "id", parentId)
          .subscribe((s: { latitude: number; longitude: number }) => {
            if (s.latitude) {
              context.map.setCenter(
                new google.maps.LatLng(s.latitude, s.longitude)
              );
              context.map.setZoom(20);
            }
          });
      } else {
        context.remoteService
          .getBy(name, "id", id)
          .subscribe((s: { latitude: number; longitude: number }) => {
            if (s.latitude) {
              context.map.setCenter(
                new google.maps.LatLng(s.latitude, s.longitude)
              );
              context.map.setZoom(20);
            }
          });
      }
    };

    this.showPlotMessage();

    this.plotTool.issue = false;
    switch (type) {
      case "siteId":
      case "site":
        if (!this.display.siteMarkers) {
          this.display.siteMarkers = true;
          this.toggleCluster("site", true);
        }
        this.display.siteShapes = true;
        this.remoteService.getBy("site", "id", id).subscribe((site) => {
          site.replace = this.matchedId(
            this.data.siteMarkers,
            site.id
          );
          this.plotMarker(site, "yellow", 2);
          getEntity(this, "site", id);
        });
        break;
      case "issueId":
      case "issue":
        if (!this.display.issueMarkers) {
          this.display.issueMarkers = true;
          this.toggleCluster("issue", true);
        }
        this.plotTool.issue = true;
        this.remoteService.getBy("issue", "id", id).subscribe((issue) => {
            issue.replace = this.matchedId(
              this.data.issueMarkers,
              issue.id
            );
            this.plotMarker(issue, "red", 3);
            getEntity(this, "issue", id);
          }
        )
        break;
      case "taskId":
      case "task":
        if (!this.display.taskMarkers) {
          this.display.taskMarkers = true;
          this.toggleCluster("task", true);
        }

        this.remoteService.getBy("task", "id", id).subscribe((task) => {
          task.replace = this.matchedId(
            this.data.taskMarkers,
            task.id
          );
          this.plotMarker(task, "blue", 1);
          getEntity(this, "task", id);
        });

        break;
    }
    this.showPlot = true;
    this.changeDetectorRef.markForCheck();
  }

  private toggleLabel(name: string, showLabels: boolean) {
    if (this.mapCluster[name] && this.mapCluster[name].markers_) {
      for (let i = 0; i < this.mapCluster[name].markers_.length; i++) {
        google.maps.event.trigger(
          this.mapCluster[name].markers_[i],
          showLabels ? "showAllLabels" : "closeAllLabels"
        );
      }
    }
  }

  toggleLabels(): void {
    this.showingAllLabels = !this.showingAllLabels;

    if (this.display.siteMarkers) {
      this.toggleLabel("site", this.showingAllLabels);
    }
    if (this.display.issueMarkers) {
      this.toggleLabel("issue", this.showingAllLabels);
    }
  }

  clickedMarker(infoWindow: any) {
    if (this.openWindow) {
      this.openWindow.close();
    }
    this.openWindow = infoWindow;
  }

  onRightClick(eventArgs: { clientX: number; clientY: number }) {
    if (this.contextmenu) {
      this.contextmenuX = eventArgs.clientX;
      this.contextmenuY = eventArgs.clientY;
    }
  }

  rightClickedMarker(
    marker: any,
    eventArgs: { pixel: { x: number; y: number } }
  ) {
    this.setContextMenu(marker);
    this.contextmenu = true;
    this.contextmenuX = eventArgs.pixel.x + window.innerWidth / 2.33;
    this.contextmenuY = eventArgs.pixel.y + window.innerHeight / 2.33;
    this.changeDetectorRef.markForCheck();
  }

  setContextMenu(marker: any) {
    this.contextMenuOptions = [
      {
        title:
          (marker.markerDtoType === 1
            ? "Task"
            : marker.markerDtoType === 2
              ? "Site"
              : "Issue") + " Properties",
        disabled: false,
        action: () => {
          this.plotTool.openPropModal = marker;
          this.plotTool.openPropModal.type =
            marker.markerDtoType === 1
              ? "task"
              : marker.markerDtoType === 2
                ? "site"
                : "issue";
          this.openPropModal();
        },
        multipleSelect: false,
        group: 1,
      },
    ];
  }

  contextMenuClick(event: { call: { action: () => void } }) {
    event.call.action();
    this.changeDetectorRef.markForCheck();
  }

  openRespectiveModal(type: any, id: any) {
    if (!this.isStatic) {
      switch (type) {
        case 1:
          this.openModal.next(new ModalRequest({
            id: id,
            requestContext: this,
            modalType: ModalType.Task,
            autoSave: true
          }))
          break;
        case 2:
          this.openModal.next(new ModalRequest({
            id: id,
            requestContext: this,
            modalType: ModalType.Site,
            autoSave: true
          }))
          break;
        case 3:
          this.openModal.next(new ModalRequest({
            id: id,
            requestContext: this,
            modalType: ModalType.Issue,
            autoSave: true
          }))
          break;
      }
    }
  }

  private parsePlottedArea(plottedArea: any) {
    if (Array.isArray(plottedArea)) {
      return plottedArea[0];
    }
    if (typeof plottedArea === "object" && plottedArea !== null) {
      return plottedArea;
    }

    plottedArea = plottedArea.split(";");
    plottedArea = plottedArea.map((latLng: any) => {
      latLng = latLng.split(",");
      latLng = {
        lat: parseFloat(latLng[0]),
        lng: parseFloat(latLng[1]),
      };
      return latLng;
    });
    return plottedArea;
  }

  private static parseCircle(marker: { plottedArea: string }) {
    const circle = {plottedArea: [], radius: 0};
    const section = marker.plottedArea.split(";");
    const latLng = section[0].split(",");
    circle.plottedArea[0] = {
      lat: parseFloat(latLng[0]),
      lng: parseFloat(latLng[1]),
    };
    circle.radius = parseFloat(section[1]);
    return circle;
  }

  private static deg2Rad(deg: number): number {
    return deg * (Math.PI / 180);
  }

  takeScreenshot() {
    const ref = this.modal.open(ConfirmModalComponent, {
      data: {
        type: "no-btn",
        body: ["Screenshot saving..."],
      },
      panelClass: "confirm-dialog-container",
      disableClose: true,
    });
    ref.afterOpened().subscribe(() => {
      // Fix issue capture google map
      const mapNode = document.querySelectorAll(
        ".gm-style>div:nth-child(2)>div:nth-child(2)>div>div:last-child>div"
      );
      mapNode.forEach((m) => {
        let n = m.querySelector('.gm-style-iw-c');
        n.setAttribute(
          "style",
          `left:-15px;top:-55px; transform:none; position:absolute; z-index:9999; box-shadow:none`
        );
      });

      const map = document
        .getElementById("map") as HTMLElement;
      html2canvas(map, {
        useCORS: true,
        allowTaint: false,
        ignoreElements: (node) => {
          return node.nodeName === "IFRAME";
        },
      }).then((canvas) => {
        this.downloadLink.nativeElement.href = canvas.toDataURL("image/png");
        this.downloadLink.nativeElement.download = "pssu-map.png";
        this.modal.closeAll();
        this.downloadLink.nativeElement.click();
      });
    });
  }

  //DRAWING
  initDrawingManager(map: any): void {
    const options: DrawingManagerOptions = {
      drawingControl: false,
      drawingControlOptions: {
        drawingModes: [OverlayType.MARKER, OverlayType.POLYGON],
      },
      polygonOptions: {
        draggable: false,
        editable: true,
      },
    };
    this.drawingManager = new google.maps.drawing.DrawingManager(options);
    this.drawingManager.setMap(map);
  }

  showSnackbar(message: string) {
    this.baseService.openSnackBar(message);
  }

  getMarkerToPlot(): void {
    if (this.plotTool.plotting) {
      this.showPlot = true;
    }
    //QUICK REPLOT MARKER
    else if (this.shape && this.shape.markerDtoType) {
      this.plotById(MapComponent.getMarkerType(this.shape), this.shape.id);
    } else {
      this.openModal.next(new ModalRequest({
        requestContext: this,
        modalType: ModalType.PlotMap,
        afterSave: this.processPlotRequest
      }));
    }
  }

  processPlotRequest(context, response, dialogRef) {
    if (response) {
      if (response.issue) {
        context.remoteService.getBy("issue", "id", response.issue.id)
          .subscribe((response) => {
            if (response.site) {
              context.remoteService.getBy("site", "id", response.site.id)
                .subscribe((s) => {
                  if (s.latitude) {
                    context.map.setCenter(
                      new google.maps.LatLng(s.latitude, s.longitude)
                    );
                    context.map.setZoom(20);
                    context.plotMarker(response, "red", 3);
                  }
                });
            } else {
              context.plotMarker(response, "red", 3);
            }
          })
      } else {
        context.remoteService.getBy("site", "id", response.site.id)
          .subscribe((response) => {
            context.plotMarker(response, "yellow", 2);
          })
      }
    }
    dialogRef.close();
  }

  private showPlotMessage() {
    setTimeout(() => {
      this.showSnackbar(
        "Click the map to Plot or select one of the other plotting options"
      );
    }, 1750);
  }

  selectTool(tool?: string) {
    if (!tool) {
      this.showPlot = false;
      this.plotTool.tool = "";
      this.plotTool.color = "";
      this.plotTool.plotting = false;
      this.drawingManager.setOptions({
        drawingMode: google.maps.drawing.OverlayType["null"],
      });
      this.plotTool.openPropModal.id = "";
      this.plotTool.openPropModal.type = "";
      this.shape = undefined;
      this.plotToDelete = {
        shape: null,
        marker: false,
        show: false,
      };
      setTimeout(() => {
        google.maps.event.clearListeners(
          this.drawingManager,
          "overlaycomplete"
        );
      }, 200);
    } else {
      this.plotTool.tool = tool;
      this.drawingManager.setOptions({
        drawingMode: google.maps.drawing.OverlayType[`${tool}`],
      });
    }
  }

  private addMarkerToCluster(value): void {
    this.mapCluster[MapComponent.getMarkerType(value)].markers_.push(
      this.createMarker(value)
    );
    this.mapCluster[MapComponent.getMarkerType(value)].repaint();
  }

  private plotMarker(value: any, colour: string, type: number): void {
    this.plotTool.tool = "MARKER";
    this.plotTool.plotting = true;
    this.showPlot = true;
    this.plotTool.color = colour;
    this.drawingManager.setOptions({
      drawingMode: google.maps.drawing.OverlayType[`MARKER`],
      markerOptions: this.overlayOptions(colour),
      polygonOptions: this.overlayOptions(colour),
      circleOptions: this.overlayOptions(colour),
      polylineOptions: this.overlayOptions(colour),
    });
    google.maps.event.addListener(
      this.drawingManager,
      "overlaycomplete",
      (event: any) => {
        // this.drawingEvent = event;
        const shape = this.manageData(event);
        shape["markerDtoType"] = type;
        this.saveNewPlot(event, value, shape);
      }
    );
  }

  overlayOptions: any = (color: string) => ({
    fillColor: color,
    strokeColor: color,
    strokeWeight: 10,
    clickable: true,
    editable: true,
    zIndex: 1,
    icon:
      color === "yellow"
        ? this.siteMarker
        : color === "blue"
          ? this.taskMarker
          : this.issueMarker,
  });

  manageData(event: {
    type: any;
    overlay: {
      getPosition: () => any;
      getCenter: () => any;
      getRadius: () => any;
      getPath: () => any;
    };
  }) {
    let path: {
      lat: () => any;
      lng: () => any;
      forEach: (arg0: (xy: any, i: any) => void) => void;
    };
    const shape: any = {};
    switch (event.type) {
      case "marker":
        shape.shapeType = event.type;
        path = event.overlay.getPosition();
        shape.lat = path.lat();
        shape.lng = path.lng();
        return shape;

      case "circle":
        shape.shapeType = event.type;
        shape.path = [];
        shape.plottedAreaLatLong = [
          event.overlay.getCenter().lat(),
          event.overlay.getCenter().lng(),
        ];
        shape.radius = event.overlay.getRadius();
        shape.centerPoint = {
          lat: event.overlay.getCenter().lat().toString(),
          lng: event.overlay.getCenter().lng().toString(),
        };
        //Making a path for the Circle (There isn't one on Google API)
        for (var i = 0; i < 512; i++) {
          shape.path.push(
            google.maps.geometry.spherical.computeOffset(
              event.overlay.getCenter(),
              shape.radius,
              (i * 360) / 512
            )
          );
        }
        shape.plottedAreaType = 4;
        shape.measuredDistances = MapComponent.calculateMeasurements(
          shape.path,
          shape.radius
        );
        return shape;

      default:
        shape.shapeType = event.type;
        shape.plottedAreaLatLong = [];
        path = event.overlay.getPath();
        path.forEach((xy: { lat: () => any; lng: () => any }, i: any) => {
          shape.plottedAreaLatLong.push({lat: xy.lat(), lng: xy.lng()});
        });
        shape.shapeType == "polyline"
          ? (shape.plottedAreaType = 2)
          : (shape.plottedAreaType = 3);
        shape.measuredDistances = MapComponent.calculateMeasurements(path);
        shape.centerPoint = MapComponent.calculateCenter(
          shape.plottedAreaLatLong
        );
        return shape;
    }
  }

  //TODO Save New Plot
  saveNewPlot(
    event: { overlay: { setMap: (arg0: any) => void } },
    value: any,
    shape: any
  ) {
    const originalShape = JSON.parse(JSON.stringify(shape));

    let dialogRef: MatDialogRef<ConfirmModalComponent, any>;

    if (shape.plottedAreaType) {
      dialogRef = this.modal.open(ConfirmModalComponent, {
        data: {
          type: "confirm",
          body: value.replace && value.replace.area
            ? [`${value.name} already has an area plot.\n Would you like to replace it?`]
            : [`Would you like to save this new plot for ${value.name}?`,]
        },
        panelClass: "confirm-dialog-container",
        disableClose: true,
      });
    } else {
      dialogRef = this.modal.open(ConfirmModalComponent, {
        data: {
          type: "confirm",
          body: value.replace && value.replace.marker
            ? [`${value.name ? value.name : "Issue " + value.issueNumber} already has a marker plot.\n Would you like to replace it?`]
            : [`Would you like to save this new marker for ${value.name ? value.name : "Issue " + value.issueNumber}?`],
        },
        panelClass: "confirm-dialog-container",
        disableClose: true,
      });
    }

    dialogRef.afterClosed().subscribe((res: any) => {
      if (res) {
        if (shape.plottedAreaType) {
          if (shape.plottedAreaType === 4) {
            shape.plottedAreaLatLong =
              shape.plottedAreaLatLong[0] +
              "," +
              shape.plottedAreaLatLong[1] +
              ";" +
              shape.radius;
          } else {
            shape.plottedAreaLatLong = shape.plottedAreaLatLong
              .reduce(
                (acc: string, c: { lat: string; lng: string }) =>
                  acc + (c.lat + "," + c.lng + ";"),
                ""
              )
              .slice(0, -1);
          }
          let val = {
            id: value.id,
            parentId: value.type ? value.type.id : value.siteId,
            plottedArea: shape.plottedAreaLatLong,
            plottedAreaType: shape.plottedAreaType,
            markerDtoType: shape.markerDtoType,
            measuredDistances: shape.measuredDistances,
            centerPoint: shape.centerPoint,
            radius: 0,
          };

          this.remoteService.update("map", null, val).subscribe(
            () => {
              event.overlay.setMap(null);
              this.plotToDelete.shape = {id: value.id};
              this.deleteArea();
              val.plottedArea = originalShape.plottedAreaLatLong;
              if (originalShape.radius) {
                val.radius = originalShape.radius;
              }

              if (value.taskCategoryName) {
                this.taskShapes.push(this.createShape(val, "blue"));
              } else {
                this.siteShapes.push(this.createShape(val, "yellow"));
              }
              this.selectTool();
              if (this.plotTool.openPropModal) {
                this.openPropModal();
              }
              this.changeDetectorRef.markForCheck();
            },
            (error) => {
              this.baseService.openSnackBar(
                `Error - Unable to update Area (${error.error})`,
                null,
                true
              );
            }
          );
        } else {
          this.remoteService
            .update(
              "map", null, {
                id: value.id,
                parentId: value.type ? value.type.id : value.siteId,
                latitude: shape.lat.toFixed(15),
                longitude: shape.lng.toFixed(15),
                markerDtoType: shape.markerDtoType,
              }
            )
            .subscribe(
              () => {
                value.latitude = shape.lat;
                value.longitude = shape.lng;
                value.label = value.name;
                value.markerDtoType = shape.markerDtoType;
                this.plotToDelete.shape = value;
                event.overlay.setMap(null);
                this.deleteMarker();
                if (this.plotTool.openPropModal) {
                  this.openPropModal();
                }
                this.addMarkerToCluster(value);
              },
              (error) => {
                this.baseService.openSnackBar(
                  `Error - Unable to update Plot (${error.error})`,
                  null,
                  true
                );
              }
            );
        }

        this.plotTool.plotting = false;
        this.showPlot = false;
        this.plotTool.color = "";
        this.drawingManager.setOptions({
          drawingMode: google.maps.drawing.OverlayType[""],
        });
        this.shape = undefined;
        this.plotToDelete = {
          shape: null,
          marker: false,
          show: false,
        };
        google.maps.event.clearListeners(
          this.drawingManager,
          "overlaycomplete"
        );
      } else {
        this.selectTool();
        event.overlay.setMap(null);
        return;
      }
    });
  }

  private openPropModal(): void {
    switch (this.plotTool.openPropModal.type) {
      case "siteId":
      case "site":
        this.openModal.next(new ModalRequest({
          id: this.plotTool.openPropModal.id,
          requestContext: this,
          modalType: ModalType.Site,
          autoSave: true
        }))
        break;
      case "taskId":
      case "task":
        this.openModal.next(new ModalRequest({
          id: this.plotTool.openPropModal.id,
          requestContext: this,
          modalType: ModalType.Task,
          autoSave: true
        }))
        break;
      case "issueId":
      case "issue":
        this.openModal.next(new ModalRequest({
          id: this.plotTool.openPropModal.id,
          requestContext: this,
          modalType: ModalType.Issue,
          autoSave: true
        }))
        break;
    }
    this.plotTool.openPropModal.type
      ? this.showSnackbar("Opening Properties")
      : "";
    this.plotTool.openPropModal.id = "";
    this.plotTool.openPropModal.type = "";
  }

  //#region Measurement

  public startMeasurement(): void {
    this.ruler.selected = true;
    this.showMeasurements = true;
  }

  public endMeasurement(): void {
    this.ruler.measuringDistance = false;
    this.ruler.selected = false;
    this.showMeasurements = false;
    this.measureCard = {
      firstText: "",
      show: false,
      secondText: "",
    };
    this.measureTool.end();
  }

  //#endregion

  mapClick(
    event: google.maps.MapMouseEvent | google.maps.IconMouseEvent,
    shape: any,
    marker: boolean
  ) {
    //Handler for any click on the map or figure
    //MEASURING
    if (this.ruler.selected) {
      if (shape && !this.ruler.measuringDistance && !marker) {
        if (shape.measuredDistances) {
          let measurements = shape.measuredDistances.split(";");
          this.measureCard.firstText = measurements[0];
          this.measureCard.secondText = measurements[1];
          this.measureCard.show = true;
          this.changeDetectorRef.markForCheck();
        }
        //Measuring shape
        else {
          let perimeter: number;
          let area: any;
          switch (shape.plottedAreaType) {
            case 1:
            case 2:
              const polyline = new google.maps.Polyline({
                path: shape.plottedArea,
              });
              perimeter =
                google.maps.geometry.spherical.computeLength(
                  polyline.getPath()
                );
              this.measureCard.firstText = `Perimeter: ${perimeter.toFixed(2)} m`;
              this.measureCard.secondText = "";
              this.measureCard.show = true;
              break;

            case 3:
              const polygon = new google.maps.Polygon({
                paths: shape.plottedArea,
              });
              perimeter =
                google.maps.geometry.spherical.computeLength(
                  polygon.getPath()
                );
              area = google.maps.geometry.spherical.computeArea(
                polygon.getPath()
              );
              this.measureCard.firstText = `Perimeter: ${(perimeter).toFixed(2)} m`;
              this.measureCard.secondText = `Area: ${area.toFixed(2)} m²`;
              this.measureCard.show = true;
              break;

            case 4:
              const circle = new google.maps.Circle({
                center: shape.plottedArea[0],
                radius: shape.radius,
              });
              const path = [];
              for (var i = 0; i < 512; i++) {
                path.push(
                  google.maps.geometry.spherical.computeOffset(
                    circle.getCenter(),
                    shape.radius,
                    (i * 360) / 512
                  )
                );
              }
              perimeter = (2 * shape.radius * Math.PI);
              area = google.maps.geometry.spherical.computeArea(path);
              this.measureCard.firstText = `Perimeter: ${perimeter.toFixed(2)} m`;
              this.measureCard.secondText = `Area: ${area.toFixed(2)} m²`;
              this.measureCard.show = true;
              break;
          }
          this.changeDetectorRef.markForCheck();
        }
      } else {
        //Measuring new distance
        if (!this.ruler.measuringDistance) {
          this.ruler.measuringDistance = true;
          marker
            ? this.measureTool.start([
              {lat: event.latLng.lat(), lng: event.latLng.lng()},
            ])
            : this.measureTool.start([
              {lat: event.latLng.lat(), lng: event.latLng.lng()},
            ]);
          this.measureTool.addListener("measure_change", () => {
            this.measureCard.firstText = `Total distance: ${this.measureTool.lengthText}`;
            this.changeDetectorRef.markForCheck();
          });
          this.measureCard.secondText = "";
        } else if (shape && this.ruler.measuringDistance && marker) {
          //Add markers on measuring new
          //The measureTool NPM doesn't have a proper way to add a node (This might break in the future)
          this.measureTool._geometry.addNode([
            event.latLng.lng(),
            event.latLng.lat(),
          ]);
          this.measureTool._onDrawOverlay();
        } else if (shape && this.ruler.measuringDistance && !!event.latLng) {
          this.measureTool._geometry.addNode([
            event.latLng.lng(),
            event.latLng.lat(),
          ]);
          this.measureTool._onDrawOverlay();
        }
      }
      return;
    }

    // Set/unset shape when clicked on marker or area
    if (!this.ruler.selected) {
      if (marker || shape) {
        this.plotToDelete = {
          shape: shape,
          marker,
          show: true,
        };
        this.shape = shape;
      } else {
        //Resetting plot to delete
        this.plotToDelete = {
          shape: null,
          marker: false,
          show: false,
        };
      }
    }

    if (this.contextmenu) {
      this.contextmenu = false;
    }
    this.changeDetectorRef.markForCheck();
  }

  getWhat3Words() {

  }

  //#region Delete

  deletePlot() {
    let shape = this.plotToDelete.shape;
    let id = shape.id;
    if (!this.plotToDelete.marker) {
      const dialogRef = this.modal.open(ConfirmModalComponent, {
        data: {
          type: "confirm",
          body: [`Are you sure you want to delete this area for ${shape.label}?`],
        },
        panelClass: "confirm-dialog-container",
        disableClose: true,
      });
      dialogRef.afterClosed().subscribe((res) => {
        if (res) {
          this.delete(id, "area");
        }
      });
    } else {
      const dialogRef = this.modal.open(ConfirmModalComponent, {
        data: {
          type: "confirm",
          body: [`Are you sure you want to delete this marker for ${shape.label}?`],
        },
        panelClass: "confirm-dialog-container",
        disableClose: true,
      });
      dialogRef.afterClosed().subscribe((res) => {
        if (res) {
          this.delete(id, "marker");
        }
      });
    }
  }

  private delete(id: any, type: string): void {
    this.remoteService.delete(`map/${type}`, id).subscribe(() => {
      if (type === "marker") {
        this.deleteMarker();
      } else {
        this.deleteArea();
      }
      this.plotToDelete.shape = "";
      this.plotToDelete.show = false;
      this.shape = null;
    });
  }

  private deleteMarker(): void {
    // func searches markercluster array, if found will removeMarker and return true
    const action = function (context, name): boolean {
      let arr = context.mapCluster[name].markers_.filter(
        (f) => f.title == context.plotToDelete.shape.label
      );
      if (arr.length < 1) {
        return false;
      }
      if (arr.length == 1) {
        arr[0].setMap(null);
        return context.mapCluster[name].removeMarker(arr[0]);
      }

      arr = arr.filter(
        (f) => f.position.lat() == context.plotToDelete.shape.latitude
      );
      arr[0].setMap(null);
      return context.mapCluster[name].removeMarker(arr[0]);
    };
    if (!action(this, "site")) {
      if (!action(this, "task")) {
        if (!action(this, "issue")) {
          // We only want to getData if we 100% need to.
          this.getData(false);
        }
      }
    }
  }

  private deleteArea(): void {
    let shapeToDelete = this.siteShapes.find(
      (f) => f.id == this.plotToDelete.shape.id
    );
    if (!shapeToDelete) {
      shapeToDelete = this.taskShapes.find(
        (f) => f.id == this.plotToDelete.shape.id
      );
    }
    if (shapeToDelete) {
      shapeToDelete.setMap(null);
    }
  }

  //#endregion

  //#region Private Methods

  private setCurrentLocation(): void {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.parsedLatitude = position.coords.latitude;
        this.parsedLongitude = position.coords.longitude;
        this.zoom = 8;
        this.getAddress(this.latitude, this.longitude);
      });
    }
  }

  private getAddress(latitude: number, longitude: number): void {
    this.geoCoder.geocode(
      {location: {lat: latitude, lng: longitude}},
      (results: { formatted_address: string }[], status: string) => {
        if (status === "OK") {
          if (results[0]) {
            this.zoom = 12;
            this.address = results[0].formatted_address;
          }
        }
      }
    );
  }

  private static calculateCenter(points: any[]): {} {
    let longitudes = points.map((i: { lng: any }) => i.lng);
    let latitudes = points.map((i: { lat: any }) => i.lat);

    latitudes.sort();
    longitudes.sort();

    let lowX = latitudes[0];
    let highX = latitudes[latitudes.length - 1];
    let lowy = longitudes[0];
    let highy = longitudes[longitudes.length - 1];

    let centerX = lowX + (highX - lowX) / 2;
    let centerY = lowy + (highy - lowy) / 2;

    return {lat: centerX.toString(), lng: centerY.toString()};
  }

  private static calculateMeasurements(path: any, radius?: number) {
    const area = google.maps.geometry.spherical.computeArea(path);
    const perimeter = radius
      ? (2 * radius * Math.PI)
      : google.maps.geometry.spherical.computeLength(path);
    return `Perimeter: ${perimeter.toFixed(2)} m; Area: ${area.toFixed(2)} m²`;
  }

  private getMetaData(): void {
    const requiredTypes = [
      MetaDataTypes.Sites,
      MetaDataTypes.IssueStatus
    ];
    this.metaDataService
      .getMetaData(requiredTypes)
      .subscribe((response) => (this.metaData = response));
  }

  private getMarkerIcon(marker: { markerDtoType: any }): string {
    switch (marker.markerDtoType) {
      case 1:
        return this.taskMarker;
      case 2:
        return this.siteMarker;
      case 3:
        return this.issueMarker;
    }
  }

  private getClusterIcon(marker: { markerDtoType: any }): string {
    switch (marker.markerDtoType) {
      case 1:
        return this.taskCluster;
      case 2:
        return this.siteCluster;
      case 3:
        return this.issueCluster;
    }
  }

  private static getMarkerType(marker: { markerDtoType: any }): string {
    switch (marker.markerDtoType) {
      case 1:
        return "task";
      case 2:
        return "site";
      case 3:
        return "issue";
    }
  }

  private getVisibleStatus(marker: { markerDtoType: any }): boolean {
    switch (marker.markerDtoType) {
      case 1:
        return this.display.taskMarkers;
      case 2:
        return this.display.siteMarkers;
      case 3:
        return this.display.issueMarkers;
    }
  }

  private averageGeolocation(coords) {
    if (coords.length === 1) {
      return coords[0];
    }

    let x = 0.0;
    let y = 0.0;
    let z = 0.0;

    for (let coord of coords) {
      const latitude = (coord.latitude * Math.PI) / 180;
      const longitude = (coord.longitude * Math.PI) / 180;

      x += Math.cos(latitude) * Math.cos(longitude);
      y += Math.cos(latitude) * Math.sin(longitude);
      z += Math.sin(latitude);
    }

    const total = coords.length;

    x = x / total;
    y = y / total;
    z = z / total;

    const centralLongitude = Math.atan2(y, x);
    const centralSquareRoot = Math.sqrt(x * x + y * y);
    const centralLatitude = Math.atan2(z, centralSquareRoot);

    return {
      latitude: (centralLatitude * 180) / Math.PI,
      longitude: (centralLongitude * 180) / Math.PI,
    };
  }

  private matchedId(arr, id) {
    const markersSameId = arr.filter((c) => c.id === id);
    let replace = {
      marker: false,
      area: false,
    };
    if (markersSameId.length > 0) {
      markersSameId.forEach((el) => {
        el.latitude ? (replace.marker = true) : "";
        el.plottedArea ? (replace.area = true) : "";
      });
    }
    console.log(replace);
    return replace;
  }

  //#endregion
}
