<div class="row h-100">
  <div class="col-sm-5 d-flex flex-column">
    <div class="card p-2">
      <span class="title">Add:</span>
      <mat-form-field class="w-100">
        <mat-label class="primary">Name</mat-label>
        <input matInput [(ngModel)]="newDocument.name" />
      </mat-form-field>
      <mat-form-field *ngIf="fileAdded" class="w-100">
        <mat-label class="primary">File Attached</mat-label>
        <input matInput [value]="newDocument.fileName" disabled />
      </mat-form-field>
      <mat-form-field *ngIf="!fileAdded" class="w-100">
        <mat-label class="primary">URL</mat-label>
        <input matInput [(ngModel)]="newDocument.url" />
      </mat-form-field>
      <div class="w-100 position-relative">
        <button mat-flat-button class="inside-modal-btn me-1">
          Browse<input
            class="position-absolute browse-btn hide-input"
            #file
            (change)="addFile(file.files)"
            type="file"
          />
        </button>

        <button
          mat-flat-button
          class="float-right px-4 me-1 inside-modal-btn"
          [disabled]="
            !canAdd || !newDocument.name || (!fileAdded && !newDocument.url)
          "
          (click)="save(newDocument)"
        >
          Save
        </button>
      </div>
    </div>
    <div class="card p-2 mt-3 flex-grow-1">
      <span class="title">Existing Links:</span>
      <mat-divider *ngIf="documents.length > 0" class="my-2"></mat-divider>
      <div *ngFor="let document of documents; let i = index">
        <div class="w-100 banner" *ngIf="document.isShared">
          Shared Document
        </div>
        <div class="">
          <label *ngIf="!document.isEditing" class="d-flex" mat-line>
            <span class="">{{ document.name }}</span>
            <span (click)="delete(i)" *ngIf="!document.isShared" class="ms-auto">
              <i class="far fa-times-circle"></i>
            </span>
          </label>
        </div>
        <mat-form-field matLine *ngIf="document.isEditing" class="w-100">
          <mat-label class="primary">Name</mat-label>
          <input matInput [(ngModel)]="document.name" />
        </mat-form-field>
        <div class="d-flex justify-content-between">
          <button
            mat-flat-button
            class="w-33"
            [disabled]="!document.url.includes('pdf')"
            (click)="set(document)"
            class="inside-modal-btn"
            [ngClass]="{
              ' bg-primary text-white': document.url === activeFile.url
            }"
          >
            Preview
          </button>
          <button
            mat-flat-button
            [disabled]="document.isShared"
            class="mx-1 inside-modal-btn"
            (click)="edit(document)"
          >
            {{ document.isEditing ? "Save" : "Edit" }}
          </button>
          <button
            mat-flat-button
            class="inside-modal-btn"
            (click)="download(document.url)"
          >
            View
          </button>
        </div>
        <mat-divider class="my-2"></mat-divider>
      </div>
    </div>
  </div>
  <div class="col-sm-7">
    <div class="card h-100 d-flex">
      <span class="p-2">PDF Preview:</span>
      <div class="card h-100">
        <pdf-viewer
          *ngIf="activeFile"
          [src]="activeFile"
          [original-size]="false"
        >
        </pdf-viewer>
      </div>
    </div>
  </div>
</div>
