<div class="equip-service-requirement-modal">
  <div class="d-flex m-0 position-relative h-100">
    <div class="corner-close" (click)="close(form)">
      <i class="fal fa-times fa-2x"></i>
    </div>
    <form
      class="bg-modal px-4 d-flex w-100"
      [formGroup]="form"
      data-lpignore="true"
      autocomplete="off"
      *ngIf="loaded; else loading"
      (input)="check(form)"
    >
      <content class="d-flex flex-column justify-content-between w-100">
        <!-- #region Header -->
        <header class="d-flex primary pt-3">
          <div>
            <h2 class="m-0">
              Equipment Service Requirement
            </h2>
          </div>
        </header>
        <!-- #endregion -->
        <!-- #region Main -->
        <main class="flex-grow-1">
          <div class="h-100">
            <div class="card p-2 d-block h-100">
              <mat-form-field class="w-100">
                <mat-label class="primary">Service Type</mat-label>
                <mat-select
                  *ngIf="metaData"
                  formControlName="equipmentServiceType"
                  [compareWith]="compareOverride"
                >
                  <mat-option
                    *ngFor="
                          let type of metaData.equipmentServiceTypes
                            | archivedFilter : serviceRequirement.equipmentServiceType
                        "
                    [value]="type"
                  >
                    {{ type.name }}
                  </mat-option>
                </mat-select>
                <mat-error
                  *ngIf="
                        form.get('equipmentServiceType').hasError('required') &&
                        form.get('equipmentServiceType').touched
                      "
                >
                  <span>Service Type Required.</span>
                </mat-error>
              </mat-form-field>
              <mat-form-field class="w-100">
                <mat-label class="primary">Equipment</mat-label>
                <mat-select
                  *ngIf="metaData"
                  formControlName="equipment"
                  [compareWith]="compareOverride"
                >
                  <mat-option
                    *ngFor="
                          let e of metaData.equipment

                            | archivedFilter : serviceRequirement.equipment
                        "
                    [value]="e"
                  >
                    {{ e.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>


              <mat-form-field class="w-100">
                <mat-label class="primary">Required Date</mat-label>
                <input
                  matInput
                  [matDatepicker]="pickerRequiredDate"
                  formControlName="requiredDate"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="pickerRequiredDate"
                ></mat-datepicker-toggle>
                <mat-datepicker #pickerRequiredDate></mat-datepicker>
                <mat-error
                  *ngIf="
                        form.get('requiredDate').hasError &&
                        form.get('requiredDate').touched
                      "
                >
                  Required Date is Required.
                </mat-error>
              </mat-form-field>
              <mat-form-field class="w-100">
                <mat-label class="primary">Required Hours</mat-label>
                <input matInput type="int" formControlName="requiredHours"/>
              </mat-form-field>
              <mat-form-field class="w-100">
                <mat-label class="primary">Required Miles</mat-label>
                <input matInput type="int" formControlName="requiredMiles"/>
              </mat-form-field>
            </div>
          </div>
        </main>
        <!-- #endregion -->
        <!-- #region Footer -->
        <footer
          class="flex justify-content-end my-3"
          [ngClass]="serviceRequirement.isArchived ? 'pb-4' : ''"
        >
          <div>
            <button
              mat-flat-button
              name="detailSaveButton"
              class="ms-2 px-4"
              [disabled]="form.invalid || !form.dirty || !hasClaim"
              (click)="save()"
            >
              Save
            </button>
            <button mat-flat-button class="ms-2 px-4" (click)="close(form)">
              Close
            </button>
          </div>
        </footer>
        <!-- #endregion -->
      </content>
    </form>
    <ng-template #loading>
      <app-loading
        class="w-100 d-flex align-items-center justify-content-center bg-modal"
      ></app-loading>
    </ng-template>
  </div>
</div>

