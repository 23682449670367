import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from "@angular/forms";
import {BaseComponent} from "../../base/base.component";

@Component({
  selector: 'app-custom-fields',
  templateUrl: './custom-fields.component.html'
})
export class CustomFieldsComponent extends BaseComponent implements OnInit {

  form: UntypedFormGroup;

  @Input()
  entity;

  @Input()
  parentForm;

  @Input()
  fields: any[];

  ngOnInit(): void {
    this.createCustomForm().then(() => {
      this.form.valueChanges.subscribe(() => {
        this.parentForm.markAsDirty();
      });
    })
  }

  onChanges(): void {
    this.entity.customFields = this.form.getRawValue();
    for(var i = 0; i < this.entity.customFields.length; i++) {
      this.entity.customFields[i] = this.entity.customFields[i].toString();
    }
  }

  createCustomForm() {
    return new Promise<void>((resolve) => {
      const group = {};
      this.fields.forEach((field) => {
        group[field.field] = new UntypedFormControl(this.entity[field.field]);
        group[field.field].valueChanges.subscribe((value) => {
          this.entity[field.field] = value;
        });
      });
      this.form = new UntypedFormGroup(group);
      resolve();
    });
  }
}
