import { Component, EventEmitter, Inject, OnInit, Output } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Claims } from "src/app/auth/models/claims";
import { BaseModalComponent } from "src/app/shared/base/base-modal.component";

@Component({
  selector: "app-equipment-check-record-modal",
  templateUrl: "./equipment-check-record-modal.component.html",
  styles: []
})
export class EquipmentCheckRecordModalComponent
  extends BaseModalComponent
  implements OnInit
{
  @Output()
  onSave = new EventEmitter();

  formFields = [
    { name: "equipmentName" },
    { name: "tempDate" },
    { name: "createdByName" },
    { name: "checkResultName" },
    { name: "reportedHours" },
    { name: "reportedMileage" },
    { name: "note" }
  ];
  allNames = ["Equipment Check Record Photos"];
  nav = [
    { name: "Check Record Details", page: "details", disabled: false },
    { name: "Photos", page: "photos" },
    { name: "Map", page: "map" },
    { name: "History", page: "auditTrail", disabled: false, hidden: this.isNew }
  ];
  current = this.nav[0];

  entity = <any>{};
  hasClaim = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<Component>
  ) {
    super();
  }

  ngOnInit(): void {
    this.entity = this.data.data;
    this.entity.tempDate = this.entity.created;
    this.createForm(this.formFields, this.entity).then((form) => {
      this.form = form;
      this.form.disable();
      this.form.controls.note.enable();
      //Check Claims
      !this.authService.hasClaim(Claims.equipmentEdit)
        ? this.form.disable()
        : (this.hasClaim = true);
    });
  }

  ngAfterViewInit() {
    this.entity.parsedLatitude =
      this.entity.latitude !== null ? parseFloat(this.entity.latitude) : null;
    this.entity.parsedLongitude =
      this.entity.longitude !== null ? parseFloat(this.entity.longitude) : null;
  }

  changeSection(item) {
    if (item.page === "auditTrail") {
      let parameter = item.page !== "auditTrail" ? "id" : null;
      if (!this.entity[item.page]) {
        this.loaded = false;
        this.remoteService
          .getBy(
            `equipmentCheckRecord/${item.page}`,
            parameter,
            this.data.data.id
          )
          .subscribe(
            (res) => {
              this.entity[item.page] = res;
              this.loaded = true;
            },
            () => {
              this.loaded = true;
            }
          );
      }
    }
    this.current = item;
  }

  trackImage($event) {
    this.entity.images.push({
      id: $event
    });
  }

  checkImages($event) {
    if (!this.entity.images) {
      this.entity.images = [];
    }
    this.entity.images.push($event);
    this.form.markAsDirty();
  }

  save() {
    this.entity = { ...this.entity, ...this.form.getRawValue() };
    this.onSave.emit(this.entity);
  }

  close(form) {
    this.closeModal(form, this.dialogRef);
  }
}
