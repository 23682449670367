import {
  Component,
  EventEmitter,
  Inject,
  Output,
  ViewChild
} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Subject} from "rxjs";
import {BaseModalComponent} from "src/app/shared/base/base-modal.component";
import {
  checksColumns,
  inspectionsColumns,
  maintenanceColumns,
  serviceRequirementColumns
} from "./equipment-modal.columns";
import {ModalRequest} from "../../shared/classes/modal.request";
import {ModalType} from "../../shared/enums/modal-fields.enum";
import {GridComponent} from "../../shared/grid/grid.component";
import {Claims} from "src/app/auth/models/claims";
import {ViewCategories} from "../../shared/enums/view-categories.enum";
import {customFields} from "../../config/config-component/config.component.object";
import {take} from "rxjs/operators";

@Component({
  selector: "app-equipment-modal",
  templateUrl: "./equipment-modal.component.html"
})
export class EquipmentModalComponent extends BaseModalComponent {
  @Output()
  onSave = new EventEmitter();

  updateTable = new Subject();
  equipment = <any>{};
  hasClaim = false;
  allNames = ["Equipment Photos"];
  getNav = () => [
    {name: "Equipment Details", page: "details"},
    {name: "Custom Fields", page: "customFields", hidden: !this.showCustomFields},
    {name: "Maintenance Records", page: "maintenanceRecords", hidden: this.isNew},
    {name: "Check Records", page: "checkRecords", hidden: this.isNew},
    {name: "Inspection Records", page: "inspectionRecords", hidden: this.isNew},
    {name: "Service Requirements", page: "serviceRequirements", hidden: this.isNew},
    {name: "Notes", page: "notes"},
    {name: "Photos", page: "photos"},
    {name: "Links & Files", page: "linksAndFiles"},
    {name: "History", page: "auditTrail", hidden: this.isNew}
  ];
  maintenanceColumns = maintenanceColumns;
  checksColumns = checksColumns;
  inspectionsColumns = inspectionsColumns;
  serviceRequirementColumns = serviceRequirementColumns;

  @ViewChild(GridComponent, {static: false})
  private maintenanceRecordsGridComponent: GridComponent;

  @ViewChild(GridComponent, {static: false})
  private checkRecordsGridComponent: GridComponent;

  @ViewChild(GridComponent, {static: false})
  private inspectionRecordsGridComponent: GridComponent;

  @ViewChild(GridComponent, {static: false})
  private serviceRequirementsGridComponent: GridComponent;

  addEquipmentServiceRequirementButtons = this.authService.hasClaim(
    Claims.equipmentEdit
  ) && [
    {
      name: "Add Service Requirement",
      action: this.addEquipmentServiceRequirement
    }
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<Component>
  ) {
    super();
    this.isNew = !data.data.id;
    this.fields = data.fields;
    this.metaData = data.metaData;

    this.remoteService.getFields(ViewCategories.Equipment)
      .pipe(take(1))
      .subscribe((response) => {
        this.customFields = response;
        this.showCustomFields = this.customFields.length > 0;

        this.nav = this.getNav();
        this.current = this.nav[0];

        if (!this.isNew) {
          this.equipment = data.data;
          this.createForm(this.fields, this.equipment).then((form) => {
            this.form = form;


            if (this.equipment.flaggedForReview) {
              this.form.controls["equipmentStatus"].setValue(null);
            }

            if (this.equipment.blockNextServiceDate) {
              this.form.controls["lastServiceDate"].disable();
              this.form.controls["nextServiceDueDate"].disable();
            }

            this.form.controls["value"].setValue(
              this.form.controls["value"].value.toFixed(2),
              {emitEvent: false}
            );
            this.form.controls["value"].valueChanges.subscribe(
              (v) => (v = parseFloat(v).toFixed(2))
            );
            setTimeout(() => this.form.markAllAsTouched(), 5000);
            //Check Claims
            !this.authService.hasClaim(Claims.equipmentEdit)
              ? this.form.disable()
              : (this.hasClaim = true);
          });
        } else {
          this.createForm(this.fields, this.equipment).then((form) => {
            this.form = form;
            if (this.equipment.blockNextServiceDate) {
              this.form.controls["lastServiceDate"].disable();
              this.form.controls["nextServiceDueDate"].disable();
            }
            setTimeout(() => this.form.markAllAsTouched(), 5000);
            //Check Claims
            !this.authService.hasClaim(Claims.equipmentEdit)
              ? this.form.disable()
              : (this.hasClaim = true);
          });
        }
      });

  }

  changeSection(item) {
    if (
      item.page === "maintenanceRecords" ||
      item.page === "checkRecords" ||
      item.page === "inspectionRecords" ||
      item.page === "serviceRequirements" ||
      item.page === "auditTrail"
    ) {
      let parameter = item.page !== "auditTrail" ? "id" : null;
      // We only want to load each section once.
      if (!this.equipment[item.page]) {
        this.loaded = false;
        this.remoteService
          .getBy(`equipment/${item.page}`, parameter, this.data.data.id)
          .subscribe(
            (res) => {
              this.loaded = true;
              this.equipment[item.page] = res;
            },
            () => {
              this.loaded = true;
            }
          );
      }
    }
    this.current = item;
  }

  trackImage($event) {
    this.equipment.images.push({
      id: $event
    });
  }

  save() {
    this.toggleSave();
    this.equipment = {...this.equipment, ...this.form.getRawValue()};
    this.onSave.emit(this.equipment);
  }

  close(form) {
    this.closeModal(form, this.dialogRef);
  }

  doubleClick(cell) {
    this.openModal.next(
      new ModalRequest({
        id: cell.id.rowID,
        requestContext: this,
        modalType:
          this.current.page == "maintenanceRecords"
            ? ModalType.EquipmentMaintenanceRecord
            : this.current.page == "checkRecords"
              ? ModalType.EquipmentCheckRecord
              : this.current.page == "inspectionRecords"
                ? ModalType.InspectionRecord
                : ModalType.EquipmentServiceRequirement,
        autoSave: false,
        afterSave:
          this.current.page == "maintenanceRecords"
            ? this.saveEquipmentMaintenanceRecord
            : this.current.page == "checkRecords"
              ? this.saveEquipmentCheckRecord
              : this.current.page == "inspectionRecords"
                ? this.saveInspectionRecord
                : this.saveEquipmentServiceRequirement
      })
    );
  }

  addEquipmentServiceRequirement(context) {
    context.equipment = {...context.equipment, ...context.form.getRawValue()};
    context.openModal.next(
      new ModalRequest({
        requestContext: context,
        modalType: ModalType.EquipmentServiceRequirement,
        afterSave: context.saveEquipmentServiceRequirement,
        payload: {equipment: {id: context.equipment.id}}
      })
    );
  }

  saveEquipmentMaintenanceRecord(context, entity, dialogRef) {
    if (!entity.mobileCreated) {
      entity.created = context.newIsoDate();
    }
    context.addOrReplace(context.equipment.maintenanceRecords, entity);
    context.maintenanceRecordsGridComponent.grid.data = [
      ...context.equipment.maintenanceRecords
    ];
    dialogRef.close();
    context.form.markAsDirty();
  }

  saveEquipmentCheckRecord(context, entity, dialogRef) {
    if (entity.recordType.name) {
      entity.recordTypeName = entity.recordType.name;
    }
    if (entity.equipmentStatus.name) {
      entity.equipmentStatusName = entity.equipmentStatus.name;
    }
    if (!entity.created) {
      entity.created = context.newIsoDate();
    }
    context.addOrReplace(context.equipment.checkRecords, entity);
    context.checkRecordsGridComponent.grid.data = [
      ...context.equipment.checkRecords
    ];
    dialogRef.close();
    context.form.markAsDirty();
  }

  saveEquipmentServiceRequirement(context, entity, dialogRef) {
    if (entity.equipmentServiceType.name) {
      entity.equipmentServiceTypeName = entity.equipmentServiceType.name;
    }
    context.addOrReplace(context.equipment.serviceRequirements, entity);
    context.serviceRequirementsGridComponent.grid.data = [
      ...context.equipment.serviceRequirements
    ];
    dialogRef.close();
    context.form.markAsDirty();
  }

  saveInspectionRecord(context, entity, dialogRef) {
    context.addOrReplace(context.equipment.inspectionRecords, entity);
    context.inspectionRecordsGridComponent.grid.data = [
      ...context.equipment.inspectionRecords
    ];
    dialogRef.close();
    context.markAsDirty(context);
  }

  checkImages($event) {
    if (!this.equipment.images) {
      this.equipment.images = [];
    }
    this.equipment.images.push($event);
    this.form.markAsDirty();
  }

  checkDocuments($event) {
    if (!this.equipment.documents) {
      this.equipment.documents = [];
    }
    this.equipment.documents.push($event);
    this.form.markAsDirty();
  }
}
