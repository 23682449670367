<div class="login-outer">
  <div class="login-middle">
    <div class="login-inner card h-auto">
      <content>
        <header>
          <h4 class="col-sm-12 text-center mt-4 mb-1">Select a Database</h4>
        </header>
        <main>
          <div *ngIf="databaseList.length > 5" class="row justify-content-center">
            <mat-form-field class="offset-s col-sm-4">
              <input matInput placeholder="Search..." (keyup)="applyFilter($event)" />
            </mat-form-field>
          </div>
          <div class="col-sm-12">
            <mat-selection-list class="col-sm-12 text-center px-5" [multiple]="false" [(ngModel)]="selectedDatabase" (ngModelChange)="selected()">
              <mat-list-option role="li" *ngFor="let database of databaseList | listFilter : searchInput  | sort : 'name'" [value]="database">
                {{database.name}}
              </mat-list-option>
            </mat-selection-list>
          </div>
        </main>
        <footer>
          <div class="col-sm-12 text-center">
            <button mat-flat-button class="text-center border my-2" (click)="cancel()">Cancel</button>
          </div>
        </footer>
      </content>
    </div>
  </div>
</div>

