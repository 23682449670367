<div class="d-flex overflow-hidden sd-modal-size flex-column bg-modal">
  <span
    class="text-center text-light font-14 bg-primary mb-2 w-100"
    *ngIf="initData.message"
    >{{ initData.message }}</span
  >
  <div class="d-flex flex-grow-1 p-3">
    <edit-form
      class="d-flex flex-column w-100 position-relative"
      [fields]="fields"
      [showUnknown]="showUnknown"
      [data]="data"
      [title]="title"
      [metaData]="metaData"
      [replaceSave]="replaceSave"
      [replaceClose]="replaceClose"
      (onSave)="onSave.emit($event); onClose.emit()"
      (onClose)="onClose.emit()"
    ></edit-form>
  </div>
</div>
