import {Component, ViewEncapsulation} from '@angular/core';
import { Location } from '@angular/common';
import {BaseComponent} from "../../shared/base/base.component";

@Component({
  selector: 'app-login-database',
  templateUrl: './login-database.component.html',
  styleUrls: ['./login-database.component.css'],
})
export class LoginDatabaseComponent extends BaseComponent {

  databaseList;
  selectedDatabase;
  searchInput = "";

  applyFilter(e: { target: { value: string; }; }) {
    this.searchInput = e.target.value.trim().toLowerCase();
  }

  constructor(private _location: Location) {
    super();
    this.databaseList = this.authService.databaseList;
    this.databaseList.map(m => m.name = m.name);

    if (this.databaseList.length === 0) {
      if (this.authService.securityObject.isAuthenticated) {
        this.router.navigate(["home"]);
      } else {
        this.router.navigate(["login"]);
      }
    }
  }

  selected() {
    if (this.selectedDatabase)
      this.authService.selectedDatabase(this.selectedDatabase[0]);
  }

  cancel() {
    this._location.back();
  }
}
