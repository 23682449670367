<div class="equip-maintenance-modal">
  <div class="d-flex m-0 position-relative h-100">
    <div class="p-3 primary">
      <div class="text-center">
        <i class="fal fa-thumbtack fa-3x"></i>
        <H5>Training Record</H5>
      </div>
      <div class="py-5 modal-nav">
        <button
          *ngFor="let item of nav"
          mat-button
          class="btn py-2 d-block w-100"
          [ngClass]="{ 'border-p': current.page == item.page }"
          (click)="changeSection(item)"
          [disabled]="item.disabled"
          [hidden]="item.hidden"
        >
          {{ item.name }}
        </button>
      </div>
    </div>
    <div class="corner-close" (click)="close(form)">
      <i class="fal fa-times fa-2x"></i>
    </div>
    <form
      class="bg-modal px-4 d-flex w-100"
      [formGroup]="form"
      data-lpignore="true"
      autocomplete="off"
      *ngIf="loaded; else loading"
      (input)="check(form)"
    >
      <content class="d-flex flex-column justify-content-between w-100">
        <!-- #region Header -->
        <header class="d-flex primary pt-3">
          <div>
            <h2 class="m-0">
              Training Record
            </h2>
          </div>
        </header>
        <!-- #endregion -->
        <!-- #region Main -->
        <main class="flex-grow-1">
          <div class="h-100" [ngSwitch]="current.page">
            <!-- #region Details -->
            <div *ngSwitchCase="'details'" class="h-100">
              <div class="card p-2 d-block h-100 gap-md-2" >
                <mat-form-field class="w-100">
                  <mat-label class="primary">Person</mat-label>
                  <mat-select
                    formControlName="person"
                    [compareWith]="compareOverride"
                  >
                    <mat-option
                      *ngFor="
                          let category of metaData.people
                            | sort: 'name'
                            | archivedFilter: entity.person ;
                        "
                      [value]="category"
                    >
                      {{ category.name }}
                    </mat-option>
                  </mat-select>
                  <mat-error
                    *ngIf="
                        form.get('person').hasError('required') &&
                        form.get('person').touched
                      "
                  >
                    Person Required.
                  </mat-error>
                </mat-form-field>
                <mat-form-field class="w-100">
                  <mat-label class="primary">Course</mat-label>
                  <mat-select
                    formControlName="course"
                    [compareWith]="compareOverride"
                  >
                    <mat-option
                      *ngFor="
                          let category of metaData.courses
                            | sort: 'name'
                            | archivedFilter: entity.course ;
                        "
                      [value]="category"
                    >
                      {{ category.name }}
                    </mat-option>
                  </mat-select>
                  <mat-error
                    *ngIf="
                        form.get('course').hasError('required') &&
                        form.get('course').touched
                      "
                  >
                    Course Required.
                  </mat-error>
                </mat-form-field>
                <mat-form-field class="w-100">
                <mat-label class="primary">Trainer</mat-label>
                <mat-select
                  formControlName="trainer"
                  [compareWith]="compareOverride"
                >
                  <mat-option
                    *ngFor="
                          let category of metaData.trainers
                            | sort: 'name'
                            | archivedFilter: entity.trainer ;
                        "
                    [value]="category"
                  >
                    {{ category.name }}
                  </mat-option>
                </mat-select>
                <mat-error
                  *ngIf="form.get('trainer').touched"
                >
                  Trainer Required.
                </mat-error>
              </mat-form-field>
                <mat-form-field class="w-100">
                  <mat-label class="primary">Completed Date</mat-label>
                  <input
                    matInput
                    [matDatepicker]="pickerStartDate"
                    formControlName="completedDate"
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="pickerStartDate"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #pickerStartDate></mat-datepicker>
                  <mat-error
                    *ngIf="
                        form.get('completedDate').hasError('required') &&
                        form.get('completedDate').touched
                      "
                  >
                    Completion date required
                  </mat-error>
                </mat-form-field>
                <mat-form-field class="w-100">
                  <mat-label class="primary">Valid Until</mat-label>
                  <input
                    matInput
                    [matDatepicker]="pickerEndDate"
                    formControlName="validUntilDate"
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="pickerEndDate"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #pickerEndDate></mat-datepicker>
                  <mat-error
                    *ngIf="
                        form.get('validUntilDate').hasError('invalid')
                      "
                  >
                    valid until date cannot procede completed date
                  </mat-error>
                </mat-form-field>

              </div>
            </div>
            <!-- #endregion -->
            <!-- #region Photos -->
            <div *ngSwitchCase="'photos'" class="h-100">
              <div class="row h-100">
                <app-photos
                  *ngIf="isNew"
                  [imageArrays]="[entity.images || []]"
                  class="col-12 pb-1 h-100"
                  [arrayNames]="allNames"
                  [isNew]="true"
                  [addPhotos]="hasClaim"
                  (addedImage)="checkImages($event)"
                  (removedImage)="form.markAsDirty()"
                >
                </app-photos>
                <app-photos
                  *ngIf="!isNew"
                  [addPhotos]="hasClaim"
                  class="col-12 pb-1 h-100"
                  [imageArrays]="[entity.images || []]"
                  [arrayNames]="allNames"
                  [entity]="{ type: 'trainingRecord', id: entity.id }"
                  (addedImage)="checkImages($event)"
                  (removedImage)="form.markAsDirty()"
                >
                </app-photos>
              </div>
            </div>
            <!-- #endregion -->
            <!-- #region Links and Files -->
            <div *ngSwitchCase="'linksAndFiles'" class="h-100">
              <app-documents class="documents"
                             [isNew]="isNew"
                             [canAdd]="hasClaim && !entity.isNew"
                             [documents]="entity.documents || []"
                             [trainingRecordId]="entity.id"
                             (addedDocument)="checkDocuments($event)"
                             [form]="form">
              </app-documents>
            </div>
            <!-- #endregion -->
            <!-- #region History / AuditTrail -->
            <div *ngSwitchCase="'auditTrail'">
              <app-audit-trail
                id="auditTrail"
                [auditTrail]="entity.auditTrail"
              ></app-audit-trail>
            </div>
            <!-- #endregion -->
          </div>
        </main>
        <!-- #endregion -->
        <!-- #region Footer -->
        <footer
          class="flex justify-content-end my-3"
          [ngClass]="entity.isArchived ? 'pb-4' : ''"
        >
          <div>
            <button
              mat-flat-button
              name="detailSaveButton"
              class="ms-2 px-4"

              [disabled]="form.invalid"
              (click)="save()"
            >
              Save
            </button>
            <button mat-flat-button class="ms-2 px-4" (click)="close(form)">
              Close
            </button>
          </div>
        </footer>
        <!-- #endregion -->
      </content>
    </form>
    <ng-template #loading>
      <app-loading
        class="w-100 d-flex align-items-center justify-content-center bg-modal"
      ></app-loading>
    </ng-template>
  </div>
</div>

