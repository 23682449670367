import {FieldType, IEditFormField} from "../../shared/components/edit-form/edit-form-model";
import {Regex} from "../../shared/helpers/regex-patterns";

export const tasksFields: IEditFormField[] = [
  {name: "name", label: "Name", type: FieldType.String, required: true},
  {
    name: "taskCategory",
    label: "Task Category",
    type: FieldType.Select,
    required: true
  },
  {
    name: "taskType",
    label: "Type",
    type: FieldType.Select,
    required: true
  },
  {
    name: "site",
    label: "Site",
    type: FieldType.Select
  },
  {
    name: "sites",
    label: "Sites",
    type: FieldType.MultipleSelect
  },
  {
    name: "programs",
    label: "Programs",
    type: FieldType.MultipleSelect
  },
  {name: "taskPriority", label: "Priority", type: FieldType.Select},
  {name: "description", label: "Description", type: FieldType.LargeText},
  {name: "cost", label: "Total Cost", type: FieldType.Decimal, validatorPattern: Regex.decimal},
  {name: "latitude", label: "Latitude", type: FieldType.String},
  {name: "longitude", label: "Longitude", type: FieldType.String},
  {name: "what3Words", label: "What3Words", type: FieldType.String, disabled: true},
  {name: "measuredDistances", label: "Measured Distances", type: FieldType.String},
  {name: "isChargeable", label: "Is Chargeable", type: FieldType.Bool},
  {name: "issueNumber", label: "Issue Number", type: FieldType.String},
  {name: "issueCategoryName", label: "Issue Category", type: FieldType.String},
  {name: "issueTypeName", label: "Issue Type", type: FieldType.String},
  {name: "siteName", label: "Site", type: FieldType.String},
  {name: "issueDescription", label: "Description", type: FieldType.LargeText},
  {name: "issueLatitude", label: "Latitude", type: FieldType.String},
  {name: "issueLongitude", label: "Longitude", type: FieldType.String},
  {name: "riskName", label: "Risk", type: FieldType.String},
  {name: "escalated", label: "Escalated", type: FieldType.Bool},
  {name: "isClosed", label: "Issue Status", type: FieldType.Bool},
  {name: "updateStatus", label: "Review Client Request", type: FieldType.Select, metaDataType: "reviewStatuses"}

];

export const taskRecordFields: IEditFormField[] = [
  {
    name: "taskName",
    label: "Task",
    type: FieldType.String,
    required: true
  },
  {
    name: "taskRecordStatus",
    label: "Task Record Status",
    type: FieldType.Select,
    required: true,
    metaDataType: "taskRecordStatuses"
  },
  {
    name: "site",
    label: "Site",
    type: FieldType.Select
  },
  {
    name: "createdByName",
    label: "Created By",
    type: FieldType.String,
    disabled: true
  },
  {name: "note", label: "Notes", type: FieldType.String},
  {
    name: "mobileCreatedDate",
    label: "Recorded Date",
    type: FieldType.Date,
    required: true
  },
  {
    name: "mobileCreatedTime",
    label: "Recorded Time",
    type: FieldType.Date,
    required: true
  },
  {
    name: "isInvoiced",
    label: "Is Invoiced",
    type: FieldType.Bool
  },
  {
    name: "program",
    label: "Program",
    type: FieldType.Select,
    required: true
  },
];

export const taskCheckRecordFields: IEditFormField[] = [
  {
    name: "taskName",
    label: "Task",
    type: FieldType.String,
    required: true
  },
  {
    name: "taskCheckRecordStatus",
    label: "Task Check Record Status",
    type: FieldType.Select,
    required: true,
    metaDataType: "taskCheckRecordStatuses"
  },
  {
    name: "siteName",
    label: "Site",
    type: FieldType.String,
  },
  {
    name: "createdByName",
    label: "Created By",
    type: FieldType.String,
    disabled: true
  },
  {name: "note", label: "Notes", type: FieldType.String},
  {
    name: "mobileCreatedDate",
    label: "Recorded Date",
    type: FieldType.Date,
    required: true
  },
  {
    name: "mobileCreatedTime",
    label: "Recorded Time",
    type: FieldType.Date,
    required: true
  },
  {
    name: "taskRecordMobileCreatedDate",
    label: "Task Record Date",
    type: FieldType.Date
  },
  {
    name: "taskRecordMobileCreatedTime",
    label: "Task Recorded Time",
    type: FieldType.Date
  },
  {
    name: "programName",
    label: "Program",
    type: FieldType.String,
    required: true
  }
];

export const taskCategoryFields: IEditFormField[] = [
  {
    name: "name",
    label: "Category Name",
    type: FieldType.String,
    required: true
  },
  {name: "description", label: "Description", type: FieldType.LargeText}
];

export const taskTypeFields: IEditFormField[] = [
  {name: "name", label: "Type Name", type: FieldType.String, required: true, validatorPattern: "^.{1,255}$"},
  {name: "description", label: "Description", type: FieldType.LargeText},
  {
    name: "type",
    label: "Task Category",
    type: FieldType.Select,
    metaDataType: "taskCategories",
    required: true
  },
  {name: "isScheduled", label: "Scheduled", type: FieldType.Bool},
  {name: "days", label: "Days",  type: FieldType.MultipleSelect, metaDataType: "days" },
  {name: "timeFrameParam", label: "timeFrameParam", type: FieldType.Select},
  {name: "startDate", label: "Start Date", type:FieldType.Date},
  {name: "endDate", label: "End Date", type:FieldType.Date},
  {name: "timeFrameDays", label: "Time Frame Days", type:FieldType.Int},
  {name: "bufferDays", label: "Buffer Days", type:FieldType.Int},
  {
    name: "periods",
    label: "Periods",
    type: FieldType.MultipleSelect,
    metaDataType: "periods"
  }
];

export const taskGroupFields: IEditFormField[] = [
  {name: "name", label: "Group Name", type: FieldType.String, required: true},
  {name: "description", label: "Description", type: FieldType.LargeText},
  {
    name: "taskCategories",
    label: "Task Category",
    type: FieldType.Select,
    metaDataType: "taskCategories",
    optionAll: true
  },
  {
    name: "taskTypes",
    label: "Task Types",
    type: FieldType.MultiDragNoOrder,
    metaDataType: "taskTypes",
    filter: "taskCategories"
  },
  {
    name: "sites",
    label: "Assigned Sites",
    type: FieldType.MultiDragNoOrder,
    metaDataType: "sites",
    disabled: true
  }
];

export const assignTaskGroupFields: IEditFormField[] = [
  {
    name: "taskGroup",
    label: "Task Group",
    type: FieldType.Select,
    metaDataType: "taskGroups",
    required: true
  },
  {
    name: "sites",
    label: "Site",
    type: FieldType.MultipleSelect,
    metaDataType: "sites",
    required: true
  },
  {
    name: "program",
    label: "Program",
    type: FieldType.Select,
    metaDataType: "programs"
  }
];

export const addTaskGroupFields: IEditFormField[] = [
  {
    name: "taskGroup",
    label: "Task Group",
    type: FieldType.Select,
    metaDataType: "taskGroups",
    required: true
  },
  {
    name: "program",
    label: "Program",
    type: FieldType.Select,
    metaDataType: "programs"
  }
];


export const weatherConditionFields: IEditFormField[] = [
  {
    name: "name",
    label: "Weather Condition Name",
    type: FieldType.String,
    required: true
  },
  {name: "description", label: "Description", type: FieldType.LargeText},
  {
    name: "taskTypes",
    label: "Restricted Task Types",
    type: FieldType.MultipleSelect
  }
];

export const taskQualityCheckRecordFields: IEditFormField[] = [
  {
    name: "taskName",
    label: "Task",
    type: FieldType.String,
    required: true
  },
  {
    name: "rating",
    label: "Rating",
    type: FieldType.Select,
    required: true,
    metaDataType: "ratings"
  },
  {
    name: "siteName",
    label: "Site",
    type: FieldType.String,
  },
  {
    name: "createdByName",
    label: "Created By",
    type: FieldType.String,
    disabled: true
  },
  {name: "note", label: "Notes", type: FieldType.String},
  {
    name: "mobileCreatedDate",
    label: "Recorded Date",
    type: FieldType.Date,
    required: true
  },
  {
    name: "mobileCreatedTime",
    label: "Recorded Time",
    type: FieldType.Date,
    required: true
  }
];
