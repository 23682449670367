
export enum ModalType {

  Site,
  SiteClient,
  SiteCategory,
  SiteQualityCheckRecord,
  NewSiteQualityCheck,
  Area,
  Ward,
  Equipment,
  EquipmentCheckRecord,
  EquipmentServiceRequirement,
  EquipmentServiceType,
  EquipmentMaintenanceRecord,
  EquipmentChecklist,
  EquipmentCategory,
  EquipmentType,
  EquipmentChecklistItem,
  Inspection,
  NewInspection,
  InspectionChecklist,
  InspectionCheckItem,
  InspectionCategory,
  InspectionType,
  InspectionRecord,
  InspectionCheckItemRecord,
  Issue,
  IssueCategory,
  IssueType,
  Risk,
  IssueContact,
  People,
  Team,
  Contractor,
  JobRole,
  MobileJobRole,
  Course,
  CourseType,
  TrainingRecord,
  TrainingRequirement,
  //Same as the Above but opened from Person
  PersonTrainingRecord,
  //Same as the Above but opened from Person
  PersonTrainingRequirement,
  Program,
  ProgramSiteRecord,
  ProgramSiteCheckRecord,
  NewProgramCheck,
  Attendance,
  Attendee,
  AttendeeTimeRecord,
  TimeRecordType,
  Stock,
  StockRequest,
  StockItemRequest,
  StockType,
  StockCategory,
  Task,
  TaskRecord,
  TaskQualityCheckRecord,
  TaskCategory,
  TaskType,
  TaskGroup,
  TaskGroupAssign,
  AddTaskGroup,
  WeatherCondition,
  TaskCheckRecord,
  IssueStatus,
  IssueRisk,
  AddInspection,
  EquipmentStatus,
  AddServiceRequirement,
  AddMultipleServiceRequirements,
  TaskChargeable,
  TaskAddToProgram,
  TaskTypeChange,
  TaskRecordInvoiced,
  TaskRecordStatus,
  UpdateSiteClient,
  UpdateSiteWard,
  UpdateSiteCategory,
  QuickNote,
  Report,
  PlotMap,
  AddToProgram,
  AddToTeam,
  AddToTeams,
  Period,
  Database,
  Account,
  User,
  SupportLink,
  News,
  ProgramSiteRecordStatus,
  IssueEscalatedContact,
  CustomField,
  Document,
  ScheduledStateChange,
  ActiveStateChange,
  StockContact,
  EquipmentContact,
}
