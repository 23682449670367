import {Pipe, PipeTransform} from "@angular/core";

@Pipe({name: "archivedFilter"})
export class ArchivedFilterPipe implements PipeTransform {
  transform(value: any, filter?: { id: string }): any {
    const result = [];
    if (value) {
      if (filter) {
        if (Array.isArray(filter)) {
          value.filter(c => {
            if (!c.isArchived || (c.isArchived && filter.find(f => f.id == c.id))) {
              result.push(c);
            }
          });
        } else {
          value.filter(c => {
            if (!c.isArchived || (c.isArchived && c.id == filter.id)) {
              result.push(c);
            }
          });
        }
      } else {
        value.filter(c => {
          if (!c.isArchived) {
            result.push(c);
          }
        });
      }
      return result;
    }
    return null;
  }
}
