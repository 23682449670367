<content class="bg-modal new-site-quality-check-record-modal position-relative">

  <header class="text-center pt-2 ">
    <h2 *ngIf= "title!='Record Site Quality Check'; else default" class="customH1 ">{{ title }}</h2>
    <ng-template #default><h2> Record Site Quality Check</h2></ng-template>
  </header>
  <main>
    <div class="d-flex flex-column justify-content-between w-100">
      <div class="corner-close " (click)="close(form)">
        <i class="fal fa-times fa-2x"></i>
      </div>
      <div class="h-100 p-2  " [ngSwitch]="page">
        <div *ngSwitchCase="'start'">
          <div class="offset-2 col-8 row">
            <div class="col-12 pb-4">
              <form
                [formGroup]="form"
                data-lpignore="true"
                autocomplete="off"
                *ngIf="loaded"
              >
                <div class="card p-2 mb-2 d-block">
                  <mat-form-field class="w-100">
                    <mat-label class="primary">Site</mat-label>
                    <mat-select
                      formControlName="site"
                      [compareWith]="compareOverride"
                    >
                      <mat-option
                        *ngFor="
                          let s of metaData.sites
                            | sort: 'name'
                            | archivedFilter
                        "
                        [value]="s"
                      >
                        {{ s.name }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <mat-form-field class="w-50">
                    <mat-label class="primary">Recorded Date</mat-label>
                    <input
                      matInput
                      [matDatepicker]="pickerMobileCreatedDate"
                      formControlName="mobileCreatedDate"
                    />
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="pickerMobileCreatedDate"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #pickerMobileCreatedDate></mat-datepicker>
                  </mat-form-field>
                  <mat-form-field class="w-50">
                    <mat-label class="primary">Recorded Time</mat-label>
                    <input
                      matInput
                      type="time"
                      formControlName="mobileCreatedTime"
                    />
                  </mat-form-field>
                </div>
                <div class="card p-2 text-center d-block">
                  <button
                    mat-flat-button
                    [disabled]="!form.controls['site'].value || !hasTasks"
                    name="detailSaveButton"
                    (click)="startSiteQualityCheck()"
                    class="ms-2 px-4 border"
                  >
                    Start Site Quality Check
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="h-100 w-100" *ngSwitchCase="'siteQualityCheck'">
          <inspection
            [checks]="siteQualityCheck.checks"
            [grades]="grades"
            [completeBtnText]="'Complete Site Quality Check'"
            [allowIncomplete]="allowIncomplete"
            (complete)="siteQualityCheckComplete($event)"
          ></inspection>
        </div>
        <div class="h-100 w-100" *ngSwitchCase="'adhocTasks'">
          <h5 class="text-center" *ngIf="!siteQualityCheck.addTasks">
            {{ isClient ? "Request" : "Add" }} any Rectification (Adhoc) Tasks?
          </h5>
          <div class="card row m-4 p-5">
            <div
              class="text-center offset-sm-2 col-sm-8"
              *ngIf="!siteQualityCheck.addTasks"
            >
              <button
                mat-flat-button
                name="detailSaveButton"
                (click)="siteQualityCheck.addTasks = true"
                class="col-sm-5 px-4 m-4 border"
              >
                Add Ad Hoc Tasks
              </button>
              <button
                mat-flat-button
                name="detailSaveButton"
                (click)="save()"
                class="col-sm-7 px-4 m-4 border"
              >
                Submit Site Quality Check
              </button>
            </div>
            <div *ngIf="siteQualityCheck.addTasks">
              <div
                *ngFor="
                  let taskQualityCheck of siteQualityCheck.taskQualityCheckRecords
                    | sort: 'order';
                  let i = index
                "
              >
                <div class="flex column-gap-lg-4">
                <mat-form-field class="w-25">
                  <mat-label class="primary">Check</mat-label>
                  <input matInput disabled [(ngModel)]="taskQualityCheck.name" />
                </mat-form-field>
                <mat-form-field class="w-25">
                  <mat-label>Raise Task</mat-label>
                  <mat-select [(ngModel)]="taskQualityCheck.adhocTaskAdded">
                    <mat-option [value]="bool.id" *ngFor="let bool of bools">
                      {{ bool.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field class="w-25">
                  <mat-label>Program</mat-label>
                  <mat-select
                    [disabled]="isClient || !taskQualityCheck.adhocTaskAdded"
                    [(ngModel)]="taskQualityCheck.program"
                  >
                    <mat-option
                      [value]="program"
                      *ngFor="let program of metaData.programs"
                    >
                      {{ program.name }}
                    </mat-option>
                  </mat-select>

                </mat-form-field>
                <div class="w-25 text-center border rounded py-3 ">
                <small-photos index="i" (addedImage)="checkImages($event, taskQualityCheck )"></small-photos>
                </div>
                </div>

                <mat-form-field class="w-100">
                  <mat-label class="primary">Notes for Task</mat-label>
                  <textarea
                    matInput
                    rows="4"
                    [disabled]="!taskQualityCheck.adhocTaskAdded"
                    [(ngModel)]="taskQualityCheck.taskNote"
                  ></textarea>
                </mat-form-field>

              </div>

            </div>
          </div>
          <footer class="pb-3 text-center">
            <button
              mat-flat-button
              *ngIf="siteQualityCheck.addTasks"
              name="detailSaveButton"
              (click)="save()"
              class="mt-2 px-4 m-4 border"
            >
              Submit Site Quality Check
            </button>
          </footer>
        </div>
      </div>
    </div>
  </main>
</content>
