<content class="main_component">
  <header>
    <mat-toolbar class="grid-toolbar box-shadow bg-sd">
      <div class="d-flex align-items-center w-100 position-relative">
        <div class="count-container">
          <span class="me-3 text-light">
            {{ gridCountText }}
            <span *ngIf="loading && grid.isLoading">
              <i class="fad fa-spinner-third fa-spin"></i>
            </span>
          </span>
        </div>
        <div class="relative-container view-button-container">
          <mat-menu #viewMenu class="px-3">
            <span>Available Views:</span>
            <ul *ngIf="views" class="m-0 mt-3">
              <li *ngFor="let view of views">
                <mat-checkbox class="grid-checkbox" (change)="viewChanged(view)"
                  [checked]="view.viewId == selectedView?.viewId">{{ view.name }}</mat-checkbox>
              </li>
            </ul>
          </mat-menu>
          <button mat-flat-button *ngIf="!small" class="me-4 view-btn toolbar-btn" title="Choose View"
            [matMenuTriggerFor]="viewMenu">
            View: {{ selectedView?.name }}
          </button>
        </div>
        <div class="relative-container view-button-container">
          <button *ngFor="let actionButton of actionBarButtons" [ngClass]="{ disabled: actionButton.disabled }"
            class="toggle-button view-button" mat-flat-button (click)="click(actionButton.action)">
            {{ actionButton.title }}
          </button>
        </div>
        <igx-input-group type="search" *ngIf="!small" class="flex-grow-1 rounded px-3 me-1 search-bar">
          <igx-prefix>
            <igx-icon *ngIf="searchText.length == 0">search</igx-icon>
            <igx-icon *ngIf="searchText.length > 0" (click)="clearSearch()">clear</igx-icon>
          </igx-prefix>

          <input #search1 id="search1" igxInput placeholder="Search" [(ngModel)]="searchText"
            (ngModelChange)="search($event)" (keydown)="searchKeyDown($event)" />

          <igx-suffix *ngIf="searchText.length > 0">
            <div class="resultsText" *ngIf="grid.lastSearchInfo">
              <span *ngIf="grid.lastSearchInfo.matchInfoCache.length > 0">
                {{ grid.lastSearchInfo.matchInfoCache.length }}
                results
              </span>
              <span *ngIf="grid.lastSearchInfo.matchInfoCache.length == 0">
                No results
              </span>
            </div>
          </igx-suffix>
        </igx-input-group>
        <button mat-flat-button *ngIf="!small" class="mx-1 toolbar-btn" [ngClass]="{
            'btn-active':
              grid.filteredData != null ||
              searchText != '' ||
              grid.groupsRecords.length > 0
          }" title="Clear Filters" id="reset" (click)="reset()">
          <i class="far fa-redo fa-lg"></i>
        </button>
        <mat-menu #chooseMenu (closed)="updated.next('')" class="px-3">
          <span>Choose Columns:</span>
          <ul *ngIf="columns" class="m-0 mt-3">
            <li (click)="$event.stopPropagation()" *ngFor="let col of columns | sort: 'header'">
              <mat-checkbox class="grid-checkbox" *ngIf="
                  col.header != 'id' && !col.hidden && col.header != 'Images'
                " (change)="col.hidden = !col.hidden; checkGrid()" [checked]="!col.hidden">{{ col.header
                }}</mat-checkbox>
            </li>
            <li (click)="$event.stopPropagation()" *ngFor="let col of columns | sort: 'header'">
              <mat-checkbox class="grid-checkbox" *ngIf="
                  col.header != 'id' && col.hidden && col.header != 'Images'
                " (change)="col.hidden = !col.hidden; checkGrid()" [checked]="!col.hidden">{{ col.header
                }}</mat-checkbox>
            </li>
          </ul>
        </mat-menu>
        <button mat-flat-button *ngIf="!small" class="mx-1 toolbar-btn" title="Choose Columns"
          [matMenuTriggerFor]="chooseMenu">
          <i class="fas fa-line-columns fa-lg"></i>
        </button>
        <mat-menu #pinMenu (closed)="updated.next('')" class="px-3">

          <span>Pinned Columns:</span>
          <ul *ngIf="columns" class="m-0 mt-3">
            <li (click)="$event.stopPropagation()" *ngFor="let col of columns | sort: 'header'">
              <mat-checkbox class="grid-checkbox" *ngIf="col.hidden != true && col.pinned"
                (change)="col.pinned = !col.pinned; checkGrid();" [checked]="col.pinned">{{ col.header }}</mat-checkbox>
            </li>
            <li (click)="$event.stopPropagation()" *ngFor="let col of columns | sort: 'header'">
              <mat-checkbox class="grid-checkbox" *ngIf="col.hidden != true && !col.pinned"
                (change)="col.pinned = !col.pinned; checkGrid()" [checked]="col.pinned">{{ col.header }}</mat-checkbox>
            </li>
          </ul>
        </mat-menu>
        <span *ngIf="buttons" class="text-end w-100">
          <button *ngFor="let button of buttons" mat-flat-button class="mx-1 toolbar-btn" title="{{ button.name }}"
            (click)="click(button.action)">
            {{ button.name }}
          </button>
        </span>
        <button mat-flat-button *ngIf="!small" class="mx-1 toolbar-btn" title="Pin Columns"
          [matMenuTriggerFor]="pinMenu">
          <i class="fas fa-thumbtack fa-lg"></i>
        </button>
        <mat-menu #exportMenu>
          <button mat-menu-item (click)="exportData()">Excel</button>
          <button mat-menu-item (click)="exportCsv()">CSV</button>
          <button *ngIf="dataExportEnabled" mat-menu-item (click)="exportDataUpdate()">
            Update
          </button>
        </mat-menu>
        <button mat-flat-button [ngClass]="'toolbar-btn ' + (small && !buttons ? 'ms-auto' : 'mx-1')" title="Export"
          [matMenuTriggerFor]="exportMenu">
          <i class="fas fa-download fa-lg"></i>
        </button>
      </div>
    </mat-toolbar>
  </header>
  <main #main>
    <div [style.height.px]="main.offsetHeight > 10 ? main.offsetHeight : 500">
      <igx-grid #grid appGridState [ngClass]="loading ? 'blur' : gridFontSize + ' grid'"
        (contextMenu)="rightClick($event)" [rowSelection]="selection" (doubleClick)="doubleClick($event)"
        (selected)="cellSelection($event)" (sortingDone)="removeSorting($event)" (rangeSelected)="getCells($event)"
        (rowSelectionChanging)="rowSelection($event)" (columnMovingEnd)="handleColumnMove($event)"
        (gridStateSaved)="reset()" (onGetColumnsFromGrid)="populateColumns($event)" [pagingMode]="pagingMode"
        (cellEditEnter)="cellEditEnter($event)" (cellEdit)="cellEdit($event)" (cellClick)="cellClick($event)"
        [emptyFilteredGridMessage]="'No Filtered Results Found'"
        [emptyGridMessage]="loading && grid.isLoading ? 'Loading...' : 'Grid has No Data'" [data]="gridData" [id]="gridName"
        [gridName]="gridName" [moving]="true" [primaryKey]="'id'" [autoGenerate]="false" [allowFiltering]="true"
        [isLoading]="loading" [loadingGridTemplate]="loadingTemplate" (columnPinned)="columnPinning($event)">
        <igx-column *igxFor="let c of columns" [sortable]="true" [filterable]="filterable" [groupable]="groupable"
          [resizable]="true" [width]="c.width" [minWidth]="'100px'" [draggable]="true" [field]="c.field"
          [header]="c.header" [dataType]="c.dataType" [pinned]="c.pinned" [hidden]="c.hidden"
          [filterCellTemplate]="defaultFilterTemplate" [disablePinning]="true" [disableHiding]="true"
          [editable]="editableColumns && editableColumns?.includes(c.field)">
          <ng-template igxHeader let-column>
            <div (pointerdown)="onHeaderClick($event, column)">
              <span>{{ column.header }}</span>
            </div>
          </ng-template>
          <ng-template *ngIf="c.dataType !== 'percent'" igxCell let-cell="cell" let-value class="d-flex">
            <div igxTextHighlight class="igx-grid__td-text" [cssClass]="'custom-highlight'"
              [activeCssClass]="'custom-active-highlight'" [row]="cell.row.data" [column]="cell.column.field"
              [containerClass]="'igx-grid__td-text'" [groupName]="'grid1'"
              [value]="c.dataType == 'boolean' ? value ? 'Yes' : 'No' : value">
              <span *ngIf="c.dataType === 'string'">
                <span *ngIf="value && value.toString().includes('http'); else noLink">
                  <a class="grid-link-click" target="_blank" [href]="value">{{ value }}</a>
                </span>
                <ng-template #noLink>
                  {{ value }}
                </ng-template>
              </span>
              <span *ngIf="c.dataType === 'date'" [ngClass]="checkPastDatesStyle(c.field, value)">
                {{ value | date: "dd/MM/yyyy" }}
              </span>
              <span *ngIf="c.dataType === 'dateTime'" [ngClass]="checkPastDatesStyle(c.field, value)">
                {{ value | date: "dd/MM/yyyy HH:mm:ss" }}
              </span>
              <span *ngIf="c.dataType === 'boolean'">
                <span *ngIf="value">Yes</span>
                <span *ngIf="!value">No</span>
              </span>
              <span *ngIf="c.dataType === 'tick'">
                <span style="color: lawngreen" *ngIf="value == 0">Pass</span>
                <span style="color: red" *ngIf="value == 1">Fail</span>
                <span *ngIf="value == 2">N/A</span>
                <span style="color: yellow" *ngIf="value == 3">Deffered</span>
              </span>
              <span *ngIf="c.dataType === 'boolean?'">
                <span *ngIf="value === true">Yes</span>
                <span *ngIf="value === false">No</span>
                <span *ngIf="value === null || value === undefined"></span>
              </span>
              <span *ngIf="c.dataType === 'money' || c.dataType === 'currency'">
                {{ value ? "£ " + value.toFixed(2) : "" }}
              </span>
              <span *ngIf="c.dataType === 'number'">
                {{ value }}
              </span>
              <div style="display: contents; position: relative;">
                <i *ngIf="
                                c.dataType === 'number' && editableColumns && editableColumns?.includes(c.field)
                              " class="fa fa-pencil"></i>
              </div>
            </div>
          </ng-template>
          <ng-template *ngIf="c.dataType === 'percent'" igxCell let-cell="cell" let-value>
            <igx-select class="cell-select" (closed)="updateCellRowData($event, cell, c)">
              <igx-select-item *ngFor="let option of igxSelectOptions[c.field]" [value]="option"
                [selected]="value.id === option.id">
                <span>{{ option.name }}</span>
              </igx-select-item>
            </igx-select>
          </ng-template>
        </igx-column>
        <igx-column *ngIf="editableColumns" field="save" header="Save" dataType="string" [filterable]="false">
          <ng-template igxCell let-cell="cell">
            <button mat-flat-button class="toggle-button me-1 border w-100" title="Save"
              [disabled]="calculateState(cell)" (click)="saveEditedRow.emit(cell)">
              Save
            </button>
          </ng-template>
        </igx-column>
        <igx-paginator #paginator [(page)]="grid.page" [totalRecords]="grid.totalRecords" [(perPage)]="grid.perPage"
          [selectOptions]="selectOptions" [displayDensity]="'compact'">
        </igx-paginator>
        <ng-template #loadingTemplate>
          <div class="spinner loading h-100 d-flex">
            <app-loading class="w-100 h-100 d-flex align-items-center justify-content-center"></app-loading>
          </div>
        </ng-template>
      </igx-grid>
      <ng-template #defaultFilterTemplate igxFilterCellTemplate let-column="column">
        <div class="filter-cell" (keydown)="$event.stopPropagation()">
          <custom-filter [column]="column" *ngIf="column.dataType == 'string'"
            [dataType]="column.dataType" [events]="clearFilterSubject.asObservable()"
            [refresh]="onMoveSubject.asObservable()" (onFiltered)="onChangesSubject.next()"
            [dataChanged]="onChangesSubject.asObservable()" [columnsMoved]="onMoveSubject.asObservable()"
            [grid]="grid"></custom-filter>
          <custom-filter [column]="column"
            *ngIf="column.dataType == 'number' || column.dataType == 'money' || column.dataType == 'rating'"
            [dataType]="column.dataType" [events]="clearFilterSubject.asObservable()"
            [dataChanged]="onChangesSubject.asObservable()" [columnsMoved]="onMoveSubject.asObservable()"
            [grid]="grid"></custom-filter>
          <bool-filter [column]="column" *ngIf="column.dataType == 'boolean'"
            [events]="clearFilterSubject.asObservable()" (onFiltered)="onChangesSubject.next()"
            [grid]="grid"></bool-filter>
          <null-bool-filter [column]="column" *ngIf="column.dataType == 'boolean?'"
            [events]="clearFilterSubject.asObservable()" (onFiltered)="onChangesSubject.next()"
            [grid]="grid" ></null-bool-filter>
          <date-filter [column]="column" *ngIf="column.dataType == 'date' || column.dataType == 'dateTime'"
            [events]="clearFilterSubject.asObservable()" [grid]="grid" [id]="column.header"></date-filter>
        </div>
      </ng-template>
    </div>
    <context-menu *ngIf="contextmenu == true" class="position-absolute z-1"
      [ngStyle]="{ 'left.px': contextmenuX, 'top.px': contextmenuY }" [cell]="clickedCell"
      [selectedCells]="multiCellArgs" [contextMenuOptions]="contextMenuOptions" (onClick)="click($event)"
      (onClose)="contextmenu = false">
    </context-menu>
  </main>
  <!--</div>-->
</content>
