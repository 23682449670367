import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AppConfigurationService } from "../app-config/app-configuration.service";

@Injectable({
  providedIn: "root"
})
export class ImageService {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly configService: AppConfigurationService
  ) {}

  updateImage(image) {
    return this.httpClient.put<any[]>(
      this.configService.apiAddress + "/image",
      image,
      {
        withCredentials: true
      }
    );
  }

  rotateImage(image) {
    return this.httpClient.put<any[]>(
      this.configService.apiAddress + "/image/rotate",
      image,
      {
        withCredentials: true
      }
    );
  }

  getImageById(id) {
    return this.httpClient.get<any>(
      this.configService.apiAddress + "/image/" + id,
      {
        withCredentials: true
      }
    );
  }

  addImage(image) {
    return this.httpClient.post<any[]>(
      this.configService.apiAddress + "/image",
      image,
      {
        withCredentials: true
      }
    );
  }

  deleteImage(image) {
    return this.httpClient.delete(
      this.configService.apiAddress + "/image/" + image.id,
      {
        withCredentials: true
      }
    );
  }
}
