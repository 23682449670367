import { IgxBooleanFilteringOperand } from "igniteui-angular";

export const boolFilterOperations =
[
  {
    name: "All",
    iconName: "all",
    logic: IgxBooleanFilteringOperand.instance().condition("all")
  }, {
    name: "Yes",
    iconName: "is_true",
    logic: IgxBooleanFilteringOperand.instance().condition("true")
  }, {
    name: "No",
    iconName: "is_false",
    logic: IgxBooleanFilteringOperand.instance().condition("false")
  },
  {
    name: "",
    iconName: "empty",
    logic: IgxBooleanFilteringOperand.instance().condition("empty")
  }
];
