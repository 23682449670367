import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { CanActivateGuard } from "./auth/guards/can-activate-guard.guard";
import { LoginComponent } from "./auth/login/login.component";
import { ResetPasswordComponent } from "./auth/reset-password/reset-password.component";
import { SelectivePreloadingStrategyService } from "./shared/services/preload-strategy/selective-preloading-strategy.service";
import { LoginDatabaseComponent } from "./auth/login-database/login-database.component";
import { HomeComponent } from "./home/home.component";
import { Claims } from "./auth/models/claims";

const routes: Routes = [
  { path: "", redirectTo: "/dashboard", pathMatch: "full" },
  { path: "login", component: LoginComponent },
  { path: "login-databases", component: LoginDatabaseComponent },
  { path: "reset-password/:token", component: ResetPasswordComponent },
  {
    path: "home",
    component: HomeComponent,
  },
  {
    path: "dashboard",
    loadChildren: () =>
      import("./dashboard/dashboard.module").then((m) => m.DashboardModule),
    canActivate: [CanActivateGuard],
    data: { claimType: Claims.dashboardView }
  },
  {
    path: "attendance",
    loadChildren: () => import("./attendance/attendance.module").then((m) => m.AttendanceModule),
    canActivate: [CanActivateGuard],
    data: { claimType: Claims.attendanceView }
  },
  {
    path: "map",
    loadChildren: () => import("./map/map.module").then((m) => m.MapModule),
    canActivate: [CanActivateGuard],
    data: { claimType: Claims.mapView }
  },
  {
    path: "map/:type/:id",
    loadChildren: () => import("./map/map.module").then((m) => m.MapModule),
    canActivate: [CanActivateGuard],
    data: { claimType: Claims.mapView }
  },
  {
    path: "map/:type/:id/:parentId",
    loadChildren: () => import("./map/map.module").then((m) => m.MapModule),
    canActivate: [CanActivateGuard],
    data: { claimType: Claims.mapView }
  },
  {
    path: "reports",
    loadChildren: () =>
      import("./reports/reports.module").then((m) => m.ReportsModule),
    canActivate: [CanActivateGuard],
    data: { claimType: Claims.reportView }
  },
  {
    path: "issues",
    loadChildren: () =>
      import("./issues/issues.module").then((m) => m.IssuesModule),
    canActivate: [CanActivateGuard],
    data: { claimType: Claims.issueView }
  },
  {
    path: "issues/:name/:viewId",
    loadChildren: () =>
      import("./issues/issues.module").then((m) => m.IssuesModule),
    canActivate: [CanActivateGuard],
    data: { claimType: Claims.issueView }
  },
  {
    path: "sites",
    loadChildren: () =>
      import("./sites/sites.module").then((m) => m.SitesModule),
    canActivate: [CanActivateGuard],
    data: { claimType: Claims.siteView }
  },
  {
    path: "sites/:name/:viewId",
    loadChildren: () =>
      import("./sites/sites.module").then((m) => m.SitesModule),
    canActivate: [CanActivateGuard],
    data: { claimType: Claims.siteView }
  },
  {
    path: "sites/:getUpdateData",
    loadChildren: () =>
      import("./sites/sites.module").then((m) => m.SitesModule),
    canActivate: [CanActivateGuard],
    data: { claimType: Claims.siteView }
  },
  {
    path: "programs",
    loadChildren: () =>
      import("./programs/programs.module").then((m) => m.ProgramsModule),
    canActivate: [CanActivateGuard],
    data: { claimType: Claims.programView }
  },
  {
    path: "programs/:name/:viewId",
    loadChildren: () =>
      import("./programs/programs.module").then((m) => m.ProgramsModule),
    canActivate: [CanActivateGuard],
    data: { claimType: Claims.programView }
  },
  {
    path: "programs/:getUpdateData",
    loadChildren: () =>
      import("./programs/programs.module").then((m) => m.ProgramsModule),
    canActivate: [CanActivateGuard],
    data: { claimType: Claims.programView }
  },
  {
    path: "tasks",
    loadChildren: () =>
      import("./tasks/tasks.module").then((m) => m.TasksModule),
    canActivate: [CanActivateGuard],
    data: { claimType: Claims.taskView }
  },
  {
    path: "tasks/:name/:viewId",
    loadChildren: () =>
      import("./tasks/tasks.module").then((m) => m.TasksModule),
    canActivate: [CanActivateGuard],
    data: { claimType: Claims.taskView }
  },
  {
    path: "tasks/:getUpdateData",
    loadChildren: () =>
      import("./tasks/tasks.module").then((m) => m.TasksModule),
    canActivate: [CanActivateGuard],
    data: { claimType: Claims.taskView }
  },
  {
    path: "equipment",
    loadChildren: () =>
      import("./equipment/equipment.module").then((m) => m.EquipmentModule),
    canActivate: [CanActivateGuard],
    data: { claimType: Claims.equipmentView }
  },
  {
    path: "equipment/:name/:viewId",
    loadChildren: () =>
      import("./equipment/equipment.module").then((m) => m.EquipmentModule),
    canActivate: [CanActivateGuard],
    data: { claimType: Claims.equipmentView }
  },
  {
    path: "equipment/:getUpdateData",
    loadChildren: () =>
      import("./equipment/equipment.module").then((m) => m.EquipmentModule),
    canActivate: [CanActivateGuard],
    data: { claimType: Claims.equipmentView }
  },
  {
    path: "people",
    loadChildren: () =>
      import("./people/people.module").then((m) => m.PeopleModule),
    canActivate: [CanActivateGuard],
    data: { claimType: Claims.peopleView }
  },
  {
    path: "people/:name/:viewId",
    loadChildren: () =>
      import("./people/people.module").then((m) => m.PeopleModule),
    canActivate: [CanActivateGuard],
    data: { claimType: Claims.peopleView }
  },
  {
    path: "people/:getUpdateData",
    loadChildren: () =>
      import("./people/people.module").then((m) => m.PeopleModule),
    canActivate: [CanActivateGuard],
    data: { claimType: Claims.peopleView }
  },
  {
    path: "training/:name/:viewId",
    loadChildren: () =>
      import("./people/people.module").then((m) => m.PeopleModule),
    canActivate: [CanActivateGuard],
    data: { claimType: Claims.peopleView }
  },
  {
    path: "inspections",
    loadChildren: () =>
      import("./inspections/inspections.module").then(
        (m) => m.InspectionsModule
      ),
    canActivate: [CanActivateGuard],
    data: { claimType: Claims.inspectionView }
  },
  {
    path: "inspections/:name/:viewId",
    loadChildren: () =>
      import("./inspections/inspections.module").then(
        (m) => m.InspectionsModule
      ),
    canActivate: [CanActivateGuard],
    data: { claimType: Claims.inspectionView }
  },
  {
    path: "stock",
    loadChildren: () =>
      import("./stock/stock.module").then((m) => m.StockModule),
    canActivate: [CanActivateGuard],
    data: { claimType: Claims.stockView }
  },
  {
    path: "stock/:name/:viewId",
    loadChildren: () =>
      import("./stock/stock.module").then((m) => m.StockModule),
    canActivate: [CanActivateGuard],
    data: { claimType: Claims.stockView }
  },
  {
    path: "stock/:getUpdateData",
    loadChildren: () =>
      import("./stock/stock.module").then((m) => m.StockModule),
    canActivate: [CanActivateGuard],
    data: { claimType: Claims.stockView }
  },
  {
    path: "support",
    loadChildren: () =>
      import("./support/support.module").then((m) => m.SupportModule),
    canActivate: [CanActivateGuard],
    data: { claimType: Claims.supportView }
  },
  {
    path: "support/:path",
    loadChildren: () =>
      import("./support/support.module").then((m) => m.SupportModule),
    canActivate: [CanActivateGuard],
    data: { claimType: Claims.supportView }
  },
  {
    path: "config",
    loadChildren: () =>
      import("./config/config.module").then((m) => m.ConfigModule),
    canActivate: [CanActivateGuard],
    data: { claimType: Claims.configuration }
  },
  {
    path: "config/:path",
    loadChildren: () =>
      import("./config/config.module").then((m) => m.ConfigModule),
    canActivate: [CanActivateGuard],
    data: { claimType: Claims.configuration }
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: SelectivePreloadingStrategyService
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
