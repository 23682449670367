import {
  FieldType,
  IEditFormField
} from "../../shared/components/edit-form/edit-form-model";

export const stockFields: IEditFormField[] = [
  { name: "name", label: "Name", type: FieldType.String, required: true },
  {
    name: "stockCategory",
    label: "Category",
    type: FieldType.String,
    required: true
  },
  { name: "stockType", label: "Type", type: FieldType.String, required: true },
  { name: "description", label: "Description", type: FieldType.String },
  { name: "storedLocation", label: "Stored Location", type: FieldType.String, validatorPattern: "^.{1,100}$" },
  { name: "reference", label: "Reference", type: FieldType.String, validatorPattern: "^.{1,100}$" },
  {
    name: "quantity",
    label: "Quantity In Stock",
    type: FieldType.Int,
    required: true,
    validatorPattern: "^\\d{1,45}$"
  },
  {
    name: "minimumStockQuantity",
    label: "Minimum Stock",
    type: FieldType.Int,
    validatorPattern: "^\\d{1,45}$"
  },
  {
    name: "maximumOrder",
    label: "Maximum Order",
    type: FieldType.Int,
    validatorPattern: "^\\d{1,45}$"
  },
  { name: "cost", label: "Cost", type: FieldType.Decimal },
  { name: "barcode", label: "Barcode", type: FieldType.String, validatorPattern: "^.{1,100}$" },
  { name: "expiryDate", label: "Expiry Date", type: FieldType.Date }
];
export const stockRequestFields: IEditFormField[] = [
  {
    name: "stockRequestStatus",
    label: "Status",
    type: FieldType.Select,
    metaDataType: "stockItemRequestStatus"
  },
  { name: "created", label: "Created Date", type: FieldType.Date },
  { name: "providedDate", label: "Provided Date", type: FieldType.Date },
  { name: "note", label: "Notes", type: FieldType.String, validatorPattern: "^.{1,200}$" },
  { name: "reference", label: "Reference", type: FieldType.String },
  {
    name: "requestedByName",
    label: "Requested By",
    type: FieldType.String
  },
  ,
  {
    name: "totalCost",
    label: "Total Cost",
    type: FieldType.Decimal
  }
];

export const stockCategoryFields: IEditFormField[] = [
  {
    name: "name",
    label: "Category Name",
    type: FieldType.String,
    required: true
  },
  { name: "description", label: "Description", type: FieldType.String }
];

export const stockTypeFields: IEditFormField[] = [
  { name: "name", label: "Type Name", type: FieldType.String, required: true },
  {
    name: "type",
    label: "Stock Category",
    type: FieldType.Select,
    metaDataType: "stockCategories",
    required: true
  },
  { name: "description", label: "Description", type: FieldType.String }
];

export const stockItemRequestFields: IEditFormField[] = [
  {
    name: "stockRequestStatus",
    label: "Status",
    type: FieldType.Select,
    metaDataType: "stockItemRequestStatus",
    required: true
  },
  { name: "created", label: "Created Date", type: FieldType.Date },
  { name: "createdByName", label: "Requested By", type: FieldType.String },
  { name: "note", label: "Notes", type: FieldType.String, validatorPattern: "^.{1,200}$" },
  {
    name: "stock",
    label: "Stock",
    type: FieldType.Select,
    metaDataType: "stock",
    required: true
  },
  {
    name: "quantityProvided",
    label: "Quantity Provided",
    type: FieldType.Int,
    required: true
  },
  {
    name: "quantityRequested",
    label: "Quantity Requested",
    type: FieldType.Int,
    required: true
  },
  {
    name: "providedDate",
    label: "ProvidedDate",
    type: FieldType.Date
  }
];

export const stockContactFields: IEditFormField[] = [
  {name: "name", label: "Contact Name", type: FieldType.String, required: true, validatorPattern: "^.{1,100}$"},
  {name: "email", label: "Email", type: FieldType.String, required: true},
  {name: "mobile", label: "Mobile", type: FieldType.String},
  {name: "stockCategories", label: "Stock Categories", type: FieldType.MultipleSelect, metaDataType: "stockCategories"  },
  {name: "outOfStock", label: "Notify on Out of Stock", type: FieldType.Bool, required: true},
  {name: "lowStock", label: "Notify on Low Stock", type: FieldType.Bool, required: true},
  {name: "stockRequestCreated", label: "Notify when Stock Request Created", type: FieldType.Bool, required: true},
]
