<content class="inspection-type-modal">
  <div
    *ngIf="inspectionType.isArchived"
    class="statusBar text-center text-light bg-primary"
  >
    <span>You are currently viewing an Archived Inspection Type</span>
  </div>
  <div class="d-flex m-0 position-relative h-100">
    <div class="p-3 primary">
      <div class="text-center">
        <i class="fal fa-thumbtack fa-3x"></i>
        <H5>Inspection Type</H5>
      </div>
      <div class="py-5 modal-nav">
        <button
          *ngFor="let item of nav"
          mat-button
          class="btn py-2 d-block w-100"
          [ngClass]="{ 'border-p': current.page == item.page }"
          (click)="changeSection(item)"
          [disabled]="item.disabled"
          [hidden]="item.hidden"
        >
          {{ item.name }}
        </button>
      </div>
    </div>
    <div class="corner-close" (click)="close(form)">
      <i class="fal fa-times fa-2x"></i>
    </div>
    <form
      class="bg-modal px-4 d-flex w-100"
      [formGroup]="form"
      data-lpignore="true"
      autocomplete="off"
      *ngIf="loaded && form; else loading"
      (input)="check(form)"
    >
      <div class="d-flex flex-column justify-content-between w-100">
        <!-- #region Header -->
        <header class="d-flex primary pt-3">
          <div>
            <h2 class="m-0">
              {{ isNew ? "Add Inspection Type" : inspectionType.name }}
            </h2>
            {{ current.name }}
          </div>
        </header>
        <!-- #endregion -->
        <!-- #region Main -->
        <main class="h-100" [ngSwitch]="current.page">
          <!-- #region Details -->
          <div *ngSwitchCase="'details'">
            <div class="row">
              <div class="col-12 form-column">
                <div class="card p-2 day-box d-block">
                  <mat-form-field class="w-100">
                    <mat-label class="primary">Name</mat-label>
                    <input matInput #name formControlName="name"/>
                    <mat-error
                      *ngIf="
                        form.get('name').hasError('required') &&
                        form.get('name').touched
                      "
                    >
                      Name Required.
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field class="w-100">
                    <mat-label class="primary">Inspection Category</mat-label>
                    <mat-select
                      formControlName="type"
                      [compareWith]="compareOverride"
                    >
                      <mat-option
                        *ngFor="
                          let category of metaData.inspectionCategories
                          | sort: 'name'
                          | archivedFilter : inspectionType.type
                        "
                        [value]="category"
                      >
                        {{ category.name }}
                      </mat-option>
                    </mat-select>
                    <mat-error
                      *ngIf="
                        form.get('type').hasError('required') &&
                        form.get('type').touched
                      "
                    >
                      Inspection Category Required.
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field class="w-100">
                    <mat-label class="primary">Description</mat-label>
                    <textarea
                      matInput
                      class="h-100"
                      rows="5"
                      formControlName="description"
                    ></textarea>
                  </mat-form-field>

                  <mat-form-field class="w-100">
                    <mat-label class="primary">Inspection Checklist</mat-label>
                    <mat-select
                      formControlName="inspectionChecklist"
                      [compareWith]="compareOverride"
                    >
                      <mat-option
                        *ngFor="
                          let inspectionChecklist of metaData.inspectionChecklists
                          | sort: 'name'
                          | archivedFilter : inspectionType.inspectionChecklist
                        "
                        [value]="inspectionChecklist"
                      >
                        {{ inspectionChecklist.name }}
                      </mat-option>
                    </mat-select>
                    <mat-error
                      *ngIf="
                        form.get('inspectionChecklist').hasError('required') &&
                        form.get('inspectionChecklist').touched
                      "
                    >
                      Inspection Checklist Required.
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field class="w-100">
                    <mat-label class="primary">Issue Type for Automatic Failed Check Issues</mat-label>
                    <mat-select
                      formControlName="issueType"
                      [compareWith]="compareOverride"
                    >
                      <mat-option [value]="null"></mat-option>
                      <mat-option
                        *ngFor="
                          let issueType of metaData.issueTypes
                          | sort: 'name'
                          | archivedFilter : inspectionType.issueType
                        "
                        [value]="issueType"
                      >
                        {{ issueType.name }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <mat-form-field *ngIf="form.controls['issueType'].value" class="w-100">
                    <mat-label class="primary">Risk Required for Failed Checks</mat-label>
                    <mat-select
                      formControlName="riskRequired"
                    >
                      <mat-option
                        *ngFor="
                          let bool of bools
                        "
                        [value]="bool.id"
                      >
                        {{ bool.name }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <mat-form-field class="w-100">
                    <mat-label class="primary">Teams</mat-label>
                    <mat-select
                      multiple
                      formControlName="teams"
                      [compareWith]="compareOverride"
                    >
                      <mat-option
                        *ngFor="
                      let team of metaData.teams | sort: 'name' | archivedFilter
                    "
                        [value]="team"
                      >
                        {{ team.name }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <mat-form-field class="w-100">
                    <mat-label class="primary">Scheduled</mat-label>
                    <mat-select
                      formControlName="isScheduled"
                    >
                      <mat-option
                        *ngFor="let bool of bools"
                        [value]="bool.id"
                      >
                        {{ bool.name }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
              <div *ngIf="form.controls['isScheduled'].value" class="col-12 d-flex form-column p-0 flex-column">
                <div
                  class="card d-block p-2 day-box flex-grow-1 text-center">
                  <mat-form-field class="w-50">
                    <mat-label class="primary">Start Date</mat-label>
                    <input
                      matInput
                      [matDatepicker]="pickerStartDate"
                      formControlName="startDate"
                    />
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="pickerStartDate"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #pickerStartDate></mat-datepicker>
                    <mat-hint class="overflow-hint text-danger" *ngIf="form.controls['startDate'].value"
                              align="start">Start Date must be removed to use Periods.
                    </mat-hint>
                  </mat-form-field>
                  <mat-form-field class="w-50">
                    <mat-label class="primary">End Date</mat-label>
                    <input
                      matInput
                      [matDatepicker]="pickerEndDate"
                      formControlName="endDate"
                    />
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="pickerEndDate"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #pickerEndDate></mat-datepicker>
                    <mat-error
                      *ngIf="
                        form.get('endDate').hasError('invalid') &&
                        form.get('endDate').touched
                      "
                    >
                      End Date cannot precede the Start Date
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field #field class="mt-3 w-100">
                    <mat-label class="mat-label primary">Schedule Periods
                      <mat-icon (click)="info('periods')" matPrefix class="my-icon">help</mat-icon>
                    </mat-label>
                    <button mat-flat-button class="border-p w-100">
                      <mat-select
                        multiple
                        #matSelect
                        class="hide-selected"
                        formControlName="periods"
                        [compareWith]="compareOverride"
                        (ngInit)="matSelectLoad(form.controls['periods'].value)"
                      >
                        <mat-option
                          #matOption
                          *ngFor="let period of metaData.periods | sort: 'name' | archivedFilter"
                          [value]="period"
                          (ngInit)="initOption(field, matOption)"
                        >
                          {{ period.name }}
                        </mat-option>
                      </mat-select>
                    </button>
                  </mat-form-field>
                  <div
                    class="d-block"
                  >
                    <div
                      class="d-flex text-start border rounded m-1 p-1 justify-content-between"
                      *ngFor="let item of form.controls['periods'].value; let i = index"
                    >
                        {{ item.name }}
                      <div
                        (click)="removeItem(item.id, form.controls['periods'].value, form.controls['periods'])"
                        [id]="'periods' + '-' + i + '-close'"
                      >
                        <i class="fas fa-times"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="form.controls['isScheduled'].value" class="col-12 d-flex form-column flex-column">
                <div class="card d-block p-2 day-box flex-grow-1 text-center">
                  <div class="m-0 row">
                    <div class="w-50 p-0">
                      <mat-form-field class="w-50 time-frame">
                        <mat-label class="mat-label-float primary">Time Frame
                        </mat-label>
                        <input matInput class="text-end" formControlName="timeFrameDays" min="1" type="number"/>
                        <mat-hint class="overflow-hint" *ngIf="form.controls['timeFrameDays'].enabled"
                                  align="start">(To be done every)
                        </mat-hint>
                      </mat-form-field>
                      <mat-form-field class="w-50 every">
                        <mat-label class="mat-label-float primary">(Every)
                          <mat-icon (click)="info('time-frame')" matPrefix class="my-icon">help</mat-icon>
                        </mat-label>
                        <mat-select
                          formControlName="timeFrameParam"
                        >
                          <mat-option [value]="1">Day(s)</mat-option>
                          <mat-option [value]="7">Week(s)</mat-option>
                          <mat-option [value]="28">Month(s)</mat-option>
                          <mat-option [value]="365">Year(s)</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div class="w-50 p-0">
                      <mat-form-field class="w-100 buffer">
                        <mat-label class="mat-label-float primary">Buffer (optional)
                          <mat-icon (click)="info('buffer')" matPrefix class="my-icon">help</mat-icon>
                        </mat-label>
                        <input matInput class="text-end"
                               formControlName="bufferDays"
                               min="0"
                               [max]="form.controls['timeFrameDays'].value *
                               form.controls['timeFrameParam'].value" type="number"/>
                        <span
                          *ngIf="form.controls['bufferDays'].enabled"
                          matSuffix> Day(s)</span>
                        <mat-hint *ngIf="form.controls['bufferDays'].enabled"
                                  align="end">(Between Time Frame occurrences)
                        </mat-hint>
                      </mat-form-field>
                    </div>
                  </div>
                  <mat-form-field #field class="mt-3 w-100">
                    <mat-label class="primary">Active Days</mat-label>
                    <button mat-flat-button class="border-p w-100">
                      <mat-select
                        multiple
                        #matSelect
                        class="hide-selected"
                        formControlName="days"
                        [compareWith]="compareOverride"

                      >
                        <mat-option
                          #matOption
                          *ngFor="let day of metaData.days"
                          [value]="day"
                          (ngInit)="initOption(field, matOption)"
                        >
                          {{ day.name }}
                        </mat-option>
                      </mat-select>
                    </button>
                  </mat-form-field>
                  <div
                    class="d-block boundary"
                  >
                    <div
                      class="d-flex border rounded m-1 p-1 justify-content-between"
                      *ngFor="let item of form.controls['days'].value; let i = index"
                    >
                      {{ item.name }}
                      <div
                        (click)="removeItem(item.id, form.controls['days'].value, form.controls['days'])"
                        [id]="'days' + '-' + i + '-close'"
                      >
                        <i class="fas fa-times"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- #endregion -->
          <!-- #region History / AuditTrail -->
          <div *ngSwitchCase="'auditTrail'">
            <app-audit-trail
              id="auditTrail"
              [auditTrail]="inspectionType.auditTrail"
            ></app-audit-trail>
          </div>
          <!-- #endregion -->
        </main>
        <!-- #endregion -->
        <!-- #region Footer -->
        <footer
          class="flex justify-content-end my-3"
          [ngClass]="inspectionType.isArchived ? 'pb-4' : ''"
        >
          <div>
            <button
              mat-flat-button
              name="detailSaveButton"
              class="ms-2 px-4"
              [disabled]="form.invalid || !form.dirty || !hasClaim || saving"
              (click)="save()"
            >
              <span *ngIf="saving">
                <i class="fad fa-spinner-third fa-spin">
                </i>
              </span>
              <span *ngIf="!saving">Save</span>
            </button>
            <button mat-flat-button class="ms-2 px-4" (click)="close(form)">
              Close
            </button>
          </div>
        </footer>
        <!-- #endregion -->
      </div>
    </form>
    <ng-template #loading>
      <app-loading
        class="w-100 d-flex align-items-center justify-content-center bg-modal"
      ></app-loading>
    </ng-template>
  </div>
</content>
