import {
  AfterViewInit,
  Component,
  EventEmitter,
  Inject,
  Output,
  ViewChild
} from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { BaseModalComponent } from "src/app/shared/base/base-modal.component";
import { ModalRequest } from "../../shared/classes/modal.request";
import { ModalType } from "../../shared/enums/modal-fields.enum";
import { Router } from "@angular/router";

import { GridComponent } from "../../shared/grid/grid.component";
import { issuesColumns } from "./inspection-check-record-modal.columns";
import { Claims } from "src/app/auth/models/claims";

@Component({
  selector: "app-inspection-check-record-modal",
  templateUrl: "./inspection-check-record-modal.component.html"
})
export class InspectionCheckRecordModalComponent
  extends BaseModalComponent
  implements AfterViewInit
{
  @Output()
  onSave = new EventEmitter();

  inspectionCheckRecord = <any>{};
  hasClaim = false;
  allNames = ["Inspection Check Record Photos"];

  // addInspectionButtons = [
  //   {name: "Add Issue", action: this.ad}
  // ];
  issuesColumns = issuesColumns;

  getNav = () => [
    { name: "Check Record Details", page: "details" },
    { name: "Issues", page: "issues", hidden: this.isNew },
    { name: "Photos", page: "photos" },
    { name: "Map", page: "map" },
    { name: "History", page: "auditTrail", hidden: this.isNew }
  ];

  @ViewChild(GridComponent, { static: false })
  private issueGridComponent: GridComponent;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<Component>,
    private router: Router
  ) {
    super();
    this.loaded = false;
    this.isNew = !data.data.id;
    this.nav = this.getNav();
    this.current = this.nav[0];
    this.fields = data.fields;
    this.metaData = data.metaData;
    this.inspectionCheckRecord = data.data;

    const process = function (context) {
      context
        .createForm(context.fields, context.inspectionCheckRecord)
        .then((form) => {
          context.form = form;
          if (context.isNew) {
            context.form.controls["createdByName"].setValue(
              context.authService.securityObject.name
            );
          } else {
            context.form.controls["inspectionCheckItemResult"].disable();
            context.form.controls["createdByName"].disable();
          }
          context.form.controls["name"].disable();
          context.form.controls["inspectionName"].disable();
          context.form.controls["siteName"].disable();
          context.form.controls["equipmentName"].disable();
          context.loaded = true;
          //Check Claims
          !context.authService.hasClaim(Claims.inspectionEdit)
            ? context.form.disable()
            : context.hasClaim = true;
        });
      setTimeout(() => context.form.markAllAsTouched(), 5000);
    };

    if (this.isNew) {
      this.inspectionCheckRecord.created = this.newIsoDate();
    }

    process(this);

    setTimeout(() => this.form.markAllAsTouched(), 5000);
  }

  ngAfterViewInit() {
    this.inspectionCheckRecord.parsedLatitude =
      this.inspectionCheckRecord.latitude !== null
        ? parseFloat(this.inspectionCheckRecord.latitude)
        : null;
    this.inspectionCheckRecord.parsedLongitude =
      this.inspectionCheckRecord.longitude !== null
        ? parseFloat(this.inspectionCheckRecord.longitude)
        : null;
  }

  changeSection(item) {
    if (item.page === "auditTrail") {
      let parameter = item.page !== "auditTrail" ? "id" : null;
      if (!this.inspectionCheckRecord[item.page]) {
        this.loaded = false;
        this.remoteService
          .getBy(
            `inspectionCheckItemRecord/${item.page}`,
            parameter,
            this.data.data.id
          )
          .subscribe(
            (res) => {
              this.inspectionCheckRecord[item.page] = res;
              this.loaded = true;
            },
            () => {
              this.loaded = true;
            }
          );
      }
    }
    this.current = item;
  }

  checkImages($event) {
    if (!this.inspectionCheckRecord.images) {
      this.inspectionCheckRecord.images = [];
    }
    this.inspectionCheckRecord.images.push($event);
    this.form.markAsDirty();
  }

  trackImage($event) {
    this.inspectionCheckRecord.images.push({
      id: $event
    });
  }

  save() {
    this.toggleSave();
    this.inspectionCheckRecord = {
      ...this.inspectionCheckRecord,
      ...this.form.getRawValue()
    };

    this.onSave.emit(this.inspectionCheckRecord);
  }

  close(form) {
    this.closeModal(form, this.dialogRef);
  }

  doubleClick(cell) {
    if (cell.row.data.isNew) {
      this.baseService.openSnackBar(
        "Error - Cannot edit new Issue until Saved",
        null,
        true
      );
    }
    this.openModal.next(
      new ModalRequest({
        id: cell.id.rowID,
        requestContext: this,
        modalType: ModalType.Issue,
        autoSave: false,
        afterSave: this.saveIssue
      })
    );
  }

  openEntityProperties(cell, context) {
    context.doubleClick(cell);
  }

  saveIssue(context, entity, dialogRef) {
    // Prepping the DTO accordingly
    if (entity.issueCategory.name) {
      entity.issueCategoryName = entity.issueCategory.name;
    }
    if (entity.issueType.name) {
      entity.issueTypeName = entity.issueType.name;
    }
    if (entity.risk?.name) {
      entity.riskName = entity.risk.name;
    }
    if (!entity.created) {
      entity.created = context.newIsoDate();
    }
    if (entity.isClosed) {
      entity.closedDate = context.newIsoDate();
    }
    context.addOrReplace(context.inspectionCheckRecord.issues, entity);
    context.issueGridComponent.grid.data = [
      ...context.inspectionCheckRecord.issues
    ];
    dialogRef.close();
    context.form.controls["inspectionName"].markAsDirty();
    context.form.markAsDirty();
  }

  navigateToMap() {
    this.closeModal(this.form, this.dialogRef).then(() =>
      this.router.navigate([
        `/map/inspectionCheckRecordId/${this.inspectionCheckRecord.id}`
      ])
    );
  }
}
