import { DOCUMENT } from "@angular/common";
import { Component, Inject, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { first } from "rxjs/operators";
import { AuthService } from "./auth/auth.service";
import { BaseService } from "./shared/base/base.service";
import { ConfirmModalComponent } from "./shared/components/modals/confirm/confirm-modal.component";
import { IconService } from "./shared/services/igx-icons/icon.service";
import { RemoteService } from "./shared/services/remote/remote.service";
import { AppUserAuth } from "./auth/models/app-user-auth";
import { NavMenu } from "./shared/models/nav-menu-model";
import { Claims } from "./auth/models/claims";
import { Title } from "@angular/platform-browser";
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html"
})
export class AppComponent implements OnInit {
  title = "app";
  navMenu: NavMenu[] = [
    new NavMenu({ title: "Dashboard" }),
    new NavMenu({ title: "Sites" }),
    new NavMenu({ title: "Programs" }),
    new NavMenu({ title: "Tasks" }),
    new NavMenu({ title: "Equipment" }),
    new NavMenu({ title: "People" }),
    new NavMenu({ title: "Inspections" }),
    new NavMenu({ title: "Stock" }),
    new NavMenu({ title: "Issues" }),
    new NavMenu({ title: "Map" }),
    new NavMenu({ title: "Reports" }),
    new NavMenu({ title: "Attendance" }),
  ];

  appUser: AppUserAuth;
  version = "V1.1";
  isStaging = window.location.href.includes("staging");
  theme = localStorage.getItem("theme")
    ? localStorage.getItem("theme").valueOf()
    : "light-theme";
  gridHeights = [
    { name: "1", rowHeight: 16, class: "fal fa-text-size f1" },
    { name: "2", rowHeight: 24, class: "fal fa-text-size f2", fontSize: "size2" },
    { name: "3", rowHeight: 32, class: "fal fa-text-size f3", fontSize: "size3" },
    { name: "4", rowHeight: 40, class: "fal fa-text-size f4", fontSize: "size4" },
    { name: "5", rowHeight: 48, class: "fal fa-text-size f5", fontSize: "size5" }
  ];

  gridHeightSelected = localStorage.rowHeight
    ? { rowHeight: +localStorage.rowHeight }
    : { rowHeight: 32 };

  //#region Constructor

  constructor(
    public readonly router: Router,
    private readonly authService: AuthService,
    private readonly iconService: IconService,
    private readonly matDialog: MatDialog,
    private readonly remoteService: RemoteService,
    private readonly baseService: BaseService,
    private readonly titleService: Title,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.appUser = authService.securityObject;
    this.document.body.classList.add(this.theme);

    if (window.location.href.includes("localhost")) {
      this.titleService.setTitle(`PSSUltimate (${this.version})`);
    } else {
      this.titleService.setTitle(`PSSUltimate`);
    }

    if (window.location.href.includes("staging")) {
      this.version += " [STAGING]";
      this.titleService.setTitle(`PSSUltimate [STAGING]`);
    }
  }

  //#endregion

  ngOnInit(): void {
    this.iconService.ngOnInit();
  }

  logOut(): void {
    this.authService.logout().pipe(first()).subscribe();
  }

  changeHeight(height) {
    this.baseService.gridHeightNotify.emit(height);
    this.gridHeightSelected = height;
  }

  toggleTheme() {
    this.document.body.classList.remove(this.theme);
    if (this.theme === "dark-theme") {
      localStorage.setItem("theme", "light-theme");
      this.theme = "light-theme";
    } else {
      localStorage.setItem("theme", "dark-theme");
      this.theme = "dark-theme";
    }
    this.document.body.classList.add(this.theme);
  }

  getGridRemoteState() {
    this.remoteService
      .getData("account/GetUserGridSettings")
      .subscribe((response: any) => {
        if (response) {
          window.localStorage.setItem("columns-sites-grid", response.sites);
          window.localStorage.setItem(
            "columns-programs-grid",
            response.programs
          );
          window.localStorage.setItem("columns-tasks-grid", response.tasks);
          window.localStorage.setItem(
            "columns-equipment-grid",
            response.equipment
          );
          window.localStorage.setItem(
            "columns-equipmentCheckRecord-grid",
            response.equipmentCheckRecord
          );
          window.localStorage.setItem(
            "columns-equipmentMaintenanceRecord-grid",
            response.equipmentMaintenanceRecord
          );
          window.localStorage.setItem(
            "columns-equipmentChecklist-grid",
            response.equipmentChecklist
          );
          window.localStorage.setItem(
            "columns-equipmentChecklistItem-grid",
            response.equipmentChecklistItem
          );
          window.localStorage.setItem("columns-people-grid", response.people);
          window.localStorage.setItem(
            "columns-inspections-grid",
            response.inspections
          );
          window.localStorage.setItem("columns-stock-grid", response.stock);
          window.localStorage.setItem("columns-issues-grid", response.issues);
        }
      });
  }

  reset() {
    const dialogRef = this.matDialog.open(ConfirmModalComponent, {
      data: {
        type: "confirmSave",
        body: [
          "This will reset your column selection and layout, filter and theme choices to default set up.",
          "Do you wish to proceed?"
        ]
      },
      panelClass: "confirm-dialog-container"
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        window.location.reload();
        localStorage.clear();
      }
    });
  }

  openConfigure() {
    if (this.authService.hasClaim(Claims.configuration)) {
      this.router.navigate(["config"]);
    } else {
      this.baseService.openSnackBar(
        "You cannot open Configuration. Please login with appropriate authorisation.",
        null,
        true
      );
    }
  }

  supportNavigate(path) {
    if (this.authService.hasClaim(Claims.supportView)) {
      setTimeout(() => {
        this.router.navigate([path]);
      });
    } else {
      this.baseService.openSnackBar(
        "You cannot open Support. Please login with appropriate authorisation.",
        null,
        true
      );
    }
  }
}
