import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";
import { MatPaginator } from "@angular/material/paginator";
import { UntypedFormGroup, UntypedFormControl, Validators } from "@angular/forms";
import { Subject } from "rxjs";

@Component({
  selector: "basic-edit-table",
  templateUrl: "./basic-edit-table.component.html",
  styleUrls: ["./basic-edit-table.component.css"],
})
export class BasicEditTableComponent implements OnInit {
  @Input()
  fixedWidth = false;
  @Input()
  columns: any[];
  @Input()
  data: any;
  @Input()
  metaData: any[];
  @Input()
  dataSource = new MatTableDataSource();
  @Input()
  dataChanged = new Subject<void>();
  @Input()
  canAdd = false;
  @Input()
  canDelete = true;
  @Input()
  canArchive = true;
  @Input()
  archiveTitle = "Archive";

  @Output()
  private onSave = new EventEmitter();
  @Output()
  private onDelete = new EventEmitter();
  @Output()
  private onArchive = new EventEmitter();
  @Output()
  private pendingChanges = new EventEmitter();
  @Output()
  private onControlChange = new EventEmitter();

  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild("table", { read: ElementRef }) table: ElementRef;
  firstFormControl: UntypedFormControl;

  bools = [
    { id: true, name: "Yes" },
    { id: false, name: "No" },
  ];
  displayedColumns: string[];
  width: number;

  myFormGroup: UntypedFormGroup;
  forms = [];
  filteredMetaData = [];
  loaded = false;
  saveChange = false;

  ngOnInit(): void {
    if (this.dataChanged) {
      this.dataChanged.subscribe(() => {
        // HACK - I couldn't see a cause for why the subject was being emitted
        if (!this.saveChange) {
          this.ngOnChanges();
        }
        this.saveChange = false;
      });
    }
    this.dataSource.filterPredicate = (data, filter: string) => {
      const accumulator = (currentTerm, key) => {
        return this.nestedFilterCheck(currentTerm, data, key);
      };
      const dataStr = Object.keys(data).reduce(accumulator, "").toLowerCase();
      // Transform the filter by converting it to lowercase and removing whitespace.
      const transformedFilter = filter.trim().toLowerCase();
      return dataStr.indexOf(transformedFilter) !== -1;
    };
  }

  ngOnChanges(): void {
    this.displayedColumns = this.columns.map((column) => column.name);
    if (this.fixedWidth) {
      this.width = 100 / this.displayedColumns.length;
    }
    if (this.data) {
      this.createForm().then(() => {
        this.dataSource.data = this.data;
        setTimeout(() => {
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.dataSource.sortingDataAccessor = (data, sortHeaderId) => {
            if (data[sortHeaderId]) {
              if (typeof data[sortHeaderId] === "string") {
                if (data[sortHeaderId].includes("/")) {
                  let dateSplit = data[sortHeaderId].split("/");
                  return `${dateSplit[2]}${dateSplit[1]}${dateSplit[0]}`;
                } else {
                  return data[sortHeaderId].toLocaleLowerCase();
                }
              } else if (
                typeof data[sortHeaderId] === "object" &&
                data[sortHeaderId] !== null
              ) {
                if (data[sortHeaderId].name) {
                  return data[sortHeaderId].name;
                }
                if (data[sortHeaderId].id) {
                  return data[sortHeaderId].id;
                }
              } else {
                return data[sortHeaderId];
              }
            } else {
              return null;
            }
          };
        });
        this.loaded = true;
      });
    }
  }

  createForm() {
    return new Promise<void>((resolve) => {
      this.forms = [];
      for (var i = 0; i < this.data.length; i++) {
        this.data[i].index = i;
        let group = {};
        this.columns.forEach((col) => {
          if (col.name !== "save") {
            group[col.name + "FormControl"] = new UntypedFormControl(
              this.data[i][col.name]
            );
            if (col.required) {
              group[col.name + "FormControl"].setValidators(
                Validators.required
              );
            }
            if (col.disabled) {
              this.canAdd = false;
              group[col.name + "FormControl"].disable();
            }
            if (this.data[i].cannotEdit) {
              group[col.name + "FormControl"].disable();
            }
            if (this.data[i].cannotEditName && col.name == "name") {
              group[col.name + "FormControl"].disable();
            }
            if (col.listenForChange) {
              group[col.name + "FormControl"].valueChanges.subscribe((v) =>
                this.onControlChange.emit([
                  group[col.name + "FormControl"],
                  col.name,
                  v,
                ])
              );
            }
            if (col.type == "filterSelect") {
              group[col.name + "FormControl"].valueChanges.subscribe(() => {
                this.setFilteredMetaData(col.metaDataType, this.metaData[col.metaDataType]);
              });
            }
          }
        });
        const formGroup = new UntypedFormGroup(group);
        formGroup.statusChanges.subscribe(() =>
          this.pendingChanges.emit(formGroup)
        );
        this.forms.push(formGroup);
      }
      resolve();
    });
  }

  setFilteredMetaData(metaDataType, $event) {
    this.filteredMetaData[metaDataType] = $event;
  }

  applyFilter(value: string) {
    this.dataSource.filter = value.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  nestedFilterCheck(search, data, key) {
    if (typeof data[key] === "object") {
      for (const k in data[key]) {
        if (k !== "id" && data[key][k] !== null) {
          search = this.nestedFilterCheck(search, data[key], k);
        }
      }
    } else {
      if (key !== "created" && key !== "updated" && key !== "id")
        search += data[key];
    }
    return search;
  }

  compareOverride(t1, t2) {
    if (t2 !== null) {
      return t1.id === t2.id;
    }
    return null;
  }

  add() {
    let entity = {
      id:  "00000000-0000-0000-0000-000000000000",
      canDelete: true,
      isArchived: false,
    };

    this.data.unshift(entity);
    this.ngOnChanges();
    setTimeout(() => {
      const firstInput = this.table.nativeElement.querySelector("input");
      if (firstInput) {
        firstInput.focus();
      }
    }, 1000);
  }

  archive(entity, index) {
    this.onArchive.emit(entity);
  }

  delete(entity, index) {
    this.onDelete.emit(entity);
    this.data.splice(index, 1);
    this.dataSource.data = this.data;
    this.ngOnChanges();
  }

  save(entity: any, form: UntypedFormGroup) {
    this.columns.forEach((col) => {
      if (col.name !== "save") {
        entity[col.name] = form.controls[col.name + "FormControl"].value;
      }
    });

    entity.pending = true;
    this.onSave.emit(entity);
    this.saveChange = true;
    form.markAsPristine();
  }
}
