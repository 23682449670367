import { Component } from "@angular/core";
import { BaseBoolFilterComponent } from "./base-bool-filter.component";
import { boolFilterOperations } from "./bool-filter.operations";

@Component({
  selector: "null-bool-filter",
  templateUrl: "./base-bool-filter.component.html"
})
export class NullableBoolFilterComponent extends BaseBoolFilterComponent {
  constructor() {
    super();
    this.operations = boolFilterOperations;
  }
}
