import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'confirm-modal',
  templateUrl: './confirm-modal.component.html',
})
export class ConfirmModalComponent {
  isError = false;

  constructor(
     @Inject(MAT_DIALOG_DATA)
     public data: any, public dialogRef: MatDialogRef<Component>) {
    this.isError = data.type !== "confirmSave";
    if (Array.isArray(data.body[0])) {
      let body = [];
      data.body[0].map(d => {
        body.push(d);
      })
      data.body = body;
    }
  }
}
