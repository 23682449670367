import {
  AfterViewInit,
  Component,
  EventEmitter,
  Inject,
  Output,
  ViewChild
} from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { BaseModalComponent } from "src/app/shared/base/base-modal.component";
import { ModalRequest } from "../../shared/classes/modal.request";
import { ModalType } from "../../shared/enums/modal-fields.enum";
import { Router } from "@angular/router";
import { taskCheckRecordModalColumns } from "./task-check-record-modal.columns";
 import { GridComponent } from "../../shared/grid/grid.component";
import { Claims } from "src/app/auth/models/claims";

@Component({
  selector: "app-task-check-record-modal",
  templateUrl: "./task-check-record-modal.component.html"
})
export class TaskCheckRecordModalComponent
  extends BaseModalComponent
{
  @Output()
  onSave = new EventEmitter();

  taskCheckRecord = <any>{};
  hasClaim = false;
  allNames = ["Task Check Record Photos", "Task Photos", "Task Record Photos"];
  taskCheckRecordModalColumns = taskCheckRecordModalColumns;

  getNav = () => [
    { name: "Check Record Details", page: "details" },
    { name: "Adhoc Tasks", page: "adhocTasks", disabled: false },
    { name: "Photos", page: "photos" },
    // { name: "Map", page: "map" },
    { name: "History", page: "auditTrail", hidden: this.isNew }
  ];

  @ViewChild(GridComponent, { static: false })
  private taskGridComponent: GridComponent;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<Component>,
    private router: Router
  ) {
    super();
    this.loaded = false;
    this.isNew = !data.data.id;
    this.nav = this.getNav();
    this.current = this.nav[0];
    this.fields = data.fields;
    this.metaData = data.metaData;
    this.taskCheckRecord = data.data;

    const process = function (context) {
      context
        .createForm(context.fields, context.taskCheckRecord)
        .then((form) => {
          context.form = form;
          if (context.isNew) {
            context.form.controls["createdByName"].setValue(
              context.authService.securityObject.name
            );
          } else {
            context.form.controls["taskCheckRecordStatus"].disable();
          }
          context.form.controls["programName"].disable();
          context.form.controls["taskName"].disable();
          context.form.controls["siteName"].disable();
          context.form.controls["createdByName"].disable();
          context.form.controls["taskRecordMobileCreatedDate"].disable();
          context.form.controls["taskRecordMobileCreatedTime"].disable();
          context.loaded = true;
          //Check Claims
          !context.authService.hasClaim(Claims.taskEdit)
            ? context.form.disable()
            : context.hasClaim = true;
          context.setImages();
        });

      setTimeout(() => context.form.markAllAsTouched(), 5000);
    };

    if (this.isNew) {
      this.taskCheckRecord.mobileCreated = this.newIsoDate();
    }

    if (!this.taskCheckRecord.taskRecordMobileCreated) {
      this.taskCheckRecord.taskRecordMobileCreated = this.newIsoDate();
    }

    this.taskCheckRecord.taskRecordMobileCreatedDate =
      this.taskCheckRecord.taskRecordMobileCreated.split("T")[0];
      this.taskCheckRecord.taskRecordMobileCreatedTime = this.taskCheckRecord.taskRecordMobileCreated
      .match(/([A-Z]?[^A-Z]*)/g)[1]
      .slice(1, -1)
      .slice(0, 5);

    this.taskCheckRecord.mobileCreatedDate =
      this.taskCheckRecord.mobileCreated.split("T")[0];
    this.taskCheckRecord.mobileCreatedTime = this.taskCheckRecord.mobileCreated
      .match(/([A-Z]?[^A-Z]*)/g)[1]
      .slice(1, -1)
      .slice(0, 5);

    process(this);

    setTimeout(() => this.form.markAllAsTouched(), 5000);
  }

  changeSection(item) {
    if (item.page === "auditTrail") {
      let parameter = item.page !== "auditTrail" ? "id" : null;
      if (!this.taskCheckRecord[item.page]) {
        this.loaded = false;
        this.remoteService
          .getBy(`taskCheckRecord/${item.page}`, parameter, this.data.data.id)
          .subscribe(
            (res) => {
              this.taskCheckRecord[item.page] = res;
              this.loaded = true;
            },
            () => {
              this.loaded = true;
            }
          );
      }
    }
    this.current = item;
  }

  setImages() {
    if (this.taskCheckRecord.images) {
      let taskRecordImages = this.taskCheckRecord.images.filter((i) => i.caption == "Task Record Image");
      let taskImages = this.taskCheckRecord.images.filter((i) => i.caption == "Task Image");
      let taskCheckImages = this.taskCheckRecord.images.filter((i) => i.caption == "" || i.caption == null);
      this.allImages = [taskCheckImages, taskImages, taskRecordImages];
    } else {
      this.allImages = [[]];
    }
  }

  checkImages($event) {
    if (!this.taskCheckRecord.images) {
      this.taskCheckRecord.images = [];
    }
    this.taskCheckRecord.images.push($event);
    this.setImages();
    this.form.markAsDirty();
  }

  trackImage($event) {
    this.taskCheckRecord.images.push({
      id: $event
    });
  }

  save() {
    this.toggleSave();
    this.taskCheckRecord = {
      ...this.taskCheckRecord,
      ...this.form.getRawValue()
    };
    this.taskCheckRecord.mobileCreated = this.taskCheckRecord.mobileCreatedDate
      ? this.formDateTime(
          this.taskCheckRecord.mobileCreatedDate,
          this.taskCheckRecord.mobileCreatedTime
        )
      : null;
    this.taskCheckRecord.created = this.taskCheckRecord.mobileCreated;
    this.taskCheckRecord.images = this.taskCheckRecord.images.filter((i) => !i.caption);
    this.onSave.emit(this.taskCheckRecord);
  }

  close(form) {
    this.closeModal(form, this.dialogRef);
  }

  addTask(context) {
    context.openModal.next(
      new ModalRequest({
        requestContext: context,
        modalType: ModalType.Task,
        afterSave: context.saveTask,
        payload: {
          isAdhoc: true,
          site: context.taskCheckRecord.site,
          program: context.taskCheckRecord.program
        }
      })
    );
  }

  doubleClick(cell) {
    if (cell.row.data.isNew) {
      this.baseService.openSnackBar(
        "Error - Cannot edit new Task until Saved",
        null,
        true
      );
    }
    this.openModal.next(
      new ModalRequest({
        id: cell.id.rowID,
        requestContext: this,
        modalType: ModalType.Task,
        autoSave: false,
        afterSave: this.saveTask
      })
    );
  }

  openEntityProperties(cell, context) {
    context.doubleClick(cell);
  }

  saveTask(context, entity, dialogRef) {
    if (!context.taskCheckRecord.adhocTasks) {
      context.taskCheckRecord.adhocTasks = [];
    }
    if (entity.programs) {
      const programNames = entity.programs.map((m) => m.name);
      entity.programNames = programNames.join(",");
    }
    if (entity.taskType.name) {
      entity.taskTypeName = entity.taskType.name;
    }
    if (!entity.created) {
      entity.created = context.newIsoDate();
    }
    if (!entity.taskStatus) {
      entity.taskStatus = "New Adhoc Task";
    }
    context.addOrReplace(context.taskCheckRecord.adhocTasks, entity);
    // If the user hasn't viewed the Task Table yet...
    if (context.taskGridComponent) {
      context.taskGridComponent.grid.data = [
        ...context.taskCheckRecord.adhocTasks
      ];
    }
    dialogRef.close();
    context.form.controls["taskCheckRecordStatus"].markAsDirty();
    context.form.markAsDirty();
  }

  navigateToMap() {
    this.closeModal(this.form, this.dialogRef).then(() =>
      this.router.navigate([
        `/map/taskCheckRecordId/${this.taskCheckRecord.id}`
      ])
    );
  }
}
