import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation
} from "@angular/core";
import {Observable, Subscription} from "rxjs";
import {StringFilterOperations} from "./custom-filter-operations/string-filter.operations";
import {NumberFilterOperations} from "./custom-filter-operations/number-filter.operations";
import {IgxColumnComponent, IgxGridComponent, IgxStringFilteringOperand} from "igniteui-angular";

@Component({
  selector: "custom-filter",
  templateUrl: "./custom-filter.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ["./custom-filter.component.css"]
})
export class CustomFilterComponent implements OnInit, OnDestroy {
  @Input()
  column: IgxColumnComponent;
  @Input()
  grid: IgxGridComponent;
  @Input()
  events: Observable<void>;
  @Input()
  dataType: string;
  @Input()
  dataChanged: Observable<void>;
  @Input()
  refresh: Observable<void>;
  @Input()
  columnsMoved: Observable<void>;
  @Output()
  onFiltered = new EventEmitter();

  filterValue = "";
  selectedFilter: any;
  operations: any[];

  availableValues = [];
  selectedOption: any;
  isMoney = false;

  private subscriptions = new Subscription();

  ngOnInit() {
    this.updateSelect().then((v) => this.initialiseFilter());

    switch (this.dataType) {
      case "string":
        this.operations = StringFilterOperations;
        break;
      case "number":
        this.operations = NumberFilterOperations;
        break;
      case "money":
        this.operations = NumberFilterOperations;
        this.isMoney = true;
        break;
    }

    this.subscriptions = this.events.subscribe(() =>
      this.clearInput(this.column)
    );
    if (this.refresh) {
      this.subscriptions.add(
        this.refresh.subscribe(() => {
          this.filterValue = "";
          this.initialiseFilter();
        })
      );
    }


    if (this.dataChanged) {
      this.subscriptions.add(
        this.dataChanged.subscribe(() => {
          this.updateSelect();
        })
      );
    }

    if (this.columnsMoved) {
      this.subscriptions.add(
        this.columnsMoved.subscribe(() => {
          setTimeout(() => {
            this.initialiseFilter();
          });
        })
      );

    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  initialiseFilter() {
    return new Promise<void>((resolve, reject) => {
      let clearInput = !!this.filterValue;
      for (
        let i = 0;
        i < this.grid.filteringExpressionsTree.filteringOperands.length;
        i++
      ) {
        // additional variable needed to circumvent incorrect reference from IGX
        const t: any = this.grid.filteringExpressionsTree.filteringOperands[i];
        if (t.fieldName === this.column.field) {
          this.selectedFilter = this.operations.find(
            (c) => c.iconName === t.filteringOperands[0].condition.iconName
          );
          clearInput = false;
          this.filterValue = t.filteringOperands[0].searchVal;
        }
      }
      if (!this.selectedFilter) {
        this.selectedFilter = this.operations[0];
      }
      if (clearInput) {
        this.filterValue = "";
      }
      resolve();
    });
  }

  filterChange($event) {
    this.selectedFilter = $event;
    if (["Empty", "Not Empty"].includes(this.selectedFilter.name)) {
      this.filterValue = "";
    }
    this.onInput({value: this.filterValue}, this.column);
  }

  optionSelected($event) {
    this.onFiltered.emit();
    this.filterValue = $event;
    this.grid.filter(
      this.column.field,
      $event,
      IgxStringFilteringOperand.instance().condition("equals"),
      true
    );
  }

  onInput(input: any, column: IgxColumnComponent) {

    if (!this.selectedFilter || !this.selectedFilter.name) {
      console.log("No filter selected")
      return;
    }

    const skippedOperations = ["Empty", "Is Not Empty", "Yes", "No"];

    if (
      input.value === "" &&
      !skippedOperations.includes(this.selectedFilter.name)
    ) {
      this.grid.clearFilter(column.field);
      this.filterValue = "";
      return;
    }

    // if a non-typed filter is selected we'll stop them trying to
    if (
      skippedOperations.includes(this.selectedFilter.name) &&
      input.value !== ""
    ) {
      this.filterValue = "";
      input.value = "";
      return;
    }

    const operand =
      this.selectedFilter.logic != undefined ? this.selectedFilter.logic : null;
    this.grid.filter(column.field, input.value, operand, true);
    this.onFiltered.emit();
  }

  clearInput(column: IgxColumnComponent) {
    this.filterValue = "";
    this.selectedOption = "";
    this.grid.clearFilter(column.field);
    this.selectedFilter = this.operations[0];
    this.onFiltered.emit();
  }

  updateSelect() {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        const data = this.grid.filteredSortedData
          ? this.grid.filteredSortedData
          : this.grid.data;
        if (Array.isArray(data)) {
          this.availableValues = [];
          data.map((c) => {
            if (
              c[this.column.field] &&
              !this.availableValues.includes(c[this.column.field])
            )
              this.availableValues.push(c[this.column.field]);
          });
          this.availableValues.sort((a: any, b: any) => {
            if (a < b) {
              return -1;
            }
            if (a > b) {
              return 1;
            }
            return 0;
          });
          resolve(this.availableValues);
        }
        resolve(this.availableValues);
      })


    });
  }
}
