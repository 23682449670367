<div class="date-filter">
  <mat-menu #appMenu="matMenu" class="date-container">
    <form
      [formGroup]="form"
      (click)="stopPropagation($event)"
      class="d-flex flex-column px-2"
    >
      <div class="d-flex except">
        <button
          mat-stroked-button
          class="flex-grow-1"
          (click)="applyEmptyFilter(true)"
          type="button"
        >
          Empty
        </button>
        <button
          mat-stroked-button
          class="w-50"
          (click)="applyEmptyFilter(false)"
          type="button"
        >
          Not Empty
        </button>
      </div>
      <mat-form-field class="mt-2">
        <mat-label>On</mat-label>
        <div class="d-flex">
          <input
            matInput
            autocomplete="onDate"
            data-lpignore="true"
            [matDatepicker]="onDate"
            formControlName="onDate"
          />
          <span
            *ngIf="form.controls['onDate'].value"
            (click)="form.controls['onDate'].setValue(null)"
          >
            <i class="fal fa-times"></i>
          </span>
        </div>
        <mat-datepicker-toggle
          matSuffix
          [for]="onDate"
          (click)="form.controls['onDate'].setValue(null)"
        ></mat-datepicker-toggle>
        <mat-datepicker #onDate (closed)="searchBtn.focus()"></mat-datepicker>
      </mat-form-field>
      <mat-form-field>
        <mat-label>From</mat-label>
        <div class="d-flex">
          <input
            matInput
            autocomplete="startDate"
            data-lpignore="true"
            [matDatepicker]="startDate"
            formControlName="startDate"
          />
          <span
            *ngIf="form.controls['startDate'].value"
            (click)="form.controls['startDate'].setValue(null)"
          >
            <i class="fal fa-times"></i>
          </span>
        </div>
        <mat-datepicker-toggle
          matSuffix
          [for]="startDate"
          (click)="form.controls['onDate'].setValue(null)"
        >
        </mat-datepicker-toggle>
        <mat-datepicker #startDate></mat-datepicker>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Until</mat-label>
        <div class="d-flex">
          <input
            matInput
            autocomplete="endDate"
            data-lpignore="true"
            [matDatepicker]="endDate"
            formControlName="endDate"
          />
          <span
            *ngIf="form.controls['endDate'].value"
            (click)="form.controls['endDate'].setValue(null)"
          >
            <i class="fal fa-times"></i>
          </span>
        </div>
        <mat-datepicker-toggle matSuffix [for]="endDate">
        </mat-datepicker-toggle>
        <mat-datepicker #endDate (closed)="searchBtn.focus()"></mat-datepicker>
      </mat-form-field>
      <button #searchBtn mat-flat-button class="my-2 border" (click)="apply()">
        Search
      </button>
      <button mat-flat-button class="my-2 border" (click)="clear()">
        Clear
      </button>
    </form>
  </mat-menu>
  <div class="d-flex justify-content-between">
    <button
      mat-icon-button
      [matMenuTriggerFor]="appMenu"
      class="w-auto ms-1"
      [id]="column.header"
    >
      <igx-icon class="small-icon" family="imx-icons" name="this-month">
      </igx-icon>
    </button>
    <div class="d-flex flex-grow-1 justify-content-center date-display">
      <span class="align-self-center" *ngIf="onEmpty">Empty</span>
      <span class="align-self-center" *ngIf="onNotEmpty">Not Empty</span>
      <span
        class="align-self-center"
        *ngIf="
          form.controls['onDate'].value &&
          !form.controls['startDate'].value &&
          !form.controls['endDate'].value
        "
      >
        {{ form.controls["onDate"].value | date: "dd/MM/yyyy" }}
      </span>
      <span
        class="align-self-center"
        *ngIf="
          form.controls['startDate'].value && !form.controls['endDate'].value
        "
      >
        From {{ form.controls["startDate"].value | date: "dd/MM/yyyy" }}
      </span>
      <span
        class="align-self-center"
        *ngIf="
          form.controls['endDate'].value && !form.controls['startDate'].value
        "
      >
        To {{ form.controls["endDate"].value | date: "dd/MM/yyyy" }}
      </span>
      <small
        class="align-self-center"
        *ngIf="
          form.controls['endDate'].value && form.controls['startDate'].value
        "
      >
        {{ form.controls["startDate"].value | date: "dd/MM/yyyy" }} -
        {{ form.controls["endDate"].value | date: "dd/MM/yyyy" }}
      </small>
    </div>
    <div
      class="d-flex pointer"
      *ngIf="
        form.controls['startDate'].value ||
        form.controls['endDate'].value ||
        form.controls['onDate'].value
      "
      (click)="clearInput(column)"
      tabindex="0"
    >
      <igx-icon class="align-self-center">clear</igx-icon>
    </div>
  </div>
</div>
