import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { Claims } from '../auth/models/claims';

@Component({
  selector: 'home',
  templateUrl: './home.component.html'
})
export class HomeComponent implements OnInit {

  constructor(public router: Router, private authService: AuthService) { }

  menu = [
    {
      name: "Content",
      children: [
        {
          name: "Dashboard",
          icon: "far fa-tachometer-alt-fastest fa-lg",
          path: "dashboard",
          hidden: !this.authService.hasClaim(Claims.dashboardView)
        },
        {
          name: "Sites",
          icon: "fal fa-globe fa-lg",
          path: "sites",
          hidden: !this.authService.hasClaim(Claims.siteView)
        },
        {
          name: "Equipment",
          icon: "fas fa-truck-pickup fa-lg",
          path: "equipment",
          hidden: !this.authService.hasClaim(Claims.equipmentView)
        },
        {
          name: "Inspections",
          icon: "far fa-clipboard-list-check  fa-lg",
          path: "inspections",
          hidden: !this.authService.hasClaim(Claims.inspectionView)
        },
        {
          name: "Issues",
          icon: "far fa-map-marker-exclamation fa-lg",
          path: "issues",
          hidden: !this.authService.hasClaim(Claims.issueView)
        },
        {
          name: "Tasks",
          icon: "fas fa-tools fa-lg",
          path: "tasks",
          hidden: !this.authService.hasClaim(Claims.taskView)
        },
        {
          name: "People",
          icon: "fal fa-user-friends",
          path: "people",
          hidden: !this.authService.hasClaim(Claims.peopleView)
        },
        {
          name: "Programs",
          icon: "fal fa-list-ol fa-lg",
          path: "programs",
          hidden: !this.authService.hasClaim(Claims.programView)
        },
        {
          name: "Stock",
          icon: "fas fa-people-carry fa-lg",
          path: "stock",
          hidden: !this.authService.hasClaim(Claims.stockView)
        },
        {
          name: "Reports",
          icon: "far fa-file-chart-pie fa-lg",
          action: this.openReportModal,
          hidden: !this.authService.hasClaim(Claims.reportView)
        },
        {
          name: "Map",
          icon: "fas fa-map-marked-alt fa-lg",
          path: "map",
          hidden: !this.authService.hasClaim(Claims.mapView)
        }
      ]
    }, {
      name: "Extra",
      children: [
        {
          name: "Log out",
          icon: "fad fa-2x fa-fw fa-door-open",
          action: this.logOut,
          hidden: false
        }
      ]
    }
  ];

  ngOnInit() {
    if (!this.authService.securityObject.isAuthenticated) {
      this.logOut(this);
    }

    var list = [];
    for (let i = 0; i < this.menu[0].children.length; i++) {
      if (!this.menu[0].children[i].hidden) {
        list.push(this.menu[0].children[i]);
      }
    }
    this.menu[0].children = list;
  }

  onAction(event) {
      event(this);
  }


  openReportModal(context) {

  }

  logOut(context) {
    context.authService.logout().subscribe();
    context.router.navigate(["login"]);
  }

}
