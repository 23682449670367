<div class="sites-modal">
  <div
    *ngIf="site.isArchived"
    class="statusBar text-center text-light bg-primary"
  >
    <span>You are currently viewing an Archived Site. All other records linked to the Site are also Archived and can be seen from the corresponding Archived Icon views.
</span>
  </div>
  <div class="d-flex m-0 position-relative h-100">
    <div class="p-3 primary">
      <div class="text-center">
        <i class="fal fa-thumbtack fa-3x"></i>
        <H5>Site</H5>
      </div>
      <div class="py-5 modal-nav">
        <button
          *ngFor="let item of nav"
          mat-button
          class="btn py-2 d-block w-100"
          [ngClass]="{ 'border-p': current.page == item.page }"
          (click)="changeSection(item)"
          [disabled]="item.disabled"
          [hidden]="item.hidden"
        >
          {{ item.name }}
        </button>
      </div>
    </div>
    <div class="corner-close" (click)="close(form)">
      <i class="fal fa-times fa-2x"></i>
    </div>
    <form
      class="bg-modal px-4 d-flex w-100"
      [formGroup]="form"
      data-lpignore="true"
      autocomplete="off"
      *ngIf="loaded && form; else loading"
      (input)="check(form)"
    >
      <content class="justify-content-between w-100">
        <!-- #region Header -->
        <header class="d-flex primary pt-3">
          <div>
            <h2 class="m-0">
              {{ isNew ? "Add Site" : site.name }}
            </h2>
            {{ current.name }}
          </div>
        </header>
        <!-- #endregion -->
        <!-- #region Main -->
        <main class="h-100" [ngSwitch]="current.page">
          <!-- #region Details -->
          <div *ngSwitchCase="'details'">
            <div class="row">
              <div class="col-12 col-md-6">
                <div class="card p-2 d-block">
                  <mat-form-field class="w-100">
                    <mat-label class="primary">Name</mat-label>
                    <input matInput #name formControlName="name"/>
                    <mat-error
                      *ngIf="
                        form.get('name').hasError('required') &&
                        form.get('name').touched
                      "
                    >
                      Name Required.
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field class="w-100">
                    <mat-label class="primary">Address</mat-label>
                    <input matInput #name formControlName="address"/>
                    <mat-error
                      *ngIf="
                        form.get('address').hasError('required') &&
                        form.get('address').touched
                      "
                    >
                      Address Required.
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field class="w-100">
                    <mat-label class="primary">Postcode</mat-label>
                    <input matInput #name formControlName="postcode"/>
                    <mat-error *ngIf="form.get('postcode').invalid">
                      Postcode is invalid.
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field class="w-100">
                    <mat-label class="primary">Category</mat-label>
                    <mat-select
                      formControlName="siteCategory"
                      [compareWith]="compareOverride"
                    >
                      <mat-option
                        *ngFor="
                          let category of metaData.siteCategories
                            | sort: 'name'
                            | archivedFilter: site.siteCategory
                        "
                        [value]="category"
                      >
                        {{ category.name }}
                      </mat-option>
                    </mat-select>
                    <mat-error
                      *ngIf="
                        form.get('siteCategory').hasError('required') &&
                        form.get('siteCategory').touched
                      "
                    >
                      Category Required.
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field class="w-100">
                    <mat-label class="primary">Area</mat-label>
                    <mat-select
                      formControlName="area"
                      [compareWith]="compareOverride"
                    >
                      <mat-option
                        *ngFor="
                          let area of metaData.areas
                            | sort: 'name'
                            | archivedFilter: site.area
                        "
                        [value]="area"
                      >
                        {{ area.name }}
                      </mat-option>
                    </mat-select>
                    <mat-error
                      *ngIf="
                        form.get('area').hasError('required') &&
                        form.get('area').touched
                      "
                    >
                      <span>Area Required.</span>
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field class="w-100">
                    <mat-label class="primary">Ward</mat-label>
                    <mat-select
                      formControlName="ward"
                      [compareWith]="compareOverride"
                    >
                      <mat-option
                        *ngFor="
                          let ward of metaData.wards
                            | sort: 'name'
                            | typeFilter: form.controls['area'].value
                            | archivedFilter: site.ward
                        "
                        [value]="ward"
                      >
                        {{ ward.name }}
                      </mat-option>
                    </mat-select>
                    <mat-error
                      *ngIf="
                        form.get('ward').hasError('required') &&
                        form.get('ward').touched
                      "
                    >
                      <span>Ward Required.</span>
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field class="w-100">
                    <mat-label class="primary">Client</mat-label>
                    <mat-select
                      formControlName="siteClient"
                      [compareWith]="compareOverride"
                    >
                      <mat-option [value]="null"></mat-option>
                      <mat-option
                        *ngFor="
                          let client of metaData.siteClients
                            | archivedFilter: site.siteClient
                            | sort: 'name'
                        "
                        [value]="client"
                      >
                        {{ client.name }}
                      </mat-option>
                    </mat-select>
                    <mat-error
                      *ngIf="
                        form.get('siteClient').hasError('required') &&
                        form.get('siteClient').touched
                      "
                    >
                      <span>Client Required.</span>
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="card p-2 mt-3 d-block">
                  <mat-form-field class="w-50 mt-2">
                    <mat-label class="primary">
                      Purchase Order Number
                    </mat-label>
                    <input matInput formControlName="purchaseOrderNumber"/>
                  </mat-form-field>
                  <mat-form-field class="w-50 mt-2">
                    <mat-label class="primary"> Reference</mat-label>
                    <input matInput formControlName="reference"/>
                  </mat-form-field>
                  <mat-form-field class="w-50">
                    <mat-label class="primary"> Latitude</mat-label>
                    <input matInput type="number" formControlName="latitude"/>
                    <mat-error *ngIf="form.get('latitude').invalid">
                      [-90 to 90] and 15 decimals max.
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field class="w-50">
                    <mat-label class="primary"> Longitude</mat-label>
                    <input matInput type="number" formControlName="longitude"/>
                    <mat-error *ngIf="form.get('longitude').invalid">
                      [-180 to 180] and 15 decimals max.
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field class="w-100">
                    <mat-label class="primary">Teams</mat-label>
                    <mat-select
                      formControlName="teams"
                      multiple
                      [compareWith]="compareOverride"
                    >
                      <mat-option
                        *ngFor="
                          let team of metaData.teams
                            | sort: 'name'
                            | archivedFilter: site.teams
                        "
                        [value]="team"
                      >
                        {{ team.name }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
              <div class="col-12 col-md-6 d-flex flex-column">
                <div class="card d-block flex-grow-1 text-center">
                  <app-image-carousel
                    [images]="site.images"
                    (goToPhotos)="current = { name: 'Photos', page: 'photos' }"
                  ></app-image-carousel>
                </div>
                <div class="card mt-3 p-2 d-block">
                  <mat-form-field class="w-100">
                    <mat-label class="primary"> Contacts</mat-label>
                    <textarea
                      matInput
                      class="h-100"
                      rows="2"
                      formControlName="contacts"
                    ></textarea>
                  </mat-form-field>
                  <mat-form-field class="w-100">
                    <mat-label class="primary">
                      Health & Safety Info
                    </mat-label>
                    <textarea
                      matInput
                      class="h-100"
                      rows="2"
                      formControlName="healthAndSafetyInfo"
                    ></textarea>
                  </mat-form-field>
                  <mat-form-field class="w-100">
                    <mat-label class="primary"> Site Information</mat-label>
                    <textarea
                      matInput
                      class="h-100"
                      rows="2"
                      formControlName="siteInfo"
                    ></textarea>
                  </mat-form-field>
                  <mat-form-field class="w-100">
                    <mat-label class="primary"> Description</mat-label>
                    <textarea
                      matInput
                      class="h-100"
                      rows="2"
                      formControlName="description"
                    ></textarea>
                  </mat-form-field>

                  <mat-form-field class="w-50">
                    <mat-label class="primary"> Hard Surface</mat-label>
                    <input matInput #hardSurface type="number" formControlName="hardSurface"/>
                  </mat-form-field>
                    <mat-form-field class="w-50">
                      <mat-label class="primary"> Soft Surface</mat-label>
                      <input matInput #softSurface type="number" formControlName="softSurface"/>
                    </mat-form-field>

                </div>
              </div>
            </div>
          </div>
          <!-- #endregion -->
          <div *ngSwitchCase="'customFields'">
            <app-custom-fields
              [parentForm]="form"
              [fields]="customFields"
              [entity]="site"
            ></app-custom-fields>
          </div>
          <div *ngSwitchCase="'schedule'" class="h-100">

            <div class="col-sm-12 p-2 mb-2 card">
              <mat-form-field class="offset-3 col-sm-6">
                <mat-label class="primary">Scheduled</mat-label>
                <mat-select
                  formControlName="isScheduled"
                >
                  <mat-option
                    *ngFor="let bool of bools"
                    [value]="bool.id"
                  >
                    {{ bool.name }}
                  </mat-option>
                </mat-select>
                <mat-hint align="start"> Scheduling a Site requires at least one Program assigned.
                </mat-hint>
              </mat-form-field>

            </div>
            <div class="row">
              <div class="col-6">
                <div class="d-flex flex-column">
                  <div
                    class="card d-block p-2 day-box flex-grow-1 text-center">
                    <mat-form-field class="w-50">
                      <mat-label class="primary">Start Date</mat-label>
                      <input
                        matInput
                        [matDatepicker]="pickerStartDate"
                        formControlName="startDate"
                      />
                      <mat-datepicker-toggle
                        matSuffix
                        [for]="pickerStartDate"
                      ></mat-datepicker-toggle>
                      <mat-datepicker #pickerStartDate></mat-datepicker>
                      <mat-hint class="overflow-hint text-danger" *ngIf="form.controls['startDate'].value"
                                align="start">Start Date must be removed to use Periods.
                      </mat-hint>
                    </mat-form-field>
                    <mat-form-field class="w-50">
                      <mat-label class="primary">End Date</mat-label>
                      <input
                        matInput
                        [matDatepicker]="pickerEndDate"
                        formControlName="endDate"
                      />
                      <mat-datepicker-toggle
                        matSuffix
                        [for]="pickerEndDate"
                      ></mat-datepicker-toggle>
                      <mat-datepicker #pickerEndDate></mat-datepicker>
                      <mat-error
                        *ngIf="
                        form.get('endDate').hasError('invalid') &&
                        form.get('endDate').touched
                      "
                      >
                        End Date cannot precede the Start Date
                      </mat-error>
                    </mat-form-field>
                    <mat-form-field #field class="mt-3 w-100">
                      <mat-label class="mat-label primary">Schedule Periods
                        <mat-icon (click)="info('periods')" matPrefix class="my-icon">help</mat-icon>
                      </mat-label>
                      <button mat-flat-button class="border-p w-100">
                        <mat-select
                          multiple
                          #matSelect
                          class="hide-selected"
                          formControlName="periods"
                          [compareWith]="compareOverride"
                          (ngInit)="matSelectLoad(form.controls['periods'].value)"
                        >
                          <mat-option
                            #matOption
                            *ngFor="let period of metaData.periods | sort: 'name' | archivedFilter"
                            [value]="period"
                            (ngInit)="initOption(field, matOption)"
                          >
                            {{ period.name }}
                          </mat-option>
                        </mat-select>
                      </button>
                    </mat-form-field>
                    <div
                      class="d-block"
                    >
                      <div
                        class="d-flex border rounded m-1 p-1 justify-content-between"
                        *ngFor="let item of form.controls['periods'].value; let i = index"
                      >
                        {{ item.name }}
                        <div
                          (click)="removeItem(item.id, form.controls['periods'].value, form.controls['periods'])"
                          [id]="'periods' + '-' + i + '-close'"
                        >
                          <i class="fas fa-times"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="d-flex flex-column">
                  <div class="card d-block p-2 day-box flex-grow-1 text-center">
                    <div class="m-0 row">
                      <div class="w-50 p-0">
                        <mat-form-field class="w-50 time-frame">
                          <mat-label class="mat-label-float primary">Time Frame
                          </mat-label>
                          <input matInput class="text-end" formControlName="timeFrameDays" min="1" type="number"/>
                          <mat-hint class="overflow-hint" *ngIf="form.controls['timeFrameDays'].enabled"
                                    align="start">(To be done every)
                          </mat-hint>
                        </mat-form-field>
                        <mat-form-field class="w-50 every">
                          <mat-label class="mat-label-float primary">(Every)
                            <mat-icon (click)="info('time-frame')" matPrefix class="my-icon">help</mat-icon>
                          </mat-label>
                          <mat-select
                            formControlName="timeFrameParam"
                          >
                            <mat-option [value]="1">Day(s)</mat-option>
                            <mat-option [value]="7">Week(s)</mat-option>
                            <mat-option [value]="28">Month(s)</mat-option>
                            <mat-option [value]="365">Year(s)</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                      <div class="w-50 p-0">
                        <mat-form-field class="w-100 buffer">
                          <mat-label class="mat-label-float primary">Buffer (optional)
                            <mat-icon (click)="info('buffer')" matPrefix class="my-icon">help</mat-icon>
                          </mat-label>
                          <input matInput class="text-end"
                                 formControlName="bufferDays"
                                 min="0"
                                 [max]="form.controls['timeFrameDays'].value *
                               form.controls['timeFrameParam'].value" type="number"/>
                          <span
                            *ngIf="form.controls['bufferDays'].enabled"
                            matSuffix> Day(s)</span>
                          <mat-hint *ngIf="form.controls['bufferDays'].enabled"
                                    align="end">(Between Time Frame occurrences)
                          </mat-hint>
                        </mat-form-field>
                      </div>
                    </div>
                    <mat-form-field #field class="mt-3 w-100">
                      <mat-label class="primary">Active Days</mat-label>
                      <mat-select
                        multiple
                        #matSelect
                        class="hide-selected"
                        formControlName="days"
                        [compareWith]="compareOverride"
                        (ngInit)="matSelectLoad(form.controls['days'].value)"
                      >
                        <mat-option
                          #matOption
                          *ngFor="let day of metaData.days"
                          [value]="day"
                          (ngInit)="initOption(field, matOption)"
                        >
                          {{ day.name }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <div
                      class="d-block"
                    >
                      <div
                        class="d-flex border rounded m-1 p-1 justify-content-between"
                        *ngFor="let item of form.controls['days'].value; let i = index"
                      >
                        {{ item.name }}
                        <div
                          (click)="removeItem(item.id, form.controls['days'].value, form.controls['days'])"
                          [id]="'days' + '-' + i + '-close'"
                        >
                          <i class="fas fa-times"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- #region tasks -->
          <div *ngSwitchCase="'tasks'" class="h-100">
            <app-grid
              #taskGridComponent
              [gridData]="site.tasks"
              [columns]="tasksColumns"
              [small]="true"
              (onClick)="click($event)"
              (onDoubleClick)="doubleClick($event)"
              [buttons]="addTaskButtons"
              [selection]="'none'"
              [groupable]="false"
            ></app-grid>
          </div>
          <!-- #endregion -->
          <!-- #region Task records -->
          <div *ngSwitchCase="'taskRecords'" class="h-100">
            <app-grid
              #taskRecordGridComponent
              [gridData]="site.taskRecords"
              [columns]="taskRecordsColumns"
              (onClick)="click($event)"
              (onDoubleClick)="doubleClick($event)"
              [small]="true"
              [selection]="'none'"
            ></app-grid>
          </div>
          <!-- #endregion -->
          <!-- #region Task checks -->
          <div *ngSwitchCase="'taskCheckRecords'" class="h-100">
            <app-grid
              #taskCheckRecordGridComponent
              [gridData]="site.taskCheckRecords"
              [columns]="taskCheckRecordsColumns"
              (onClick)="click($event)"
              (onDoubleClick)="doubleClick($event)"
              [small]="true"
              [selection]="'none'"
            ></app-grid>
          </div>
          <!-- #endregion -->
          <!-- #region quality checks -->
          <div *ngSwitchCase="'siteQualityCheckRecords'" class="h-100">
            <app-grid
              #siteQualityCheckRecordGridComponent
              [gridData]="site.siteQualityCheckRecords"
              [columns]="siteQualityCheckRecordsColumns"
              (onClick)="click($event)"
              (onDoubleClick)="doubleClick($event)"
              [small]="true"
              [selection]="'none'"
            ></app-grid>
          </div>
          <!-- #endregion -->
          <!-- #region issues -->
          <div *ngSwitchCase="'issues'" class="h-100">
            <app-grid
              #issuesGridComponent
              [gridData]="site.issues"
              [columns]="issuesColumns"
              [small]="true"
              (onClick)="click($event)"
              (onDoubleClick)="doubleClick($event)"
              [buttons]="addIssueButtons"
              [selection]="'none'"
              [groupable]="false"
            ></app-grid>
          </div>
          <!-- #endregion -->
          <!-- #region issues -->
          <div *ngSwitchCase="'inspections'" class="h-100">
            <app-grid
              #inspectionGridComponent
              [gridData]="site.inspections"
              [columns]="inspectionColumns"
              [small]="true"
              (onClick)="click($event)"
              (onDoubleClick)="doubleClick($event)"
              [buttons]="addInspectionButtons"
              [selection]="'none'"
              [groupable]="false"
            ></app-grid>
          </div>
          <!-- #endregion -->
          <!-- #region attendance -->
          <div *ngSwitchCase="'attendance'" class="h-100">
            <app-grid
              [gridData]="site.attendance"
              [columns]="attendanceColumns"
              [small]="true"
              [selection]="'none'"
            ></app-grid>
          </div>
          <!-- #endregion -->
          <!-- #region Notes -->
          <div *ngSwitchCase="'notes'" class="h-100">
            <div class="card p-3">
              <mat-form-field class="w-100">
                <mat-label class="primary">Notes</mat-label>
                <textarea
                  matInput
                  class="h-100"
                  rows="6"
                  formControlName="notes"
                ></textarea>
              </mat-form-field>
            </div>
            <div class="card p-3 mt-3">
              <div class="text-center">
                <div class="row m-0">
                  <div class="w-50 p-0">
                    <mat-form-field class="w-50">
                      <mat-label class="primary">Latitude</mat-label>
                      <input
                        matInput
                        formControlName="latitude"
                      />
                      <mat-error
                        *ngIf="
                                  form.get('latitude').status == 'INVALID' &&
                                  form.get('latitude').touched
                                "
                      >
                        [-90 to 90] and 15 decimals max.
                      </mat-error>
                    </mat-form-field>
                    <mat-form-field class="w-50">
                      <mat-label class="primary">Longitude</mat-label>
                      <input
                        matInput
                        formControlName="longitude"
                      />
                      <mat-error
                        *ngIf="
                                  form.get('longitude').status == 'INVALID' &&
                                  form.get('longitude').touched
                                "
                      >
                        [-180 to 180] and 15 decimals max.
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="w-50 row p-0">
                    <mat-form-field class="w-50">
                      <mat-label class="primary">What3Words</mat-label>
                      <input
                        matInput
                        disabled
                        formControlName="what3Words"
                      />
                    </mat-form-field>
                    <div class="w-50" *hasClaim="'map.edit'">
                      <button
                        class="plot-button border ms-3"
                        *ngIf="!site.isNew && !form.dirty"
                        mat-flat-button
                        (click)="navigateToMap()"
                      >
                        Plot Site on Map
                      </button>
                    </div>
                  </div>
                  <mat-form-field class="w-100 p-0">
                    <mat-label class="primary">Measured Distances</mat-label>
                    <input
                      matInput
                      disabled
                      formControlName="measuredDistances"
                    />
                  </mat-form-field>
                </div>

                <div
                  class="col-sm-12 p-0"
                  *ngIf="
                          site.parsedLatitude && site.parsedLongitude
                          else explain
                        "
                >
                  <div class="map-container">
                    <app-map
                      class="h-100"
                      [latitude]="
                              site.parsedLatitude
                                ? site.parsedLatitude
                                : form.controls['latitude'].value
                            "
                      [longitude]="
                              site.parsedLongitude
                                ? site.parsedLongitude
                                : form.controls['longitude'].value
                            "
                      [isStatic]="true"
                      [zoom]="18"
                      [id]="site.id"
                    >
                    </app-map>
                  </div>
                </div>
                <ng-template #explain>
                        <span class="explain">
                          Add a valid Latitude & Longitude to view the Map.
                        </span>

                </ng-template>
              </div>
            </div>
          </div>
          <!-- #endregion -->
          <!-- #region Photos -->
          <div *ngSwitchCase="'photos'" class="h-100">
            <div class="row h-100">
              <app-photos
                *ngIf="isNew"
                [imageArrays]="[site.images || []]"
                class="col-12 pb-1 h-100"
                [arrayNames]="allNames"
                [isNew]="true"
                [addPhotos]="hasClaim"
                (addedImage)="checkImages($event)"
                (removedImage)="form.markAsDirty()"
              >
              </app-photos>
              <app-photos
                *ngIf="!isNew"
                [addPhotos]="hasClaim"
                class="col-12 pb-1 h-100"
                [imageArrays]="[site.images || []]"
                [arrayNames]="allNames"
                [entity]="{ type: 'site', id: site.id }"
                (addedImage)="checkImages($event)"
                (removedImage)="form.markAsDirty()"
              >
              </app-photos>
            </div>
          </div>
          <!-- #endregion -->
          <!-- #region Links and Files -->
          <div *ngSwitchCase="'linksAndFiles'" class="h-100">
            <app-documents
              class="documents"
              [canAdd]="hasClaim && !site.isNew"
              [documents]="site.documents || []"
              [siteId]="site.id"
              [form]="form"
              (addedDocument)="checkDocuments($event)"
            >
            </app-documents>
          </div>
          <!-- #endregion -->
          <!-- #region History / AuditTrail -->
          <div *ngSwitchCase="'auditTrail'">
            <app-audit-trail
              id="auditTrail"
              [auditTrail]="site.auditTrail"
            ></app-audit-trail>
          </div>
          <!-- #endregion -->
        </main>
        <!-- #endregion -->
        <!-- #region Footer -->
        <footer
          class="flex justify-content-end my-3"
          [ngClass]="site.isArchived ? 'pb-4' : ''"
        >
          <div>
            <button
              mat-flat-button
              name="detailSaveButton"
              class="ms-2 px-4"
              [disabled]="form.invalid || !form.dirty || !hasClaim || saving"
              (click)="save()"
            >
              <span *ngIf="saving">
                <i class="fad fa-spinner-third fa-spin"> </i>
              </span>
              <span *ngIf="!saving">Save</span>
            </button>
            <button mat-flat-button class="ms-2 px-4" (click)="close(form)">
              Close
            </button>
          </div>
        </footer>
        <!-- #endregion -->
      </content>
    </form>
    <ng-template #loading>
      <app-loading
        class="w-100 d-flex align-items-center justify-content-center bg-modal"
      ></app-loading>
    </ng-template>
  </div>
</div>
