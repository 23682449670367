import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "typeFilter" })
export class TypeFilterPipe implements PipeTransform {
  transform(value: any, filter: any): any {
    if (value) {
      if (filter && filter.id == "ALL") {
        return value;
      } else {
        return value.filter((c) => {
          if (filter) {
            return c.type.id === filter.id;
          }
        });
      }
    }
  }
}
