import {
  Component,
  EventEmitter,
  Input,
  Output,
  Injectable,
  HostListener
} from "@angular/core";

@Component({
  selector: "context-menu",
  templateUrl: "./context-menu.component.html"
})
@Injectable()
export class ContextmenuComponent {
  @Input()
  x = 0;
  @Input()
  y = 0;
  @Input()
  cell;
  @Input()
  selectedCells;
  @Input()
  contextMenuOptions = [];

  groups = [];

  screenHeight: number;
  parsedOptions = [];
  hasEntered = false;

  @Output()
  onClose = new EventEmitter<any>();
  @Output()
  onClick = new EventEmitter<any>();

  constructor() {
    this.getScreenSize();
  }

  click(call) {
    if (!call.disabled) {
      this.onClick.emit({ cell: this.cell, call: call });
    }
  }

  ngOnInit() {
    let multipleSelect = false;
    if (this.selectedCells) {
      if (this.selectedCells.data && this.selectedCells.data.length > 1) {
        this.contextMenuOptions = this.contextMenuOptions.filter(
          (c) => c.multipleSelect
        );
        multipleSelect = true;
      } else {
        this.contextMenuOptions = this.contextMenuOptions.filter(
          (c) => !c.multipleSelectOnly
        );
      }
      for (let i = 0; i < this.contextMenuOptions.length; i++) {
        if (multipleSelect && this.contextMenuOptions[i].multipleCondition) {
          if (
            this.contextMenuOptions[i].multipleCondition(
              this.selectedCells.data
            )
          ) {
            this.parsedOptions.push(this.contextMenuOptions[i]);
          }
        } else if (this.contextMenuOptions[i].condition != null) {
          if (this.contextMenuOptions[i].condition(this.cell.row.data)) {
            this.parsedOptions.push(this.contextMenuOptions[i]);
          }
        } else {
          this.parsedOptions.push(this.contextMenuOptions[i]);
        }
      }
    } else {
      if (!this.cell) {
        this.parsedOptions = this.contextMenuOptions.filter(
          (c) => !c.multipleSelect
        );
      }
    }

    const renderedHeight = this.parsedOptions.length * 25;
    if (this.y + renderedHeight > this.screenHeight) {
      this.y = this.y - renderedHeight;
    }

    const group = this.groupBy(this.parsedOptions, "group");
    this.groups = group.filter((g) => g.length > 0);
  }

  groupBy(xs, key) {
    return xs.reduce((rv, x) => {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, []);
  }

  @HostListener("window:resize", ["$event"])
  getScreenSize(event?) {
    this.screenHeight = window.innerHeight;
  }
}
