<div class="corner-close" (click)="close(form)">
  <i class="fal fa-times fa-2x"></i>
</div>
<form
  class="d-flex flex-grow-1 edit-form w-100"
  [formGroup]="form"
  autocomplete="ignore"
  *ngIf="loaded; else loading"
  (input)="check(form)"
>
  <content class="d-flex flex-column justify-content-between w-100">
    <header class="p-2 pt-4 text-center">
      <h2>{{ title }}</h2>
    </header>
    <main class="card">
      <ng-container *ngFor="let field of fields">
        <mat-form-field
          class="w-100 p-2"
          *ngIf="!field.dependent || form.controls[field.dependent].value"
        >
          <mat-label class="h6 primary">{{
            !field.required ? field.label : field.label + "*"
          }}</mat-label>
          <ng-container [ngSwitch]="field.type">
            <!-- Bool -->
            <ng-container *ngSwitchCase="'bool'">
              <mat-select
                matInput
                formControlName="{{ field.name }}"
                name="{{ field.name }}"
                [id]="field.name"
              >
                <mat-option *ngFor="let bool of bools" [value]="bool.id">
                  {{ bool.name }}
                </mat-option>
              </mat-select>
            </ng-container>
            <!-- Select -->
            <ng-container *ngSwitchCase="'select'">
              <mat-select
                matInput
                formControlName="{{ field.name }}"
                [compareWith]="compareOverride"
                [id]="field.name"
              >
                <mat-option *ngIf="field.optionAll" value="null"
                  >All
                </mat-option>
                <mat-option *ngIf="field.blank" [value]="null"> </mat-option>
                <span *ngIf="!field.filter; else filterResults">
                  <span *ngIf="field.metaDataType; else noSpecify">
                    <mat-option
                      #matOption
                      *ngFor="
                        let m of metaData[field.metaDataType]
                          | sort: 'name'
                          | archivedFilter: form.controls[field.name].value
                      "
                      [value]="m"
                    >
                      {{ m.name }}
                    </mat-option>
                  </span>
                  <ng-template #noSpecify>
                    <mat-option
                      #matOption
                      *ngFor="
                        let m of metaData[field.name]
                          | sort: 'name'
                          | archivedFilter: form.controls[field.name].value
                      "
                      [value]="m"
                    >
                      {{ m.name }}
                    </mat-option>
                  </ng-template>
                </span>
                <ng-template #filterResults>
                  <mat-option
                    *ngFor="
                      let m of metaData[field.name]
                        | sort: 'name'
                        | archivedFilter: form.controls[field.name].value
                    "
                    [value]="m"
                  >
                    {{ m.name }}
                  </mat-option>
                </ng-template>
              </mat-select>
            </ng-container>
            <!-- Multiple Select -->
            <ng-container *ngSwitchCase="'multipleSelect'">
              <mat-select
                multiple
                *ngIf="metaData[field.name] || metaData[field.metaDataType]"
                formControlName="{{ field.name }}"
                [compareWith]="compareOverride"
                [id]="field.name"
              >
                <span *ngIf="!field.filter; else filterResults">
                  <span *ngIf="field.metaDataType; else noSpecify">
                    <mat-option
                      #matOption
                      *ngFor="
                        let m of metaData[field.metaDataType]
                          | sort: 'name'
                          | archivedFilter: form.controls[field.name].value
                      "
                      [value]="m"
                    >
                      {{ m.name }}
                    </mat-option>
                  </span>
                  <ng-template #noSpecify>
                    <mat-option
                      #matOption
                      *ngFor="
                        let m of metaData[field.name]
                          | sort: 'name'
                          | archivedFilter: form.controls[field.name].value
                      "
                      [value]="m"
                    >
                      {{ m.name }}
                    </mat-option>
                  </ng-template>
                </span>
                <ng-template #filterResults>
                  <mat-option
                    *ngFor="
                      let m of metaData[field.name]
                        | sort: 'name'
                        | typeFilter: form.controls[field.filter].value
                        | archivedFilter: form.controls[field.name].value
                    "
                    [value]="m"
                  >
                    {{ m.name }}
                  </mat-option>
                </ng-template>
              </mat-select>
            </ng-container>
            <!-- Same as above but with drag and drop included later -->
            <ng-container *ngSwitchCase="'multiDrag'">
              <mat-select
                *ngIf="metaData[field.name] || metaData[field.metaDataType]"
                multiple
                #matSelect
                class="multiDrag"
                formControlName="{{ field.name }}"
                [compareWith]="compareOverride"
                (selectionChange)="
                  changeSelected(field, matSelect.selected, $event)
                "
                (ngInit)="matSelectLoad(form.controls[field.name].value)"
                [id]="field.name"
              >
                <span *ngIf="!field.filter; else filterResults">
                  <span *ngIf="field.metaDataType; else noSpecify">
                    <mat-option
                      #matOption
                      *ngFor="
                        let m of metaData[field.metaDataType]
                          | sort: 'name'
                          | archivedFilter: form.controls[field.name].value
                      "
                      [value]="m"
                      (ngInit)="initOption(field, matOption)"
                    >
                      {{ m.name }}
                    </mat-option>
                  </span>
                  <ng-template #noSpecify>
                    <mat-option
                      #matOption
                      *ngFor="let m of metaData[field.name]
                      | sort: 'order'
                      | archivedFilter : form.controls[field.name].value
                      "
                      [value]="m"
                      (ngInit)="initOption(field, matOption)"
                    >
                      {{ m.name }}
                    </mat-option>
                  </ng-template>
                </span>
                <ng-template #filterResults>
                  <mat-option
                    #matOption
                    *ngFor="let m of metaData[field.name]
                    | sort: 'name'
                    | archivedFilter : form.controls[field.name].value"
                    [value]="m"
                    (ngInit)="initOption(field, matOption)"
                    [ngClass]="
                      form.controls[field.filter].value === null ||
                      form.controls[field.filter].value === 'All' ||
                      form.controls[field.filter].value.id === m.type.id
                        ? ''
                        : 'd-none'
                    "
                  >
                    {{ m.name }}
                  </mat-option>
                </ng-template>
              </mat-select>
            </ng-container>
            <!-- Multiple Drag No Order -->
            <ng-container *ngSwitchCase="'multiDragNoOrder'">
              <mat-select
                *ngIf="metaData[field.name] || metaData[field.metaDataType]"
                multiple
                #matSelect
                class="multiDrag"
                formControlName="{{ field.name }}"
                [compareWith]="compareOverride"
                (selectionChange)="
                  changeSelected(field, matSelect.selected, $event)
                "
                (ngInit)="matSelectLoad(form.controls[field.name].value)"
                [id]="field.name"
              >
                <span *ngIf="!field.filter; else filterResults">
                  <span *ngIf="field.metaDataType; else noSpecify">
                    <mat-option
                      #matOption
                      *ngFor="
                        let m of metaData[field.metaDataType]
                        | sort: 'name'
                        | archivedFilter : form.controls[field.name].value
                      "
                      [value]="m"
                      (ngInit)="initOption(field, matOption)"
                    >
                      {{ m.name }}
                    </mat-option>
                  </span>
                  <ng-template #noSpecify>
                    <mat-option
                      #matOption
                      *ngFor="let m of metaData[field.name]
                      | sort: 'name'
                      | archivedFilter : form.controls[field.name].value
                      "
                      [value]="m"
                      (ngInit)="initOption(field, matOption)"
                    >
                      {{ m.name }}
                    </mat-option>
                  </ng-template>
                </span>
                <ng-template #filterResults>
                  <mat-option
                    #matOption
                    *ngFor="
                      let m of metaData[field.name]
                        | sort: 'name'
                        | archivedFilter: form.controls[field.name].value
                    "
                    [value]="m"
                    (ngInit)="initOption(field, matOption)"
                    [ngClass]="
                      form.controls[field.filter].value === null ||
                      form.controls[field.filter].value === 'All' ||
                      form.controls[field.filter].value.id === m.type.id
                        ? ''
                        : 'd-none'
                    "
                  >
                    {{ m.name }}
                  </mat-option>
                </ng-template>
              </mat-select>
            </ng-container>
            <!-- Email -->
            <ng-container *ngSwitchCase="'email'">
              <input
                name="email"
                data-lpignore="true"
                matInput
                formControlName="{{ field.name }}"
                type="email"
                email
                [id]="field.name"
              />
            </ng-container>
            <!-- Password -->
            <ng-container *ngSwitchCase="'password'">
              <input
                data-lpignore="true"
                placeholder="Requires 8 Characters, 1 Symbol and 1 Number"
                name="{{ field.name }}"
                autocomplete="chrome-off"
                matInput
                type="{{ showPwd ? 'text' : 'password' }}"
                formControlName="{{ field.name }}"
                [id]="field.name"
              />
              <mat-icon
                matSuffix
                class="pointer"
                (click)="showPwd = !showPwd"
                >{{ showPwd ? "visibility_off" : "visibility" }}</mat-icon
              >
            </ng-container>
            <!-- Large Text -->
            <ng-container *ngSwitchCase="'largeText'">
              <textarea
                data-lpignore="true"
                matInput
                rows="4"
                formControlName="{{ field.name }}"
                [id]="field.name"
              ></textarea>
            </ng-container>
            <!-- Int -->
            <ng-container *ngSwitchCase="'int'">
              <input
                data-lpignore="true"
                matInput
                formControlName="{{ field.name }}"
                name="{{ field.name }}"
                type="number"
                step="1"
                min="0"
                [id]="field.name"
              />
            </ng-container>
            <!-- Decimal -->
            <ng-container *ngSwitchCase="'decimal'">
              <input
                data-lpignore="true"
                matInput
                formControlName="{{ field.name }}"
                name="{{ field.name }}"
                type="number"
                placeholder="0.00"
                step="0.01"
                min="0"
                [id]="field.name"
              />
            </ng-container>
            <!-- Date -->
            <ng-container *ngSwitchCase="'date'">
              <div *ngIf="!showUnknown" class="d-flex align-content-around">
                <input
                  matInput
                  [matDatepicker]="datePicker"
                  formControlName="{{ field.name }}"
                  name="{{ field.name }}"
                  [max]="field.untilToday ? today : null"
                  [id]="field.name"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="datePicker"
                ></mat-datepicker-toggle>
                <mat-datepicker #datePicker></mat-datepicker>
              </div>
              <div *ngIf="showUnknown" class="d-flex align-content-around">
                <input
                  matInput
                  [matDatepicker]="datePicker1"
                  formControlName="{{ field.name }}"
                  name="{{ field.name }}"
                  [max]="field.untilToday ? today : null"
                  [id]="field.name"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="datePicker1"
                ></mat-datepicker-toggle>
                <mat-datepicker
                  [calendarHeaderComponent]="customHeader"
                  #datePicker1
                ></mat-datepicker>
              </div>
            </ng-container>
            <!-- DateTime -->
            <ng-container *ngSwitchCase="'dateTime'">


                <input matInput type="datetime-local" formControlName="{{ field.name }}">

            </ng-container>
            <!-- Default -->
            <ng-container *ngSwitchDefault>
              <input
                data-lpignore="true"
                autocomplete="nu{{ field.name }}"
                matInput
                name="{{ field.name }}"
                formControlName="{{ field.name }}"
                [id]="field.name"
              />
            </ng-container>
          </ng-container>
          <mat-error>
            <span *ngIf="form.get(field.name)?.invalid"
              >{{ field.label }} is invalid.</span
            >
            <span
              *ngIf="
                form.get(field.name)?.hasError('required') &&
                form.get(field.name)?.touched
              "
            >
              {{ field.label }} Required.
            </span>
          </mat-error>
        </mat-form-field>
        <ng-container *ngIf="field.type === 'multiDrag' || field.type === 'multiDragNoOrder'">
          <div
            cdkDropList
            (cdkDropListDropped)="
              handleListDrop($event, form.controls[field.name].value)
            "
            class="d-block"
          >
            <div
              class="d-flex border rounded p-1 justify-content-between"
              *ngFor="
                let item of form.controls[field.name].value;
                let i = index
              "
              cdkDrag
              cdkDragLockAxis="y"
              cdkDragBoundary=".boundary"
              [cdkDragData]="form.controls[field.name].value"
              [id]="field.name + '-' + i"
            >
              {{ i + 1 }}) {{ item.name }}
              <div
                (click)="
                  removeItem(
                    item.id,
                    form.controls[field.name].value,
                    form.controls[field.name]
                  )
                "
                [id]="field.name + '-' + i + '-close'"
              >
                <i class="fas fa-times"></i>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </main>
    <footer class="flex justify-content-end mt-3">
      <button
        mat-flat-button
        class="ms-2 px-4"
        [disabled]="form.invalid || !form.dirty || !hasClaim || data.isSystem"
        name="save"
        (click)="save()"
      >
        <span *ngIf="saving">
                <i class="fad fa-spinner-third fa-spin">
                </i>
              </span>
        <span *ngIf="!saving">{{ replaceSave ? replaceSave : "Save" }}</span>
      </button>
      <button
        mat-flat-button
        class="ms-2 px-4"
        name="close"
        (click)="closeModal(form, dialogRef)"
      >
        {{ replaceClose ? replaceClose : "Close" }}
      </button>
    </footer>
  </content>
</form>
<ng-template #loading>
  <app-loading
    class="w-100 h-100 d-flex align-items-center justify-content-center"
  ></app-loading>
</ng-template>
