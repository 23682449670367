import { Component, Input, OnDestroy, OnInit, Output, EventEmitter, ViewChild } from
  "@angular/core";
import { IgxGridComponent, IgxColumnComponent } from "igniteui-angular";
import { MatMenuTrigger } from "@angular/material/menu";
import { Observable, Subscription } from "rxjs";
import { UntypedFormGroup, UntypedFormControl } from "@angular/forms";
import { CustomDateFilteringOperand } from "./custom-date-filter.component";
import { distinctUntilChanged } from "rxjs/operators";
import { BaseComponent } from "src/app/shared/base/base.component";


@Component({
  selector: "date-filter",
  templateUrl: "./date-filter.component.html"
})
export class DateFilterComponent extends BaseComponent implements OnInit, OnDestroy  {

  customDateFilteringOperand = CustomDateFilteringOperand.instance();

  @Input()
  column: IgxColumnComponent;
  @Input()
  grid: IgxGridComponent;
  @Input()
  events: Observable<void>;
  @Output()
  onFiltered = new EventEmitter();
  @Output("closed")
  closedStream: EventEmitter<void>;

  storageReference;
  form: UntypedFormGroup;
  startDate;
  endDate;
  onDate;

  subscriptions;

  onEmpty = false;
  onNotEmpty = false;

  @ViewChild(MatMenuTrigger, { static: false })
  trigger: MatMenuTrigger;


  private eventsSubscription: Subscription;

  ngOnInit() {

    this.form = new UntypedFormGroup({
      startDate: new UntypedFormControl(this.startDate),
      endDate: new UntypedFormControl(this.endDate),
      onDate: new UntypedFormControl(this.onDate)
    });

    this.subscriptions = this.form.controls["onDate"].valueChanges.pipe(distinctUntilChanged())
      .subscribe(value => {
        if (value) {
          this.form.controls["startDate"].disable();
          this.form.controls["endDate"].disable();
        } else {
          this.form.controls["startDate"].enable();
          this.form.controls["endDate"].enable();
        }
      });

    this.subscriptions.add(this.form.controls["startDate"].valueChanges.pipe(distinctUntilChanged())
      .subscribe(value => {
        if (value) {
          this.form.controls["onDate"].disable();
        } else if (!this.form.controls["endDate"].value) {
          this.form.controls["onDate"].enable();
        }
      }));

    this.subscriptions.add(this.form.controls["endDate"].valueChanges.pipe(distinctUntilChanged())
      .subscribe(value => {
        if (value) {
          this.form.controls["onDate"].disable();
        } else if (!this.form.controls["startDate"].value) {
          this.form.controls["onDate"].enable();
        }
      }));

    this.clearInput(this.column);

    // As we're using a custom filter we cannot depend on IGX to retrieve the filter
    this.storageReference = this.grid.id + this.column.field;
    var local = JSON.parse(localStorage.getItem(this.storageReference));

    if (local != undefined) {

      if (local[0]) {
        this.form.controls["startDate"].setValue(this.startDate = new Date(local[0]));
      }

      if (local[1]) {
        this.form.controls["endDate"].setValue(this.startDate = new Date(local[1]));
      }

      if (local[2]) {
        this.form.controls["onDate"].setValue(this.onDate = new Date(local[2]));
      }

      setTimeout(() => {
          if (local == true || local == false) {
            this.applyEmptyFilter(local);
          } else {
            this.apply();
          }
        },
        100);
    }

    this.eventsSubscription = this.events.subscribe(() => this.clearInput(this.column));
  }

  ngOnDestroy() {
    this.eventsSubscription.unsubscribe();
    this.subscriptions.unsubscribe();
  }

  stopPropagation(event) {
    event.stopPropagation();
  }

  applyEmptyFilter(isEmpty: boolean) {

    this.clearInput(this.column);

    if (isEmpty) {
      this.grid.filter(this.column.field, "", this.customDateFilteringOperand.operations[4]);
      this.onEmpty = true;
    } else {
      this.grid.filter(this.column.field, "", this.customDateFilteringOperand.operations[5]);
      this.onNotEmpty = true;
    }

    localStorage.setItem(this.storageReference, JSON.stringify(isEmpty));
    this.onFiltered.emit();
    this.trigger.closeMenu();
  }

  clear() {
    this.clearInput(this.column);
    this.trigger.closeMenu();
  }

  apply() {
    this.clearEmpties();

    this.startDate = this.form.controls["startDate"].value;
    this.endDate = this.form.controls["endDate"].value;
    this.onDate = this.form.controls["onDate"].value;

      if (this.startDate && !this.endDate) {
        this.grid.filter(this.column.field,
          this.startDate,
          this.customDateFilteringOperand.operations[0]);
      } else if (!this.startDate && this.endDate) {
        this.grid.filter(this.column.field,
          this.endDate,
          this.customDateFilteringOperand.operations[1]);
      } else if (this.startDate && this.endDate) {
        this.grid.filter(this.column.field,
          [this.startDate, this.endDate],
          this.customDateFilteringOperand.operations[2]);
      } else if (this.onDate) {
        this.grid.filter(this.column.field,
          this.onDate,
          this.customDateFilteringOperand.operations[3]);
      }

    this.onFiltered.emit();
    const array = [this.startDate, this.endDate, this.onDate];
    localStorage.setItem(this.storageReference, JSON.stringify(array));
    this.trigger.closeMenu();
  }

  clearInput(column: IgxColumnComponent) {
    this.form.controls["startDate"].setValue(null);
    this.form.controls["endDate"].setValue(null);
    this.form.controls["onDate"].setValue(null);
    this.startDate = this.form.controls["startDate"].value;
    this.startDate = this.form.controls["endDate"].value;
    this.endDate = this.form.controls["onDate"].value;
    this.grid.clearFilter(column.field);
    localStorage.removeItem(this.storageReference);
    this.onFiltered.emit();
    this.clearEmpties();
  }

  clearEmpties() {
    this.onEmpty = false;
    this.onNotEmpty = false;
  }
}
