import {
  Component,
  EventEmitter,
  Inject,
  Output
} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {BaseModalComponent} from "src/app/shared/base/base-modal.component";
import {userDatabaseColumns} from "./support-user-modal.columns";
import {Subject} from "rxjs";
import {Validators} from "@angular/forms";
import {Regex} from "../../shared/helpers/regex-patterns";


@Component({
  selector: "app-support-user-modal",
  templateUrl: "./support-user-modal.component.html"
})
export class SupportUserModalComponent extends BaseModalComponent {
  @Output()
  onSave = new EventEmitter();

  supportUser = <any>{};
  userDatabaseColumns = userDatabaseColumns;

  getNav = () => [
    {name: "User Details", page: "details"},
    {name: "User Databases", page: "userDatabases"}
  ];

  updateTable = new Subject<void>();


  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<Component>
  ) {
    super();
    this.isNew = !data.data.id;
    this.nav = this.getNav();
    this.current = this.nav[0];
    this.metaData = data.metaData;
    this.fields = data.fields;
    this.supportUser = data.data;

    if (!this.supportUser.userDatabases) {
      this.supportUser.userDatabases = [];
    }

    this.createForm(this.fields, this.supportUser)
      .then((form) => {
        this.form = form;
        this.loaded = true;
        this.form.controls["newPassword"].setValidators(Validators.pattern(Regex.password));
      });
  }

  changeSection(item) {
    this.current = item;
  }


  // Validation against the API to see if the Email / Username has already been used.
  checkValidEmail(control): Promise<void> {
    return new Promise((resolve) => {
      let avail = false;
      this.remoteService
        .getBy(
          "people",
          "CheckUserNameAvailable",
          this.form.controls[control].value
        )
        .subscribe((response) => {
          avail = response;
          if (
            !avail &&
            this.form.controls[control].value != this.supportUser[control]
          ) {
            this.form.controls[control].setErrors({inUse: true});
          }
          resolve();
        });
    });
  }

  save() {
    this.supportUser = {...this.supportUser, ...this.form.getRawValue()};
    this.onSave.emit(this.supportUser);
  }

  close(form) {
    this.closeModal(form, this.dialogRef);
  }


  updateDatabase(entity) {
    entity.canDelete = true;
    entity.pending = false;
    if (this.supportUser.userDatabases[entity.index]) {
      this.supportUser.userDatabases[entity.index].database = entity.database;
      this.supportUser.userDatabases[entity.index].role = entity.role;
    } else {
      this.supportUser.userDatabases.push(entity);
    }
    this.form.markAsDirty();
  }

  removeDatabase(entity) {
    let match = this.supportUser.userDatabases.find(
      (b) => b.database && b.database.id === entity.id
    );
    if (match) {
      this.supportUser.userDatabases.splice(entity.index, 1);
      this.updateTable.next();
    }
    this.form.markAsDirty();
    this.form.controls["firstName"].markAsTouched();
    this.form.controls["firstName"].markAsDirty();
  }
}
