import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Output,
  ViewChild
} from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Claims } from "src/app/auth/models/claims";
import { BaseModalComponent } from "src/app/shared/base/base-modal.component";
import { GridComponent } from "src/app/shared/grid/grid.component";
import { stockRequestColumns } from "src/app/stock/stock-component/stock.component.columns";

@Component({
  selector: "app-stock-request-modal",
  templateUrl: "./stock-request-modal.component.html",
  styles: []
})
export class StockRequestModalComponent extends BaseModalComponent implements AfterViewInit {
  @Output()
  onSave = new EventEmitter();
  stockRequest = <any>{};
  hasClaim = false;
  fields = <any>{};
  stockRequestColumns = stockRequestColumns;
  claims = Claims;

  @ViewChild(GridComponent, { static: false })
  private gridComponent: GridComponent;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<Component>
  ) {
    super();
    this.loaded = false;
    this.fields = data.fields;
    this.remoteService
      .getBy("stockRequest", "id", data.data.id)
      .subscribe((res: any) => {
        this.stockRequest = res;
        this.createForm(this.fields, this.stockRequest).then((form) => {
          this.form = form;
          this.form.disable();
          this.form.controls.stockRequestStatus.enable();
          this.form.controls.note.enable();
          this.loaded = true;
          //Check Claims
          !this.authService.hasClaim(Claims.stockEdit)
            ? this.form.disable()
            : this.hasClaim = true;
        });
      });
  }

  ngAfterViewInit() {
    // #6464 Hack to fix top row not loading edit options
    setTimeout(() => {
      this.gridComponent.markForCheck();
    },1000)
  }

  save() {
    this.toggleSave();
    let totalCost = this.stockRequest.requestedItems.reduce(
      (acc, current) => acc + current.stockCost * current.quantityRequested,
      0
    );
    this.stockRequest = { ...this.stockRequest, ...this.form.getRawValue() };
    this.stockRequest["totalCost"] = totalCost;
    this.onSave.emit(this.stockRequest);
  }
  saveEditedRow(cell) {
    cell.row.data.originalNote = cell.row.data.note;
    cell.row.data.originalQuantityProvided = cell.row.data.quantityProvided;
    cell.row.data.originalStockRequestStatusName =
      cell.row.data.stockRequestStatus.name;
    cell.row.data.originalQuantityRequested = cell.row.data.quantityRequested;
    this.stockRequest.requestedItems = this.stockRequest.requestedItems.map(
      (obj) => (cell.id.rowID === obj.id ? cell.row.data : obj)
    );

    //Update row
    cell.row.update(this.stockRequest.requestedItems[cell.row.index]);

    this.stockRequest = { ...this.stockRequest, ...this.form.getRawValue() };
    const allSame = this.stockRequest.requestedItems.every(
      (item) =>
        item.stockRequestStatus.id ===
        this.stockRequest.requestedItems[0].stockRequestStatus.id
    );
    if (allSame) {
      this.form.controls.stockRequestStatus.setValue(
        this.data.metaData.stockItemRequestStatus.find(
          (s) =>
            s.id === this.stockRequest.requestedItems[0].stockRequestStatus.id
        )
      );
    } else {
      if (
        this.stockRequest.requestedItems.some(
          (item) => item.stockRequestStatus.name === "Outstanding"
        )
      ) {
        this.form.controls.stockRequestStatus.setValue(
          this.data.metaData.stockItemRequestStatus.find(
            (s) => s.name === "Outstanding"
          )
        );
      } else if (
        this.stockRequest.requestedItems.some(
          (item) => item.stockRequestStatus.name === "Back Order"
        )
      ) {
        this.form.controls.stockRequestStatus.setValue(
          this.data.metaData.stockItemRequestStatus.find(
            (s) => s.name === "Back Order"
          )
        );
      } else if (
        this.stockRequest.requestedItems.some(
          (item) => item.stockRequestStatus.name === "Part Supplied"
        )
      ) {
        this.form.controls.stockRequestStatus.setValue(
          this.data.metaData.stockItemRequestStatus.find(
            (s) => s.name === "Part Supplied"
          )
        );
      } else if (
        this.stockRequest.requestedItems.some(
          (item) => item.stockRequestStatus.name === "Rejected"
        )
      ) {
        this.form.controls.stockRequestStatus.setValue(
          this.data.metaData.stockItemRequestStatus.find(
            (s) => s.name === "Rejected"
          )
        );
      } else {
        console.log("Error: Could not change main status");
      }
    }
  }
  close(form) {
    this.closeModal(form, this.dialogRef);
  }
}
