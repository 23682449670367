export enum ViewCategories {
  Dashboard = 0,
  Map = 1,
  Reports = 2,
  Issues = 3,
  Sites = 5,
  SiteCategories = 6,
  Programs = 7,
  Tasks = 8,
  Equipment = 9,
  People = 10,
  JobRoles = 11,
  Teams = 12,
  EquipmentCheckRecords = 13,
  EquipmentMaintenanceRecords = 14,
  EquipmentChecklists = 15,
  EquipmentChecklistItems = 16,
  Stock = 17,
  StockRequest = 18,
  StockItemRequest = 19,
  Courses = 30,
  TrainingRecords = 31,
  TrainingRequirements = 32,
  CourseTypes = 33,
  EquipmentServiceRequirements = 90,
  EquipmentCategories = 91,
  EquipmentTypes = 92,
  EquipmentServiceTypes = 93,
  MobileRoles = 100,
  Areas = 110,
  Wards = 111,
  SiteClients = 112,
  SiteQualityCheckRecords = 113,
  InspectionCategories = 120,
  InspectionTypes = 121,
  InspectionChecklists = 122,
  InspectionCheckItems = 123,
  Inspections = 124,
  InspectionRecords = 125,
  InspectionCheckRecords = 126,
  InspectionSchedules = 127,
  TaskRecords = 130,
  TaskCategories = 131,
  TaskTypes = 132,
  WeatherConditions = 133,
  TaskCheckRecords = 134,
  TaskGroups = 135,
  TaskQualityCheckRecords = 136,
  TaskSchedules = 137,
  IssueCategories = 140,
  IssueTypes = 141,
  Risks = 142,
  IssueContacts = 143,
  StockCategories = 150,
  StockTypes = 151,
  Attendance = 160,
  Attendees = 161,
  AttendeeTimeRecords = 162,
  TimeRecordTypes = 163,
  ProgramSiteRecords = 164,
  ProgramSiteCheckRecords = 165,
  SiteSchedules = 166,
  Periods = 170,
  QuickNotes = 200,
  Accounts = 201,
  Databases = 202,
  Users = 203,
  SupportLinks = 204,
  News = 205,
  LoginRecords = 206,
  Contractors = 207,
  IssueEscalatedContacts = 208,
  CustomFields =   209,
  Documents = 210,
  StockContacts = 211,
  EquipmentContacts = 212
}
