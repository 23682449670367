import {
  AfterViewInit,
  Component,
  EventEmitter,
  Inject,
  OnInit,
  Output
} from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Claims } from "src/app/auth/models/claims";
import { BaseModalComponent } from "src/app/shared/base/base-modal.component";
import {FieldType} from "../../shared/components/edit-form/edit-form-model";
import {ModalRequest} from "../../shared/classes/modal.request";
import {ModalType} from "../../shared/enums/modal-fields.enum";

@Component({
  selector: "app-training-record-modal",
  templateUrl: "./training-record-modal.component.html",
  styleUrls:["./training-record-modal.component.css"]
})
export class TrainingRecordModalComponent extends BaseModalComponent
  implements AfterViewInit
{
  @Output()
  onSave = new EventEmitter();
  form: UntypedFormGroup;



  allImages = [];
  allNames = ["Training Record Photos"];
  getNav=() => [
    { name: "Training Record Details", page: "details" },
    { name: "Photos", page: "photos" },
    { name: "Links & Files", page: "linksAndFiles" },
    { name: "History", page: "auditTrail", disabled: false, hidden: this.isNew }
  ];
  current = this.nav[0];
  entity = <any>{};
  hasClaim = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<Component>
  ) {
    super();
    this.loaded = false;
    this.isNew = !data.data.id;
    this.nav = this.getNav();
    this.current = this.nav[0];
    this.fields = data.fields;

    this.metaData = data.metaData;
    this.entity = data.data;
    this.entity.tempDate = this.entity.created;
    this.createForm(this.fields, this.entity).then((form) => {
      this.form = form;
      this.loaded = true;
      this.form.controls["validUntilDate"].valueChanges.subscribe((value) => {
        if (value != null && value != undefined && value.toDate()) {
          let valueDate = value.toDate();
          let startDate = new Date(this.form.controls["completedDate"].value);
          if (valueDate < startDate) {
            this.form.controls["validUntilDate"].setErrors({ invalid: true });
          } else {
            this.form.controls["validUntilDate"].setErrors(null);
          }
        }
      });


      //Check Claims
      this.hasClaim=this.authService.hasClaim(Claims.peopleEdit)
      !this.authService.hasClaim(Claims.peopleEdit)
        ? this.form.disable():"";
    });}

  ngAfterViewInit() {
    this.entity.parsedLatitude =
      this.entity.latitude !== null ? parseFloat(this.entity.latitude) : null;
    this.entity.parsedLongitude =
      this.entity.longitude !== null ? parseFloat(this.entity.longitude) : null;
    }
  changeSection(item) {
    if (item.page === "auditTrail") {
      let parameter = item.page !== "auditTrail" ? "id" : null;
      if (!this.entity[item.page]) {
        this.loaded = false;
        this.remoteService
          .getBy(
            `trainingRecord/${item.page}`,
            parameter,
            this.data.data.id
          )
          .subscribe(
            (res) => {
              this.loaded = true;
              setTimeout(() => {
                this.entity[item.page] = res;
              }, 50);              this.entity[item.page] = res;

            },
            () => {
              this.loaded = true;
            }
          );
      }
    }
    this.current = item;
  }
  checkDocuments($event) {
    if (!this.entity.documents) {
      this.entity.documents = [];
    }
    this.entity.documents.push($event);
    this.form.markAsDirty();
  }
  checkImages($event) {
    if (!this.entity.images) {
      this.entity.images = [];
    }
    this.entity.images.push($event);
    this.form.markAsDirty();
  }

  save() {
    this.toggleSave()
    this.entity = {...this.entity, ...this.form.getRawValue()};

    this.entity.mobileCreated = this.entity
      .mobileCreatedDate
      ? this.formDateTime(
        this.entity.mobileCreatedDate,
        this.entity.mobileCreatedTime
      )
      :null;
    this.onSave.emit(this.entity);

  }

  close(form) {
    this.closeModal(form, this.dialogRef);
  }

doubleClick(cell) {
  this.openModal.next(
    new ModalRequest({
      id: cell.id.rowID,
      requestContext: this,
        modalType: ModalType.TrainingRecord,
        autoSave: false,
        afterSave: this.saveTrainingRecord
    })
  );
}

  saveTrainingRecord(context, entity, dialogRef) {
    context.addOrReplace(
    context.TrainingRecords,
    entity
  );
  context.TrainingRecord.grid.data = [
    ...context.TrainingRecords
  ];
  dialogRef.close();
  context.form.markAsDirty();
}
}
