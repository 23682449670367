import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

@Injectable()
export class AppConfigurationService {
  private configuration: IServerConfiguration;

  constructor(private readonly http: HttpClient) {}

  loadConfig() {
    return this.http.get<IServerConfiguration>("/api/Configuration/ConfigurationData")
      .toPromise()
      .then(result => {
          this.configuration = result;
        },
        error => console.error(error));
  }

  get apiAddress() {
    return this.configuration.BaseApiEndpoint;
  }

}

export interface IServerConfiguration {
  BaseApiEndpoint: string;
}
