import {
  Component,
  EventEmitter,
  Inject,
  OnInit,
  Output
} from "@angular/core";
import {UntypedFormGroup} from "@angular/forms";
import {MatDialogRef, MAT_DIALOG_DATA} from "@angular/material/dialog";
import {BaseModalComponent} from "../../shared/base/base-modal.component";
import {Claims} from "../../auth/models/claims";

@Component({
  selector: "app-custom-field-modal",
  templateUrl: "./custom-field-modal.component.html"
})
export class CustomFieldModalComponent
  extends BaseModalComponent
  implements OnInit {
  @Output()
  onSave = new EventEmitter();
  form: UntypedFormGroup;
  customField = <any>{};
  hasClaim = false;
  loaded = false;


  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<Component>
  ) {
    super();
    this.metaData = data.metaData;
    this.fields = data.fields;
    this.isNew = !data.id;
  }

  onAdd() {
    if (this.form.controls['optionsEntry'].value.trim()) {
      this.customField.options.push({id: this.newGuid(), name: this.form.controls['optionsEntry'].value.trim()});
      this.form.controls['optionsEntry'].setValue(null);
    }
  }

  removeItem(option) {
    let value = this.form.controls['options'].value;

    let foundIndex = value.findIndex((e) => e.id === option.id);
    if (foundIndex !== -1) {
      value.splice(foundIndex, 1);
    }
    this.form.controls['options'].setValue(value);
    this.form.controls['options'].markAsDirty();
  }

  ngOnInit(): void {


    this.customField = this.data.data;
    if (!this.customField.options) {
      this.customField.options = [];
    }

    this.createForm(this.fields, this.customField).then((form) => {
      this.form = form;
      setTimeout(() => {
        this.loaded = true;
      },500)
      //Check Claims
      !this.authService.hasClaim(Claims.configuration)
        ? this.form.disable()
        : this.hasClaim = true;

      if (!this.isNew) {
        this.form.controls['associatedEntityType'].disable();
      }

    });
  }


  save() {
    this.customField = {...this.customField, ...this.form.getRawValue()};
    this.onSave.emit(this.customField);
  }

  close(form) {
    this.closeModal(form, this.dialogRef);
  }
}
