<div class="modal-photos" *ngIf="loaded; else loading">
  <mat-accordion>
    <mat-expansion-panel
      *ngFor="let imageArray of imageArrays; let expansionIndex = index"
      [expanded]="expansionIndex == 0"
    >
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ arrayNames[expansionIndex] }}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="row">
        <div
          *ngIf="addPhotos && expansionIndex === 0"
          class="col-sm-4 photo-height mb-3"
        >
          <div
            class="
              d-flex
              align-items-center
              justify-content-center
              photo-height
              bg-modal
            "
          >
            <input
              class="hide-input photo-height position-absolute"
              #file
              [id]="'input-file-id' + expansionIndex"
              (change)="save(file.files)"
              multiple
              type="file"
              accept="image/png,image/jpeg,image/jpg"
            />
            <label
              [for]="'input-file-id' + expansionIndex"
              class="plus-icon"
            >
              <i class="fal fa-plus fa-4x"></i>
            </label>
          </div>
        </div>
        <ng-template
          ngFor
          let-image
          [ngForOf]="imageArray | reverseSort: 'created'"
          let-i="index"
        >
          <div *ngIf="image && !image.isArchived" class="col-sm-4 photo-height mb-3">
            <div class="position-relative h-100">
              <div class="d-flex flex-column align-items-center bg-modal h-100">
                <div
                  class="d-flex justify-content-between position-absolute w-100"
                >
                  <div
                    appDebounceClick
                    (debounceClick)="saveRotate(i, image)"
                    *ngIf="expansionIndex == 0 && addPhotos"
                    (click)="image.rotation = image.rotation + 90"
                    class="
                      d-flex
                      align-items-center
                      justify-content-center
                      side-icon
                      bg-white
                    "
                  >
                    <i class="fas fa-undo-alt"></i>
                  </div>
                  <div
                    *ngIf="expansionIndex == 0 && canDelete"
                    (click)="delete(i)"
                    class="
                      d-flex
                      align-items-center
                      justify-content-center
                      side-icon
                      bg-white
                    "
                  >
                    <i class="fas fa-times"></i>
                  </div>
                </div>
                <div
                  class="d-flex flex-column justify-content-center flex-grow-1"
                >
                  <label *ngIf="image.isHidden">
                    <i
                      *ngIf="image.error < 4"
                      class="fas fa-circle-notch fa-spin"
                    >
                    </i>
                  </label>
                  <img
                    alt="{{ arrayNames[expansionIndex] }}"
                    (error)="errorHandler($event, i)"
                    (load)="image.isHidden = false"
                    [src]="changeThumbJpg(image.thumb)
                    "
                    class="image-max-height"
                    [ngClass]="'px-4 ' + rotateClass(image)"
                    (click)="open(imageArrays[expansionIndex], i)"
                  />
                </div>
                <div
                  *ngIf="image.caption"
                  class="position-absolute bottom-0 photo-date rounded p-2"
                >
                  {{ image.caption }}
                </div>
                <div
                  *ngIf="image.created && !image.caption"
                  class="position-absolute bottom-0 photo-date rounded p-2"
                >
                  {{ image.created | date: "dd/MM/yyyy HH:mm:ss" }}
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>
<ng-template #loading>
  <app-loading
    [message]="'Updating Images...'"
    class="w-100 h-100 d-flex align-items-center justify-content-center"
  ></app-loading>
</ng-template>
