import { Component, Input, AfterViewInit, OnDestroy, OnInit, Output, EventEmitter } from
  "@angular/core";
import { IgxColumnComponent } from "igniteui-angular";
import { Observable, Subscription } from "rxjs";

@Component({
  selector: "base-bool-filter",
  templateUrl: "./base-bool-filter.component.html"
})
export class BaseBoolFilterComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input()
  column: IgxColumnComponent;
  @Input()
  grid;
  @Input()
  events: Observable<void>;
  @Output()
  onFiltered = new EventEmitter();

  operations = [];

  filterValue = "";
  selectedFilter;

  eventsSubscription: Subscription;

  ngOnInit() {
    this.eventsSubscription = this.events.subscribe(() => this.clearInput(this.column));
  }

  ngOnDestroy() {
    this.eventsSubscription.unsubscribe();
  }

  ngAfterViewInit() {
    // timeout needed to allow the grid to populate the filtering Expressions tree
    setTimeout(() => {
        for (let i = 0; i < this.grid.filteringExpressionsTree.filteringOperands.length; i++) {
          // additional variable needed to circumvent incorrect reference from IGX
          const t = this.grid.filteringExpressionsTree.filteringOperands[i];
          if (t.fieldName === this.column.field) {
            this.selectedFilter =
              this.operations.find(c => c.iconName == t.filteringOperands[0].condition.iconName);
            this.filterValue = t.filteringOperands[0].searchVal;
          }
        }
        if (!this.selectedFilter) {
          this.selectedFilter = this.operations[0];
        }
      });
  }

  filterChange($event) {
    this.selectedFilter = $event;
    this.onInput({ value: this.filterValue }, this.column);
    this.onFiltered.emit();
  }

  onInput(input, column: IgxColumnComponent) {
    const operand = this.selectedFilter.logic !== undefined ? this.selectedFilter.logic : null;
      this.grid.filter(column.field, input.value, operand, true);
  }

  clearInput(column: IgxColumnComponent) {
    this.filterValue = "";
    this.selectedFilter = this.operations[0];
    this.grid.clearFilter(column.field);
    this.onFiltered.emit();
    this.selectedFilter = this.operations[0];
  }
}
