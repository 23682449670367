import {
  Component,
  ViewChild,
  Input,
  Output,
  EventEmitter,
  OnDestroy, SimpleChanges, ViewChildren, QueryList, OnInit, OnChanges
} from "@angular/core";
import {
  IgxCsvExporterOptions,
  CsvFileTypes,
  IgxGridComponent,
  IgxToggleDirective,
  IgxDateFilteringOperand,
  IgxPaginatorComponent,
  IgxExcelExporterService,
  IgxCsvExporterService,
  IgxExcelExporterOptions, IgxTextHighlightDirective, GridPagingMode
} from "igniteui-angular";
import { GridStateDirective } from "./grid-state/grid-state.directive";
import { Subject, Subscription } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { IgxColumnComponent } from "@infragistics/igniteui-angular/lib/grids/columns/column.component";
import { IGridColumn } from "./grid-model";
import { BaseService } from "../base/base.service";

@Component({
  selector: "app-grid",
  templateUrl: "./grid.component.html",
  host: {
    class: "main_component content w-100 "
  }
})
export class GridComponent
  implements OnDestroy, OnInit, OnChanges {
  @Input()
  buttons: any[];
  @Input()
  columns: IGridColumn[];
  @Input()
  gridData: any[] = [];
  @Input()
  contextMenuOptions: any[];
  @Input()
  gridName: string;
  @Input()
  views: any[];
  @Input()
  selectedView: any;
  @Input()
  actionBarButtons: any[];
  @Input()
  groupable: boolean = true;
  @Input()
  filterable: boolean = true;
  @Input()
  small: boolean = false;
  @Input()
  selection: string = "multiple";
  @Input()
  storeColumns: boolean = false;
  @Input()
  controller: string;
  @Input()
  loading = false;
  @Input()
  editableColumns;
  @Input()
  remotePaginationEnabled = false;
  @Input()
  igxSelectOptions;
  @Input()
  dataExportEnabled: boolean = false;
  @Output()
  onClick = new EventEmitter<any>();
  @Output()
  onViewChange = new EventEmitter<any>();
  @Output()
  onActionClick = new EventEmitter<any>();
  @Output()
  columnsChanged = new EventEmitter<any>();
  @Output()
  onDoubleClick = new EventEmitter<any>();
  @Output()
  onExportDataUpdate = new EventEmitter<any>();
  @Output()
  saveEditedRow = new EventEmitter<any>();

  doubleClickBlocked = false;
  // Blocking Double Click from being fired several times
  handleDoubleClick = new Subject();

  // For custom filter clears
  clearFilterSubject = new Subject<void>();

  // For select options update
  onChangesSubject = new Subject<void>();

  // For columns moving
  onMoveSubject = new Subject<void>();

  pagingMode = GridPagingMode.Local;

  updated = new Subject();
  private subscription: Subscription;

  @ViewChild("grid", { static: true })
  grid: IgxGridComponent;
  @ViewChild(GridStateDirective, { static: true })
  state;
  @ViewChild("toggleRefView", { static: true })
  toggleView: IgxToggleDirective;
  @ViewChild("toggleRefHiding", { static: true })
  toggleColumns: IgxToggleDirective;
  @ViewChild("paginator", { static: false })
  paginator: IgxPaginatorComponent;

  @ViewChildren(IgxTextHighlightDirective, { read: IgxTextHighlightDirective })
  highlights: QueryList<IgxTextHighlightDirective>;

  gridId: string;
  gridFontSize: string;
  searchText = "";
  contextmenu = false;
  contextmenuX = 0;
  contextmenuY = 0;
  clickedCell = null;
  multiCellSelection: { data: any[] } = { data: [] };
  multiCellArgs;
  selectOptions = [25, 50, 100, 500, 1000];

  checkGridState = new Subject();

  private filterValues = new Map<IgxColumnComponent, any>();

  constructor(
    private excelExporterService: IgxExcelExporterService,
    private csvExportService: IgxCsvExporterService,
    private baseService: BaseService
  ) {

    this.subscription = this.updated.pipe(debounceTime(500))
      .subscribe((e) => this.columnsChanged.emit(this.columns));

    this.checkGridState.pipe(debounceTime(500)).subscribe(() => {
      this.markForCheck();
    });

    this.handleDoubleClick.subscribe((value) => {
      this.doubleClickBlocked = true;
      this.onDoubleClick.emit(value);
      setTimeout(() => (this.doubleClickBlocked = false), 2000);
    });

    this.subscription.add(this.onChangesSubject.subscribe(() => {
      this.grid.deselectAllRows(false);
    }))
  }

  get gridCountText() {
    if (this.loading && !this.grid.isLoading && this.gridData.length === 0) {
      return "0 Records";
      }

    return this.loading
      ? "Loading "
      : this.grid.pagingMode == GridPagingMode.Remote
        ? this.grid.totalRecords + (this.grid.totalRecords == 1 ? " Record" : " Records")
        : this.grid.filteredData && this.grid.filteredData.length
          ? this.grid.filteredData.length + (this.gridData.length == 1 ? " Record" : " Records")
          : this.gridData ?
            this.gridData.length + " Records" : "0 Records";
  }

  ngOnChanges(changes: SimpleChanges) {
    this.onChangesSubject.next();

    if (this.gridData) {
      setTimeout(() => this.updatePaginator());
    }

    if (this.selectedView && this.selectedView.length == 0) {
      this.selectedView = this.views ? this.views[0] : [];
    }
  }

  ngOnInit() {
    this.grid.rowHeight = localStorage.rowHeight ? localStorage.rowHeight : 32;
    this.gridFontSize = localStorage.gridFontSize
      ? localStorage.gridFontSize
      : "";

    this.baseService.gridHeightNotify.subscribe((c) => {
      this.grid.rowHeight = c.rowHeight;
      this.gridFontSize = c.fontSize;
      this.grid.markForCheck();
    });

    this.grid.pagingMode = this.remotePaginationEnabled ? GridPagingMode.Remote : GridPagingMode.Local;
    this.grid.markForCheck();
  }

  removeSorting($event) {
    if (
      !this.grid.groupingExpressions ||
      this.grid.groupingExpressions.length === 0
    ) {
      this.grid.columns.forEach((col) => {
        if (!(col.field === $event.fieldName)) {
          this.grid.clearSort(col.field);
        }
      });
    } else {
      this.grid.columns.forEach((col) => {
        if (
          col.field !== $event.fieldName &&
          col.field !== this.grid.groupingExpressions[0].fieldName
        ) {
          this.grid.clearSort(col.field);
        }
      });
    }
  }

  updatePaginator() {
    if (this.grid.perPage === 15) {
      this.grid.perPage = 25;
    }
    if (this.paginator) {

      if (this.grid.totalRecords && this.grid.totalRecords > 0) {
        if (
          this.paginator.perPage > 1000 &&
          this.paginator.perPage !== this.grid.totalRecords
        ) {
          this.paginator.selectOptions = [25, 50, 100, 500, 1000];
          this.paginator.perPage =
            this.grid.totalRecords > 500 ? this.grid.totalRecords : 500;
        } else if (this.grid.totalRecords > 500) {
          this.paginator.selectOptions = [25, 50, 100, 500, 1000, this.grid.totalRecords];
        }
      } else {
        this.paginator.selectOptions = [25, 50, 100, 500, 1000];
        this.paginator.perPage = 25;
        if (
          this.paginator.perPage > 1000 &&
          this.paginator.perPage !== this.gridData.length
        ) {
          this.paginator.selectOptions = [25, 50, 100, 500, 1000];
          this.paginator.perPage =
            this.gridData.length > 500 ? this.gridData.length : 500;
        } else if (this.grid.totalRecords > 500) {
          this.paginator.selectOptions = [25, 50, 100, 500, 1000, this.gridData.length];
        }
      }
      this.checkGrid();
    }
  }

  onHeaderClick(event, column) {
    let next = 1;
    if (column.sortable) {
      const sorted = this.grid.sortingExpressions.find(
        (x) => x.fieldName === column.field
      );
      if (sorted !== undefined) {
        if (sorted.dir === 1) {
          next = 2;
        }
        if (sorted.dir === 2) {
          // The below line is needed to prevent an edge-case of the grouping being removed from the grid
          // without the grid realising...
          next =
            this.grid.groupsRecords && this.grid.groupsRecords.length > 0
              ? 1
              : 0;
        }
        this.grid.sort({
          fieldName: column.field,
          dir: next,
          ignoreCase: true
        });
      } else {
        this.grid.sort({
          fieldName: column.field,
          dir: next,
          ignoreCase: true
        });
      }
    }
  }

  ngOnDestroy() {
    localStorage.rowHeight = this.grid.rowHeight;
    localStorage.gridFontSize = this.gridFontSize;
    this.subscription.unsubscribe();
    // JIC as unsubscribe doesn't always come through...
    this.subscription = null;
  }

  click($event) {
    // We pass the context-menu events back to the calling component
    // to handle all business logic.
    this.onClick.emit($event);
    this.cellSelection($event);
  }

  viewChanged(view) {
    this.loading = true;
    this.grid.data = [];
    this.selectedView = view;
    // Alert the component that the view has changed.
    this.onViewChange.emit(view);
    this.grid.markForCheck();
  }

  columnPinning(event) {
    if (event.column.field === "Name") {
      event.insertAtIndex = 0;
    }
  }

  checkGrid() {
    if (this.grid.data && this.grid.data.length > 0 && this.loading) {
      this.loading = false;
    }
    this.checkGridState.next('value');
  }

  markForCheck() {
    this.grid.markForCheck();
  }

  clearFilter() {
    this.clearFilterSubject.next();
  }

  exportData() {
    this.excelExporterService.export(
      this.grid,
      new IgxExcelExporterOptions(this.gridName + "_data")
    );
  }

  async exportDataAsync() {
    await this.excelExporterService.export(
      this.grid,
      new IgxExcelExporterOptions(this.gridName + "_data")
    );
  }

  exportCsv() {
    this.csvExportService.export(
      this.grid,
      new IgxCsvExporterOptions(this.gridName + "_csv_data", CsvFileTypes.CSV)
    );
  }

  exportDataUpdate() {
    this.onExportDataUpdate.emit();
  }

  searchKeyDown(ev) {
    if (ev.key === "Enter" || ev.key === "ArrowDown" || ev.key === "ArrowRight") {
      ev.preventDefault();
      this.grid.findNext(this.searchText);

    } else if (ev.key === "ArrowUp" || ev.key === "ArrowLeft") {
      ev.preventDefault();
      this.grid.findPrev(this.searchText);
    }
  }

  clearSearch() {
    this.searchText = "";
    this.grid.clearSearch();
    this.search("");
  }

  search(event) {
    const dirs = this.highlights.toArray();
    this.grid.findNext(this.searchText, false, false);
    for (const dir of dirs) {
      dir.highlight(this.searchText);
    }
  }

  updateCache() {
    this.state.clearStorageForGrid();
  }

  populateColumns(storedColumns) {
    this.reset();
    const columnsFromState = storedColumns;
    const tempArray = [];
    this.columns.map((c) => {
      c.hasOwnProperty("hidden") ? "" : (c.hidden = false);
      c.hasOwnProperty("pinned") ? "" : (c.pinned = false);
      const stateColumn = columnsFromState
        ? columnsFromState.find((s) => s.field == c.field)
        : null;
      if (stateColumn) {
        stateColumn.header = c.header;
        c.width = stateColumn.width;
        tempArray.push(stateColumn);
      } else {
        tempArray.push(c);
      }
    });

    tempArray.sort((a, b) => {
      if (a.visibleIndex < b.visibleIndex) {
        return -1;
      }
      if (a.visibleIndex > b.visibleIndex) {
        return 1;
      }
    });
    this.columns = tempArray;
    this.state.restoreGridState().then((v) => this.onChangesSubject.next());
  }

  rightClick(eventArgs) {
    eventArgs.event.preventDefault();
    if (!eventArgs.cell.row.isSelected) {
      this.grid.selectRows([eventArgs.cell.row.rowID],
        false
      );
    }

    this.multiCellArgs = {};

    if (this.multiCellSelection) {
      const node = eventArgs.cell.selectionNode;
      const isCellWithinRange = this.grid
        .getSelectedRanges()
        .some((range) => {
          return node.row >= range.rowStart && node.row <= range.rowEnd;
        });
      if (isCellWithinRange) {
        this.multiCellArgs = { data: this.multiCellSelection.data };
      }
    }

    this.contextmenuX =
      window.innerWidth - eventArgs.event.clientX > 240
        ? eventArgs.event.clientX
        : eventArgs.event.clientX - 130;
    this.contextmenuY = eventArgs.event.clientY;
    this.clickedCell = eventArgs.cell;
    this.contextmenu = true;
  }

  doubleClick(eventArgs) {
    if (!this.doubleClickBlocked) {
      this.handleDoubleClick.next(eventArgs.cell);
    }
  }

  getCells(event) {
    const selectedRows = this.grid.filteredSortedData
      ? this.grid.filteredSortedData.slice(event.rowStart, event.rowEnd + 1)
      : this.grid.data.slice(event.rowStart, event.rowEnd + 1);

    this.grid.selectRows(
      selectedRows.map((c) => c.id),
      true
    );

    this.multiCellSelection = {
      data: selectedRows
    };
  }

  cellSelection(event) {
    this.contextmenu = false;
  }

  rowSelection(event) {
    this.multiCellSelection.data = event.newSelection;
    this.grid.selectRows(this.multiCellSelection.data, true);
  }

  addRow(newData) {
    this.grid.addRow(newData);
  }

  updateRow(id, newData) {
    this.grid.updateRow(newData, id);
  }

  deleteRow(id) {
    this.grid.deleteRowById(id);
  }

  formatCurrency(val: string) {
    return parseInt(val, 10).toFixed(2);
  }

  onDateSelected(event, column: IgxColumnComponent) {
    this.filterValues.set(column, event);

    this.grid.filter(
      column.field,
      event,
      IgxDateFilteringOperand.instance().condition("equals"),
      column.filteringIgnoreCase
    );
  }

  reset() {
    this.grid.clearSort();
    this.grid.clearFilter();
    this.grid.clearSearch();
    this.clearFilter();
    this.grid.clearGrouping();
    this.searchText = "";
    this.clearSearch();
  }

  handleColumnMove(e) {
    this.columns.splice(
      this.columns.findIndex((el) => el.field === e.target.field),
      0,
      this.columns.splice(
        this.columns.findIndex((el) => el.field === e.source.field),
        1
      )[0]
    );
    this.onChangesSubject.next();
    this.onMoveSubject.next();
    this.grid.ngDoCheck();
  }

  calculateState(cell: any) {
    if (cell.row.data.stockRequestStatus) {
      return !(cell.row.data.stockRequestStatus.name !=
        cell.row.data.originalStockRequestStatusName ||
        cell.row.data.quantityProvided !=
        cell.row.data.originalQuantityProvided ||
        cell.row.data.quantityRequested !=
        cell.row.data.originalQuantityRequested ||
        cell.row.data.note != cell.row.data.originalNote);
    }


  }

  updateCellRowData(e, cell, c) {
    cell.row.data[c.field] = e.owner.value;
  }

  cellEdit(e) {
    // Changes the status of a stock request item to Closed after the provided reaches the requested amount
    if (
      e.column.header === "Provided" &&
      e.newValue >= e.rowData.quantityRequested
    ) {
      e.rowData.stockRequestStatus = { id: 1, name: "Supplied" };
    }
  }

  cellEditEnter(e) {
    //Cancels the editing of the select column Status to work properly
    if (e.column.header === "Status") {
      e.cancel = true;
    }
  }

  checkPastDatesStyle(field: string, value: string) {
    if (
      (value && field === "validUntilDate" && Date.parse(value) < Date.now()) ||
      (value &&
        field === "nextServiceDueDate" &&
        Date.parse(value) < Date.now()) ||
      (value && field === "requiredDate" && Date.parse(value) < Date.now())
    ) {
      return "text-danger";
    }
  }

  cellClick(args) {
    if (
      args.cell.editable &&
      typeof args.cell.value === "number" &&
      args.cell.editMode === false
    ) {
      args.cell.editMode = true;
    }
  }

}
