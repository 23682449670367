<div class="sd-modal">
  <div class="d-flex m-0 position-relative h-100">
  <div class="p-3 primary">
    <div class="text-center">
      <i class="fal fa-thumbtack fa-3x"></i>
      <H5>Equipment Check Record</H5>
    </div>
    <div class="py-5 modal-nav">
      <button
        *ngFor="let item of nav"
        mat-button
        class="btn py-2 d-block w-100"
        [ngClass]="{ 'border-p': current.page == item.page }"
        (click)="changeSection(item)"
        [disabled]="item.disabled"
        [hidden]="item.hidden"
      >
        {{ item.name }}
      </button>
    </div>
  </div>
    <div class="corner-close" (click)="close(form)">
      <i class="fal fa-times fa-2x"></i>
    </div>
  <form
    class="bg-modal px-4 d-flex w-100"
    [formGroup]="form"
    data-lpignore="true"
    autocomplete="off"
    *ngIf="loaded; else loading"
    (input)="check(form)"
  >
    <content class="d-flex flex-column justify-content-between w-100">
      <!-- #region Header -->
      <header class="d-flex primary pt-3">
        <div>
          <h2 class="m-0">
            Equipment Check Record
          </h2>
          {{ current.name }}
        </div>
      </header>
      <!-- #endregion -->
      <!-- #region Main -->
      <main class="flex-grow-1">
        <div class="h-100" [ngSwitch]="current.page">
          <!-- #region Details -->
          <div *ngSwitchCase="'details'" class="h-100">
            <div class="card p-2 d-block h-100">
              <mat-form-field class="w-100">
                <mat-label class="primary">Equipment Name</mat-label>
                <input matInput formControlName="equipmentName" />
              </mat-form-field>
              <mat-form-field class="w-100">
                <mat-label class="primary">Checked Date</mat-label>
                <input
                  matInput
                  formControlName="tempDate"
                  [ngModel]="entity.tempDate | date: 'd/M/yy H:mm:ss'"
                />
              </mat-form-field>
              <mat-form-field class="w-100">
                <mat-label class="primary">Checked By</mat-label>
                <input matInput formControlName="createdByName" />
              </mat-form-field>
              <mat-form-field class="w-100">
                <mat-label class="primary">Check Result</mat-label>
                <input matInput formControlName="checkResultName" />
              </mat-form-field>
              <mat-form-field class="w-100">
                <mat-label class="primary">Reported Mileage</mat-label>
                <input matInput #name formControlName="reportedMileage" />
              </mat-form-field>
              <mat-form-field class="w-100">
                <mat-label class="primary">Notes</mat-label>
                <textarea
                  matInput
                  class="h-100"
                  rows="6"
                  formControlName="note"
                ></textarea>
              </mat-form-field>
            </div>
          </div>
          <!-- #endregion -->
          <!-- #region Photos -->
          <div *ngSwitchCase="'photos'" class="h-100">
            <div class="row h-100">
              <app-photos
                *ngIf="isNew"
                [imageArrays]="[entity.images || []]"
                class="col-12 pb-1 h-100"
                [arrayNames]="allNames"
                [isNew]="true"
                [addPhotos]="hasClaim"
                (addedImage)="checkImages($event)"
                (removedImage)="form.markAsDirty()"
              >
              </app-photos>
              <app-photos
                *ngIf="!isNew"
                [addPhotos]="hasClaim"
                class="col-12 pb-1 h-100"
                [imageArrays]="[entity.images || []]"
                [arrayNames]="allNames"
                [entity]="{ type: 'equipmentCheckRecord', id: entity.id }"
                (addedImage)="checkImages($event)"
                (removedImage)="form.markAsDirty()"
              >
              </app-photos>
            </div>
          </div>
          <!-- #endregion -->
          <!-- #region Map -->
          <div *ngSwitchCase="'map'" class="h-100">
            <div class="col-sm-12 pt-3 text-center">
              <div class="card">
                <div
                  class="col-sm-12 p-0"
                  *ngIf="
                          entity.parsedLatitude;
                          else explain
                        "
                >
                  <div class="map-container">
                    <app-map
                      class="h-100"
                      [latitude]="
                              entity.parsedLatitude
                            "
                      [longitude]="
                              entity.parsedLongitude
                            "
                      [staticType]="3"
                      [isStatic]="true"
                      [zoom]="12"
                    >
                    </app-map>
                  </div>
                </div>
                <ng-template #explain>
                        <span class="explain">
                          Check Record Co-ordinates not found.
                        </span>
                </ng-template>
              </div>
            </div>
          </div>
          <!-- #endregion -->
          <!-- #region History / AuditTrail -->
          <div *ngSwitchCase="'auditTrail'">
            <app-audit-trail
              id="auditTrail"
              [auditTrail]="entity.auditTrail"
            ></app-audit-trail>
          </div>
          <!-- #endregion -->
        </div>
      </main>
      <!-- #endregion -->
      <!-- #region Footer -->
      <footer
        class="flex justify-content-end my-3"
        [ngClass]="entity.isArchived ? 'pb-4' : ''"
      >
        <div>
          <button
            mat-flat-button
            name="detailSaveButton"
            class="ms-2 px-4"
            [disabled]="form.invalid || !form.dirty || !hasClaim"
            (click)="save()"
          >
            Save
          </button>
          <button mat-flat-button class="ms-2 px-4" (click)="close(form)">
            Close
          </button>
        </div>
      </footer>
      <!-- #endregion -->
    </content>
  </form>
  <ng-template #loading>
    <app-loading
      class="w-100 d-flex align-items-center justify-content-center bg-modal"
    ></app-loading>
  </ng-template>
</div>
</div>
