<form
  class="d-flex flex-grow-1 edit-form w-100"
  [formGroup]="form"
  autocomplete="ignore"
  (input)="onChanges()"
>
  <main class="card">
    <ng-container *ngFor="let customField of fields">
      <mat-form-field
        class="w-100 px-2 pt-1"
      >
        <mat-label class="h6 primary">{{customField.name }}</mat-label>
        <ng-container [ngSwitch]="customField.dataTypeName">
          <!-- Bool -->
          <ng-container *ngSwitchCase="'Yes/No'">
            <mat-select
              formControlName="{{ customField.field }}"
              [compareWith]="compareOverrideCustomBoolean"
              [id]="customField.name"
            >
              <mat-option (click)="onChanges()" *ngFor="let bool of bools" [value]="bool.id">
                {{ bool.name }}
              </mat-option>
            </mat-select>
          </ng-container>
          <!-- Select -->
          <ng-container *ngSwitchCase="'Select'">
            <mat-select
              formControlName="{{ customField.field }}"
              [id]="customField.name"
            >
              <mat-option
                *ngFor="
                        let m of customField.options
                          | sort: 'name'
                      "
                (click)="onChanges()"
                [value]="m.name"
              >
                {{ m.name }}
              </mat-option>
            </mat-select>
          </ng-container>
          <!-- Large Text -->
          <ng-container *ngSwitchCase="'Large Text'">
              <textarea
                data-lpignore="true"
                matInput
                rows="4"
                formControlName="{{ customField.field }}"
                [id]="customField.name"
              ></textarea>
          </ng-container>
          <!-- Int -->
          <ng-container *ngSwitchCase="'Number'">
            <input
              data-lpignore="true"
              matInput
              formControlName="{{ customField.field }}"
              name="{{ customField.field }}"
              type="number"
              step="1"
              min="0"
              [id]="customField.field"
            />
          </ng-container>
          <!-- Decimal -->
          <ng-container *ngSwitchCase="'Decimal'">
            <input
              data-lpignore="true"
              matInput
              formControlName="{{ customField.field }}"
              name="{{ customField.field }}"
              type="number"
              placeholder="0.00"
              step="0.01"
              min="0"
              [id]="customField.field"
            />
          </ng-container>
          <ng-container *ngSwitchCase="'Date'">
            <div class="row custom-field-date">

              <input
                matInput
                [matDatepicker]="datePicker"
                formControlName="{{ customField.field }}"
                name="{{ customField.field}}"
                [id]="customField.name"
              />
              <mat-datepicker-toggle
                matSuffix

                [for]="datePicker"
              ></mat-datepicker-toggle>
              <mat-datepicker (closed)="onChanges()" #datePicker></mat-datepicker>

            </div>
          </ng-container>
          <!-- Default -->
          <ng-container *ngSwitchDefault>
            <input
              data-lpignore="true"
              autocomplete="nu{{ customField.field }}"
              matInput
              name="{{ customField.field }}"
              formControlName="{{ customField.field }}"
              [id]="customField.field"
            />
          </ng-container>
        </ng-container>
      </mat-form-field>
    </ng-container>
  </main>
</form>
