import { GridColumnDataType } from "@infragistics/igniteui-angular";
import { IGridColumn } from "../../shared/grid/grid-model";

export const inspectionCheckRecordColumns: IGridColumn[] = [
  {
    field: "created",
    header: "Checked Date",
    dataType: GridColumnDataType.DateTime
  },
  {
    field: "name",
    header: "Check",
    dataType: GridColumnDataType.String
  },
  {
    field: "inspectionCheckItemResultName",
    header: "Check Result",
    dataType: GridColumnDataType.String
  },
  {
    field: "note",
    header: "Note",
    dataType: GridColumnDataType.String
  },
  {
    field: "issueRaised",
    header: "Issue Raised",
    dataType: GridColumnDataType.Boolean
  },
  {
    field: "taskRaised",
    header: "Task Raised",
    dataType: GridColumnDataType.Boolean
  },
  {
    field: "hasImage",
    header: "Has Photo(s)",
    dataType: GridColumnDataType.Boolean
  }
];
