import {
  AfterViewInit,
  Component,
  EventEmitter,
  Inject,
  Output,
  ViewChild
} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {BaseModalComponent} from "src/app/shared/base/base-modal.component";
import {debounceTime, take} from "rxjs/operators";
import {
  attendanceColumns,
  inspectionColumns,
  issuesColumns,
  siteQualityCheckRecordsColumns,
  taskCheckRecordsColumns,
  taskRecordsColumns,
  tasksColumns
} from "./site-modal.columns";
import {GridComponent} from "src/app/shared/grid/grid.component";
import {ModalRequest} from "../../shared/classes/modal.request";
import {ModalType} from "../../shared/enums/modal-fields.enum";
import {Router} from "@angular/router";
import {ConfirmModalComponent} from "../../shared/components/modals/confirm/confirm-modal.component";
import {Claims} from "src/app/auth/models/claims";
import {ViewCategories} from "../../shared/enums/view-categories.enum";

@Component({
  selector: "app-sites-modal",
  templateUrl: "./site-modal.component.html",
  styles: []
})
export class SiteModalComponent
  extends BaseModalComponent {
  @Output()
  onSave = new EventEmitter();
  site = <any>{};
  allNames = ["Site Photos"];

  claims = Claims;
  hasClaim = false;

  tasksColumns = tasksColumns;
  taskRecordsColumns = taskRecordsColumns;
  taskCheckRecordsColumns = taskCheckRecordsColumns;
  siteQualityCheckRecordsColumns = siteQualityCheckRecordsColumns;
  issuesColumns = issuesColumns;
  inspectionColumns = inspectionColumns;
  attendanceColumns = attendanceColumns;

  addTaskButtons = this.authService.hasClaim(Claims.taskEdit) && [
    {name: "Add Task", action: this.addTask},
    {name: "Add Adhoc Task", action: this.addAdHocTask}
  ];

  addIssueButtons = this.authService.hasClaim(Claims.issueEdit) && [
    {name: "Add Issue", action: this.addIssue}
  ];

  addInspectionButtons = this.authService.hasClaim(Claims.inspectionEdit) && [
    {name: "Add Inspection", action: this.addInspection}
  ];

  getNav = () => [
    {name: "Sites Details", page: "details"},
    {name: "Custom Fields", page: "customFields", hidden: !this.showCustomFields},
    {name: "Site Schedule", page: "schedule"},
    {name: "Tasks", page: "tasks", hidden: this.isNew},
    {
      name: "Task Records",
      page: "taskRecords",
      hidden: this.isNew
    },
    {
      name: "Task Checks",
      page: "taskCheckRecords",
      hidden: this.isNew
    },
    {
      name: "Quality Check Records",
      page: "siteQualityCheckRecords",
      hidden: this.isNew
    },
    {name: "Issues", page: "issues", hidden: this.isNew},
    {
      name: "Inspections",
      page: "inspections",
      hidden: this.isNew
    },
    // {name: "Attendance", page: "attendance", hidden: this.isNew},
    {name: "Notes & Map", page: "notes"},
    {name: "Photos", page: "photos"},
    {name: "Links & Files", page: "linksAndFiles", disabled: false},
    {name: "History", page: "auditTrail", disabled: false, hidden: this.isNew}
  ];

  @ViewChild(GridComponent, {static: false})
  private taskGridComponent: GridComponent;

  @ViewChild(GridComponent, {static: false})
  private issueGridComponent: GridComponent;

  @ViewChild(GridComponent, {static: false})
  private inspectionGridComponent: GridComponent;

  @ViewChild(GridComponent, {static: false})
  private taskRecordGridComponent: GridComponent;

  @ViewChild(GridComponent, {static: false})
  private taskCheckRecordGridComponent: GridComponent;

  @ViewChild(GridComponent, {static: false})
  private siteQualityCheckRecordGridComponent: GridComponent;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private router: Router,
    public dialogRef: MatDialogRef<Component>
  ) {
    super();
    this.isNew = !data.data.id;
    this.fields = data.fields;
    this.metaData = data.metaData;
    this.site = data.data;

    this.remoteService.getFields(ViewCategories.Sites)
      .pipe(take(1))
      .subscribe((response) => {
        this.customFields = response;
        this.showCustomFields = this.customFields.length > 0;
        this.nav = this.getNav();
        this.current = this.nav[0];

        this.site.parsedLatitude =
          this.site.latitude !== null ? parseFloat(this.site.latitude) : null;
        this.site.parsedLongitude =
          this.site.longitude !== null ? parseFloat(this.site.longitude) : null;

        this.createForm(this.fields, this.site).then((form) => {
          this.form = form;

          this.form.controls["measuredDistances"].disable();
          this.form.controls["what3Words"].disable();

          this.form.controls["postcode"].valueChanges
            .pipe(debounceTime(1500))
            .subscribe(() => this.getCoordFromPostcode());

          this.form.controls["area"].valueChanges.subscribe(() =>
            this.form.controls["ward"].setValue(null)
          );

          this.form.controls["latitude"].valueChanges
            .pipe(debounceTime(500))
            .subscribe((v) => {
              this.site.parsedLatitude = parseFloat(v);
            })

          this.form.controls["longitude"].valueChanges
            .pipe(debounceTime(500))
            .subscribe((v) => {
              this.site.parsedLongitude = parseFloat(v);
            })

          this.disableSchedules(form);

          if (this.site.isScheduled) {
            this.enableSchedules(this.form);
          }

          if (this.form.controls["timeFrameDays"].value) {
            this.handleTimeFrameParam(this.form.controls["timeFrameDays"].value, this.form);
          }

          if (this.form.controls["timeFrameDays"].value == 1) {
            if (this.form.controls["timeFrameParam"].value == 1) {
              this.form.controls["bufferDays"].setValue(0);
              this.form.controls["bufferDays"].disable();
            }
          }

          this.form.controls["timeFrameDays"].valueChanges.subscribe((v) => {
            if (v > 1) {
              this.form.controls["bufferDays"].enable();
            } else {
              if (this.form.controls["timeFrameParam"].value == 1) {
                this.form.controls["bufferDays"].disable();
              }
            }
          });

          this.form.controls["timeFrameParam"].valueChanges.subscribe((v) => {
            if (v > 1) {
              this.form.controls["bufferDays"].enable();
            } else {
              if (this.form.controls["timeFrameDays"].value == 1) {
                this.form.controls["bufferDays"].disable();
              }
            }
          });

          this.form.controls["isScheduled"].valueChanges.subscribe((v) => {
            if (v) {
              this.enableSchedules(this.form);
            } else {
              this.disableSchedules(this.form);
            }
          });

          this.form.controls["periods"].valueChanges.subscribe((v) => {
            if (v && v.length > 0) {
              this.form.controls["startDate"].setValue(null, {emitEvent: false});
              this.form.controls["endDate"].setValue(null, {emitEvent: false});
              this.form.controls["startDate"].disable();
              this.form.controls["endDate"].disable();
            } else if (this.form.controls["startDate"].disabled) {
              this.form.controls["startDate"].enable();
              this.form.controls["endDate"].enable();
            }
          })

          this.form.controls["startDate"].valueChanges.subscribe((v) => {
            if (v) {
              this.form.controls["periods"].setValue(null, {emitEvent: false});
              this.form.controls["periods"].disable();
            } else if (this.form.controls["periods"].disabled) {
              this.form.controls["periods"].enable();
            }
          })

          this.form.controls["endDate"].valueChanges.subscribe((v) => {
            if (v) {
              if (v < this.form.controls["startDate"].value) {
                this.form.controls["endDate"].setErrors({'invalid': true});
              } else {
                this.form.controls["endDate"].setErrors(null);
              }
            }
          });

          this.loaded = true;
          setTimeout(() => this.form.markAllAsTouched(), 5000);
          //Check Claims
          !this.authService.hasClaim(Claims.siteEdit)
            ? this.form.disable()
            : this.hasClaim = true;
        });
      });
  }

  changeSection(item) {
    if (
      item.page === "tasks" ||
      item.page === "taskRecords" ||
      item.page === "taskCheckRecords" ||
      item.page === "siteQualityCheckRecords" ||
      item.page === "issues" ||
      item.page === "inspections" ||
      item.page === "attendance" ||
      item.page === "auditTrail"
    ) {
      let parameter = item.page !== "auditTrail" ? "id" : null;
      // We only want to load each section once.
      if (!this.site[item.page]) {
        this.loaded = false;
        this.remoteService
          .getBy(`site/${item.page}`, parameter, this.data.data.id)
          .subscribe(
            (res) => {
              this.loaded = true;
              this.site[item.page] = res;
            },
            () => {
              this.loaded = true;
            }
          );
      }
    }
    this.current = item;
  }

  save() {
    this.saving = true;
    this.site = {...this.site, ...this.form.getRawValue()};
    if (this.site.isScheduled) {
      if (!this.site.startDate && (!this.site.periods || this.site.periods.length == 0)) {
        this.matDialog.open(ConfirmModalComponent, {
          data: {
            type: "no-btn",
            body: [
              "Please select a Start Date or at least one Period for your Scheduled Site"
            ]
          },
          panelClass: "confirm-dialog-container"
        });
        return;
      }

      if (this.site.timeFrameDays && this.site.timeFrameDays > 0) {
        this.site.timeFrameDays = this.site.timeFrameDays * this.site.timeFrameParam;
      }
      this.baseService.openSnackBar(
        "Creating/Updating your Site Schedule Records...");
      this.onSave.emit(this.site);
      setTimeout(() => this.saving = false, 50000);
    } else {
      this.onSave.emit(this.site);
      setTimeout(() => this.saving = false, 10000);
    }
  }

  close(form) {
    this.closeModal(form, this.dialogRef);
  }

  trackImage($event) {
    this.site.images.push({
      id: $event
    });
  }

  checkImages($event) {
    if (!this.site.images) {
      this.site.images = [];
    }
    this.site.images.push($event);

    this.form.markAsDirty();
  }

  getCoordFromPostcode() {
    if (
      !this.form.controls["latitude"].value &&
      !this.form.controls["longitude"].value &&
      this.form.controls["postcode"].valid
    ) {
      this.remoteService
        .postcodeToCoords(this.form.controls["postcode"].value)
        .subscribe((res) => {
          if (res) {
            this.form.controls["latitude"].setValue(res.lat);
            this.form.controls["longitude"].setValue(res.lng);
          }
        });
    }
  }

  checkDocuments($event) {
    if (!this.site.documents) {
      this.site.documents = [];
    }
    this.site.documents.push($event);
    this.form.markAsDirty();
  }

  addTask(context) {
    context.openModal.next(
      new ModalRequest({
        requestContext: context,
        modalType: ModalType.Task,
        afterSave: context.saveTask,
        payload: {site: {id: context.site.id}}
      })
    );
  }

  addInspection(context) {
    context.openModal.next(
      new ModalRequest({
        requestContext: context,
        modalType: ModalType.Inspection,
        afterSave: context.saveInspection,
        payload: {site: [{id: context.site.id}]}
      })
    );
  }

  addAdHocTask(context) {
    context.openModal.next(
      new ModalRequest({
        requestContext: context,
        modalType: ModalType.Task,
        afterSave: context.saveTask,
        payload: {site: {id: context.site.id}, isAdhoc: true}
      })
    );
  }

  addIssue(context) {
    context.remoteService
      .getData("issue", "IssueNumber")
      .pipe(take(1))
      .subscribe((r) => {
        context.openModal.next(
          new ModalRequest({
            requestContext: context,
            modalType: ModalType.Issue,
            afterSave: context.saveIssue,
            payload: {site: {id: context.site.id}, issueNumber: r}
          })
        );
      });
  }

  saveInspection(context, entity, dialogRef) {
    if (entity.inspectionType.name) {
      entity.inspectionTypeName = entity.inspectionType.name;
    }
    context.addOrReplace(context.site.inspections, entity);
    context.inspectionGridComponent.grid.data = [...context.site.inspections];
    dialogRef.close();
    context.markAsDirty(context);
  }

  saveTask(context, entity, dialogRef) {
    if (!context.site.tasks) {
      context.site.tasks = [];
    }

    if (entity.taskType.name) {
      entity.taskTypeName = entity.taskType.name;
    }
    if (entity.taskCategory.name) {
      entity.taskCategoryName = entity.taskCategory.name;
    }
    if (!entity.created) {
      entity.created = context.newIsoDate();
    }

    if (!entity.taskPriority) {
      if (!entity.taskStatus) {
        entity.taskStatus = "New Task";
      }
    } else if (entity.taskPriority && entity.taskPriority.name) {
      entity.taskPriorityName = entity.taskPriority.name;
      if (!entity.taskStatus) {
        entity.taskStatus = "New Adhoc Task";
      }
    }

    if (entity.programs) {
      const programNames = entity.programs.map((m) => m.name);
      entity.programNames = programNames.join(",");
    }
    if (!entity.id) {
      entity.id = context.newGuid();
      entity.isNew = true;
    }

    context.addOrReplace(context.site.tasks, entity);

    if (context.site && context.site.tasks) {
      context.taskGridComponent.grid.data = [...context.site.tasks];
    }
    dialogRef.close();
    context.markAsDirty(context);
  }

  markAsDirty(context) {
    context.form.controls["name"].markAsDirty();
    context.form.markAsDirty();
  }

  saveTaskRecord(context, entity, dialogRef) {
    context.addOrReplace(context.site.taskRecords, entity);
    context.taskRecordGridComponent.grid.data = [...context.site.taskRecords];
    dialogRef.close();
    context.markAsDirty(context);
  }

  saveTaskCheckRecord(context, entity, dialogRef) {
    context.addOrReplace(context.site.taskCheckRecords, entity);
    context.taskCheckRecordGridComponent.grid.data = [
      ...context.site.taskCheckRecords
    ];
    dialogRef.close();
    context.markAsDirty(context);
  }

  saveSiteQualityCheckRecord(context, entity, dialogRef) {
    context.addOrReplace(context.site.siteQualityCheckRecords, entity);
    context.siteQualityCheckRecordGridComponent.grid.data = [
      ...context.site.siteQualityCheckRecords
    ];
    dialogRef.close();
    context.markAsDirty(context);
  }

  saveInspectionRecord(context, entity, dialogRef) {
    context.addOrReplace(context.site.inspectionRecords, entity);
    context.inspectionRecordsGridComponent.grid.data = [
      ...context.site.inspectionRecords
    ];
    dialogRef.close();
    context.markAsDirty(context);
  }

  saveIssue(context, entity, dialogRef) {
    // Prepping the DTO accordingly
    if (entity.issueCategory.name) {
      entity.issueCategoryName = entity.issueCategory.name;
    }
    if (entity.issueType.name) {
      entity.issueTypeName = entity.issueType.name;
    }
    if (entity.risk?.name) {
      entity.riskName = entity.risk.name;
    }
    if (!entity.created) {
      entity.created = context.newIsoDate();
    }
    if (entity.isClosed) {
      entity.closedDate = context.newIsoDate();
    }
    context.addOrReplace(context.site.issues, entity);
    context.issueGridComponent.grid.data = [...context.site.issues];
    dialogRef.close();
    context.markAsDirty(context);
  }

  navigateToMap() {
    this.closeModal(this.form, this.dialogRef).then(() =>
      this.router.navigate([`/map/site/${this.site.id}`])
    );
  }

  doubleClick(cell) {
    if (cell.row.data.isNew) {
      this.baseService.openSnackBar(
        "Error - Cannot edit new Item until Saved",
        null,
        true
      );
    }
    this.openModal.next(
      new ModalRequest({
        id: cell.id.rowID,
        requestContext: this,
        modalType:
          this.current.name == "Tasks"
            ? ModalType.Task
            : this.current.name == "Issues"
              ? ModalType.Issue
              : this.current.name == "Inspections"
                ? ModalType.Inspection
                : this.current.name == "Task Records"
                  ? ModalType.TaskRecord
                  : this.current.name == "Task Checks"
                    ? ModalType.TaskCheckRecord
                    : ModalType.SiteQualityCheckRecord,
        autoSave: false,
        afterSave: this.current.name == "Tasks"
          ? this.saveTask
          : this.current.name == "Issues"
            ? this.saveIssue
            : this.current.name == "Inspections"
              ? this.saveInspection
              : this.current.name == "Task Records"
                ? this.saveTaskRecord
                : this.current.name == "Task Check Records"
                  ? this.saveTaskCheckRecord
                  : this.current.name == "Inspection Records"
                    ? this.saveInspectionRecord
                    : this.saveSiteQualityCheckRecord
      })
    );
  }

  //#region Schedule Handlers
  disableSchedules(form) {
    form.controls["periods"].disable();
    form.controls["days"].disable();
    form.controls["startDate"].disable();
    form.controls["endDate"].disable();
    form.controls["timeFrameDays"].disable();
    form.controls["bufferDays"].disable();
    form.controls["timeFrameParam"].disable();
  }

  enableSchedules(form) {
    if (!form.controls["periods"].value || form.controls["periods"].value.length == 0) {
      form.controls["startDate"].enable();
      form.controls["endDate"].enable();
      if (!form.controls["startDate"].value) {
        this.defaultStartDate(form);
      }
    }

    if (!form.controls["startDate"].value) {
      form.controls["periods"].enable();
    }

    form.controls["days"].enable();

    this.form.controls["timeFrameParam"].setValue(1);

    form.controls["timeFrameDays"].enable();
    form.controls["timeFrameParam"].enable();


    if (!form.controls["timeFrameDays"].value) {
      form.controls["timeFrameDays"].setValue(1);
    }
    form.controls["bufferDays"].enable();
    if (!form.controls["days"].value || form.controls["days"].value.length == 0) {
      this.defaultDays(form);
    }
  }

  defaultStartDate(form) {
    let d = new Date();
    d.setDate(d.getDate() + (((1 + 7 - d.getDay()) % 7) || 7));
    form.controls["startDate"].setValue(d);
  }

  defaultDays(form) {
    this.form.controls["days"].setValue(this.metaData.days);
  }

  handleTimeFrameParam(val, form) {
    if (Number.isInteger(val / 365)) {
      form.controls["timeFrameParam"].setValue(365);
      form.controls["timeFrameDays"].setValue(val / 365);
    } else if (Number.isInteger(val / 28)) {
      form.controls["timeFrameParam"].setValue(28);
      form.controls["timeFrameDays"].setValue(val / 28);
    } else if (Number.isInteger(val / 7)) {
      form.controls["timeFrameParam"].setValue(7);
      form.controls["timeFrameDays"].setValue(val / 7);
    } else {
      form.controls["timeFrameParam"].setValue(1);
    }
  }

  initOption(field, option) {
    if (field.selected) {
      let found = field.selected.find((o) => o.value.id === option.value.id);
      if (!found) {
        field.selected.push(option);
      }
    }
  }

  //Order on Selection or Drag & Drop
  matSelectLoad(items) {
    if (items) {
      items.sort((a, b) => a.order - b.order);
    }
  }

  removeItem(id, value, formField) {
    if (value.selected) {
      value.selected.find((e) => e.value.id === id).deselect();
    } else {
      let foundIndex = value.findIndex((e) => e.id === id);
      if (foundIndex !== -1) {
        value.splice(foundIndex, 1);
      }
    }
    formField.setValue(value);
    formField.markAsDirty();
  }


  //#endregion
}
