export interface IEditFormField {
  name: string;
  label: string;
  type: FieldType;
  required?: boolean;
  validatorPattern?: string;
  selected?: any[];
  disabled?: boolean;
  metaDataType?: string;
  filter?: string;
  optionAll?: boolean;
  dependent?: string;
  blank?: boolean;
}

export enum FieldType {
  Date = "date",
  DateTime = "dateTime",
  String = "string",
  Int = "int",
  Decimal = "decimal",
  LargeText = "largeText",
  Bool = "bool",
  Select = "select",
  MultipleSelect = "multipleSelect",
  MultiDrag = "multiDrag",
  MultiDragNoOrder = "multiDragNoOrder",
  Email = "email",
  Password = "password",
  Table = "table",
  List = "list"
}
