import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { MetaDataTypes } from "../../enums/meta-data-types.enum";
import { AppConfigurationService } from "../app-config/app-configuration.service";

@Injectable({ providedIn: "root" })
export class MetaDataService {
  constructor(private httpClient: HttpClient,
    private readonly configService: AppConfigurationService) {
  }

  getMetaData(types: MetaDataTypes[]) {
    return this.httpClient.get<any[]>(this.configService.apiAddress + "/metaData/",
      {
        // any is used to satisfy compiler / intellisense.
        withCredentials: true,
        params: { 'types': types as any },
        responseType: "json"
      });
  }
}
