<igx-input-group class="filter-input" type="box" [displayDensity]="'compact'">
  <igx-prefix class="prefix">
    <igx-icon
      class="small-icon"
      family="imx-icons"
      name="{{ selectedFilter?.iconName }}"
    ></igx-icon>
    <mat-select class="filter-select transparent" (ngModelChange)="filterChange($event)" [(ngModel)]="selectedFilter">
      <mat-select-trigger>
      </mat-select-trigger>
      <mat-option *ngFor="let item of operations" [value]="item">
        <igx-icon family="imx-icons" name="{{item?.iconName}}"></igx-icon>
        {{item?.name}}
      </mat-option>
    </mat-select>
  </igx-prefix>
  <input
    #input
    igxInput
    [type]="dataType === 'money' || dataType === 'number' ? 'number' : 'text'"
    igxFocus
    autocomplete="off"
    [(ngModel)]="filterValue"
    (input)="onInput(input, column)"
    [id]="column.field"
  />
  <igx-suffix
    *ngIf="input.value || input.value === 0"
    (click)="clearInput(column)"
    tabindex="0"
  >
    <igx-icon class="d-flex align-items-center">clear</igx-icon>
  </igx-suffix>
  <igx-suffix  *ngIf="grid.pagingMode != 1">
      <mat-select class="filter-select" (ngModelChange)="optionSelected($event)" [(ngModel)]="selectedOption">
        <mat-option *ngFor="let string of availableValues" [value]="string">
          <span>{{ string }}</span>
        </mat-option>
      </mat-select>
  </igx-suffix>
</igx-input-group>
