import {
  Component,
  EventEmitter,
  Inject,
  Output,
  ViewChild
} from "@angular/core";
import {MatDialogRef, MAT_DIALOG_DATA} from "@angular/material/dialog";
import {BaseModalComponent} from "src/app/shared/base/base-modal.component";
import {Validators} from "@angular/forms";
import {stockRequestsColumns} from "./stock-modal.component.columns";
import {ModalRequest} from "src/app/shared/classes/modal.request";
import {ModalType} from "src/app/shared/enums/modal-fields.enum";
import {GridComponent} from "src/app/shared/grid/grid.component";
import {Claims} from "src/app/auth/models/claims";
import {ViewCategories} from "../../shared/enums/view-categories.enum";
import {take} from "rxjs/operators";

@Component({
  selector: "app-stock-modal",
  templateUrl: "./stock-modal.component.html",
  styleUrls: ["./stock-modal.component.css"]
})
export class StockModalComponent extends BaseModalComponent {
  @Output()
  onSave = new EventEmitter();
  stock = <any>{};
  hasClaim = false;
  allNames = ["Stock Photos"];
  requestsColumns = stockRequestsColumns;

  getNav = () => [
    {name: "Stock Details", page: "details"},
    {name: "Custom Fields", page: "customFields", hidden: !this.showCustomFields},
    {name: "Photos", page: "photos"},
    {name: "Links & Files", page: "linksAndFiles"},
    {name: "History", page: "auditTrail", hidden: this.isNew}
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<Component>
  ) {
    super();
    this.isNew = !data.data.id;

    this.fields = data.fields;
    this.metaData = data.metaData;
    this.remoteService.getFields(ViewCategories.Stock)
      .pipe(take(1))
      .subscribe((response) => {
        this.customFields = response;
        this.showCustomFields = this.customFields.length > 0;
        this.nav = this.getNav();
        this.current = this.nav[0];

        const process = function (context) {
          context.createForm(context.fields, context.stock).then((form) => {
            context.form = form;
            context.form.controls["quantity"].setValidators(Validators.required);
            setTimeout(() => context.form.markAllAsTouched(), 5000);
            //Check Claims
            !context.authService.hasClaim(Claims.stockEdit)
              ? context.form.disable()
              : context.hasClaim = true;
          });
        };

        if (!this.isNew) {
          this.remoteService
            .getBy("stock", "id", data.data.id)
            .subscribe((res: any) => {
              this.stock = res;
              process(this);
            });
        } else {
          process(this);
        }
      });


  }

  changeSection(item) {
    if (item.page === "auditTrail") {
      if (!this.stock[item.page]) {
        this.loaded = false;
        this.remoteService
          .getBy(`issue/${item.page}`, null, this.data.data.id)
          .subscribe(
            (res) => {
              this.stock[item.page] = res;
              this.loaded = true;
            },
            () => {
              this.loaded = true;
            }
          );
      }
    }
    if (item.page === "stockLog") {
      if (!this.stock[item.page]) {
        this.loaded = false;
        this.remoteService
          .getBy(`stock/${item.page}`, null, this.data.data.id)
          .subscribe(
            (res) => {
              this.stock[item.page] = res;
              this.loaded = true;
            },
            () => {
              this.loaded = true;
            }
          );
      }
    }
    if (item.page === "requests") {
      if (!this.stock[item.page]) {
        this.loaded = false;
        this.remoteService
          .getBy("stock/stockItemRequests", "id", this.data.data.id)
          .subscribe(
            (res) => {
              this.stock[item.page] = res;
              this.loaded = true;
            },
            () => {
              this.loaded = true;
            }
          );
      }
    }
    this.current = item;
  }

  checkImages($event) {
    if (!this.stock.images) {
      this.stock.images = [];
    }
    this.stock.images.push($event);
    this.form.markAsDirty();
  }

  trackImage($event) {
    this.stock.images.push({
      id: $event
    });
  }

  save() {
    this.toggleSave();
    this.stock = {...this.stock, ...this.form.getRawValue()};
    this.onSave.emit(this.stock);
  }

  close(form) {
    this.closeModal(form, this.dialogRef);
  }

  checkDocuments($event) {
    if (!this.stock.documents) {
      this.stock.documents = [];
    }
    this.stock.documents.push($event);
    this.form.markAsDirty();
  }

  saveRequest(context, entity, dialogRef) {
    context.addOrReplace(context.stock.requests, entity);
    context.taskGridComponent.grid.data = [...context.stock.requests];
    dialogRef.close();
    context.form.markAsDirty();
  }

  doubleClick(cell) {
    this.openModal.next(
      new ModalRequest({
        id: cell.id.rowID,
        requestContext: this,
        modalType: ModalType.StockRequest,
        autoSave: false,
        afterSave: this.saveRequest
      })
    );
  }
}
