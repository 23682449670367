import { GridColumnDataType } from "@infragistics/igniteui-angular";
import { IGridColumn } from "../../shared/grid/grid-model";

export const issuesColumns: IGridColumn[] = [
  {field: "created", header: "Reported Date", dataType: GridColumnDataType.DateTime},
  {field: "issueCategoryName", header: "Category", dataType: GridColumnDataType.String},
  {field: "issueTypeName", header: "Type", dataType: GridColumnDataType.String},
  {field: "issueNumber", header : "Issue Number", dataType : GridColumnDataType.String},
  {field: "description", header: "Description", dataType: GridColumnDataType.String},
  {field: "riskName", header: "Risk", dataType: GridColumnDataType.String},
  {field: "closedDate", header: "Closed Date", dataType: GridColumnDataType.DateTime}
];

