import { FieldType, IEditFormField } from "../../shared/components/edit-form/edit-form-model";

export const equipmentFields: IEditFormField[] = [
  { name: "name", label: "Equipment Name", type: FieldType.String, required: true },
  { name: "equipmentCategory", label: "Category", type: FieldType.String, required: true },
  { name: "equipmentType", label: "Type", type: FieldType.String, required: true },
  { name: "description", label: "Description", type: FieldType.String },
  { name: "storedLocation", label: "Location", type: FieldType.String, validatorPattern: "^.{1,100}$" },
  { name: "make", label: "Make", type: FieldType.String, validatorPattern: "^.{1,100}$" },
  { name: "model", label: "Model", type: FieldType.String, validatorPattern: "^.{1,100}$" },
  { name: "serialNo", label: "Serial No.", type: FieldType.String, validatorPattern: "^.{1,100}$" },
  { name: "equipmentNo", label: "Equipment No.", type: FieldType.String, validatorPattern: "^.{1,24}$" },
  { name: "fleetNo", label: "Fleet No.", type: FieldType.String, validatorPattern: "^.{1,100}$" },
  { name: "year", label: "Year", type: FieldType.String },
  { name: "value", label: "Value", type: FieldType.String },
  { name: "reference", label: "Reference", type: FieldType.String, validatorPattern: "^.{1,100}$" },
  { name: "mileage", label: "Mileage", type: FieldType.Int },
  { name: "hoursUsed", label: "Hours Used", type: FieldType.Int },
  { name: "havReading", label: "HAV Reading", type: FieldType.Int },
  { name: "noiseLevel", label: "Noise Level", type: FieldType.Decimal },
  { name: "tagId", label: "Tag Id", type: FieldType.String },
  { name: "equipmentStatus", label: "Equipment Status", type: FieldType.String, required: true },
  { name: "lastServiceDate", label: "Lst Service Date", type: FieldType.String },
  { name: "nextServiceDueDate", label: "Next Service Due Date", type: FieldType.String },
  { name: "notes", label: "Notes", type: FieldType.String }
];
export const equipmentChecksRecordFields: IEditFormField[] = [];
export const equipmentMaintenanceRecordsFields: IEditFormField[] = [];

export const equipmentCategoriesFields: IEditFormField[] = [
  { name: "name", label: "Category Name", type: FieldType.String, required: true },
  { name: "description", label: "Description", type: FieldType.LargeText }
];
export const equipmentTypesFields: IEditFormField[] = [
  { name: "name", label: "Type Name", type: FieldType.String, required: true },
  {
    name: "type", label: "Equipment Category", type: FieldType.Select, metaDataType: "equipmentCategories",
    required: true
  },
  { name: "description", label: "Description", type: FieldType.LargeText },
  {
    name: "trainingRequirements",
    label: "Required Courses for Equipment Type",
    metaDataType: "courses",
    type: FieldType.MultiDragNoOrder,
    selected: []
  }
];
export const equipmentChecklistsFields: IEditFormField[] = [
  { name: "name", label: "Checklist Name", type: FieldType.String, required: true, validatorPattern: "^.{1,200}$" },
  {
    name: "equipmentTypes", label: "Equipment Types", metaDataType: "availableEquipmentTypes",
    type: FieldType.MultiDrag, selected: []
  },
  { name: "description", label: "Description", type: FieldType.LargeText },
  {
    name: "equipmentCheckItems",
    label: "Checks",
    metaDataType: "equipmentCheckItems",
    type: FieldType.MultiDrag,
    selected: []
  }
];
export const equipmentChecksFields: IEditFormField[] = [
  { name: "name", label: "Check Name", type: FieldType.String, required: true, validatorPattern: "^.{1,200}$" }
];

export const equipmentServiceTypeFields: IEditFormField[] = [
  { name: "name", label: "Service Type Name", type: FieldType.String, required: true },
  { name: "description", label: "Description", type: FieldType.String },
  { name: "validWeeks", label: "Valid Weeks", type: FieldType.Int },
  { name: "validHours", label: "Valid Hours", type: FieldType.Int },
  { name: "validMiles", label: "Valid Miles", type: FieldType.Int },
  {
    name: "expiredServiceAction",
    label: "Expired Service Action",
    metaDataType: "expiredServiceActions",
    type: FieldType.Select
  }
];

export const equipmentServiceRequirementFields: IEditFormField[] = [
  { name: "equipmentServiceType", label: "Service Type", metaDataType: "equipmentServiceTypes", type: FieldType.Select },
  { name: "equipment", label: "Equipment", metaDataType: "equipment", type: FieldType.Select },
  { name: "requiredDate", label: "Required Date", type: FieldType.Date },
  { name: "requiredHours", label: "Required Hours", type: FieldType.Int },
  { name: "requiredMiles", label: "Required Miles", type: FieldType.Int },
];

export const multipleEquipmentServiceRequirementFields: IEditFormField[] = [
  { name: "equipmentServiceType", label: "Service Type", metaDataType: "equipmentServiceTypes", type: FieldType.Select },
  { name: "equipmentList", label: "Equipment", metaDataType: "equipment", type: FieldType.MultipleSelect },
  { name: "requiredDate", label: "Required Date", type: FieldType.Date },
  { name: "requiredHours", label: "Required Hours", type: FieldType.Int },
  { name: "requiredMiles", label: "Required Miles", type: FieldType.Int },
];


export const equipmentContactFields: IEditFormField[] = [
  { name: "name", label: "Contact Name", type: FieldType.String, required: true, validatorPattern: "^.{1,100}$" },
  { name: "email", label: "Email", type: FieldType.String, required: true },
  { name: "mobile", label: "Mobile", type: FieldType.String },
  { name: "equipmentCategories", label: "Equipment Categories", type: FieldType.MultipleSelect, selected: [] },
  { name: "equipmentStatuses", label: "Equipment Statuses", type: FieldType.MultipleSelect, metaDataType: "equipmentStatus", selected: [] },

];