import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-audit-trail',
  templateUrl: './audit-trail.component.html',
  styles: [
  ]
})
export class AuditTrailComponent{

  @Input()
  message = "Audit History will display here after additions or changes are made.";

  @Input()
  auditTrail:any[];

}
