import { Router } from "@angular/router";
import { Component } from "@angular/core";
import { Subscription } from "rxjs";
import { MatDialog } from "@angular/material/dialog";
import { InjectorService } from "../services/injector/injector.service";
import { IEditModalData } from "../components/modals/edit-modal.component";
import { HubComponent } from "../hub/hub.component";
import { BaseService } from "./base.service";
import { ModalRequest } from "../classes/modal.request";
import { GridComponent } from "../grid/grid.component";
import { IGridColumn } from "../grid/grid-model";

@Component({
  template: ""
})
export class BaseComponent extends HubComponent {
  protected router: Router;
  protected baseService: BaseService;
  protected matDialog: MatDialog;

  blankGuid = "00000000-0000-0000-0000-000000000000";
  //readOnly = this.sharedModel.user.isReadOnly;
  readOnly = false;

  bools = [
    { id: true, name: "Yes" },
    { id: false, name: "No" }
  ];

  nullableBools = [
    { id: null, name: "" },
    { id: true, name: "Yes" },
    { id: false, name: "No" }
  ];

  sideNav = <any>{};
  data: any[];
  editModalData: IEditModalData = {
    fields: [],
    title: "",
    data: {},
    metaData: {}
  };
  contextMenuOptions = [];
  today = Date.now();

  singleSubscription: Subscription;
  subscriptions: Subscription;

  constructor() {
    super();
    const injector = InjectorService.getInjector();
    this.router = injector.get(Router);
    this.matDialog = injector.get(MatDialog);
    this.baseService = injector.get(BaseService);
  }

  compareOverride(t1, t2) {
    if (t2 !== null) {
      return t1.id === t2.id;
    }
    return null;
  }

  compareOverrideCustomBoolean(t1, t2) {
    if (t2 !== null) {
      return t1.toString().toLowerCase() === t2.toString().toLowerCase();
    }
    return null;
  }

  // fn -> should must return string/number because it will be use as key in object
  groupBy = (array, fn) =>
    array.reduce((a, b) => ((a[fn(b)] ||= []).push(b), a), {});

  newIsoDate() {
    const date = new Date();
    if (date.toString().includes("(British Summer Time)")) {
      date.setHours(date.getHours() + 1);
    }
    return date.toISOString();
  }

  openEntityProperties(cell, context) {
    if (cell.id.rowID) {
      context.openModal.next(
        new ModalRequest({
          id: cell.id.rowID,
          requestContext: context,
          modalType: context.current.modalType,
          autoSave: true,
          afterSave: context.hook
        })
      );
    }
  }

  camelize(str) {
    return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) => {
      if (+match === 0) return "";
      return index == 0 ? match.toLowerCase() : match.toUpperCase();
    });
  }

  setBool(object) {
    if (!object) {
      return false;
    }
    return object;
  }

  clearUrl(context, location) {
    // Removes the parameter from the URL once a modal is closed.
    if (context.router.url.includes(location) && context.location) {
      context.location.replaceState(`/${location}`);
    }
  }

  //pass in the date fields and time fields and return concatted version
  formDateTime(date, time) {
    if (time.hour < 10) {
      time.hour = `0${time.hour.toString()}`;
    }
    if (time.minute < 10) {
      time.minute = `0${time.minute.toString()}`;
    }

    const formattedDate = new Date(date).toISOString().substring(0, 10);
    return formattedDate + "T" + time + "Z";
  }

  click(event) {
    if (event.cell) {
      event.call.action(event.cell, this, event.call.extra);
    } else {
      event(this);
    }
  }

  newGuid() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
      const r = (Math.random() * 16) | 0,
        v = c === "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }

  getDays(type, number, date?) {
    const d = date ? date : new Date();

    switch (type) {
      case "day": {
        d.setDate(d.getDate() + number);
        break;
      }
      case "week": {
        d.setDate(d.getDate() + number * 7);
        break;
      }
      case "month": {
        // this isn't a typo, TS was pulling rand dates without it
        d.setMonth(d.getMonth() + number * 1);
        break;
      }
      case "year": {
        d.setMonth(d.getMonth() + number * 12);
        break;
      }
    }
    return d;
  }

  countDecimals(value) {
    if (Math.floor(value) !== value)
      return value.toString().split(".")[1].length || 0;
    return 0;
  }

  convertDate(inputFormat) {
    function pad(s) {
      return s < 10 ? "0" + s : s;
    }

    var d = new Date(inputFormat);
    return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join("/");
  }

  convertDateTime(inputFormat) {
    function pad(s) {
      return s < 10 ? "0" + s : s;
    }

    var d = new Date(inputFormat);
    return (
      [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join("/") +
      " " +
      [pad(d.getHours()), pad(d.getMinutes())].join(":")
    );
  }

  getWeekOfMonth(exact) {
    var month = exact.getMonth(),
      year = exact.getFullYear(),
      firstWeekday = new Date(year, month, 1).getDay(),
      lastDateOfMonth = new Date(year, month + 1, 0).getDate(),
      offsetDate = exact.getDate() + firstWeekday - 1,
      index = 1,
      weeksInMonth =
        index + Math.ceil((lastDateOfMonth + firstWeekday - 7) / 7),
      week = index + Math.floor(offsetDate / 7);
    if (exact || week < 2 + index) return week;
    return week === weeksInMonth ? index + 5 : week;
  }

  getOrdinalNum(number) {
    let selector;
    if (number <= 0) {
      selector = 4;
    } else if ((number > 3 && number < 21) || number % 10 > 3) {
      selector = 0;
    } else {
      selector = number % 10;
    }
    return number + ["th", "st", "nd", "rd", ""][selector];
  }

  hook(context, response) {
    let index = context.current.data.findIndex(
      (el) => el.id === response.id
    );
    if (index !== -1) {
      context.current.data[index] = response;
      context.openSnackBar(
        `${context.current.singular} updated successfully`
      );
    } else {
      if(context.gridComponent.selectedView.viewId%10==0){
        context.current.data.push(response)
      }
      context.openSnackBar(
        `${context.current.singular} added successfully`
      );
    }
      context.gridComponent.grid.data = [...context.current.data];
  }

  isArray(what) {
    return Object.prototype.toString.call(what) === '[object Array]';
  }

  saveMultiple(context, entity, dialogRef): Promise<void> {
    return new Promise((resolve, error) => {
      let currentData = context.current.data;
      if (entity.ids[entity.ids.length - 1] == undefined) {
        entity.ids.pop();
      }
      entity.ids.map((e) => {
        let payload = JSON.parse(JSON.stringify(entity));
        payload.id = e;
        context.remoteService
          .patch(context.current.controller, null, payload)
          .subscribe(
            () => {
              dialogRef.close();
              context.openSnackBar(
                `${context.current.singular} updated successfully`
              );
              // If the Entity is on its own, we'll do a get by afterwards, otherwise we'll just get all data
              // which will also help if the data shouldn't be in the Selected view.
              if (entity.ids.length == 1) {
                context.remoteService
                  .getBy(context.current.controller, "id", payload.id)
                  .subscribe((res) => {
                    let index = currentData.findIndex(
                      (el) => el.id === payload.id
                    );
                    if (index !== -1) {
                      currentData[index] = res;
                    }
                    //Trigger change detection for grid data
                    context.gridComponent.grid.data = [...currentData];
                  });
              } else {
                context.remoteService
                  .getColumnData(
                    context.current.controller,
                    context.current.selectedView.viewId,
                    context.current.selectedColumns.map((c) => {
                      if (!c.hidden) {
                        return c.field;
                      }
                    }))
                  .subscribe((res) => {
                    //Trigger change detection for grid data
                    context.gridComponent.grid.data = res;
                  });
              }
            },
            (errorResponse) => {
              dialogRef.close();
              error(errorResponse);
              context.openSnackBar(
                `Error - ${errorResponse.message}`,
                null,
                true
              );
            }
          );
      });
      resolve();
    });
  }

  downloadUpdateData(gridComponent: GridComponent, viewId: number = 0,
    currentColumns: IGridColumn[], columns: IGridColumn[], httpController: string) {
    gridComponent.checkGrid();
    // Initially clone the columns array
    let originalGridColumns = JSON.parse(JSON.stringify(currentColumns));
    // Now use the Update Only Columns
    let gridColumnsToEnable = columns.filter((column) => column.usedInDataUpdate);
    // Ensure that all grid columns are visible
    gridColumnsToEnable.map((column) => column.hidden = false);
    //Filter the columns so that they're only the field.
    let gridColumnsToRequest = gridColumnsToEnable.map((column) => column.field);
    gridComponent.columns = gridColumnsToEnable;
    // Now download the data
    this.remoteService.getColumnData(httpController, viewId, gridColumnsToRequest)
      .subscribe(async (data) => {
        gridComponent.gridData = data;
        setTimeout(async () =>
          await gridComponent.exportDataAsync().then(() => {
            // Reset the columns to the original
            gridComponent.columns = originalGridColumns;
            this.remoteService.getColumnData(httpController, viewId, gridColumnsToRequest)
              .subscribe((secondData) => {
                gridComponent.gridData = secondData;
              });
          }), 1500);
      });
  }
}
