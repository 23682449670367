import { GridColumnDataType } from "@infragistics/igniteui-angular";
import { IGridColumn } from "../../shared/grid/grid-model";

export const taskCheckRecordModalColumns: IGridColumn[] = [
  {
    field: "created",
    header: "Created Date",
    dataType: GridColumnDataType.DateTime
  },
  {
    field: "name",
    header: "Task Name",
    dataType: GridColumnDataType.String
  },
  {
    field: "programNames",
    header: "Program(s)",
    dataType: GridColumnDataType.String
  },
  {
    field: "taskStatus",
    header: "Task Status",
    dataType: GridColumnDataType.String
  }
];
