<div class="small-modal-photos">
  <input
    class="hide-input position-absolute precise-location"
    #file
    [id]="'input-file-id'"
    (change)="save(file.files)"
    multiple
    type="file"
    accept="image/png,image/jpeg,image/jpg"
  />
  <label
    [for]="'input-file-id{{number}}'"
  >
    <i class="fad fa-camera-alt fa-2x"></i>
    <br/>
    <span *ngIf="files.length === 0; else imageCount">Add Image</span>
    <ng-template #imageCount>{{files.length}} Image<span *ngIf="files.length > 1">s</span></ng-template>
  </label>
</div>
