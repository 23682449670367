import {TaskModalComponent} from "./task-modal/task-modal.component";
import {TaskRecordModalComponent} from "./task-record-modal/task-record-modal.component";
import {EquipmentModalComponent} from "./equipment-modal/equipment-modal.component";
import {EquipmentCheckRecordModalComponent} from "./equipment-check-record-modal/equipment-check-record-modal.component";
import {EquipmentMaintenanceRecordModalComponent} from "./equipment-maintenance-record-modal/equipment-maintenance-record-modal.component";
import {IssueModalComponent} from "./issue-modal/issue-modal.component";
import {ProgramModalComponent} from "./program-modal/program-modal.component";
import {StockModalComponent} from "./stock-modal/stock-modal.component";
import {StockRequestModalComponent} from "./stock-request-modal/stock-request-modal.component";
import {SiteModalComponent} from "./site-modal/site-modal.component";
import {PersonModalComponent} from "./person-modal/person-modal.component";
import {InspectionModalComponent} from "./inspection-modal/inspection-modal.component";
import {InspectionRecordModalComponent} from "./inspection-record-modal/inspection-record-modal.component";
import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {SharedModule} from "../shared/modules/shared.module";
import {IgxModule} from "../shared/modules/igx.module";
import {MapModule} from "../map/map.module";
import {TaskCheckRecordModalComponent} from "./task-check-record-modal/task-check-record-modal.component";
import {ProgramSiteRecordModalComponent} from "./program-site-record-modal/program-site-record-modal.component";
import {ProgramSiteCheckRecordModalComponent} from "./program-site-check-record-modal/program-site-check-record-modal.component";
import {InspectionCheckRecordModalComponent} from "./inspection-check-record-modal/inspection-check-record-modal.component";
import { InspectionBaseComponent } from './shared/inspection-base/inspection-base.component';
import { NewInspectionModalComponent } from './new-inspection-modal/new-inspection-modal.component';
import { NewSiteQualityCheckModalComponent } from './new-site-quality-check-modal/new-site-quality-check-modal.component';
import {StockItemRequestModalComponent} from "./stock-request-item-modal/stock-item-request-modal.component";
import {SiteQualityCheckRecordModalComponent} from "./site-quality-check-record-modal/site-quality-check-record-modal.component";
import {TaskQualityCheckRecordModalComponent} from "./task-quality-check-record-modal/task-quality-check-record-modal.component";
import {TaskTypeModalComponent} from "./task-type-modal/task-type-modal.component";
import {InspectionTypeModalComponent} from "./inspection-type-modal/inspection-type-modal.component";
import {SupportUserModalComponent} from "./support-user-modal/support-user-modal.component";
import {NewsModalComponent} from "./news-modal/news-modal.component";
import {DatabaseModalComponent} from "./database-modal/database-modal.component";
import {
  EquipmentServiceRequirementModalComponent
} from "./equipment-service-requirement-modal/equipment-service-requirement-modal.component";
import {AttendanceModalComponent} from "./attendance-modal/attendance-modal.component";
import {NewProgramCheckModalComponent} from "./new-program-check-modal/new-program-check-modal.component";
import {ProgramSiteCheckBaseComponent} from "./shared/program-site-check-base/program-site-check-base.component";
import {CustomFieldModalComponent} from "./custom-field-modal/custom-field-modal.component";
import {SharedDocumentModalComponent} from "./shared-document-modal/shared-document-modal.component";
import {TrainingRecordModalComponent} from "./training-record-modal/training-record-modal.component";
import { VideoModalComponent } from "./video-modal/video-modal.component";
import {MatTooltip} from "@angular/material/tooltip";

@NgModule({
  declarations: [
    TaskModalComponent,
    TaskRecordModalComponent,
    TaskCheckRecordModalComponent,
    EquipmentModalComponent,
    EquipmentCheckRecordModalComponent,
    EquipmentMaintenanceRecordModalComponent,
    IssueModalComponent,
    ProgramModalComponent,
    StockModalComponent,
    StockRequestModalComponent,
    SiteModalComponent,
    PersonModalComponent,
    InspectionModalComponent,
    InspectionRecordModalComponent,
    ProgramSiteRecordModalComponent,
    ProgramSiteCheckRecordModalComponent,
    InspectionCheckRecordModalComponent,
    InspectionBaseComponent,
    ProgramSiteCheckBaseComponent,
    NewInspectionModalComponent,
    NewSiteQualityCheckModalComponent,
    NewProgramCheckModalComponent,
    StockItemRequestModalComponent,
    SiteQualityCheckRecordModalComponent,
    TaskQualityCheckRecordModalComponent,
    TaskTypeModalComponent,
    InspectionTypeModalComponent,
    SupportUserModalComponent,
    NewsModalComponent,
    DatabaseModalComponent,
    EquipmentServiceRequirementModalComponent,
    AttendanceModalComponent,
    CustomFieldModalComponent,
    SharedDocumentModalComponent,
    TrainingRecordModalComponent, VideoModalComponent],
    imports: [
        CommonModule,
        SharedModule,
        IgxModule,
        MapModule,
        MatTooltip
    ]
})
export class ModalsModule {
}
