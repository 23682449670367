import {FieldType, IEditFormField} from "../../shared/components/edit-form/edit-form-model";

export const programsFields: IEditFormField[] = [
  {name: "name", label: "Program Name", type: FieldType.String, required: true},
  {name: "description", label: "Description", type: FieldType.LargeText},
  {name: "teams", label: "Teams", type: FieldType.MultipleSelect},
  {name: "sites", label: "Sites", type: FieldType.Select},
  {name: "contractor", label: "Contractor", type: FieldType.Select},
];

export const programSiteRecordFields: IEditFormField[] = [
  {
    name: "siteName",
    label: "Site",
    type: FieldType.String,
    required: true
  },
  {
    name: "createdByName",
    label: "Created By",
    type: FieldType.String,
    disabled: true
  },
  {name: "note", label: "Notes", type: FieldType.String},
  {
    name: "mobileCreatedDate",
    label: "Recorded Date",
    type: FieldType.Date,
    required: true
  },
  {
    name: "mobileCreatedTime",
    label: "Recorded Time",
    type: FieldType.Date,
    required: true
  },
  {
    name: "siteRecordStatus",
    label: "Site Record Status",
    type: FieldType.Select,
    required: true,
    metaDataType: "siteRecordStatus"
  },
  {
    name: "program",
    label: "Program",
    type: FieldType.Select,
    required: true
  }
];

export const programsSiteCheckRecordFields: IEditFormField[] = [
  {
    name: "siteName",
    label: "Site",
    type: FieldType.String,
    required: true
  },
  {
    name: "createdByName",
    label: "Created By",
    type: FieldType.String,
    disabled: true
  },
  {name: "note", label: "Notes", type: FieldType.String},
  {
    name: "mobileCreatedDate",
    label: "Recorded Date",
    type: FieldType.Date,
    required: true
  },
  {
    name: "mobileCreatedTime",
    label: "Recorded Time",
    type: FieldType.Date,
    required: true
  },
  {
    name: "programName",
    label: "Program",
    type: FieldType.String,
    disabled: true
  }
];
