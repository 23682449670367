import { Component, EventEmitter, Inject, Output } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

export class IEditModalData {
  public data: {};
  public fields: any[];
  public metaData?: any;
  public message?: string;
  public title?: string;
  public showUnknownDate?: boolean;
  public replaceSave?: string;
  public replaceClose?: string;
}

@Component({
  selector: "app-edit-modal",
  templateUrl: "./edit-modal.component.html",
  styles: []
})
export class EditModalComponent {
  @Output()
  onClose = new EventEmitter();
  @Output()
  onSave = new EventEmitter();
  @Output()
  onNavigate = new EventEmitter();

  data;
  title;
  fields: any[];
  metaData: any[];
  showUnknown = false;
  replaceSave: string;
  replaceClose: string;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public initData: IEditModalData
  )
  {
    if (initData) {
      this.data = initData.data;
      this.fields = initData.fields;
      this.metaData = initData.metaData;
      this.title = initData.title;
      this.showUnknown = initData.showUnknownDate;
      this.replaceSave = initData.replaceSave;
      this.replaceClose = initData.replaceClose;
    }
  }
}
