import {EventEmitter, Input, Output} from "@angular/core";
import { Component } from "@angular/core";
import { BaseComponent } from "../../base/base.component";
import { ImageService } from "../../services/image/image.service";

@Component({
  selector: "small-photos",
  templateUrl: "./small-photos.component.html"
})
export class SmallPhotosComponent extends BaseComponent {
  @Output()
  addedImage = new EventEmitter();
  @Output()
  newId = new EventEmitter();
  @Input()
  index : number;

  constructor(private imageService: ImageService) {
    super();
  }

  files = [];

  save(files) {
    if (files.length === 0) {
      return;
    }
    for (let i = 0; i < files.length; i++) {
      const dto = <any>{};
      dto.id = this.newGuid();
      const formData = new FormData();
      formData.append(files[i].name, files[i]);
      formData.set("imageDto", JSON.stringify(dto));
      this.imageService.addImage(formData).subscribe(
        (response: any) => {
          const image = response;
          this.addedImage.emit(image);
          this.newId.emit(image.id);
          this.files.push(image.id);
        },
        () => {
          this.openSnackBar(
            "Error - Your image could not be saved at this time. \n " +
              "If this problem persists please contact PSS for advice",
            null,
            true
          );
        }
      );
    }
  }
}
