import {EventEmitter, Injectable, Output} from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class MapService {

  @Output() public idPlotReceived: EventEmitter<any> = new EventEmitter();

  public gridFilter: any = {};

  matchedId(arr, id) {
    const markersSameId = arr.filter((c) => c.id === id);
    let replace = {
      marker: false,
      area: false,
    };
    if (markersSameId.length > 0) {
      markersSameId.forEach((el) => {
        el.latitude ? (replace.marker = true) : "";
        el.customRegion ? (replace.area = true) : "";
      });
    }
    return replace;
  }

  getFilterMethods(type) {
    switch (type) {
      case FilterableTypes.Sites:
        return [
          {
            filter: "ward",
            name: "Ward",
            logic: this.logic,
          },
          {
            filter: "area",
            name: "Area",
            logic: this.logic,
          },
          {
            filter: "client",
            name: "Site Client",
            logic: this.logic,
          },
          {
            filter: "category",
            name: "Site Category",
            logic: this.logic,
          }
        ];

      case FilterableTypes.Issues:
        return [
          {
            filter: "status",
            name: "Issue Status",
            logic: this.logic,
          },
          {
            filter: "risk",
            name: "Issue Risk",
            logic: this.logic,
          }
        ];

      case FilterableTypes.Tasks:
        return [
          {
            filter: "status",
            name: "Status",
            logic: this.logic,
          }
        ];
    }
  }

  logic(data, filter) {
    return !filter
      ? data
      : data.filter((entity) => Object.keys(entity)[filter] == filter);
  }

  averageGeolocation(coords) {
    if (coords.length === 1) {
      return coords[0];
    }

    let x = 0.0;
    let y = 0.0;
    let z = 0.0;

    for (let coord of coords) {
      const latitude = (coord.latitude * Math.PI) / 180;
      const longitude = (coord.longitude * Math.PI) / 180;

      x += Math.cos(latitude) * Math.cos(longitude);
      y += Math.cos(latitude) * Math.sin(longitude);
      z += Math.sin(latitude);
    }

    const total = coords.length;

    x = x / total;
    y = y / total;
    z = z / total;

    const centralLongitude = Math.atan2(y, x);
    const centralSquareRoot = Math.sqrt(x * x + y * y);
    const centralLatitude = Math.atan2(z, centralSquareRoot);

    return {
      latitude: (centralLatitude * 180) / Math.PI,
      longitude: (centralLongitude * 180) / Math.PI,
    };
  }
}

export enum FilterableTypes {
  Tasks = 1,
  Issues = 2,
  Sites = 3
}
