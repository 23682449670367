import {EventEmitter, Injectable} from "@angular/core";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {switchMap, tap} from "rxjs/operators";
import {Router} from "@angular/router";
import {Subject, timer} from "rxjs";
import {AppConfigurationService} from "../shared/services/app-config/app-configuration.service";
import {AppUserAuth} from "./models/app-user-auth";
import {Claims} from "./models/claims";
import {AppUser} from "./models/app-user";
import {BaseService} from "../shared/base/base.service";

@Injectable({providedIn: "root"})
export class AuthService {
  securityObject: AppUserAuth = new AppUserAuth();
  expired = new Subject();
  loggedIn = new Subject();
  databaseList = [];
  require2Fa = false;
  databaseSelected = new Subject();

  constructor(
    private readonly httpClient: HttpClient,
    private readonly configService: AppConfigurationService,
    private readonly router: Router,
    private readonly baseService: BaseService
  ) {
    // convenience setter.
    if (sessionStorage.bearerToken) {
      Object.assign(
        this.securityObject,
        JSON.parse(atob(sessionStorage.bearerToken.split(".")[1]))
      );
      this.securityObject.readOnly =
        JSON.parse(
          atob(sessionStorage.bearerToken.split(".")[1])
        ).readOnly.toLowerCase() === "true";
      this.securityObject.isSupport = this.hasClaim(Claims.supportView);
    }
  }

  resetSecurityObject(): void {
    this.securityObject.isAuthenticated = false;
    this.securityObject.isSupport = false;
    this.securityObject.webClaims = [];
    sessionStorage.removeItem("bearerToken");
  }

  hasClaim(claimType): boolean {

    let ret = false;
    if (typeof claimType === "string") {
      ret = this.isClaimValid(claimType);
    } else {
      const claims: string[] = claimType;
      if (claims) {
        for (let index = 0; index < claims.length; index++) {
          ret = this.isClaimValid(claims[index]);
          if (ret) {
            break;
          }
        }
      }
    }
    return ret;
  }

  login(entity: AppUser) {
    this.resetSecurityObject();
    return this.httpClient
      .post(this.configService.apiAddress + "/account/login", entity, {
        responseType: "text",
        withCredentials: true
      })
      .pipe(
        tap((response) => {
          if (response.includes("2FA Required")) {
            this.require2Fa = true;
            return;
          }
          if (response.includes("shardingKey")) {
            this.databaseList = JSON.parse(response);
            this.router.navigate(["login-databases"]);
            this.require2Fa = false;
          } else if (response.includes("resetToken")) {
            const json = JSON.parse(response);
            this.require2Fa = false;
            this.router.navigate([`reset-password/${json.resetToken}`]);
          } else {
            this.processLogin(response);
            this.require2Fa = false;
          }
        }),
        switchMap(() =>
          this.httpClient.get(this.configService.apiAddress + "/antiforgery")
        )
      );
  }

  loginWithMS(token: string) {
    this.resetSecurityObject();

    return this.httpClient
      .post(this.configService.apiAddress + "/account/loginWithMS", null,
        {
          responseType: "text",
          withCredentials: true,
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
          }),
        })
      .pipe(
        tap((response: any) => {

          if (response.includes("shardingKey")) {
            this.databaseList = JSON.parse(response);
            this.router.navigate(["login-databases"]);
            this.require2Fa = false;
          } else {
            this.processLogin(response);
            this.require2Fa = false;
          }
        }),
        switchMap(() =>
          this.httpClient.get(this.configService.apiAddress + "/antiforgery")
        )
      );
  }


  selectedDatabase(account) {
    this.httpClient
      .post(this.configService.apiAddress + "/account/loginDatabase", account, {
        responseType: "text",
        withCredentials: true
      })
      .subscribe((response) =>
        this.processLogin(response).then(
          () => {

            if (!this.securityObject.isSupport) {
              this.databaseList = [];
            }
            this.databaseSelected.next("");
          },
          () => {
            this.baseService.openSnackBar(
              "Error - Unable to open Database.",
              null,
              true
            );
            this.router.navigate(["login"]);
          }
        )
      );
  }

  private processLogin(response): Promise<any> {
    return new Promise((resolve) => {
      Object.assign(
        this.securityObject,
        JSON.parse(atob(response.split(".")[1]))
      );
      this.securityObject.readOnly =
        JSON.parse(atob(response.split(".")[1])).readOnly.toLowerCase() ===
        "true";
      this.securityObject.isSupport = this.hasClaim(Claims.supportView);
      this.loggedIn.next("");
      timer(
        (new Date().getTime() - this.securityObject.exp) * 6 * 1000
      ).subscribe(() => {
        this.expired.next("");
      });
      sessionStorage.setItem("bearerToken", response);
      setTimeout(() => {
        this.router.navigate(["dashboard"]);
        resolve("");
      });
    });
  }

  requestReset(entity: AppUser) {
    this.resetSecurityObject();
    this.httpClient
      .post(this.configService.apiAddress + "/account/forgotPassword", entity, {
        responseType: "text",
        withCredentials: true
      })
      .subscribe(
        () =>
          this.baseService.openSnackBar(
            "Request sent, please check your inbox"
          ),
        () =>
          this.baseService.openSnackBar(
            "Error, could not request reset, contact PSS for support",
            null,
            true
          )
      );
    this.router.navigate(["login"]);
  }

  reset(entity: AppUser) {
    this.resetSecurityObject();
    this.httpClient
      .post(this.configService.apiAddress + "/account/resetPassword", entity, {
        responseType: "text",
        withCredentials: true
      })
      .subscribe(
        () =>
          this.baseService.openSnackBar("Success, password reset successfully"),
        () =>
          this.baseService.openSnackBar(
            "Error, could not reset password, contact PSS for support",
            null,
            true
          )
      );
    this.router.navigate(["login"]);
  }

  logout() {
    this.router.navigate(["login"]);
    return this.httpClient
      .post(this.configService.apiAddress + "/account/logout", null, {
        responseType: "text"
      })
      .pipe(
        tap(() => {
          this.resetSecurityObject();
        })
      );
  }

  private isClaimValid(claimValue: string): boolean {
    const auth = this.securityObject;
    if (auth) {
      claimValue = claimValue.toLowerCase();
      if (!Array.isArray(auth.webClaims)) {
        const firstClaim = JSON.parse(JSON.stringify(auth.webClaims));
        auth.webClaims = [];
        auth.webClaims.push(firstClaim);
      }
      for (let i = 0; i < auth.webClaims.length; i++) {
        if (auth.webClaims[i].toLowerCase() === claimValue) return true;
      }
    }
    return false;
  }
}
