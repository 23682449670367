import {
  AfterViewInit,
  Component,
  EventEmitter,
  Inject,
  Output,
  ViewChild
} from "@angular/core";
import {MatDialogRef, MAT_DIALOG_DATA} from "@angular/material/dialog";
import {BaseModalComponent} from "src/app/shared/base/base-modal.component";
import {GridComponent} from "../../shared/grid/grid.component";
import {ModalRequest} from "../../shared/classes/modal.request";
import {ModalType} from "../../shared/enums/modal-fields.enum";
import {taskQualityCheckRecordsColumns} from "./site-quality-check-record-modal.component.columns";
import {Claims} from "src/app/auth/models/claims";

@Component({
  selector: "app-site-quality-check-record-modal",
  templateUrl: "./site-quality-check-record-modal.component.html"
})
export class SiteQualityCheckRecordModalComponent
  extends BaseModalComponent
  implements AfterViewInit {
  @Output()
  onSave = new EventEmitter();

  siteQualityCheckRecord = <any>{};
  allNames = ["Site Quality Check Record Photos"];

  getNav = () => [
    {name: "Record Details", page: "details"},
    {name: "Task Quality Check Records", page: "taskQualityCheckRecords"},
    {name: "Photos", page: "photos"},
    {name: "Map", page: "map"},
    {name: "History", page: "auditTrail", hidden: this.isNew}
  ];

  taskQualityCheckRecordColumns = taskQualityCheckRecordsColumns;

  @ViewChild(GridComponent, {static: false})
  private taskQualityCheckRecordsGridComponent: GridComponent;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<Component>
  ) {
    super();
    this.loaded = false;
    this.isNew = !data.data.id;
    this.nav = this.getNav();
    this.current = this.nav[0];
    this.fields = data.fields;
    this.metaData = data.metaData;
    this.siteQualityCheckRecord = data.data;

    const process = function (context) {
      context
        .createForm(context.fields, context.siteQualityCheckRecord)
        .then((form) => {
          context.form = form;
          if (context.isNew) {
            context.form.controls["createdByName"].setValue(
              context.authService.securityObject.name
            );
          }
          context.form.controls["siteName"].disable();
          context.form.controls["createdByName"].disable();
          context.loaded = true;
          //Check Claims
          !context.authService.hasClaim(Claims.siteEdit)
            ? context.form.disable()
            : context.hasClaim = true;
        });
      setTimeout(() => context.form.markAllAsTouched(), 5000);
    };

    if (this.isNew) {
      this.siteQualityCheckRecord.mobileCreated = this.newIsoDate();
    }

    this.siteQualityCheckRecord.mobileCreatedDate =
      this.siteQualityCheckRecord.mobileCreated.split("T")[0];
    this.siteQualityCheckRecord.mobileCreatedTime =
      this.siteQualityCheckRecord.mobileCreated
        .match(/([A-Z]?[^A-Z]*)/g)[1]
        .slice(1, -1)
        .slice(0, 5);

    process(this);

    setTimeout(() => this.form.markAllAsTouched(), 5000);
  }

  ngAfterViewInit() {
    this.siteQualityCheckRecord.parsedLatitude =
      this.siteQualityCheckRecord.latitude !== null
        ? parseFloat(this.siteQualityCheckRecord.latitude)
        : null;
    this.siteQualityCheckRecord.parsedLongitude =
      this.siteQualityCheckRecord.longitude !== null
        ? parseFloat(this.siteQualityCheckRecord.longitude)
        : null;
  }

  changeSection(item) {
    if (item.page === "auditTrail" || item.page === "taskQualityCheckRecords") {
      let parameter = item.page !== "auditTrail" ? "id" : null;
      if (!this.siteQualityCheckRecord[item.page]) {
        this.loaded = false;
        this.remoteService
          .getBy(
            `siteQualityCheckRecord/${item.page}`,
            parameter,
            this.data.data.id
          )
          .subscribe(
            (res) => {
              this.loaded = true;
              setTimeout(() => {
                this.siteQualityCheckRecord[item.page] = res;
              }, 50);

            },
            () => {
              this.loaded = true;
            }
          );
      }
    }
    this.current = item;
  }

  checkImages($event) {
    if (!this.siteQualityCheckRecord.images) {
      this.siteQualityCheckRecord.images = [];
    }
    this.siteQualityCheckRecord.images.push($event);
    this.form.markAsDirty();
  }

  trackImage($event) {
    this.siteQualityCheckRecord.images.push({
      id: $event
    });
  }

  save() {
    this.toggleSave();
    this.siteQualityCheckRecord = {
      ...this.siteQualityCheckRecord,
      ...this.form.getRawValue()
    };
    this.siteQualityCheckRecord.mobileCreated = this.siteQualityCheckRecord
      .mobileCreatedDate
      ? this.formDateTime(
        this.siteQualityCheckRecord.mobileCreatedDate,
        this.siteQualityCheckRecord.mobileCreatedTime
      )
      : null;
    this.onSave.emit(this.siteQualityCheckRecord);
  }

  close(form) {
    this.closeModal(form, this.dialogRef);
  }

  doubleClick(cell) {
    this.openModal.next(
      new ModalRequest({
        id: cell.id.rowID,
        requestContext: this,
        modalType: ModalType.TaskQualityCheckRecord,
        autoSave: false,
        afterSave: this.saveTaskQualityCheckRecord
      })
    );
  }

  saveTaskQualityCheckRecord(context, entity, dialogRef) {
    context.addOrReplace(
      context.siteQualityCheckRecord.taskQualityCheckRecords,
      entity
    );
    context.taskQualityCheckRecordsGridComponent.grid.data = [
      ...context.siteQualityCheckRecord.taskQualityCheckRecords
    ];
    dialogRef.close();
    context.form.markAsDirty();
  }
}
