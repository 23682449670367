import {CdkDragDrop, moveItemInArray} from "@angular/cdk/drag-drop";
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from "@angular/core";
import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {BaseModalComponent} from "../../base/base-modal.component";
import {Regex} from "../../helpers/regex-patterns";
import {MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: "edit-form",
  templateUrl: "./edit-form.component.html",
  styles: []
})
export class EditFormComponent extends BaseModalComponent
  implements OnInit, OnDestroy {
  @Input()
  title;
  @Input()
  fields: any[];
  @Input()
  data: any;
  @Input()
  metaData: any[];
  @Input()
  showUnknown: false;
  @Input()
  replaceSave: string;
  @Input()
  replaceClose: string;
  tableHeaders = [];
  @Output()
  onSave = new EventEmitter();
  @Output()
  onClose = new EventEmitter();

  form: UntypedFormGroup;
  // customHeader = DatepickerCustomHeader;
  showPwd = false;
  loaded = false;

  constructor(public dialogRef: MatDialogRef<Component>) {
    super();
  }

  ngOnInit() {
    if (this.title
      && !this.title.toLowerCase().includes("update")
      && !this.title.toLowerCase().includes("add")
      && !this.title.toLowerCase().includes("assign")
      && !this.title.toLowerCase().includes("select")) {
      this.title =
        this.data.id
          ? `Update ${this.title}` : `Add ${this.title}`;
    }

    this.createEditForm().then(() => {
      this.loaded = true;
      setTimeout(() => this.form.markAllAsTouched(), 5000);
    });
  }

  ngOnDestroy(): void {
    this.fields.forEach((f) => {
      if (f.selected) {
        f.selected = [];
      }
    });
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
  }

  createEditForm() {
    return new Promise<void>((resolve) => {
      const group = {};
      this.fields.forEach((field) => {
        group[field.name] = new UntypedFormControl(this.data[field.name]);
        if (field.type === "password") {
          group[field.name].setValidators(
            Validators.minLength(8),
            Validators.maxLength(20),
            Validators.pattern(
              /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/
            )
          );
        }

        if (field.type === "website") {
          group[field.name].setValidators(Validators.pattern(Regex.website));
        }

        if (field.type === "postcode") {
          group[field.name].setValidators(Validators.pattern(Regex.postcode));
        }
        if (field.type === "int") {
          group[field.name].setValidators(Validators.pattern(Regex.int));
        }

        if (field.required) {
          group[field.name].setValidators(Validators.required);
        }
        if (field.validatorPattern) {
          group[field.name].setValidators(
            Validators.pattern(field.validatorPattern)
          );
        }
        if (field.disabled) {
          group[field.name].disable();
        }
      });
      this.form = new UntypedFormGroup(group);
      resolve();
    });
  }

  removeItem(id, value, formField) {
    if (value.selected) {
      value.selected.find((e) => e.value.id === id).deselect();
    } else {
      let foundIndex = value.findIndex((e) => e.id === id);
      if (foundIndex !== -1) {
        value.splice(foundIndex, 1);
      }
    }
    formField.setValue(value);
    formField.markAsDirty();
  }

  save() {
    this.saving = true;
    // let entity = this.data.id
    //   ? {...this.data, ...this.form.getRawValue()}
    //   : this.form.getRawValue();

    // this.onSave.emit(entity);

    for(var i = 0; i < this.fields.length; i++) {
      if (this.fields[i].type == "dateTime") {
       
      }
    }


    this.onSave.emit({...this.data, ...this.form.getRawValue()});
    setTimeout(() => this.saving = false,1000);
  }

  close(form) {
    this.closeModal(form, this.dialogRef);
  }

  changeSelected(field, selected, e) {
    field.selected = selected;
    for (let i = 0; i < this.form.controls[field.name].value.length; i++) {
      const el = this.form.controls[field.name].value[i];
      el["order"] = i;
    }
  }

  initOption(field, option) {
    if (field.selected) {
      let found = field.selected.find((o) => o.value.id === option.value.id);
      if (!found) {
        field.selected.push(option);
      }
    }
  }

  //Order on Selection or Drag & Drop
  matSelectLoad(items) {
    if (items) {
      items.sort((a, b) => a.order - b.order);
    }
  }

  handleListDrop(event: CdkDragDrop<string>, value) {
    moveItemInArray(value, event.previousIndex, event.currentIndex);
    for (let i = 0; i < value.length; i++) {
      const el = value[i];
      el["order"] = i;
    }

    this.form.markAsDirty();
  }
}
