import {Component, EventEmitter, Input, Output} from "@angular/core";
import {BaseComponent} from "../../base/base.component";

@Component({
  selector: "app-documents",
  templateUrl: "./documents.component.html",
  styles: []
})
export class DocumentsComponent extends BaseComponent {

  @Input()
  form: any;
  @Input()
  documents: any[];
  @Input()
  siteId?: string;
  @Input()
  personId?: string;
  @Input()
  stockId?: string;
  @Input()
  issueId?: string;
  @Input()
  equipmentId?: string;
  @Input()
  canAdd: boolean;
  @Input()
  isNew: boolean;
  @Input()
  taskId?: string;
  @Input()
  trainingRecordId?: string;

  @Output()
  onClick = new EventEmitter<any>();
  @Output()
  addedDocument = new EventEmitter();

  activeFile = "";
  newDocument = {
    name: "",
    fileName: "",
    url: "",
    file: []
  };
  fileAdded = false;
  httpController = "document";

  constructor() {
    super();
  }

  set(url) {
    this.activeFile === url ? (this.activeFile = "") : (this.activeFile = url);
  }

  addFile(files) {
    if (files.length === 0) {
      return;
    }
    this.newDocument = {
      name: this.newDocument.name ? this.newDocument.name : files[0].name,
      fileName: files[0].name,
      file: files[0],
      url: null
    };
    this.fileAdded = true;
    this.form.markAsDirty();
  }

  save(newDocument) {
    newDocument.siteId = this.siteId ? this.siteId.toString() : null;
    newDocument.equipmentId = this.equipmentId ? this.equipmentId.toString() : null;
    newDocument.personId = this.personId ? this.personId.toString() : null;
    newDocument.stockId = this.stockId ? this.stockId.toString() : null;
    newDocument.issueId = this.issueId ? this.issueId.toString() : null;
    newDocument.taskId = this.taskId ? this.taskId.toString() : null;
    newDocument.trainingRecordId = this.trainingRecordId ? this.trainingRecordId.toString():null;

    const formData = new FormData();

    // Removes invalid names
    newDocument.name = newDocument.name.replace(/[^\x00-\x7F]/g, "");
    let isDocument = false;

    if (newDocument.file && newDocument.file.size) {
      formData.append(newDocument.name, newDocument.file);
      isDocument = true;
    }

    formData.set("documentDto", JSON.stringify(newDocument));

    this.remoteService.add("document/upload", null, formData).subscribe(
      (response) => {
        if (response != null
          && !response.error
        ) {
          newDocument = response;
          this.addedDocument.emit(response);

          this.openSnackBar(isDocument ? "File uploaded successfully" : "Link saved successfully");
          this.newDocument = {
            name: "",
            url: "",
            file: [],
            fileName: null
          };
        } else {
          this.openSnackBar("Error - Your document could not be saved at this time. \n " +
            "If this problem persists please contact PSS for advice", null, true);
        }
      },
      (err) => {
        this.openSnackBar(
          "Error - Unable to upload File",
          null,
          true
        );
        console.log(err);
      }
    );
    this.fileAdded = false;
    this.form.markAsDirty();
  }

  edit(document) {
    if (!document.isEditing) {
      document.isEditing = true;
    } else {
      this.remoteService.update(this.httpController, document.id, document).subscribe(
        () => {
          this.openSnackBar("Updated successfully");
          document.isEditing = false;
        },
        (errorResponse) =>
          this.openSnackBar(
            `Error - Cannot save (${errorResponse.error})`,
            null,
            true
          )
      );
    }
    this.form.markAsDirty();
  }

  delete(i) {
    this.remoteService
      .delete(this.httpController, this.documents[i].id)
      .subscribe(() => {
        this.documents.splice(i, 1);
      });
    this.form.markAsDirty();
  }

  download(url) {
    const win = window.open(url, "_blank");
    win.focus();
  }
}
