export enum MetaDataTypes {
  Sites = 50,
  AvailableClientSites = 51,
  SiteCategories = 60,

  Programs = 70,

  EquipmentCategories = 90,
  EquipmentTypes = 91,
  EquipmentStatus = 92,
  Equipment = 93,

  People = 100,
  Trainers = 101,
  JobRoles = 110,
  MobileRoles = 111,
  WebRoles = 112,
  RoleClaims = 113,
  Teams = 120,

  SiteClients = 121,
  ReviewStatuses = 122,
  Ratings = 136,
  EquipmentCheckItems = 160,
  AvailableEquipmentTypes = 161,

  StockCategories = 170,
  StockTypes = 171,
  StockItemRequestStatus = 172,
  Stock = 173,
  Courses = 300,
  CourseTypes = 330,

  EquipmentServiceTypes = 900,
  ExpiredServiceActions = 901,
  Areas = 1100,
  Wards = 1110,

  InspectionCategories = 1200,
  InspectionTypes = 1210,
  InspectionChecklists = 1220,
  InspectionCheckItems = 1230,
  InspectionStatuses = 1240,
  InspectionCheckItemResults = 1260,
  TaskCategories = 1310,
  TaskTypes = 1320,
  TaskPriorities = 1321,
  TaskRecordStatuses = 1322,
  TaskCheckRecordStatuses = 1323,
  TaskGroups = 1350,
  IssueCategories = 1400,
  Issues = 1401,
  IssueEscalatedContacts = 1402,
  IssueTypes = 1410,
  Risks = 1420,
  IssueStatus = 1430,
  TimeRecordTypes = 1620,
  SiteRecordStatus = 1640,
  ProgramSiteRecordStatuses = 1641,
  Periods = 1700,
  Days = 1701,
  QuickNoteTypes = 2000,
  Accounts = 2010,
  Databases = 2020,
  SupportTypes = 2040,
  SectionClaims = 2050,
  Contractors = 2070,
  NonContractorPeople = 2071,
  NonContractorPrograms = 2072,
  CustomFieldDataTypes = 2090,
  CustomFieldEntityTypes = 2091,
  CustomFieldOptions = 2092,
  SitesForIssues = 2093,
}
