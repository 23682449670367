import {
  AfterViewInit,
  Component,
  EventEmitter,
  Inject,
  Output,
  ViewChild
} from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { BaseModalComponent } from "src/app/shared/base/base-modal.component";
import { inspectionCheckRecordColumns } from "./inspection-record-modal.columns";
import { ModalRequest } from "../../shared/classes/modal.request";
import { ModalType } from "../../shared/enums/modal-fields.enum";
import { GridComponent } from "../../shared/grid/grid.component";
import { Claims } from "src/app/auth/models/claims";

@Component({
  selector: "app-inspection-record-modal",
  templateUrl: "./inspection-record-modal.component.html"
})
export class InspectionRecordModalComponent
  extends BaseModalComponent
  implements AfterViewInit
{
  @Output()
  onSave = new EventEmitter();

  inspectionRecord = <any>{};
  hasClaim = false;
  inspectionCheckRecordColumns = inspectionCheckRecordColumns;
  allNames = ["Inspection Record Photos"];

  getNav = () => [
    { name: "Record Details", page: "details", disabled: false },
    { name: "Check Records", page: "checkResults", disabled: false },
    { name: "Photos", page: "photos", disabled: false },
    { name: "Map", page: "map", disabled: false },
    { name: "History", page: "auditTrail", disabled: false, hidden: this.isNew }
  ];

  @ViewChild(GridComponent, { static: false })
  private inspectionCheckItemRecordGridComponent: GridComponent;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<Component>
  ) {
    super();
    this.loaded = false;
    this.isNew = !data.data.id;
    this.nav = this.getNav();
    this.current = this.nav[0];
    this.fields = data.fields;
    this.metaData = data.metaData;

    const process = function (context) {
      context
        .createForm(context.fields, context.inspectionRecord)
        .then((form) => {
          context.form = form;
          context.form.controls["siteName"].disable();
          context.form.controls["inspectionName"].disable();
          context.form.controls["equipmentName"].disable();
          context.loaded = true;
          //Check Claims
          !context.authService.hasClaim(Claims.inspectionEdit)
            ? context.form.disable()
            : context.hasClaim = true;
        });
    };

    if (!this.isNew) {
      this.inspectionRecord = data.data;
      if (!this.inspectionRecord.mobileCreated) {
        this.inspectionRecord.mobileCreated = this.inspectionRecord.created;
      }
      this.inspectionRecord.mobileCreatedDate =
        this.inspectionRecord.mobileCreated.split("T")[0];
      this.inspectionRecord.mobileCreatedTime =
        this.inspectionRecord.mobileCreated
          .match(/([A-Z]?[^A-Z]*)/g)[1]
          .slice(1, -1)
          .slice(0, 5);
      process(this);
    } else {
      process(this);
    }
    setTimeout(() => this.form.markAllAsTouched(), 5000);
  }

  ngAfterViewInit() {
    this.inspectionRecord.parsedLatitude =
      this.inspectionRecord.latitude !== null
        ? parseFloat(this.inspectionRecord.latitude)
        : null;
    this.inspectionRecord.parsedLongitude =
      this.inspectionRecord.longitude !== null
        ? parseFloat(this.inspectionRecord.longitude)
        : null;
  }

  trackImage($event) {
    this.inspectionRecord.images.push({
      id: $event
    });
  }

  save() {
    this.toggleSave();
    this.inspectionRecord = {
      ...this.inspectionRecord,
      ...this.form.getRawValue()
    };
    this.inspectionRecord.mobileCreated = this.inspectionRecord
      .mobileCreatedDate
      ? this.formDateTime(
          this.inspectionRecord.mobileCreatedDate,
          this.inspectionRecord.mobileCreatedTime
        )
      : null;
    this.onSave.emit(this.inspectionRecord);
  }

  doubleClick(cell) {
    this.openModal.next(
      new ModalRequest({
        id: cell.id.rowID,
        requestContext: this,
        modalType: ModalType.InspectionCheckItemRecord,
        autoSave: false,
        afterSave: this.saveInspectionCheckItemRecord
      })
    );
  }

  openEntityProperties(cell, context) {
    context.doubleClick(cell);
  }

  saveInspectionCheckItemRecord(context, entity, dialogRef) {
    if (entity.inspectionCheckItemResult.name) {
      entity.inspectionCheckItemResultName =
        entity.inspectionCheckItemResult.name;
    }
    if (!entity.created) {
      entity.created = context.newIsoDate();
    }
    context.addOrReplace(
      context.inspectionRecord.inspectionCheckRecords,
      entity
    );
    // If the user hasn't viewed the InspectionCheckItemRecord Table yet...
    if (context.inspectionCheckItemRecordGridComponent) {
      context.inspectionCheckItemRecordGridComponent.grid.data = [
        ...context.inspectionRecord.inspectionCheckRecords
      ];
    }
    dialogRef.close();
    context.form.markAsDirty();
  }

  changeSection(item) {
    if (item.page === "auditTrail") {
      let parameter = item.page !== "auditTrail" ? "id" : null;
      if (!this.inspectionRecord[item.page]) {
        this.loaded = false;
        this.remoteService
          .getBy(`inspectionRecord/${item.page}`, parameter, this.data.data.id)
          .subscribe(
            (res) => {
              this.inspectionRecord[item.page] = res;
              this.loaded = true;
            },
            () => {
              this.loaded = true;
            }
          );
      }
    }
    this.current = item;
  }

  close(form) {
    this.closeModal(form, this.dialogRef);
  }
}
