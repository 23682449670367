import { Component, EventEmitter, Inject, Output } from "@angular/core";
import { UntypedFormGroup, FormControl, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import {BaseModalComponent} from "../../shared/base/base-modal.component";

@Component({
  selector: "news-modal",
  templateUrl: "./news-modal.component.html",
  styleUrls: ["./news-modal.component.css"],
})
export class NewsModalComponent extends BaseModalComponent {
  @Output()
  onSave = new EventEmitter();

  news: any;
  form: UntypedFormGroup;
  isLoaded: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private readonly dialogRef: MatDialogRef<NewsModalComponent>
  ) {
    super();
    this.isNew = !data.data.id;
    this.fields = data.fields;
    this.news = this.data.data;

    this.createForm(this.fields, this.news).then((form) => {
      this.form = form;
      if (this.isNew) {
        this.form.controls["created"].setValue(this.newIsoDate());
        this.form.controls["draft"].setValue(true);
      }
      this.isLoaded = true;
    });
  }

  save() {
    this.toggleSave();
    this.news = { ...this.news, ...this.form.getRawValue() };
    this.onSave.emit(this.news);
  }

  close(form) {
    this.closeModal(form, this.dialogRef);
  }
}
