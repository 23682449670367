<content class="news-modal" *ngIf="isLoaded; else loading">
  <header>
    <div class="col-sm-12 pb-1 mt-3 text-center">
      <h4 *ngIf="!isNew; else add">{{ news.title }}</h4>
      <ng-template #add>
        <h4>Add News Article</h4>
      </ng-template>
    </div>
  </header>
  <main>
    <form [formGroup]="form">

      <div class="p-3 overflow-hidden">
        <div class="card col-sm-12 mx-auto p-3 border border-p">
          <div class="d-flex justify-content-around">
            <mat-form-field>
              <mat-label>Title</mat-label>
              <input matInput formControlName="title"/>
              <mat-error
                *ngIf="
                      form.get('title').hasError('required') &&
                      form.get('title').touched
                    "
              >
                This field is Required.
              </mat-error>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Created Date</mat-label>
              <input
                matInput
                [matDatepicker]="picker"
                formControlName="created"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="picker"
              ></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Draft</mat-label>
              <mat-select formControlName="draft">
                <mat-option [value]="true">Yes</mat-option>
                <mat-option [value]="false">No</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="card col-sm-12 mt-2 h-100 mx-auto p-3 border border-p">
          <mat-form-field>
            <mat-label>Article</mat-label>
            <quill-editor formControlName="article" [styles]="{height: '30em'}"></quill-editor>
            <input matInput class="d-none"/>
          </mat-form-field>
        </div>
      </div>
    </form>
  </main>
  <footer>
    <div class="col-sm-12 mb-3 pe-3 text-end">
      <button
        mat-flat-button
        (click)="save()"
        [disabled]="form.invalid || form.pristine"
        class="mx-1"
      >
        Save
      </button>
      <button mat-flat-button (click)="close(form)">Close</button>
    </div>
  </footer>
</content>
<ng-template #loading>
  <div class="spinner">
    <p class="fad fa-spinner-third fa-spin fa-7x"></p>
  </div>
</ng-template>
