import {Component} from "@angular/core";
import {Subject} from "rxjs";
import {InjectorService} from "../services/injector/injector.service";
import {MetaDataService} from "../services/meta-data/meta-data.service";
import {ModalService} from "../services/modal/modal.service";
import {ModalRequest} from "../classes/modal.request";
import {ModalType} from "../enums/modal-fields.enum";
import {
  areaFields,
  siteCategoryFields,
  siteClientFields,
  siteQualityCheckRecordFields,
  sitesFields,
  wardFields
} from "../../modals/modal-fields/sites.fields";
import {MetaDataTypes} from "../enums/meta-data-types.enum";
import {
  equipmentCategoriesFields,
  equipmentChecklistsFields,
  equipmentChecksFields,
  equipmentChecksRecordFields,
  equipmentContactFields,
  equipmentFields,
  equipmentMaintenanceRecordsFields,
  equipmentServiceRequirementFields,
  equipmentServiceTypeFields,
  equipmentTypesFields,
  multipleEquipmentServiceRequirementFields
} from "../../modals/modal-fields/equipment.fields";
import {EditModalComponent} from "../components/modals/edit-modal.component";
import {SiteModalComponent} from "../../modals/site-modal/site-modal.component";
import {
  inspectionCategoryFields,
  inspectionCheckItemFields,
  inspectionChecklistFields,
  inspectionCheckRecordFields,
  inspectionFields,
  inspectionRecordFields,
  inspectionTypeFields
} from "../../modals/modal-fields/inspections.fields";
import {InspectionModalComponent} from "../../modals/inspection-modal/inspection-modal.component";
import {InspectionRecordModalComponent} from "../../modals/inspection-record-modal/inspection-record-modal.component";
import {IssueModalComponent} from "../../modals/issue-modal/issue-modal.component";
import {
  issueCategoryFields,
  issueContactFields,
  issueEscalatedContactFields,
  issueFields,
  issueTypeFields,
  riskFields
} from "../../modals/modal-fields/issues.fields";
import {PersonModalComponent} from "../../modals/person-modal/person-modal.component";
import {ModalObject} from "../classes/modal.object";
import {
  contractorFields,
  courseFields,
  courseTypeFields,
  jobRoleFields,
  personFields,
  teamFields,
  trainingRecordFields,
  trainingRequirementFields
} from "../../modals/modal-fields/people.fields";
import {FieldType} from "../components/edit-form/edit-form-model";
import {
  programsFields,
  programSiteRecordFields,
  programsSiteCheckRecordFields
} from "../../modals/modal-fields/programs.fields";
import {ProgramModalComponent} from "../../modals/program-modal/program-modal.component";
import {StockModalComponent} from "../../modals/stock-modal/stock-modal.component";
import {
  stockCategoryFields,
  stockContactFields,
  stockFields,
  stockRequestFields,
  stockTypeFields
} from "../../modals/modal-fields/stock.fields";
import {StockRequestModalComponent} from "../../modals/stock-request-modal/stock-request-modal.component";
import {TaskModalComponent} from "../../modals/task-modal/task-modal.component";
import {
  addTaskGroupFields,
  assignTaskGroupFields,
  taskCategoryFields,
  taskCheckRecordFields,
  taskGroupFields,
  taskQualityCheckRecordFields,
  taskRecordFields,
  tasksFields,
  taskTypeFields,
  weatherConditionFields
} from "../../modals/modal-fields/tasks.fields";
import {TaskRecordModalComponent} from "../../modals/task-record-modal/task-record-modal.component";
import {EquipmentModalComponent} from "../../modals/equipment-modal/equipment-modal.component";
import {
  EquipmentCheckRecordModalComponent
} from "../../modals/equipment-check-record-modal/equipment-check-record-modal.component";
import {
  EquipmentMaintenanceRecordModalComponent
} from "../../modals/equipment-maintenance-record-modal/equipment-maintenance-record-modal.component";
import {AuthService} from "../../auth/auth.service";
import {MatDialog} from "@angular/material/dialog";
import {RemoteService} from "../services/remote/remote.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {HubEventService} from "./hub-event.service";
import {TaskCheckRecordModalComponent} from "../../modals/task-check-record-modal/task-check-record-modal.component";
import {
  ProgramSiteRecordModalComponent
} from "../../modals/program-site-record-modal/program-site-record-modal.component";
import {
  ProgramSiteCheckRecordModalComponent
} from "../../modals/program-site-check-record-modal/program-site-check-record-modal.component";
import {
  InspectionCheckRecordModalComponent
} from "../../modals/inspection-check-record-modal/inspection-check-record-modal.component";
import {NewInspectionModalComponent} from "../../modals/new-inspection-modal/new-inspection-modal.component";
import {
  NewSiteQualityCheckModalComponent
} from "../../modals/new-site-quality-check-modal/new-site-quality-check-modal.component";
import {
  SiteQualityCheckRecordModalComponent
} from "../../modals/site-quality-check-record-modal/site-quality-check-record-modal.component";
import {
  TaskQualityCheckRecordModalComponent
} from "../../modals/task-quality-check-record-modal/task-quality-check-record-modal.component";
import {
  customFieldFields,
  documentFields,
  periodFields,
  quickNoteFields
} from "../../modals/modal-fields/config.fields";
import {reportFields} from "../../modals/modal-fields/reports.fields";
import {mapFields} from "../../modals/modal-fields/map.fields";
import {TaskTypeModalComponent} from "../../modals/task-type-modal/task-type-modal.component";
import {InspectionTypeModalComponent} from "../../modals/inspection-type-modal/inspection-type-modal.component";
import {
  accountFields,
  databaseFields,
  newsFields,
  supportLinkFields,
  userFields
} from "../../modals/modal-fields/support.fields";
import {SupportUserModalComponent} from "../../modals/support-user-modal/support-user-modal.component";
import {NewsModalComponent} from "../../modals/news-modal/news-modal.component";
import {DatabaseModalComponent} from "../../modals/database-modal/database-modal.component";
import {
  EquipmentServiceRequirementModalComponent
} from "../../modals/equipment-service-requirement-modal/equipment-service-requirement-modal.component";
import {AttendeeFields, AttendeeTimeRecordFields} from "../../modals/modal-fields/attendance.fields";
import {AttendanceModalComponent} from "../../modals/attendance-modal/attendance-modal.component";
import {NewProgramCheckModalComponent} from "../../modals/new-program-check-modal/new-program-check-modal.component";
import {CustomFieldModalComponent} from "../../modals/custom-field-modal/custom-field-modal.component";
import {SharedDocumentModalComponent} from "../../modals/shared-document-modal/shared-document-modal.component";
import {TrainingRecordModalComponent} from "../../modals/training-record-modal/training-record-modal.component";

@Component({
  template: ""
})
export class HubComponent {
  protected modalService: ModalService;
  protected metaDataService: MetaDataService;
  protected authService: AuthService;
  protected matDialog: MatDialog;
  protected remoteService: RemoteService;
  protected snackBar: MatSnackBar
  protected hubEventService: HubEventService;
  openModal: Subject<ModalRequest>;

  constructor() {
    const injector = InjectorService.getInjector();
    this.modalService = injector.get(ModalService);
    this.metaDataService = injector.get(MetaDataService);
    this.authService = injector.get(AuthService);
    this.matDialog = injector.get(MatDialog);
    this.remoteService = injector.get(RemoteService);
    this.snackBar = injector.get(MatSnackBar);
    this.hubEventService = injector.get(HubEventService);

    this.openModal = this.hubEventService.openModal;

    if (this.openModal.observers.length === 0) {
      this.openModal.subscribe(async (modalRequest: ModalRequest) => {
        let modalObject: ModalObject = modalRequest;

        //Set controller based on Enum Name
        modalObject.controller = ModalType[modalRequest.modalType];
        //Set modal Fields based on Enum itself
        switch (modalRequest.modalType) {
          case ModalType.Site:
            modalObject.modalFields = sitesFields;
            modalObject.modalComponent = SiteModalComponent;
            modalObject.metaDataTypes = [MetaDataTypes.Areas, MetaDataTypes.Wards, MetaDataTypes.Teams,
            MetaDataTypes.SiteClients, MetaDataTypes.SiteCategories, MetaDataTypes.Periods, MetaDataTypes.Days];
            break;
          case ModalType.SiteQualityCheckRecord:
            modalObject.modalFields = siteQualityCheckRecordFields;
            modalObject.modalComponent = SiteQualityCheckRecordModalComponent;
            modalObject.metaDataTypes = [MetaDataTypes.Sites];
            break;
          case ModalType.SiteCategory:
            modalObject.modalFields = siteCategoryFields;
            break;
          case ModalType.SiteClient:
            modalObject.modalFields = siteClientFields;
            modalObject.metaDataTypes = [MetaDataTypes.AvailableClientSites, MetaDataTypes.People];
            break;
          case ModalType.Area:
            modalObject.modalFields = areaFields;
            break;
          case ModalType.Ward:
            modalObject.modalFields = wardFields;
            modalObject.metaDataTypes = [MetaDataTypes.Areas];
            break;
          case ModalType.Equipment:
            modalObject.modalFields = equipmentFields;
            modalObject.modalComponent = EquipmentModalComponent;
            modalObject.metaDataTypes = [MetaDataTypes.EquipmentCategories,
            MetaDataTypes.EquipmentTypes, MetaDataTypes.EquipmentStatus];
            break;
          case ModalType.EquipmentCheckRecord:
            modalObject.modalFields = equipmentChecksRecordFields;
            modalObject.modalComponent = EquipmentCheckRecordModalComponent;
            break;
          case ModalType.EquipmentMaintenanceRecord:
            modalObject.modalFields = equipmentMaintenanceRecordsFields;
            modalObject.modalComponent = EquipmentMaintenanceRecordModalComponent;
            break;
          case ModalType.EquipmentCategory:
            modalObject.modalFields = equipmentCategoriesFields;
            break;
          case ModalType.EquipmentType:
            modalObject.modalFields = equipmentTypesFields;
            modalObject.metaDataTypes = [MetaDataTypes.EquipmentCategories, MetaDataTypes.Courses];
            break;
          case ModalType.EquipmentChecklist:
            modalObject.modalFields = equipmentChecklistsFields;
            modalObject.metaDataTypes = [MetaDataTypes.EquipmentCheckItems, MetaDataTypes.AvailableEquipmentTypes];
            break;
          case ModalType.EquipmentChecklistItem:
            modalObject.modalFields = equipmentChecksFields;
            break;
          case ModalType.EquipmentServiceRequirement:
            modalObject.modalComponent = EquipmentServiceRequirementModalComponent;
            modalObject.modalFields = equipmentServiceRequirementFields;
            modalObject.metaDataTypes = [MetaDataTypes.EquipmentServiceTypes, MetaDataTypes.Equipment];
            modalObject.title = "Equipment Service Requirement";
            break;
          case ModalType.EquipmentServiceType:
            modalObject.modalFields = equipmentServiceTypeFields;
            modalObject.metaDataTypes = [MetaDataTypes.ExpiredServiceActions];
            break;
          case ModalType.Inspection:
            modalObject.modalComponent = InspectionModalComponent;
            modalObject.modalFields = inspectionFields;
            modalObject.metaDataTypes = [MetaDataTypes.InspectionCategories, MetaDataTypes.InspectionTypes,
            MetaDataTypes.Sites, MetaDataTypes.Equipment, MetaDataTypes.IssueTypes];
            break;
          case ModalType.NewInspection:
            modalObject.modalComponent = NewInspectionModalComponent;
            modalObject.metaDataTypes = [MetaDataTypes.Equipment, MetaDataTypes.Sites, MetaDataTypes.Risks];
            break;
          case ModalType.NewSiteQualityCheck:
            modalObject.modalComponent = NewSiteQualityCheckModalComponent;
            modalObject.metaDataTypes = [MetaDataTypes.Sites, MetaDataTypes.Programs];
            break;
          case ModalType.NewProgramCheck:
            modalObject.modalComponent = NewProgramCheckModalComponent;
            break;
          case ModalType.InspectionCategory:
            modalObject.modalFields = inspectionCategoryFields;
            break;
          case ModalType.InspectionType:
            modalObject.modalComponent = InspectionTypeModalComponent;
            modalObject.metaDataTypes = [MetaDataTypes.InspectionCategories, MetaDataTypes.Periods,
            MetaDataTypes.Days, MetaDataTypes.InspectionChecklists, MetaDataTypes.IssueTypes, MetaDataTypes.Teams];
            modalObject.modalFields = inspectionTypeFields;
            break;
          case ModalType.InspectionChecklist:
            modalObject.modalFields = inspectionChecklistFields;
            modalObject.metaDataTypes = [MetaDataTypes.InspectionCheckItems, MetaDataTypes.InspectionTypes];
            break;
          case ModalType.InspectionCheckItem:
            modalObject.modalFields = inspectionCheckItemFields;
            break;
          case ModalType.InspectionRecord:
            modalObject.modalComponent = InspectionRecordModalComponent;
            modalObject.modalFields = inspectionRecordFields;
            modalObject.metaDataTypes = [MetaDataTypes.InspectionStatuses];
            break;
          case ModalType.InspectionCheckItemRecord:
            modalObject.modalComponent = InspectionCheckRecordModalComponent;
            modalObject.modalFields = inspectionCheckRecordFields;
            modalObject.metaDataTypes = [MetaDataTypes.InspectionCheckItemResults];
            break;
          case ModalType.Issue:
            modalObject.modalComponent = IssueModalComponent;
            modalObject.modalFields = issueFields;
            modalObject.metaDataTypes = [MetaDataTypes.Sites, MetaDataTypes.IssueTypes, MetaDataTypes.SiteCategories,
            MetaDataTypes.IssueCategories, MetaDataTypes.Risks, MetaDataTypes.IssueEscalatedContacts];
            break;
          case ModalType.IssueEscalatedContact:
            modalObject.modalFields = issueEscalatedContactFields;
            break;
          case ModalType.Risk:
            modalObject.modalFields = riskFields;
            break;
          case ModalType.IssueCategory:
            modalObject.modalFields = issueCategoryFields;
            modalObject.metaDataTypes = [MetaDataTypes.SiteCategories];
            break;
          case ModalType.IssueContact:
            modalObject.title = "Issue Notification Contact";
            modalObject.modalFields = issueContactFields;
            modalObject.metaDataTypes = [MetaDataTypes.Areas, MetaDataTypes.Wards, MetaDataTypes.Risks, MetaDataTypes.SiteClients];
            modalObject.message = "If neither Area or Ward is selected then no notifications will be sent.";
            break;
          case ModalType.StockContact:
            modalObject.title = "Stock Notification Contact";
            modalObject.modalFields = stockContactFields;
            modalObject.metaDataTypes = [MetaDataTypes.StockCategories];
            break;
          case ModalType.EquipmentContact:
            modalObject.title = "Equipment Notification Contact";
            modalObject.modalFields = equipmentContactFields;
            modalObject.metaDataTypes = [MetaDataTypes.EquipmentCategories, MetaDataTypes.EquipmentStatus];
            break;
          case ModalType.IssueType:
            modalObject.modalFields = issueTypeFields;
            modalObject.metaDataTypes = [MetaDataTypes.IssueCategories, MetaDataTypes.Courses];
            break;
          case ModalType.People:
            modalObject.modalComponent = PersonModalComponent;
            modalObject.modalFields = personFields;
            modalObject.metaDataTypes = [MetaDataTypes.JobRoles, MetaDataTypes.MobileRoles, MetaDataTypes.Teams,
            MetaDataTypes.SiteClients, MetaDataTypes.WebRoles, MetaDataTypes.RoleClaims, MetaDataTypes.Contractors];
            break;
          case ModalType.Team:
            modalObject.modalFields = teamFields;
            modalObject.metaDataTypes = [MetaDataTypes.People, MetaDataTypes.Sites, MetaDataTypes.Programs,
            MetaDataTypes.InspectionTypes];
            break;
          case ModalType.Contractor:
            modalObject.modalFields = contractorFields;
            modalObject.metaDataTypes = [MetaDataTypes.NonContractorPrograms, MetaDataTypes.NonContractorPeople];
            break;
          case ModalType.JobRole:
            modalObject.modalFields = jobRoleFields;
            modalObject.metaDataTypes = [MetaDataTypes.Courses];
            modalObject.message = "Upon Save, any newly added Courses will be automatically applied " +
              "to all People of this Role";
            break;
          case ModalType.MobileJobRole:
            modalObject.modalFields = jobRoleFields;
            modalObject.metaDataTypes = [MetaDataTypes.Courses];
            modalObject.message = "Upon Save, any newly added Courses will be automatically applied " +
              "to all People of this Role";
            break;
          case ModalType.Course:
            modalObject.modalFields = courseFields;
            modalObject.metaDataTypes = [MetaDataTypes.CourseTypes];
            break;
          case ModalType.CourseType:
            modalObject.modalFields = courseTypeFields;
            break;
          case ModalType.TrainingRecord:
            modalObject.modalComponent = TrainingRecordModalComponent;
            modalObject.modalFields = trainingRecordFields;
            if (!modalObject.modalFields.find(f => f.name == "person")) {
              modalObject.modalFields.unshift({
                name: "person",
                label: "Person",
                metaDataType: "people",
                type: FieldType.Select
              });
            }
            modalObject.metaDataTypes = [MetaDataTypes.Courses, MetaDataTypes.People, MetaDataTypes.Trainers];
            break;
          case ModalType.TrainingRequirement:
            modalObject.modalFields = trainingRequirementFields;
            if (!modalObject.modalFields.find(f => f.name == "person")) {
              modalObject.modalFields.unshift({
                name: "person",
                label: "Person",
                metaDataType: "people",
                type: FieldType.Select
              });
            }
            modalObject.metaDataTypes = [MetaDataTypes.Courses, MetaDataTypes.People];
            break;
          case ModalType.PersonTrainingRecord:
            modalObject.modalComponent = TrainingRecordModalComponent;
            modalObject.controller = "trainingRecord";
            modalObject.modalFields = trainingRecordFields;
            modalObject.metaDataTypes = [MetaDataTypes.Courses, MetaDataTypes.People, MetaDataTypes.Trainers];
            break;
          case ModalType.PersonTrainingRequirement:
            modalObject.controller = "trainingRequirement";
            modalObject.modalFields = trainingRequirementFields;
            modalObject.metaDataTypes = [MetaDataTypes.Courses, MetaDataTypes.People];
            break;
          case ModalType.Program:
            modalObject.modalComponent = ProgramModalComponent;
            modalObject.modalFields = programsFields;
            modalObject.metaDataTypes = [MetaDataTypes.Teams, MetaDataTypes.Sites, MetaDataTypes.Contractors];
            break;
          case ModalType.ProgramSiteRecord:
            modalObject.modalComponent = ProgramSiteRecordModalComponent;
            modalObject.modalFields = programSiteRecordFields;
            modalObject.metaDataTypes = [MetaDataTypes.Programs, MetaDataTypes.SiteRecordStatus];
            break;
          case ModalType.ProgramSiteCheckRecord:
            modalObject.modalComponent = ProgramSiteCheckRecordModalComponent;
            modalObject.modalFields = programsSiteCheckRecordFields;
            break;
          case ModalType.Attendance:
            //TODO
            break;
          case ModalType.Attendee:
            modalObject.modalComponent = AttendanceModalComponent;
            modalObject.modalFields = AttendeeFields;
            modalObject.metaDataTypes = [MetaDataTypes.People, MetaDataTypes.Programs,
            MetaDataTypes.Sites, MetaDataTypes.TimeRecordTypes];
            break;
          case ModalType.AttendeeTimeRecord:
            modalObject.modalFields = AttendeeTimeRecordFields;
            modalObject.metaDataTypes = [MetaDataTypes.Sites, MetaDataTypes.TimeRecordTypes];
            break;
          case ModalType.TimeRecordType:
            modalObject.modalFields = [
              {
                name: "name",
                label: "Name",
                type: FieldType.String,
                required: true,
              },
              {
                name: "description",
                label: "Description",
                type: FieldType.String
              }
            ];
            break;
          case ModalType.Stock:
            modalObject.modalComponent = StockModalComponent;
            modalObject.modalFields = stockFields;
            modalObject.metaDataTypes = [MetaDataTypes.StockCategories, MetaDataTypes.StockTypes]
            break;
          case ModalType.StockRequest:
            modalObject.modalComponent = StockRequestModalComponent;
            modalObject.modalFields = stockRequestFields;
            modalObject.metaDataTypes = [MetaDataTypes.StockItemRequestStatus]
            break;
          case ModalType.StockItemRequest:
            modalObject.modalComponent = StockRequestModalComponent;
            modalObject.modalFields = stockRequestFields;
            modalObject.metaDataTypes = [MetaDataTypes.StockItemRequestStatus]
            break;
          case ModalType.StockType:
            modalObject.modalFields = stockTypeFields;
            modalObject.metaDataTypes = [MetaDataTypes.StockCategories];
            break;
          case ModalType.StockCategory:
            modalObject.modalFields = stockCategoryFields;
            break;
          case ModalType.Task:
            modalObject.modalComponent = TaskModalComponent;
            modalObject.modalFields = tasksFields;
            modalObject.metaDataTypes = [MetaDataTypes.TaskCategories, MetaDataTypes.TaskTypes,
            MetaDataTypes.Sites, MetaDataTypes.Programs, MetaDataTypes.TaskPriorities, MetaDataTypes.ReviewStatuses];
            break;
          case ModalType.TaskRecord:
            modalObject.modalComponent = TaskRecordModalComponent;
            modalObject.modalFields = taskRecordFields;
            modalObject.metaDataTypes = [MetaDataTypes.TaskRecordStatuses, MetaDataTypes.Programs];
            break;
          case ModalType.TaskCheckRecord:
            if (modalObject.payload && modalObject.payload.taskRecord) {
              try {
                const r = await this.remoteService.getBy("taskRecord", "id", modalObject.payload.taskRecord.id).toPromise();
                const taskRecordId = modalObject.payload.taskRecord.id;
                modalObject.payload = r;
                delete modalObject.payload.id;
                delete modalObject.payload.notes;
                modalObject.payload.taskRecordMobileCreated = r.mobileCreated;
                modalObject.payload.taskRecord = { id: taskRecordId };
              } catch (error) {
                console.error("Error while fetching data:", error);
                // Handle the error as required
              }
            }
            modalObject.modalComponent = TaskCheckRecordModalComponent;
            modalObject.modalFields = taskCheckRecordFields;
            modalObject.metaDataTypes = [MetaDataTypes.TaskCheckRecordStatuses];
            break;
          case ModalType.TaskQualityCheckRecord:
            modalObject.modalComponent = TaskQualityCheckRecordModalComponent;
            modalObject.modalFields = taskQualityCheckRecordFields;
            modalObject.metaDataTypes = [MetaDataTypes.Ratings];
            break;
          case ModalType.TaskCategory:
            modalObject.modalFields = taskCategoryFields;
            break;
          case ModalType.TaskType:
            modalObject.modalComponent = TaskTypeModalComponent;
            modalObject.modalFields = taskTypeFields;
            modalObject.metaDataTypes = [MetaDataTypes.TaskCategories, MetaDataTypes.Periods, MetaDataTypes.Days];
            break;
          case ModalType.TaskGroup:
            modalObject.modalFields = taskGroupFields;
            modalObject.metaDataTypes = [MetaDataTypes.TaskCategories, MetaDataTypes.TaskTypes, MetaDataTypes.Sites];
            modalObject.message = "Please use the Assign Task Group function to assign to new Sites," +
              " any changes to the Task Types will be applied to ALL assigned Sites";
            break;
          case ModalType.TaskGroupAssign:
            modalObject.title = "Assign Task Group";
            modalObject.modalFields = assignTaskGroupFields;
            modalObject.metaDataTypes = [MetaDataTypes.Sites, MetaDataTypes.TaskGroups, MetaDataTypes.Programs];
            break;
          case ModalType.AddTaskGroup:
            modalObject.modalFields = addTaskGroupFields;
            modalObject.title = "Add Task Group(s)";
            modalObject.metaDataTypes = [MetaDataTypes.TaskGroups, MetaDataTypes.Programs];
            break;
          case ModalType.WeatherCondition:
            modalObject.modalFields = weatherConditionFields;
            modalObject.metaDataTypes = [MetaDataTypes.TaskTypes];
            break;
          case ModalType.IssueStatus:
            modalObject.modalFields = [{
              name: "isClosedUpdate",
              label: "Is Issue Resolved?",
              type: FieldType.Bool,
            }]
            modalObject.title = "Update Issue Status";
            break;
          case ModalType.IssueRisk:
            modalObject.modalFields = [{
              name: "risk",
              label: "Risk",
              type: FieldType.Select,
              metaDataType: "risks"
            }]
            modalObject.title = "Update Risk";
            modalObject.metaDataTypes = [MetaDataTypes.Risks];
            break;
          case ModalType.AddInspection:
            modalObject.modalFields = [{
              name: "inspectionType",
              label: "Inspection Type",
              type: FieldType.Select,
              metaDataType: "inspectionTypes"
            }]
            modalObject.title = "Add Inspection";
            modalObject.metaDataTypes = [MetaDataTypes.Risks];
            break;
          case ModalType.EquipmentStatus:
            modalObject.modalFields = [{
              name: "equipmentStatus",
              label: "Equipment Status",
              type: FieldType.Select,
            }]
            modalObject.title = "Update Equipment Status";
            modalObject.metaDataTypes = [MetaDataTypes.EquipmentStatus];
            break;
          case ModalType.AddServiceRequirement:
            modalObject.modalFields = equipmentServiceRequirementFields;
            modalObject.metaDataTypes = [MetaDataTypes.EquipmentServiceTypes, MetaDataTypes.Equipment];
            modalObject.controller = "equipmentServiceRequirement";
            break;
          case ModalType.AddMultipleServiceRequirements:
            modalObject.modalFields = multipleEquipmentServiceRequirementFields;
            modalObject.metaDataTypes = [MetaDataTypes.EquipmentServiceTypes, MetaDataTypes.Equipment];
            modalObject.controller = "equipmentServiceRequirement";
            break;
          case ModalType.ActiveStateChange:
            modalObject.modalFields = [{
              name: "isActive",
              label: "Is Active",
              type: FieldType.Bool,
            }]
            modalObject.title = "Update Is Active State";
            break;
          case ModalType.ScheduledStateChange:
            modalObject.modalFields = [{
              name: "isScheduled",
              label: "Is Scheduled",
              type: FieldType.Bool,
            }]
            modalObject.title = "Update Is Scheduled State";
            break;

          case ModalType.TaskChargeable:
            modalObject.modalFields = [{
              name: "isChargeable",
              label: "Is the Task Chargeable",
              type: FieldType.Bool,
            }]
            modalObject.title = "Update Chargeable status";
            break;
          case ModalType.TaskAddToProgram:
            modalObject.modalFields = [{
              name: "program",
              label: "Program",
              type: FieldType.Select,
              metaDataType: "programs"
            }]
            modalObject.title = "Add Task To Program";
            modalObject.metaDataTypes = [MetaDataTypes.Programs];
            break;
          case ModalType.TaskTypeChange:
            modalObject.modalFields = [{
              name: "taskType",
              label: "Task Type",
              type: FieldType.Select,
              metaDataType: "taskTypes"
            }]
            modalObject.metaDataTypes = [MetaDataTypes.TaskTypes];
            modalObject.title = "Update Task Type(s)";
            break;
          case ModalType.TaskRecordInvoiced:
            modalObject.modalFields = [{
              name: "isInvoiced",
              label: "Has the Task Record been Invoiced",
              type: FieldType.Bool,
            }]
            modalObject.title = "Update Invoiced Status";
            break;
          case ModalType.TaskRecordStatus:
            modalObject.modalFields = [{
              name: "taskRecordStatus",
              label: "Task Record Status",
              type: FieldType.Select,
              metaDataType: "taskRecordStatuses"
            }]
            modalObject.metaDataTypes = [MetaDataTypes.TaskRecordStatuses];
            modalObject.title = "Update Task Record Status";
            break;
          case ModalType.UpdateSiteClient:
            modalObject.modalFields = [{
              name: "siteClient",
              label: "Site Client",
              type: FieldType.Select,
              metaDataType: "siteClients"
            }]
            modalObject.title = "Update Site Client";
            modalObject.metaDataTypes = [MetaDataTypes.SiteClients];
            break;
          case ModalType.AddToProgram:
            modalObject.modalFields = [{
              name: "program",
              label: "Program",
              type: FieldType.Select,
              metaDataType: "programs"
            }]
            modalObject.title = "Add To Program";
            modalObject.metaDataTypes = [MetaDataTypes.Programs];
            break;
          case ModalType.AddToTeam:
            modalObject.modalFields = [{
              name: "team",
              label: "Team",
              type: FieldType.Select,
              metaDataType: "teams"
            }]
            modalObject.title = "Add To Team";
            modalObject.metaDataTypes = [MetaDataTypes.Teams];
            break;
          case ModalType.AddToTeams:
            modalObject.modalFields = [{
              name: "teams",
              label: "Team",
              type: FieldType.MultipleSelect,
              metaDataType: "teams"
            }]
            modalObject.title = "Add To Team";
            modalObject.metaDataTypes = [MetaDataTypes.Teams];
            break;
          case ModalType.UpdateSiteWard:
            modalObject.modalFields = [{
              name: "ward",
              label: "Ward",
              type: FieldType.Select,
              metaDataType: "wards"
            }]
            modalObject.title = "Update Site Ward";
            modalObject.metaDataTypes = [MetaDataTypes.Wards];
            break;
          case ModalType.UpdateSiteCategory:
            modalObject.modalFields = [{
              name: "siteCategory",
              label: "Site Category",
              type: FieldType.Select,
              metaDataType: "siteCategories"
            }]
            modalObject.title = "Update Site Category";
            modalObject.metaDataTypes = [MetaDataTypes.SiteCategories];
            break;
          case ModalType.QuickNote:
            modalObject.modalFields = quickNoteFields;
            modalObject.metaDataTypes = [MetaDataTypes.QuickNoteTypes]
            break;
          case ModalType.Report:
            modalObject.modalFields = reportFields;
            break;
          case ModalType.PlotMap:
            modalObject.title = "Select Item to Plot";
            modalObject.modalFields = mapFields;
            modalObject.metaDataTypes = [MetaDataTypes.Sites, MetaDataTypes.Issues]
            break;
          case ModalType.Period:
            modalObject.title = "Schedule Period";
            modalObject.modalFields = periodFields;
            modalObject.message = "A Period is the defined date range within which schedules will be active";
            break;
          case ModalType.Database:
            modalObject.modalFields = databaseFields;
            modalObject.modalComponent = DatabaseModalComponent;
            modalObject.metaDataTypes = [MetaDataTypes.Accounts, MetaDataTypes.SectionClaims];
            break;
          case ModalType.Account:
            modalObject.modalFields = accountFields;
            break;
          case ModalType.User:
            modalObject.modalComponent = SupportUserModalComponent;
            modalObject.modalFields = userFields;
            modalObject.metaDataTypes = [MetaDataTypes.WebRoles, MetaDataTypes.Databases, MetaDataTypes.Accounts]
            break;
          case ModalType.SupportLink:
            modalObject.modalFields = supportLinkFields;
            modalObject.metaDataTypes = [MetaDataTypes.SupportTypes]
            break;
          case ModalType.News:
            modalObject.modalFields = newsFields;
            modalObject.modalComponent = NewsModalComponent;
            break;
          case ModalType.ProgramSiteRecordStatus:
            modalObject.modalFields = [{
              name: "siteRecordStatus",
              label: "Program Site Record Status",
              type: FieldType.Select,
            }]
            modalObject.title = "Update Program Site Record Status";
            modalObject.metaDataTypes = [MetaDataTypes.ProgramSiteRecordStatuses];
            break;
          case ModalType.CustomField:
            modalObject.modalFields = customFieldFields;
            modalObject.modalComponent = CustomFieldModalComponent;
            modalObject.metaDataTypes = [MetaDataTypes.CustomFieldEntityTypes, MetaDataTypes.CustomFieldDataTypes];
            break;
          case ModalType.Document:
            modalObject.modalFields = documentFields;
            modalObject.modalComponent = SharedDocumentModalComponent;
            modalObject.metaDataTypes = [MetaDataTypes.SiteCategories, MetaDataTypes.EquipmentCategories,
            MetaDataTypes.EquipmentTypes, MetaDataTypes.StockCategories, MetaDataTypes.StockTypes,
            MetaDataTypes.InspectionCategories, MetaDataTypes.InspectionTypes, MetaDataTypes.TaskCategories,
            MetaDataTypes.TaskTypes];
            break;
        }

        //If no component set then its the Edit Modal
        if (!modalObject.modalComponent) {
          modalObject.modalComponent = EditModalComponent;
        }

        this.modalService.openHubModal(modalObject, this.metaDataService);
      })
    }
  }

  blankGuid = "00000000-0000-0000-0000-000000000000";

  newGuid() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx"
      .replace(/[xy]/g, (c) => {
        const r = (Math.random() * 16) | 0,
          v = c === "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      });
  }

  camelize(str) {
    return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) => {
      if (+match === 0) return "";
      return index == 0 ? match.toLowerCase() : match.toUpperCase();
    });
  }

  toTitleCase(str: string) {
    return str.replace(
      /\w\S*/g,
      (txt: string) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    );
  }

  newIsoDate() {
    const date = new Date();
    if (date.toString().includes("(British Summer Time)")) {
      date.setHours(date.getHours() + 1);
    }
    return date.toISOString();
  }

  setBool(object) {
    if (!object) {
      return false;
    }
    return object;
  }

  getTheme() {
    if (localStorage.getItem("theme")) {
      return localStorage.getItem("theme").valueOf();
    }
    return "light-theme";
  }

  getViewFromStorage(gridKey) {
    let view = localStorage[`view-${gridKey}-grid`];
    if (view) {
      view = JSON.parse(view);
    }
    return view;
  }

  setViewToStorage(gridKey, view) {
    if (view) {
      view = JSON.stringify(view);
      localStorage[`view-${gridKey}-grid`] = view;
    } else {
      localStorage[`view-${gridKey}-grid`] = '';
    }
  }

  openSnackBar(
    message: string,
    action?: string,
    error?: boolean,
    dismissFn?: Function
  ) {
    this.snackBar
      .open(message, action, {
        duration: 4000,
        verticalPosition: "top",
        panelClass: [
          "alert",
          "alert-heading",
          "text-white",
          "text-center",
          "d-flex",
          "justify-content-center",
          error ? "bg-danger" : "bg-success"
        ]
      })
      .afterDismissed()
      .subscribe(() => (dismissFn ? dismissFn() : ""));
  }

}
