import {
  Component,
  EventEmitter,
  Inject,
  OnInit,
  Output
} from "@angular/core";
import {UntypedFormGroup} from "@angular/forms";
import {MatDialogRef, MAT_DIALOG_DATA} from "@angular/material/dialog";
import {BaseModalComponent} from "../../shared/base/base-modal.component";
import {Claims} from "../../auth/models/claims";


@Component({
  selector: "app-equipment-service-requirement-modal",
  templateUrl: "./equipment-service-requirement-modal.component.html"
})
export class EquipmentServiceRequirementModalComponent
  extends BaseModalComponent
  implements OnInit {
  @Output()
  onSave = new EventEmitter();
  form: UntypedFormGroup;
  serviceRequirement = <any>{};

  formFields = [
    {name: "equipmentServiceType", required: true},
    {name: "requiredDate", required: true},
    {name: "equipment"},
    {name: "requiredHours"},
    {name: "requiredMiles"}
  ];

  hasClaim = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<Component>
  ) {
    super();
    this.metaData = data.metaData;
  }

  ngOnInit(): void {
    this.serviceRequirement = this.data.data;
    this.createForm(this.formFields, this.serviceRequirement).then((form) => {
      this.form = form;

      //Check Claims
      !this.authService.hasClaim(Claims.equipmentEdit)
        ? this.form.disable()
        : this.hasClaim = true;

      // Need to check existing Selected Equipment Service Type.
      if (!this.isNew) {
        let match = this.metaData["equipmentServiceTypes"].find(f => f.id == this.serviceRequirement
          .equipmentServiceType.id);

        if (match) {
          this.enforceServiceType(match);
        }
      }

      this.form.controls["equipmentServiceType"].valueChanges.subscribe((v) => {
        if (v) {
          this.enforceServiceType(v);
        }
      })

    });
  }

  enforceServiceType(v) {
    if (v.validHours) {
      this.form.controls["requiredHours"].enable();
    } else {
      this.form.controls["requiredHours"].setValue(0);
      this.form.controls["requiredHours"].disable();
    }

    if (v.validMiles) {
      this.form.controls["requiredMiles"].enable();
    } else {
      this.form.controls["requiredMiles"].setValue(0);
      this.form.controls["requiredMiles"].disable();
    }
  }

  save() {
    this.serviceRequirement = {...this.serviceRequirement, ...this.form.getRawValue()};
    this.onSave.emit(this.serviceRequirement);
  }

  close(form) {
    this.closeModal(form, this.dialogRef);
  }
}
