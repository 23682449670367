import {FieldType, IEditFormField} from "../../shared/components/edit-form/edit-form-model";

export const quickNoteFields: IEditFormField[] = [
  {name: "note", label: "Quick Note", type: FieldType.String, required: true, validatorPattern: "^.{1,100}$"},
  {name: "type", label: "Quick Note Type", type: FieldType.Select, required: true, metaDataType: "quickNoteTypes"}
];

export const periodFields: IEditFormField[] = [
  {
    name: "name",
    label: "Schedule Period",
    type: FieldType.String,
    required: true
  },
  {name: "startDate", label: "Start Date", type: FieldType.Date, required: true},
  {name: "endDate", label: "End Date", type: FieldType.Date, required: true},
  {name: "autoRepeat", label: "Auto Repeats", type: FieldType.Bool, required: true}
]

export const customFieldFields: IEditFormField[] = [
  {name: "name", label: "Custom Field Name", type: FieldType.String, required: true},
  {name: "dataType", label: "Data Type", type: FieldType.Select, metaDataType: "customFieldDataTypes", required: true},
  {
    name: "associatedEntityType",
    label: "Entity Type",
    type: FieldType.Select,
    metaDataType: "customFieldEntityTypes",
    required: true
  },
  {name: "optionsEntry", label: "Options", type: FieldType.String},
  {name: "options", label: "Options", type: FieldType.MultipleSelect},
];

export const documentFields: IEditFormField[] = [
  {name: "name", label: "Custom Field Name", type: FieldType.String, required: true},
  {name: "url", label: "Url", type: FieldType.String},
  {name: "siteCategories", label: "Site Categories", type: FieldType.MultipleSelect, metaDataType: "siteCategories"},
  {name: "equipmentCategories", label: "Equipment Categories", type: FieldType.MultipleSelect, metaDataType: "equipmentCategories"},
  {name: "equipmentTypes", label: "Equipment Types", type: FieldType.MultipleSelect, metaDataType: "equipmentTypes"},
  {name: "stockCategories", label: "Stock Categories", type: FieldType.MultipleSelect, metaDataType: "stockCategories"},
  {name: "stockTypes", label: "Stock Types", type: FieldType.MultipleSelect, metaDataType: "stockTypes"},
  {name: "inspectionCategories", label: "Inspection Categories", type: FieldType.MultipleSelect, metaDataType: "inspectionCategories"},
  {name: "inspectionTypes", label: "Inspection Types", type: FieldType.MultipleSelect, metaDataType: "inspectionTypes"},
  {name: "taskCategories", label: "Task Categories", type: FieldType.MultipleSelect, metaDataType: "taskCategories"},
  {name: "taskTypes", label: "Task Types", type: FieldType.MultipleSelect, metaDataType: "taskTypes"},
]

