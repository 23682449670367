<content class="support-user-modal">
  <div
    *ngIf="supportUser.isArchived"
    class="statusBar text-center text-light bg-primary"
  >
    <span>You are currently viewing an Archived User</span>
  </div>
  <div class="d-flex m-0 position-relative h-100">
    <div class="p-3 primary">
      <div class="text-center">
        <i class="fal fa-users fa-3x"></i>
        <H5>User</H5>
      </div>
      <div class="py-5 modal-nav">
        <button
          *ngFor="let item of nav"
          mat-button
          class="btn py-2 d-block w-100"
          [ngClass]="{ 'border-p': current.page == item.page }"
          (click)="changeSection(item)"
          [disabled]="item.disabled"
          [hidden]="item.hidden"

        >
          {{ item.name }}
        </button>
      </div>
    </div>
    <div class="corner-close" (click)="close(form)">
      <i class="fal fa-times fa-2x"></i>
    </div>
    <form
      class="bg-modal px-4 d-flex w-100"
      [formGroup]="form"
      data-lpignore="true"
      autocomplete="off"
      *ngIf="loaded && form; else loading"
      (input)="check(form)"
    >
      <div class="d-flex flex-column justify-content-between w-100">
        <!-- #region Header -->
        <header class="d-flex justify-content-between primary pt-3">
          <div>
            <h2 class="m-0">
              {{ isNew ? "Add User" : supportUser.fullName }}
            </h2>
            {{ current.name }}
          </div>
        </header>
        <!-- #endregion -->
        <!-- #region Main -->
        <main class="flex-grow-1">
          <div class="h-100" [ngSwitch]="current.page">
            <!-- #region Details -->
            <div *ngSwitchCase="'details'">
              <div class="row">
                <div class="col-12 col-md-6">
                  <div class="card min-photo-height p-2 d-block">
                    <mat-form-field class="w-100">
                      <mat-label class="primary">First Name</mat-label>
                      <input matInput #firstName formControlName="firstName"/>
                      <mat-error
                        *ngIf="
                          form.get('firstName').hasError('required') &&
                          form.get('firstName').touched
                        "
                      >
                        First Name Required.
                      </mat-error>
                    </mat-form-field>
                    <mat-form-field class="w-100">
                      <mat-label class="primary">Last Name</mat-label>
                      <input matInput #lastName formControlName="lastName"/>
                      <mat-error
                        *ngIf="
                          form.get('lastName').hasError('required') &&
                          form.get('lastName').touched
                        "
                      >
                        Last Name Required.
                      </mat-error>
                    </mat-form-field>
                    <mat-form-field class="w-100">
                      <mat-label class="primary">Email (Web Username)</mat-label>
                      <input
                        matInput
                        #email
                        formControlName="email"
                        type="email"
                        email
                        id="newUserEmail"
                        autocomplete="off"
                      />
                      <mat-error
                        *ngIf="
                          form.get('email').invalid &&
                          !form.get('email').hasError('inUse')
                        "
                      >Email is invalid.
                      </mat-error
                      >
                      <mat-error *ngIf="form.get('email').hasError('inUse')"
                      >This Email is already in use, please choose
                        another.
                      </mat-error
                      >
                    </mat-form-field>
                    <mat-form-field class="w-100">
                      <mat-label class="primary">Account</mat-label>
                      <mat-select
                        formControlName="account"
                        [compareWith]="compareOverride"
                      >
                        <mat-option
                          *ngFor="
                          let account of metaData.accounts
                          | sort: 'name'
                          | archivedFilter : supportUser.account
                        "
                          [value]="account"
                        >
                          {{ account.name }}
                        </mat-option>
                      </mat-select>
                      <mat-error
                        *ngIf="
                        form.get('account').hasError('required') &&
                        form.get('account').touched
                      "
                      >
                        Account Required.
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>
                <div class="col-12 col-md-6">
                  <div class="card min-photo-height p-2 d-block">
                    <mat-form-field class="w-100">
                      <mat-label class="primary">New Web Password</mat-label>
                      <input
                        matInput
                        #newPassword
                        formControlName="newPassword"
                      />
                      <mat-error *ngIf="form.get('newPassword').invalid"
                      >Password must be at least 8 Characters containing at least 1 Upper Case, 1 Lower Case, 1 symbol
                        and 1 number.
                      </mat-error
                      >
                    </mat-form-field>
                    <mat-form-field class="w-100">
                      <mat-label class="primary">Mobile Username</mat-label>
                      <input matInput #shortUsername formControlName="shortUsername"/>
                    </mat-form-field>
                    <mat-form-field class="w-100">
                      <mat-label class="primary">Mobile PIN</mat-label>
                      <input
                        matInput
                        type="text" pattern="[0-9]*"
                        #newShortPassword
                        formControlName="newShortPassword"
                      />
                      <mat-error *ngIf="form.get('newShortPassword').invalid"
                      >Mobile PIN must be at least 4 numbers.
                      </mat-error
                      >
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
            <!-- #region History / AuditTrail -->
            <div *ngSwitchCase="'userDatabases'" class="h-100">
              <basic-edit-table
                [data]="supportUser.userDatabases"
                [metaData]="metaData"
                [canAdd]="true"
                [canDelete]="true"
                [columns]="userDatabaseColumns"
                [dataChanged]="updateTable"
                [canArchive]="false"
                (onSave)="updateDatabase($event)"
                (onDelete)="removeDatabase($event)"
              ></basic-edit-table>
            </div>
            <!-- #region History / AuditTrail -->
            <div *ngSwitchCase="'auditTrail'" class="h-100">
              <app-audit-trail
                id="auditTrail"
                [auditTrail]="supportUser.auditTrail"
              ></app-audit-trail>
            </div>
            <!-- #endregion -->
          </div>
        </main>
        <!-- #endregion -->
        <!-- #region Footer -->
        <footer
          class="flex justify-content-end my-3"
          [ngClass]="supportUser.isArchived ? 'pb-4' : ''"
        >
          <div>
            <button
              mat-flat-button
              name="detailSaveButton"
              class="ms-2 px-4"
              [disabled]="form.invalid || !form.dirty"
              (click)="save()"
            >
              Save
            </button>
            <button mat-flat-button class="ms-2 px-4" (click)="close(form)">
              Close
            </button>
          </div>
        </footer>
        <!-- #endregion -->
      </div>
    </form>
    <ng-template #loading>
      <app-loading
        class="w-100 d-flex align-items-center justify-content-center bg-modal"
      ></app-loading>
    </ng-template>
  </div>
</content>
