import {
  AfterViewInit,
  Component,
  EventEmitter,
  Inject,
  OnInit,
  Output
} from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Claims } from "src/app/auth/models/claims";
import { BaseModalComponent } from "src/app/shared/base/base-modal.component";
import {FieldType} from "../../shared/components/edit-form/edit-form-model";

@Component({
  selector: "app-equipment-maintenance-record-modal",
  templateUrl: "./equipment-maintenance-record-modal.component.html"
})
export class EquipmentMaintenanceRecordModalComponent
  extends BaseModalComponent
  implements OnInit, AfterViewInit
{
  @Output()
  onSave = new EventEmitter();
  form: UntypedFormGroup;
  formFields = [
    { name: "equipmentName", required: true },
    { name: "tempDate", required: true },
    { name: "createdByName", required: true },
    { name: "equipmentServiceTypeName" },
    { name: "equipmentStatusName" },
    { name: "recordTypeName" },
    { name: "cost" },
    { name: "havReading" },
    { name: "note" }
  ];

  allImages = [];
  allNames = ["Equipment Maintenance Record Photos"];
  nav = [
    { name: "Maintenance Record Details", page: "details" },
    { name: "Photos", page: "photos" },
    { name: "Map", page: "map" },
    { name: "History", page: "auditTrail", disabled: false, hidden: this.isNew }
  ];
  current = this.nav[0];
  entity = <any>{};
  hasClaim = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<Component>
  ) {
    super();
  }

  ngOnInit(): void {
    this.entity = this.data.data;
    this.entity.tempDate = this.entity.created;
    this.createForm(this.formFields, this.entity).then((form) => {
      this.form = form;
      this.form.controls["cost"].setValue( this.form.controls["cost"].value ?

          this.form.controls["cost"].value.toFixed(2) : 0,
            {emitEvent: false}

      );
      this.form.controls["cost"].valueChanges.subscribe(
        (v) => (v = parseFloat(v).toFixed(2))
      );
      this.form.disable();
      this.form.controls.note.enable();
      this.form.controls.cost.enable();
      //Check Claims
      !this.authService.hasClaim(Claims.equipmentEdit)
        ? this.form.disable()
        : this.hasClaim = true;
    });
  }

  ngAfterViewInit() {
    this.entity.parsedLatitude =
      this.entity.latitude !== null ? parseFloat(this.entity.latitude) : null;
    this.entity.parsedLongitude =
      this.entity.longitude !== null ? parseFloat(this.entity.longitude) : null;
  }

  changeSection(item) {
    if (item.page === "auditTrail") {
      let parameter = item.page !== "auditTrail" ? "id" : null;
      if (!this.entity[item.page]) {
        this.loaded = false;
        this.remoteService
          .getBy(
            `equipmentMaintenanceRecord/${item.page}`,
            parameter,
            this.data.data.id
          )
          .subscribe(
            (res) => {
              this.entity[item.page] = res;
              this.loaded = true;
            },
            () => {
              this.loaded = true;
            }
          );
      }
    }
    this.current = item;
  }

  trackImage($event) {
    this.entity.images.push({
      id: $event
    });
  }

  checkImages($event) {
    if (!this.entity.images) {
      this.entity.images = [];
    }
    this.entity.images.push($event);
    this.form.markAsDirty();
  }

  save() {
    this.entity = { ...this.entity, ...this.form.getRawValue() };
    this.onSave.emit(this.entity);
  }

  close(form) {
    this.closeModal(form, this.dialogRef);
  }
}
