<div *ngIf="groups" class="border-p context-menu"  [ngStyle]="{'left.px': x, 'top.px': y}" (mouseleave)="onClose.emit()">
  <div *ngFor="let g of groups" class="group">
    <small
      class="item px-3"
      [ngClass]="{ disabled: c.disabled }"
      *ngFor="let c of g"
      (click)="click(c)"
    >
      {{ c.title }}
    </small>
  </div>
</div>
<!--<ng-template #noGroup class="item" [ngClass]="{'disabled' : c.disabled}" *ngFor="let c of parsedOptions" (click)="click(c)">
    {{c.title}}
  </ng-template>-->
