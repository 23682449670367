import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { GridComponent } from "../grid/grid.component";
import { SharedModule } from "./shared.module";

// IGX
import {
    IgxGridModule,
    IgxForOfModule,
    IgxInputGroupModule,
    IgxTextHighlightModule, IgxSelectComponent, IgxIconComponent, IgxSelectItemComponent, IgxFocusDirective
} from "igniteui-angular";
//CUSTOM
import { CustomFilterComponent } from "../grid/grid-filters/custom-filter/custom-filter.component";
import { NullableBoolFilterComponent } from "../grid/grid-filters/bool-filter/nullable-bool-filter.component";
import { StandardBoolFilterComponent } from "../grid/grid-filters/bool-filter/standard-bool-filter.component";
import { BaseBoolFilterComponent } from "../grid/grid-filters/bool-filter/base-bool-filter.component";
import { DateFilterComponent } from "../grid/grid-filters/date-filter/date-filter.component";
import { ContextmenuComponent } from "../grid/context-menu/context-menu.component";
import { GridStateDirective } from "../grid/grid-state/grid-state.directive";

const customIgx = [
  CustomFilterComponent,
  GridComponent,
  BaseBoolFilterComponent,
  NullableBoolFilterComponent,
  StandardBoolFilterComponent,
  DateFilterComponent
];
@NgModule({
    imports: [
        CommonModule,
        IgxGridModule,
        IgxForOfModule,
        IgxInputGroupModule,
        IgxTextHighlightModule,
        SharedModule,
        IgxSelectComponent,
        IgxIconComponent,
        IgxSelectItemComponent,
        IgxFocusDirective
    ],
  declarations: [customIgx, GridStateDirective],
  exports: [
    CommonModule,
    IgxGridModule,
    IgxForOfModule,
    IgxInputGroupModule,
    GridStateDirective,
    IgxTextHighlightModule,
    customIgx,
    SharedModule
  ]
})
export class IgxModule {}
