<content class="database-modal">
  <div
    *ngIf="database.isArchived"
    class="statusBar text-center text-light bg-primary"
  >
    <span>You are currently viewing an Archived Database</span>
  </div>
  <div class="d-flex m-0 position-relative h-100">
    <div class="p-3 primary">
      <div class="text-center">
        <i class="fal fa-database fa-3x"></i>
        <H5>Database</H5>
      </div>
      <div class="py-5 modal-nav">
        <button
          *ngFor="let item of nav"
          mat-button
          class="btn py-2 d-block w-100"
          [ngClass]="{ 'border-p': current.page == item.page }"
          (click)="changeSection(item)"
          [disabled]="item.disabled"
          [hidden]="item.hidden"

        >
          {{ item.name }}
        </button>
      </div>
    </div>
    <div class="corner-close" (click)="close(form)">
      <i class="fal fa-times fa-2x"></i>
    </div>
    <form
      class="bg-modal px-4 d-flex w-100"
      [formGroup]="form"
      data-lpignore="true"
      autocomplete="off"
      *ngIf="loaded && form; else loading"
      (input)="check(form)"
    >
      <div class="d-flex flex-column justify-content-between w-100">
        <!-- #region Header -->
        <header class="d-flex justify-content-between primary pt-3">
          <div>
            <h2 class="m-0">
              {{ isNew ? "Add Database" : database.name }}
            </h2>
            {{ current.name }}
          </div>
        </header>
        <!-- #endregion -->
        <!-- #region Main -->
        <main class="flex-grow-1">
          <div class="h-100" [ngSwitch]="current.page">
            <!-- #region Details -->
            <div *ngSwitchCase="'details'">
              <div class="row">
                <div class="col-12 col-md-6">
                  <div class="card min-photo-height p-2 d-block">
                    <mat-form-field class="w-100">
                      <mat-label class="primary">Name</mat-label>
                      <input matInput #name formControlName="name"/>
                      <mat-error
                        *ngIf="
                          form.get('name').hasError('required') &&
                          form.get('name').touched
                        "
                      >
                        Name Required.
                      </mat-error>
                    </mat-form-field>
                    <mat-form-field class="w-100">
                      <mat-label class="primary">Account</mat-label>
                      <mat-select
                        formControlName="account"
                        [compareWith]="compareOverride"
                      >
                        <mat-option
                          *ngFor="
                          let account of metaData.accounts
                          | sort: 'name'
                          | archivedFilter : database.account
                        "
                          [value]="account"
                        >
                          {{ account.name }}
                        </mat-option>
                      </mat-select>
                      <mat-error
                        *ngIf="
                        form.get('account').hasError('required') &&
                        form.get('account').touched
                      "
                      >
                        Account Required.
                      </mat-error>
                    </mat-form-field>
                    <mat-form-field class="w-100">
                      <mat-label class="primary">Issue Number</mat-label>
                      <input matInput type="number" #issueNumber formControlName="issueNumber"/>
                      <mat-error
                        *ngIf="
                          form.get('issueNumber').hasError('required') &&
                          form.get('issueNumber').touched
                        "
                      >
                        Issue Number Required.
                      </mat-error>
                    </mat-form-field>
                    <mat-form-field class="w-100">
                      <mat-label class="primary">Stock Request Number</mat-label>
                      <input matInput type="number" #stockRequestNumber formControlName="stockRequestNumber"/>
                      <mat-error
                        *ngIf="
                          form.get('stockRequestNumber').hasError('required') &&
                          form.get('stockRequestNumber').touched
                        "
                      >
                        Stock Request Number Required.
                      </mat-error>
                    </mat-form-field>
                    <mat-form-field class="w-100">
                      <mat-label class="primary">Auto Archive</mat-label>
                      <mat-select
                        formControlName="autoArchiveEnabled"
                      >
                        <mat-option
                          *ngFor="
                          let bool of bools
                        "
                          [value]="bool.id"
                        >
                          {{ bool.name }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
                <div class="col-12 col-md-6">
                  <div class="card min-photo-height p-2 d-block">
                    <mat-form-field class="w-100">
                      <mat-label class="primary">Mobile Users Limit</mat-label>
                      <input matInput type="number" #peopleLimit formControlName="peopleLimit"/>
                      <mat-error
                        *ngIf="
                          form.get('peopleLimit').hasError('required') &&
                          form.get('peopleLimit').touched
                        "
                      >
                        Mobile Users Limit Required.
                      </mat-error>
                    </mat-form-field>
                    <mat-form-field class="w-100">
                      <mat-label class="primary">Zoho Users Limit</mat-label>
                      <input matInput type="number" #zohoLimit formControlName="zohoLimit"/>
                      <mat-error
                        *ngIf="
                          form.get('zohoLimit').hasError('required') &&
                          form.get('zohoLimit').touched
                        "
                      >
                       Zoho Users Limit Required.
                      </mat-error>
                    </mat-form-field>
                   <mat-form-field class="w-100">
                      <mat-label class="primary">Zoho Database Name</mat-label>
                      <input matInput #zohoDatabaseName formControlName="zohoDatabaseName"/>
                    </mat-form-field>
                    <mat-form-field class="w-100">
                      <mat-label class="primary">Zoho Database ID</mat-label>
                      <input matInput #zohoDatabaseId formControlName="zohoDatabaseId"/>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
            <!-- #endregion -->
            <div *ngSwitchCase="'claims'" class="h-100">
              <div
                label="Section Access"
              >
                <div class="card">
                  <div class="flex">
                    <h5 class="col-sm-3 text-center m-0">Section</h5>
                    <h5 class="col-sm-9 text-center m-0">Access</h5>
                  </div>
                  <div class="flex">
                    <span class="col-sm-3 text-center"> </span>
                    <div class="col-sm-9 flex text-center level-text">

                        <span class="col-sm-6"> Off </span>
                        <span class="col-sm-6"> Enabled </span>

                    </div>
                  </div>
                  <mat-divider></mat-divider>
                  <div
                    class="flex"
                    *ngFor="
                  let claim of metaData.sectionClaims
                "
                  >
                <span class="col-sm-3 text-center value-text">
                  {{ claim.name }}
                </span>
                    <mat-divider [vertical]="true"></mat-divider>
                    <div class="col-sm-9 text-center">
                      <mat-slider
                        class="w-75"
                        [max]="1"
                        [min]="0"
                        [step]="1"
                        showTickMarks
                        [displayWith]="formatLabel"
                       #ngSlider><input matSliderThumb
                                        [formControlName]="'section-claim-' + claim.name"
                                        (input)="onSliderChange({source: ngSliderThumb, parent: ngSlider, value: ngSliderThumb.value}, claim.valueType)"
                                        #ngSliderThumb="matSliderThumb" />
                      </mat-slider>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- #region History / AuditTrail -->
            <div *ngSwitchCase="'auditTrail'" class="h-100">
              <app-audit-trail
                id="auditTrail"
                [auditTrail]="database.auditTrail"
              ></app-audit-trail>
            </div>
            <!-- #endregion -->
          </div>
        </main>
        <!-- #endregion -->
        <!-- #region Footer -->
        <footer
          class="flex justify-content-end my-3"
          [ngClass]="database.isArchived ? 'pb-4' : ''"
        >
          <div>
            <button
              mat-flat-button
              name="detailSaveButton"
              class="ms-2 px-4"
              [disabled]="form.invalid || !form.dirty"
              (click)="save()"
            >
              Save
            </button>
            <button mat-flat-button class="ms-2 px-4" (click)="close(form)">
              Close
            </button>
          </div>
        </footer>
        <!-- #endregion -->
      </div>
    </form>
    <ng-template #loading>
      <app-loading
        class="w-100 d-flex align-items-center justify-content-center bg-modal"
      ></app-loading>
    </ng-template>
  </div>
</content>
