<content class="issues-modal">
  <div *ngIf="issue.isArchived" class="statusBar text-center text-light bg-primary">
    <span>You are currently viewing an Archived Issue</span>
  </div>
  <div class="d-flex m-0 position-relative h-100">
    <div class="p-3 primary">
      <div class="text-center">
        <i class="fal fa-thumbtack fa-3x"></i>
        <H5>Issue</H5>
      </div>
      <div class="py-5 modal-nav">
        <button *ngFor="let item of nav" mat-button class="btn py-2 d-block w-100"
          [ngClass]="{ 'border-p': current.page == item.page }" (click)="changeSection(item)" [disabled]="item.disabled"
          [hidden]="item.hidden">
          {{ item.name }}
        </button>
      </div>
    </div>
    <div class="corner-close" (click)="close(form)">
      <i class="fal fa-times fa-2x"></i>
    </div>
    <form class="bg-modal px-4 d-flex w-100" [formGroup]="form" data-lpignore="true" autocomplete="off"
      *ngIf="loaded && form; else loading" (input)="check(form)">
      <div class="d-flex flex-column justify-content-between w-100">
        <!-- #region Header -->
        <header class="d-flex primary pt-3">
          <div>
            <h2 class="m-0">
              {{ isNew ? "Add Issue" : "Issue " + issue.issueNumber }}
            </h2>
            {{ current.name }}
          </div>
        </header>
        <!-- #endregion -->
        <!-- #region Main -->
        <main class="h-100" [ngSwitch]="current.page">
          <!-- #region Details -->
          <div *ngSwitchCase="'details'">
            <div class="row">
              <div class="col-12 col-md-6">
                <div class="card p-2 d-block">
                  <mat-form-field class="w-100">
                    <mat-label class="primary">Issue Number</mat-label>
                    <input matInput #issueNumber formControlName="issueNumber" />
                  </mat-form-field>
                  <mat-form-field class="w-100">
                    <mat-label class="primary">Issue Category</mat-label>
                    <mat-select formControlName="issueCategory" [compareWith]="compareOverride">
                      <mat-option *ngFor="
                          let category of filteredIssueCategories
                            | sort: 'name'
                            | archivedFilter: issue.issueCategory
                        " [value]="category">
                        {{ category.name }}
                      </mat-option>
                    </mat-select>
                    <mat-error *ngIf="
                        form.get('issueCategory').hasError('required') &&
                        form.get('issueCategory').touched
                      ">
                      Issue Category Required.
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field class="w-100">
                    <mat-label class="primary">Issue Type</mat-label>
                    <mat-select formControlName="issueType" [compareWith]="compareOverride">
                      <mat-option *ngFor="
                          let type of metaData.issueTypes
                            | sort: 'name'
                            | typeFilter: form.controls['issueCategory'].value
                            | archivedFilter: issue.issueType
                        " [value]="type">
                        {{ type.name }}
                      </mat-option>
                    </mat-select>
                    <mat-error *ngIf="
                        form.get('issueType').hasError('required') &&
                        form.get('issueType').touched
                      ">
                      <span>Issue Type Required.</span>
                    </mat-error>
                  </mat-form-field>

                  <div *ngIf="filterSiteCategories">
                    <mat-form-field class="w-100">
                      <mat-label class="primary">Site Category</mat-label>
                      <mat-select formControlName="siteCategory" [compareWith]="compareOverride">
                        <mat-option *ngIf="!hideNASite" [value]="null"></mat-option>
                        <mat-option *ngFor="let siteCategory of filteredSiteCategories
                        | sort: 'name'
                         | archivedFilter: issue.siteCategory" [value]="siteCategory">
                          {{ siteCategory.name }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <mat-form-field class="w-100">
                      <mat-label class="primary">Site</mat-label>
                      <mat-select formControlName="site" [compareWith]="compareOverride">
                        <mat-option *ngIf="!hideNASite" [value]="null"></mat-option>
                        <mat-option *ngFor="let site of filteredSites
                        | sort: 'name'
                        | typeFilter: form.controls['siteCategory'].value
                         | archivedFilter: issue.site" [value]="site">
                          {{ site.name }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <mat-form-field *ngIf="!filterSiteCategories" class="w-100">
                    <mat-label class="primary">Site</mat-label>
                    <mat-select formControlName="site" [compareWith]="compareOverride">
                      <mat-option *ngIf="!hideNASite" [value]="null"></mat-option>
                      <mat-option *ngFor="let site of filteredSites
                        | sort: 'name'
                         | archivedFilter: issue.site" [value]="site">
                        {{ site.name }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <mat-form-field class="w-100">
                    <mat-label class="primary"> Description</mat-label>
                    <textarea matInput class="h-100" rows="{{filterSiteCategories ? 8 : 12}}"
                      formControlName="description"></textarea>
                    <mat-error *ngIf="
                        form.get('description').hasError('required') &&
                        form.get('description').touched
                      ">
                      Description Required.
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="col-12 col-md-6 d-flex flex-column">
                <div class="card d-block flex-grow-1 max-photo-height overflow-hidden text-center">
                  <app-image-carousel [images]="issue.images"
                    (goToPhotos)="current = { name: 'Photos', page: 'photos' }"></app-image-carousel>
                </div>
                <div class="card mt-3 p-2 d-block">
                  <mat-form-field class="w-50">
                    <mat-label class="primary"> Latitude</mat-label>
                    <input matInput type="number" formControlName="latitude" />
                    <mat-error *ngIf="form.get('latitude').invalid">
                      [-90 to 90] and 15 decimals max.
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field class="w-50">
                    <mat-label class="primary"> Longitude</mat-label>
                    <input matInput type="number" formControlName="longitude" />
                    <mat-error *ngIf="form.get('longitude').invalid">
                      [-180 to 180] and 15 decimals max.
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field class="w-50">
                    <mat-label class="primary"> Risk</mat-label>
                    <mat-select formControlName="risk" [compareWith]="compareOverride">
                      <mat-option *ngFor="let risk of metaData.risks
                        | sort: 'order'
                        | archivedFilter: issue.risk
                         " [value]="risk">
                        {{ risk.name }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <mat-form-field class="w-50">
                    <mat-label class="primary"> Escalated</mat-label>
                    <mat-select formControlName="escalated">
                      <mat-option *ngFor="let bool of bools" [value]="bool.id">
                        {{ bool.name }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <mat-form-field class="w-50">
                    <mat-label class="primary"> Issue Status</mat-label>
                    <mat-select formControlName="isClosed">
                      <mat-option [value]="true"> Resolved</mat-option>
                      <mat-option [value]="false"> Open</mat-option>
                    </mat-select>
                    <mat-error *ngIf="
                        form.get('isClosed').hasError('required') &&
                        form.get('isClosed').touched
                      ">
                      Issue Status Required.
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field class="w-50">
                    <mat-label class="primary">Resolved Date</mat-label>
                    <input matInput [matDatepicker]="pickerResolvedDate" formControlName="closedDate" />
                    <mat-datepicker-toggle matSuffix [for]="pickerResolvedDate"></mat-datepicker-toggle>
                    <mat-datepicker #pickerResolvedDate></mat-datepicker>
                  </mat-form-field>
                  <div *ngIf="form.controls['escalated'].value">
                    <mat-form-field class="w-50">
                      <mat-label class="primary"> Escalated To</mat-label>

                      <mat-select formControlName="issueEscalatedContact" [compareWith]="compareOverride">
                        <mat-option *ngFor="
                          let type of metaData.issueEscalatedContacts
                            | sort: 'name'
                            | archivedFilter: issue.issueEscalatedContact
                        " [value]="type">
                          {{ type.name }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <mat-form-field class="w-50 issueEscalatedReference">
                      <mat-label class="primary">Escalated Reference</mat-label>
                      <input matInput #escalatedReference formControlName="escalatedReference" />
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- #endregion -->
          <div *ngSwitchCase="'customFields'">
            <app-custom-fields [parentForm]="form" [fields]="customFields" [entity]="issue"></app-custom-fields>
          </div>
          <!-- #region Inspection Check Record -->
          <div *ngSwitchCase="'inspectionCheckItemRecord'">
            <div class="row">
              <div class="col-12 col-md-6">
                <div class="card min-photo-height p-2 d-block">
                  <mat-form-field class="w-100">
                    <mat-label class="primary">Inspection</mat-label>
                    <input matInput formControlName="inspectionName" />
                  </mat-form-field>
                  <mat-form-field class="w-50">
                    <mat-label class="primary">Site</mat-label>
                    <input matInput formControlName="siteName" />
                  </mat-form-field>
                  <mat-form-field class="w-50">
                    <mat-label class="primary">Equipment</mat-label>
                    <input matInput formControlName="equipmentName" />
                  </mat-form-field>
                  <mat-form-field class="w-50">
                    <mat-label class="primary">Inspected Date</mat-label>
                    <input matInput [matDatepicker]="inspectionCheckItemRecordCreatedDate"
                      formControlName="inspectionCheckItemRecordCreatedDate" />
                    <mat-datepicker-toggle matSuffix
                      [for]="inspectionCheckItemRecordCreatedDate"></mat-datepicker-toggle>
                    <mat-datepicker #inspectionCheckItemRecordCreatedDate></mat-datepicker>
                  </mat-form-field>
                  <mat-form-field class="w-100">
                    <mat-label class="primary">Recorded By</mat-label>
                    <input matInput formControlName="inspectionCheckItemRecordCreatedByName" />
                  </mat-form-field>
                  <mat-form-field class="w-100">
                    <mat-label class="primary">Inspection Check Result
                    </mat-label>
                    <input matInput formControlName="inspectionCheckItemResultName" />
                  </mat-form-field>
                </div>
              </div>
              <div class="col-12 col-md-6 d-flex flex-column">
                <div class="
                      card
                      min-photo-height
                      d-block
                      flex-grow-1
                      text-center
                    ">
                  <app-image-carousel [images]="issue.inspectionCheckItemRecordImages"
                    [showButton]="false"></app-image-carousel>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 mt-4 col-md-12">
                <div class="card p-2 d-block">
                  <mat-form-field class="w-100">
                    <mat-label class="primary">Notes</mat-label>
                    <textarea matInput class="h-100" rows="7"
                      formControlName="inspectionCheckItemRecordNotes"></textarea>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
          <!-- #endregion -->
          <!-- #region Tasks -->
          <div *ngSwitchCase="'tasks'" class="h-100">
            <app-grid #taskGridComponent [gridData]="issue.tasks" (onDoubleClick)="doubleClick($event)"
              [columns]="issueTaskColumns" [small]="true" [selection]="'none'" [groupable]="false"></app-grid>
          </div>
          <!-- #endregion -->
          <!-- #region Photos -->
          <div *ngSwitchCase="'photos'" class="h-100">
            <div class="row h-100">
              <app-photos *ngIf="isNew" [imageArrays]="[issue.images || []]" class="col-12 pb-1 h-100"
                [arrayNames]="allNames" [isNew]="true" [addPhotos]="hasClaim" (addedImage)="checkImages($event)"
                (removedImage)="form.markAsDirty()">
              </app-photos>
              <app-photos *ngIf="!isNew" [addPhotos]="hasClaim" class="col-12 pb-1 h-100" [imageArrays]="allImages"
                [arrayNames]="allNames" [entity]="{ type: 'issue', id: issue.id }" (addedImage)="checkImages($event)"
                (removedImage)="form.markAsDirty()">
              </app-photos>
            </div>
          </div>
          <!-- #endregion -->
          <!-- #region Notes -->
          <div *ngSwitchCase="'notes'" class="h-100">
            <div class="card p-2">
              <mat-form-field class="w-100">
                <mat-label class="primary">Issue Notes</mat-label>
                <textarea matInput class="h-100" rows="10" formControlName="note"></textarea>
              </mat-form-field>
            </div>
            <div class="card p-2 mt-2">
              <div class="col-sm-12 text-center">
                <div class="row m-0">
                  <div class="w-50 row m-0">
                    <mat-form-field class="w-50">
                      <mat-label>Latitude</mat-label>
                      <input matInput formControlName="latitude" />
                      <mat-error *ngIf="
                                  form.get('latitude').status == 'INVALID' &&
                                  form.get('latitude').touched
                                ">
                        [-90 to 90] and 15 decimals max.
                      </mat-error>
                    </mat-form-field>
                    <mat-form-field class="w-50">
                      <mat-label>Latitude</mat-label>
                      <input matInput formControlName="longitude" />
                      <mat-error *ngIf="
                                  form.get('longitude').status == 'INVALID' &&
                                  form.get('longitude').touched
                                ">
                        [-180 to 180] and 15 decimals max.
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="w-50 row m-0">
                    <mat-form-field class="w-50">
                      <mat-label>What3Words</mat-label>
                      <input matInput formControlName="what3Words" />
                    </mat-form-field>
                    <div class="w-50" *hasClaim="'map.edit'">
                      <button class="plot-button border ms-3" *ngIf="issue.id && !form.dirty" mat-flat-button
                        (click)="navigateToMap()">
                        Plot Issue on Map
                      </button>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 p-0" *ngIf="
                          issue.parsedLatitude && issue.parsedLongitude
                          else explain
                        ">
                  <div class="map-container">
                    <app-map class="h-100" [latitude]="
                              issue.parsedLatitude
                                ? issue.parsedLatitude
                                : form.controls['latitude'].value
                            " [longitude]="
                              issue.parsedLongitude
                                ? issue.parsedLongitude
                                : form.controls['longitude'].value
                            " [isStatic]="true" [zoom]="15" [id]="issue.id">
                    </app-map>
                  </div>
                </div>
                <ng-template #explain>
                  <span class="explain">
                    Add a valid Latitude & Longitude to view the Map.
                  </span>
                </ng-template>
              </div>
            </div>
          </div>
          <!-- #region Links and Files -->
          <div *ngSwitchCase="'linksAndFiles'" class="h-100">
            <app-documents class="documents" [isNew]="isNew" [canAdd]="hasClaim && !issue.isNew"
              [documents]="issue.documents || []" [issueId]="issue.id" (addedDocument)="checkDocuments($event)"
              [form]="form">
            </app-documents>
          </div>
          <!-- #endregion -->
          <!-- #region History / AuditTrail -->
          <div *ngSwitchCase="'auditTrail'">
            <app-audit-trail id="auditTrail" [auditTrail]="issue.auditTrail"></app-audit-trail>
          </div>
          <!-- #endregion -->
        </main>
        <!-- #endregion -->

        <!-- #region Footer -->
        <footer class="flex justify-content-end my-3" [ngClass]="issue.isArchived ? 'pb-4' : ''">
          <div>
            <button mat-flat-button name="detailTaskButton" class="ms-2 px-4"
              [disabled]="form.invalid || !hasClaim || issue.isClosed" (click)="addTask()">
              Add Task
            </button>
            <button mat-flat-button name="detailSaveButton" class="ms-2 px-4"
              [disabled]="form.invalid || !form.dirty || !hasClaim || saving" (click)="save()">
              <span *ngIf="saving">
                <i class="fad fa-spinner-third fa-spin">
                </i>
              </span>
              <span *ngIf="!saving">Save</span>
            </button>
            <button mat-flat-button class="ms-2 px-4" (click)="close(form)">
              Close
            </button>
          </div>
        </footer>
        <!-- #endregion -->
      </div>
    </form>
    <ng-template #loading>
      <app-loading class="w-100 d-flex align-items-center justify-content-center bg-modal"></app-loading>
    </ng-template>
  </div>
</content>
