import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {BaseModalComponent} from "../../shared/base/base-modal.component";

@Component({
  selector: 'app-new-program-check-modal',
  templateUrl: './new-program-check-modal.component.html',
  styleUrls: ['./new-program-check-modal.component.css']
})
export class NewProgramCheckModalComponent extends BaseModalComponent {

  title = 'Record Program Site Check Record'
  programSiteCheckRecord = <any>{};
  programSiteRecord = <any>{};
  program = <any>{};
  site = <any>{};
  grades = [
    {id: 0, name: 'Passed'},
    {id: 1, name: 'Failed - Fix Next Visit'},
    {id: 2, name: 'Failed - Fix Urgently'},
  ]

  gradeArray = [
    'Passed',
    'Failed - Fix Next Visit',
    'Failed - Fix Urgently'
  ]

  allowIncomplete = false;
  inComplete: boolean = true;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<Component>,
  ) {
    super();
    this.metaData = data.metaData;
    this.programSiteRecord = data.data.programSiteRecord;

    this.allowIncomplete = this.authService.securityObject.allowIncompleteProgramCheck;

    this.programSiteCheckRecord.mobileCreated = this.newIsoDate();
    this.programSiteCheckRecord.mobileCreatedDate = this.programSiteCheckRecord.mobileCreated.split("T")[0];
    this.programSiteCheckRecord.mobileCreatedTime = this.programSiteCheckRecord.mobileCreated.match(/([A-Z]?[^A-Z]*)/g)[1]
      .slice(1, -1).slice(0, 5);

    this.remoteService.getBy("programSiteRecord", "id", this.programSiteRecord.id).subscribe((response: any) => {
      this.site = response.site;
      this.program = response.program;
    });

    this.getTasks();

  }

  getTasks() {
    this.remoteService.getBy("programSiteRecord", "taskRecords/id", this.programSiteRecord.id)
      .subscribe((response) => {
        if (response && response.length > 0) {
          this.programSiteCheckRecord.checks = response;
          for (let i = 0; i < this.programSiteCheckRecord.checks.length; i++) {
            this.programSiteCheckRecord.checks[i].name = this.programSiteCheckRecord.checks[i].name + "(" + this.programSiteCheckRecord.checks[i].taskRecordStatusName + ")";
            this.programSiteCheckRecord.checks[i].taskRecord = {
              id: this.programSiteCheckRecord.checks[i].id
            }
          }
          this.loaded = true
        } else {
          this.baseService.openSnackBar(`Program Site Record has no Task Records Associated.`,
            null, true);
        }
      })
  }

  close(form) {
    this.closeModal(form, this.dialogRef);
  }

  programSiteRecordComplete(checks) {
    this.prepChecks(checks).then(() =>
      this.programSiteCheckRecord.taskQualityCheckRecords = checks
    ).then(() => this.save());
  }

  private save() {
    this.programSiteCheckRecord.mobileCreated = this.programSiteCheckRecord.mobileCreatedDate
      ? this.formDateTime(
        this.programSiteCheckRecord.mobileCreatedDate,
        this.programSiteCheckRecord.mobileCreatedTime
      )
      : null;

    this.remoteService.add("programSiteCheckRecord", null, {
      site: this.site,
      program: this.program,
      taskCheckRecords: this.programSiteCheckRecord.taskQualityCheckRecords,
      created: this.programSiteCheckRecord.mobileCreated
    }).subscribe(() => {
      this.baseService.openSnackBar("Program Site Check Recorded Successfully");
      this.dialogRef.close();
    }, ((error) => {
      this.baseService.openSnackBar(`Error - Unable to save Program Site Check Record (${error.message})`,
        null, true);
    }))
  }

  private prepChecks(checks) {
    return new Promise<void>((resolve) => {
      for (let i = 0; i < checks.length; i++) {
        checks[i].TaskCheckRecordStatus = checks[i].grade;
        checks[i].Task = {id: checks[i].id};
        delete checks[i].id;
      }
      resolve();
    })
  }
}
