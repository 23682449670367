import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { PdfViewerModule } from "ng2-pdf-viewer";
import { LightboxModule } from "ngx-lightbox";
import { QuillModule } from "ngx-quill";
import Quill from "quill";
import ImageCompress from "quill-image-compress";

// MATERIAL
import { MatTabsModule } from "@angular/material/tabs";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatButtonModule } from "@angular/material/button";
import { MatInputModule } from "@angular/material/input";
import { MatDatepickerModule } from "@angular/material/datepicker";
import {
  DateAdapter,
  MatNativeDateModule,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE
} from "@angular/material/core";
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter
} from "@angular/material-moment-adapter";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatRadioModule } from "@angular/material/radio";
import { MatSliderModule } from "@angular/material/slider";
import { MatSelectModule } from "@angular/material/select";
import { MatMenuModule } from "@angular/material/menu";
import { MatIconModule } from "@angular/material/icon";
import { MatListModule } from "@angular/material/list";
import { MatExpansionModule } from "@angular/material/expansion";
import { MAT_DIALOG_DATA, MatDialogModule } from "@angular/material/dialog";
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from "@angular/material/form-field";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { MatBadgeModule } from "@angular/material/badge";
import { MatCardModule } from "@angular/material/card";
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';


//PIPES
import { FilterArrayPipe } from "../pipes/array-filter.pipe";
import { FilterPipe } from "../pipes/filter.pipe";
import { ListFilterPipe } from "../pipes/list-filter.pipe";
import { NoCommaPipe } from "../pipes/no-comma.pipe";
import { ReverseSortPipe } from "../pipes/reverse-sort.pipe";
import { SortPipe } from "../pipes/sort.pipe";
import { TypeFilterPipe } from "../pipes/type-filter.pipe";
import { FilterStockPipe } from "../pipes/stock-filter.pipe";
import { IncludesPipe } from "../pipes/includes-filter.pipe";
import { IdFilterPipe } from "../pipes/id-filter.pipe";

// SERVICES
import { AppConfigurationService } from "../services/app-config/app-configuration.service";

// COMPONENTS
import { SidenavComponent } from "../components/sidenav/sidenav.component";
import { EditModalComponent } from "../components/modals/edit-modal.component";
import { EditFormComponent } from "../components/edit-form/edit-form.component";
import { ImageCarouselComponent } from "../components/image-carousel/image-carousel.component";
import { PhotosComponent } from "../sections/photos/photos.component";
import { LoadingComponent } from "../components/loading/loading.component";
import { DocumentsComponent } from "../sections/documents/documents.component";
import { AuditTrailComponent } from "../sections/audit-trail/audit-trail.component";
import { ConfirmModalComponent } from "../components/modals/confirm/confirm-modal.component";
import { MatSnackBar } from "@angular/material/snack-bar";
import { NgInitDirective } from "../ngInit.directive";
import { UnderConstructionComponent } from "../sections/under-construction/under-construction.component";

// DIRECTIVES
import { DebounceClickDirective } from "../directives/debounce-click.directive";
import { CurrencyFormatterDirective } from "../directives/currency-formatter.directive";
import { MatSelectDirective } from "../directives/mat-select.directive";
import { ArchivedFilterPipe } from "../pipes/archived-filter.pipe";
import { MenuComponent } from "../menu/menu.component";
import { HasClaimDirective } from "../../auth/directives/has-claim.directive";
import { SmallPhotosComponent } from "../sections/small-photos/small-photos.component";
import { SafePipe } from "../pipes/safe.pipe";
import { ContextmenuComponent } from "../grid/context-menu/context-menu.component";
import { CleanPipe } from "../pipes/clean.pipe";
import { BasicEditTableComponent } from "../components/basic-edit-table/basic-edit-table.component";
import { MatTableModule } from "@angular/material/table";
import { MatSortModule } from "@angular/material/sort";
import { SettingsComponent } from "../components/settings/settings.component";
import { SmallImageCarouselComponent } from "../components/small-image-carousel/small-image-carousel.component";
import { CustomFieldsComponent } from "../sections/custom-fields/custom-fields.component";
import { HoldableDirective } from "../../auth/directives/holdable.directive";

const matModules = [
  MatMenuModule,
  MatTabsModule,
  MatToolbarModule,
  MatButtonModule,
  MatInputModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatCheckboxModule,
  MatRadioModule,
  MatSliderModule,
  MatSelectModule,
  MatMenuModule,
  MatIconModule,
  MatListModule,
  MatExpansionModule,
  DragDropModule,
  MatBadgeModule,
  MatDialogModule,
  MatCardModule,
  MatProgressSpinnerModule
]; 

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    // MATERIAL
    matModules,
    //OTHER
    LightboxModule,
    PdfViewerModule,
    MatTableModule,
    MatSortModule,
    QuillModule.forRoot({
      modules: {
        toolbar: [
          ["bold", "italic", "underline", "strike"],
          ["blockquote", "code-block"],
          [{ header: 1 }, { header: 2 }],
          [{ list: "ordered" }, { list: "bullet" }],
          [{ indent: "-1" }, { indent: "+1" }],
          [{ direction: "rtl" }],
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          [{ color: [] }, { background: [] }],
          [{ font: [] }],
          [{ align: [] }],
          ["image"],
        ],
        imageCompress: {
          quality: 0.9,
          maxWidth: 700,
          maxHeight: 1000,
          imageType: "image/jpeg",
          debug: false,
        },
      },
    }),
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    // MATERIAL
    matModules,
    // PIPES
    FilterArrayPipe,
    SafePipe,
    CleanPipe,
    FilterPipe,
    ArchivedFilterPipe,
    ListFilterPipe,
    NoCommaPipe,
    ReverseSortPipe,
    SortPipe,
    TypeFilterPipe,
    FilterStockPipe,
    IncludesPipe,
    IdFilterPipe,
    // OTHER
    LightboxModule,
    PdfViewerModule,
    // CUSTOM
    SidenavComponent,
    EditModalComponent,
    EditFormComponent,
    ImageCarouselComponent,
    SmallImageCarouselComponent,
    PhotosComponent,
    SmallPhotosComponent,
    DocumentsComponent,
    AuditTrailComponent,
    CustomFieldsComponent,
    LoadingComponent,
    ConfirmModalComponent,
    UnderConstructionComponent,
    NgInitDirective,
    CurrencyFormatterDirective,
    MatSelectDirective,
    MenuComponent,
    HasClaimDirective,
    HoldableDirective,
    ContextmenuComponent,
    BasicEditTableComponent,
    QuillModule,
    SettingsComponent
  ],
  declarations: [
    FilterArrayPipe,
    FilterPipe,
    SafePipe,
    CleanPipe,
    ArchivedFilterPipe,
    ListFilterPipe,
    NoCommaPipe,
    ReverseSortPipe,
    SortPipe,
    TypeFilterPipe,
    FilterStockPipe,
    IncludesPipe,
    IdFilterPipe,
    // CUSTOM
    SidenavComponent,
    EditModalComponent,
    EditFormComponent,
    ImageCarouselComponent,
    SmallImageCarouselComponent,
    PhotosComponent,
    SmallPhotosComponent,
    DocumentsComponent,
    AuditTrailComponent,
    CustomFieldsComponent,
    LoadingComponent,
    ConfirmModalComponent,
    UnderConstructionComponent,
    NgInitDirective,
    DebounceClickDirective,
    CurrencyFormatterDirective,
    MatSelectDirective,
    MenuComponent,
    HasClaimDirective,
    HoldableDirective,
    ContextmenuComponent,
    BasicEditTableComponent,
    SettingsComponent
  ],
  providers: [
    AppConfigurationService,
    MatSnackBar,
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline' } },

    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { floatLabel: "always" }
    },
    { provide: MAT_DATE_LOCALE, useValue: "en-GB" },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } }
  ]
})
export class SharedModule { }
