import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../auth.service';

@Injectable({
  providedIn: 'root'
})
export class CanActivateGuard  {
  constructor(private router: Router, private authService: AuthService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
       let claimType: string = route.data["claimType"];
      if (
        this.authService.securityObject.isAuthenticated
         &&
        this.authService.hasClaim(claimType)
      ) {
        return true;
      } else {
        if (this.authService.securityObject.isAuthenticated) {
          this.router.navigate(["home"]);
        } else {
          this.router.navigate(["login"], {
            queryParams: { returnUrl: state.url },
          });
        }
        return false;
      }
  }

}
