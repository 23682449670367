import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Check } from "../models/check.model";

@Component({
  selector: "inspection",
  templateUrl: "./inspection-base.component.html",
  styleUrls: ["./inspection-base.component.css"]
})
export class InspectionBaseComponent {
  @Input() data;
  @Input() completeBtnText;
  @Input() grades = [
    { id: 0, name: "Pass" },
    { id: 1, name: "Fail" }
  ];
  @Output() complete = new EventEmitter();
  @Input() checks: Check[];
  inComplete: boolean = true;
  @Input() allowIncomplete: boolean = false;

  @Input() saving: boolean = false;


  checkImages($event, check) {
    if (!check.images) {
      check.images = [];
    }
    check.images.push($event);
  }

  checkFinished() {
    setTimeout(() => {
      for (let i = 0; i < this.checks.length; i++) {
        if (!this.checks[i].grade && !this.allowIncomplete) {
          return;
        }
      }
      this.inComplete = false;
    }, 100);
  }

  submit() {
    this.complete.emit(this.checks);
  }
}
