import {FieldType, IEditFormField} from "../../shared/components/edit-form/edit-form-model";

export const issueFields: IEditFormField[] = [
  { name: "issueNumber", label: "Issue Number", type: FieldType.Int, required: true, disabled: true },
  { name: "issueCategory", label: "Category", type: FieldType.String, required: true },
  { name: "issueType", label: "Type", type: FieldType.String, required: true },
  { name: "site", label: "Site", type: FieldType.String },
  { name: "risk", label: "Risk", type: FieldType.String },
  { name: "latitude", label: "Latitude", type: FieldType.String },
  { name: "longitude", label: "Longitude", type: FieldType.String },
  { name: "what3Words", label: "What3Words", type: FieldType.String, disabled: true },
  { name: "escalated", label: "Escalated", type: FieldType.Bool },
  { name: "escalatedReference", label: "Escalated Reference", type: FieldType.String },
  { name: "issueEscalatedContact", label: "Escalated To", type: FieldType.String },
  { name: "description", label: "Description", type: FieldType.String, required: true },
  { name: "note", label: "Notes", type: FieldType.String },
  { name: "isClosed", label: "Issue Resolved", type: FieldType.Select, required: true },
  { name: "closedDate", label: "Issue Resolved Date", type: FieldType.Date },
  { name: "inspectionName", label: "Inspection", type: FieldType.String },
  { name: "equipmentName", label: "Equipment", type: FieldType.String },
  { name: "siteName", label: "Site", type: FieldType.String },
  { name: "inspectionCheckItemRecordCreatedDate", label: "Inspected Date", type: FieldType.Date },
  { name: "inspectionCheckItemRecordCreatedByName", label: "Inspected By", type: FieldType.String },
  { name: "inspectionCheckItemResultName", label: "Check Result", type: FieldType.String },
  { name: "inspectionCheckItemRecordNotes", label: "Notes", type: FieldType.String },
];
export const issueCategoryFields: IEditFormField[] = [
  { name: "name", label: "Category Name", type: FieldType.String, required: true },
  { name: "description", label: "Description", type: FieldType.LargeText },
  { name: "siteCategories", label: "Site Categories", type: FieldType.MultipleSelect, selected: [] },

];
export const issueTypeFields: IEditFormField[] = [
  { name: "name", label: "Type Name", type: FieldType.String, required: true },
  {
    name: "type",
    label: "Issue Category",
    type: FieldType.Select,
    required: true,
    selected: [],
    metaDataType: "issueCategories"
  },
  { name: "description", label: "Description", type: FieldType.LargeText }
];
export const riskFields: IEditFormField[] = [
  { name: "name", label: "Risk Name", type: FieldType.String, required: true },
  { name: "order", label: "Order", type: FieldType.Int },
  { name: "description", label: "Description", type: FieldType.LargeText }
];
export const issueEscalatedContactFields: IEditFormField[] = [
  { name: "name", label: "Name", type: FieldType.String, required: true, validatorPattern: "^.{1,100}$" },
  { name: "address", label: "Address", type: FieldType.String, validatorPattern: "^.{1,200}$" },
  { name: "postcode", label: "Postcode", type: FieldType.String, validatorPattern: "^.{1,10}$" },
  { name: "phoneNumber", label: "Phone Number", type: FieldType.String, validatorPattern: "^.{1,15}$" },
  { name: "mobile", label: "Mobile", type: FieldType.String, validatorPattern: "^.{1,15}$" },
  { name: "website", label: "Website", type: FieldType.String },
  { name: "email", label: "Email", type: FieldType.String },
  { name: "notes", label: "Notes", type: FieldType.String },
]
export const issueContactFields: IEditFormField[] = [
  { name: "name", label: "Contact Name", type: FieldType.String, required: true, validatorPattern: "^.{1,100}$" },
  { name: "email", label: "Email", type: FieldType.String, required: true },
  { name: "mobile", label: "Mobile", type: FieldType.String },
  { name: "areas", label: "Areas", type: FieldType.MultipleSelect, selected: [] },
  { name: "wards", label: "Wards", type: FieldType.MultipleSelect, selected: [] },
  { name: "siteClients", label: "Clients", type: FieldType.MultipleSelect, selected: [] },
  { name: "risks", label: "Risks", type: FieldType.MultipleSelect, selected: [] },
  { name: "creation", label: "Notify on Issue Creation", type: FieldType.Bool, required: true },
  { name: "update", label: "Notify on Issue Update", type: FieldType.Bool, required: true },
  { name: "closure", label: "Notify on Resolved", type: FieldType.Bool, required: true },
];
