import {
  Directive,
  EventEmitter,
  Host,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  Self,
  SimpleChanges
} from "@angular/core";
import { Router } from "@angular/router";
import {
  DefaultSortingStrategy,
  FilteringExpressionsTree,
  IFilteringExpression,
  IgxGridComponent,
  ISortingExpression
} from "igniteui-angular";
import { HttpClient } from "@angular/common/http";
import { AppConfigurationService } from "../../services/app-config/app-configuration.service";
import { Subscription } from "rxjs";
import { SectionService } from "src/app/shared/services/section/section.service";

interface IGridState {
  paging: { index: number; recordsPerPage: number };
  selection: any[];
  filtering: FilteringExpressionsTree;
  sorting: ISortingExpression[];
  columns: any[];
}

@Directive({
  selector: "[appGridState]"
})
export class GridStateDirective implements OnInit, OnDestroy, OnChanges {
  @Input() gridName: string;
  @Output() gridStateSaved = new EventEmitter();
  @Output() onGetColumnsFromGrid = new EventEmitter();

  perPage = 15;
  selection = true;
  filtering = true;
  paging = true;
  sorting = true;
  columns = true;
  shouldSaveState = false;

  subscription: Subscription;

  @HostListener("window:beforeunload", ["$event"])
  unloadNotification($event: any) {
    this.saveGridState();
  }

  initialState: IGridState = {
    filtering: new FilteringExpressionsTree(0),
    paging: { index: 0, recordsPerPage: this.perPage },
    selection: [],
    sorting: [],
    columns: []
  };

  gridState: IGridState;

  constructor(
    @Host() @Self() @Optional() public grid: IgxGridComponent,
    private router: Router,
    private readonly httpClient: HttpClient,
    private readonly configService: AppConfigurationService,
    private sectionService: SectionService
  ) {}

  ngOnInit() {

    this.subscription = this.sectionService.onSectionChange.subscribe(() => {
      if (this.shouldSaveState){
        this.saveGridState();
      }
      this.gridStateSaved.emit();
    });
    this.loadGridState().then((value) => {
      this.shouldSaveState = true;
      this.onGetColumnsFromGrid.emit(value);
      this.grid.markForCheck();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.gridName &&
      this.gridName &&
      changes.gridName.firstChange === false
    ) {
      this.loadGridState().then((value) => {
        this.onGetColumnsFromGrid.emit(value);
        this.grid.markForCheck();
      });
    }
  }

  ngOnDestroy() {
    this.saveGridState();
    this.subscription.unsubscribe();
  }

  saveGridState() {
    //#9
    const pagingState = { paging: this.grid.pagingState };
    this.storeState("paging", pagingState);

    const sortingState = { sorting: this.grid.sortingExpressions };
    this.storeState("sorting", sortingState);

    const filteringState = { filtering: this.grid.filteringExpressionsTree };
    this.storeState("filtering", filteringState);

    const selectionState = { selection: this.grid.selectedRows };
    this.storeState("selection", selectionState);

    const columnsState = { columns: this.getColumns() };
    this.storeState("columns", columnsState);
    // this.saveGridStateRemotely();
    // this.onNewGridLoaded.emit();
  }

  loadGridState(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.gridState = { ...this.initialState };
      const allPromises = [];
      let columns;
      // Make a promise for each state item
      for (const propt in this.gridState) {
        if ((this.gridState as any).hasOwnProperty(propt)) {
          const tempPromise = this.getStoredState(propt).then((value) => {
            if (propt === "columns") {
              columns = value;
            }
            this.gridState[propt] = value;
          });
          allPromises.push(tempPromise);
        }
      }
      // After resolving all promises resolve
      Promise.all(allPromises).then((value) => {
        resolve(columns);
      });
    });
  }

  restoreGridState() {
    //#10
    return new Promise((resolve, reject) => {
      if (this.filtering && this.gridState.filtering) {
        const gridFilteringExpressionsTree = new FilteringExpressionsTree(
          this.gridState.filtering.operator
        );

        for (const f of this.gridState.filtering.filteringOperands) {
          // The logic from the custom-date-filters doesn't come through normal grid state
          if (!f.fieldName.includes("Date")) {
            const filtOperand = f as FilteringExpressionsTree;
            let columnsFiltOperands: any;

            if (filtOperand.filteringOperands.length > 1) {
              columnsFiltOperands =
                filtOperand.filteringOperands as IFilteringExpression[];
            } else {
              columnsFiltOperands = filtOperand
                .filteringOperands[0] as IFilteringExpression;
              if (Array.isArray(columnsFiltOperands.filteringOperands)) {
                columnsFiltOperands = columnsFiltOperands.filteringOperands;
              } else {
                columnsFiltOperands = [columnsFiltOperands];
              }
            }
            this.createExpressionsTree(columnsFiltOperands, filtOperand).then(
              (filteringExpression) =>
                gridFilteringExpressionsTree.filteringOperands.push(
                  filteringExpression
                )
            );
          }

          this.grid.filteringExpressionsTree = gridFilteringExpressionsTree;
        }
      } else this.grid.filteringExpressionsTree = null;
      //Restoring paging, sorting and filtering
      if (this.paging && this.gridState.paging) {
        if (this.grid.perPage !== this.gridState.paging.recordsPerPage) {
          this.grid.perPage = this.gridState.paging.recordsPerPage;
          this.grid.cdr.detectChanges();
        }
        if (this.grid.page !== this.gridState.paging.index) {
          //TODO
          //this.grid.paginate(this.gridState.paging.index);
        }
      }

      if (this.sorting && this.gridState.sorting) {
        const strategy = DefaultSortingStrategy.instance();
        this.gridState.sorting.forEach((expr) => (expr.strategy = strategy));
        this.grid.sortingExpressions = this.gridState.sorting;
      }

      if (this.selection && this.gridState.selection) {
        this.grid.selectRows(this.gridState.selection);
      }
    });
  }

  storeState(action: string, args: any) {
    if (this[action]) {
      const actionKey = action + "-" + this.gridName;
      window.localStorage.setItem(actionKey, JSON.stringify(args));
    }
  }

  getStoredState(action: string): Promise<any> {
    //#5 #6 #7 #8
    return new Promise((resolve, reject) => {
      const actionKey = action + "-" + this.gridName;
      const item = JSON.parse(window.localStorage.getItem(actionKey));
      resolve(item ? item[action] : null);
    });
  }

  clearStorageForGrid() {
    for (const propt in this.gridState) {
      if ((this.gridState as any).hasOwnProperty(propt)) {
        const actionKey = propt + "-" + this.gridName;
        window.localStorage.removeItem(actionKey);
      }
    }
  }

  private createExpressionsTree(
    columnsFiltOperands: IFilteringExpression[],
    filtOperand: FilteringExpressionsTree
  ): Promise<FilteringExpressionsTree> {
    return new Promise((resolve, reject) => {
      const columnFilteringExpressionsTree = new FilteringExpressionsTree(
        filtOperand.operator,
        filtOperand.fieldName
      );
      const column = this.grid.columns.filter(
        (col) => col.field === filtOperand.fieldName
      )[0];
      for (const fo of columnsFiltOperands) {
        const columnFiltOperand = fo as IFilteringExpression;
        columnFiltOperand.condition = column.filters.condition(
          columnFiltOperand.condition.name
        );
        columnFilteringExpressionsTree.filteringOperands.push(
          columnFiltOperand
        );
      }
      resolve(columnFilteringExpressionsTree);
    });
  }

  private getColumns() {
    return this.grid.columns.map((c) => {
      return {
        pinned: c.pinned,
        sortable: c.sortable,
        filterable: c.filterable,
        movable: c.movable,
        hidden: c.hidden,
        dataType: c.dataType,
        field: c.field,
        width: c.width,
        header: c.header,
        visibleIndex: c.visibleIndex
      };
    });
  }
}
