import {FieldType, IEditFormField} from "../../shared/components/edit-form/edit-form-model";

export const databaseFields: IEditFormField[] = [
  {name: "name", label: "Name", type: FieldType.String, required: true},
  {name: "account", label: "Account", type: FieldType.Select, required: true, metaDataType: "accounts"},
  {name: "issueNumber", label: "IssueNumber", type: FieldType.Int, required: true},
  {name: "stockRequestNumber", label: "StockRequestNumber", type: FieldType.Int, required: true},
  {name: "autoArchiveEnabled", label: "AutoArchiveEnabled", type: FieldType.Bool, required: true},
  {name: "zohoDatabaseName", label: "ZohoDatabaseName", type: FieldType.String},
  {name: "zohoDatabaseId", label: "ZohoDatabaseId", type: FieldType.String},
  {name: "peopleLimit", label: "People Limit", type: FieldType.Int, required: true},
  {name: "zohoLimit", label: "Zoho Limit", type: FieldType.Int, required: true}
];

export const accountFields: IEditFormField[] = [
  {name: "name", label: "Name", type: FieldType.String, required: true},
  {name: "mandatoryTwoFactor", label: "Mandatory Two Factor", type: FieldType.Bool}
];

export const userFields: IEditFormField[] = [
  {name: "firstName", label: "First Name", type: FieldType.String, required: true},
  {name: "lastName", label: "Last Name", type: FieldType.String, required: true},
  {name: "email", label: "Email", type: FieldType.String},
  {name: "newPassword", label: "New Password", type: FieldType.String},
  {name: "shortUsername", label: "Mobile Username", type: FieldType.String},
  {name: "account", label: "Account", type: FieldType.Select, required: true, metaDataType: "accounts"},
  {name: "newShortPassword", label: "Mobile PIN", type: FieldType.String}
];

export const supportLinkFields: IEditFormField[] = [
  {name: "name", label: "Name", type: FieldType.String, required: true},
  {name: "url", label: "Url", type: FieldType.String, required: true},
  {name: "note", label: "Note", type: FieldType.String},
  {name: "supportType", label: "Type", type: FieldType.Select, required: true, metaDataType: "supportTypes"},
];


export const newsFields: IEditFormField[] = [
  {name: "title", label: "Title", type: FieldType.String, required: true},
  {name: "draft", label: "Draft", type: FieldType.Bool, required: true},
  {name: "article", label: "Article", type: FieldType.String, required: true},
  {name: "created", label: "Created", type: FieldType.Date, required: true},
];
