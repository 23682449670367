<div>
  <div class="row justify-content-center">
    <mat-form-field class="offset-s col-sm-4">
      <mat-label></mat-label>
      <input matInput (keyup)="applyFilter($event)" placeholder="Search" />
    </mat-form-field>
  </div>
  <div *ngIf="!activePage" class="row justify-content-center">
    <ng-container *ngFor="let section of menu">
      <button
        mat-raised-button
        *ngFor="
          let item of section.children | sort: 'name' | listFilter: searchInput
        "
        (click)="action(item)"
        class="col-md-6 col-lg-4 m-3 sectionBtn"
      >
        <div class="d-flex align-items-center justify-content-center">
          <i [ngClass]="'me-3 ' + item.icon"></i>
          <p>{{ item.name }}</p>
        </div>
      </button>
    </ng-container>
  </div>
</div>
