<div
  *ngIf="images?.length > 0; else camera"
  id="carouselIndicators"
  class="small-carousel slide w-100"
  data-ride="carousel"
>
  <div class="carousel-inner">
    <div *ngFor="let image of images; let i = index">
      <div class="carousel-item" [class]="i == activeId ? 'active' : ''">
        <img
          (click)="open(i)"
          class="d-block pointer"
          [src]="image.source"
          [alt]="image.name ? image.name : 'Photo'"
        />
      </div>
    </div>
  </div>
  <a class="carousel-control-prev" role="button" (click)="prev()">
    <i class="fas fa-angle-left primary fa-2x"></i>
    <span class="sr-only">Previous</span>
  </a>
  <div class="carousel-control-next" role="button" (click)="next()">
    <i class="fas fa-angle-right primary fa-2x"></i>
    <span class="sr-only">Next</span>
  </div>
</div>
<ng-template #camera>
  <div class="loading h-100 d-flex">
    <div class="align-self-center pt-5 text-center mx-auto">
      <i class="fas fa-image-polaroid fa-3x"></i>
      <p>No Photos</p>
    </div>
  </div>
</ng-template>
