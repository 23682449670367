import { IgxStringFilteringOperand } from "igniteui-angular";
import { CustomStringFilteringOperand } from "../custom-filter-operands/custom-string-filters";

export const StringFilterOperations = [
  {
    name: "Contains",
    iconName: "contains",
    logic: IgxStringFilteringOperand.instance().condition("contains")
  }, {
    name: "Does Not Contain",
    iconName: "does-not-contain",
    logic: IgxStringFilteringOperand.instance().condition("doesNotContain")
  }, {
    name: "Starts With",
    iconName: "starts-with",
    logic: IgxStringFilteringOperand.instance().condition("startsWith")
  }, {
    name: "Ends With",
    iconName: "ends-with",
    logic: IgxStringFilteringOperand.instance().condition("endsWith")
  }, {
    name: "Equals",
    iconName: "equals",
    logic: IgxStringFilteringOperand.instance().condition("equals")
  }, {
    name: "Does Not Equal",
    iconName: "not-equal",
    logic: IgxStringFilteringOperand.instance().condition("doesNotEqual")
  }, {
    name: "Empty",
    iconName: "is-empty",
    logic: CustomStringFilteringOperand.instance().condition("empty")
  }, {
    name: "Is Not Empty",
    iconName: "not-empty",
    logic: CustomStringFilteringOperand.instance().condition("notEmpty")
  }
];
