import { Injectable, OnInit } from "@angular/core";
import { IgxIconService } from "igniteui-angular";
import {
  contains,
  doesNotContain,
  startsWith,
  endsWith,
  equals,
  notEqual,
  greaterThan,
  lessThan,
  greaterThanOrEqual,
  lessThanOrEqual,
  isEmpty,
  notEmpty,
  nextYear,
  lastYear,
  thisYear,
  nextMonth,
  lastMonth,
  thisMonth,
  yesterday,
  today,
  selectAll,
  isAfter,
  isBefore,
  isTrue,
  isNull
} from "@igniteui/material-icons-extended";

@Injectable({
  providedIn: "root"
})
export class IconService implements OnInit {
  public allIcons = [
    contains,
    doesNotContain,
    startsWith,
    endsWith,
    equals,
    notEqual,
    greaterThan,
    lessThan,
    greaterThanOrEqual,
    lessThanOrEqual,
    isEmpty,
    notEmpty,
    nextYear,
    lastYear,
    thisYear,
    nextMonth,
    lastMonth,
    thisMonth,
    yesterday,
    today,
    selectAll,
    isAfter,
    isBefore,
    isTrue,
    isNull
  ];

  constructor(private iconService: IgxIconService) {}

  ngOnInit() {
    // this.iconService.addSvgIcon(
    //   "logo",
    //   "../assets/pss_live_login.svg",
    //   "icons"
    // );

    // // register custom SVG icons
    // for (let i = 0; i < this.icons.length; i++) {
    //   const icon = this.icons[i];
    //   this.iconService.addSvgIcon(icon, `../assets/igx/${icon}.svg`,'filter-icons')
    // }

    this.addIcons();
  }
  addIcons() {
    for (let icon of this.allIcons) {
      this.iconService.addSvgIconFromText(icon.name, icon.value, "imx-icons");
    }
  }
}
