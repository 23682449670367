<div class="sd-modal">
  <div class="d-flex m-0 position-relative h-100">
    <div class="p-3 primary">
      <div class="text-center">
        <i class="fal fa-clipboard-user fa-3x"></i>
        <H5>Attendance</H5>
      </div>
      <div class="py-5 modal-nav">
        <button
          *ngFor="let item of nav"
          mat-button
          class="btn py-2 d-block w-100"
          [ngClass]="{ 'border-p': current.page == item.page }"
          (click)="changeSection(item)"
          [disabled]="item.disabled"
          [hidden]="item.hidden"
        >
          {{ item.name }}
        </button>
      </div>
    </div>
    <div class="corner-close" (click)="close(form)">
      <i class="fal fa-times fa-2x"></i>
    </div>
    <form
      class="bg-modal px-4 d-flex w-100"
      [formGroup]="form"
      data-lpignore="true"
      autocomplete="off"
      *ngIf="loaded; else loading"
      (input)="check(form)"
    >
      <content class="d-flex flex-column justify-content-between w-100">
        <!-- #region Header -->
        <header class="d-flex primary pt-3">
          <div>
            <h2 class="m-0">
              Attendance
            </h2>
          </div>
        </header>
        <!-- #endregion -->
        <!-- #region Main -->
        <main class="flex-grow-1">
          <div class="h-100" [ngSwitch]="current.page">
            <!-- #region Details -->
            <div *ngSwitchCase="'details'" class="h-100">
              <div class="row">
                <div class="col-6">
                  <div class="card p-2 d-block">
                    <mat-form-field class="w-100">
                      <mat-label class="primary">Person</mat-label>
                      <mat-select
                        formControlName="person"
                        [compareWith]="compareOverride"
                      >
                        <mat-option
                          *ngFor="
                          let person of metaData.people
                            | sort: 'name'
                            | archivedFilter : entity.person
                        "
                          [value]="person"
                        >
                          {{ person.name }}
                        </mat-option>
                      </mat-select>
                      <mat-error
                        *ngIf="
                        form.get('person').hasError('required') &&
                        form.get('person').touched
                      "
                      >
                        Person Required.
                      </mat-error>
                    </mat-form-field>
                    <mat-form-field class="w-50">
                      <mat-label class="primary">Started Date</mat-label>
                      <input
                        matInput
                        [matDatepicker]="pickerStartedDate"
                        formControlName="startedDate"
                      />
                      <mat-datepicker-toggle
                        matSuffix
                        [for]="pickerStartedDate"
                      ></mat-datepicker-toggle>
                      <mat-datepicker #pickerStartedDate></mat-datepicker>
                      <mat-error
                        *ngIf="
                        form.get('startedDate').hasError('startedDate') &&
                        form.get('startedDate').touched
                      "
                      >
                        Started Date Required.
                      </mat-error>
                    </mat-form-field>
                    <mat-form-field class="w-50">
                      <mat-label class="primary">Started Time</mat-label>
                      <input
                        matInput
                        type="time"
                        formControlName="startedTime"
                      />
                      <mat-error
                        *ngIf="
                        form.get('startedTime').hasError('startedTime') &&
                        form.get('startedTime').touched
                      "
                      >
                        Started Time Required.
                      </mat-error>
                    </mat-form-field>
                    <mat-form-field class="w-50">
                      <mat-label class="primary">Finished Date</mat-label>
                      <input
                        matInput
                        [matDatepicker]="pickerFinishedDate"
                        formControlName="finishedDate"
                      />
                      <mat-datepicker-toggle
                        matSuffix
                        [for]="pickerFinishedDate"
                      ></mat-datepicker-toggle>
                      <mat-datepicker #pickerFinishedDate></mat-datepicker>
                    </mat-form-field>
                    <mat-form-field class="w-50">
                      <mat-label class="primary">Finished Time</mat-label>
                      <input
                        matInput
                        type="time"
                        formControlName="finishedTime"
                      />
                    </mat-form-field>
                    <mat-form-field class="w-100">
                      <mat-label class="primary">Program</mat-label>
                      <mat-select
                        formControlName="program"
                        [compareWith]="compareOverride"
                      >
                        <mat-option value="null">
                          N/A
                        </mat-option>
                        <mat-option
                          *ngFor="
                          let person of metaData.programs
                            | sort: 'name'
                            | archivedFilter : entity.program
                        "
                          [value]="person"
                        >
                          {{ person.name }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <mat-form-field class="w-100">
                      <mat-label class="primary">Created By</mat-label>
                      <input
                        disabled
                        matInput
                        formControlName="createdByName">
                    </mat-form-field>
                  </div>
                </div>
                <div class="col-6">
                  <div class="card d-block"><h5 class="p-2">Time Records</h5>

                    <div class="text-center">
                      <button
                        mat-button
                        (click)="addTimeRecord()"
                        class="btn p-1 mb-2 w-50 border-p"
                      >
                        Add Time Record <span><i class="fa-regular fa-circle-plus"></i></span>
                      </button>
                    </div>
                    <div class="p-1"
                      *ngFor="let timeRecord of timeRecords">

                      <div class="border-p rounded p-2">
                        <form [formGroup]="timeRecord">
                          <mat-form-field class="w-50">
                            <mat-label class="primary">Record Type</mat-label>
                            <mat-select
                              [formControl]="timeRecord.controls['timeRecordType']"
                              [compareWith]="compareOverride"
                            >
                              <mat-option
                                *ngFor="
                          let recordType of metaData.timeRecordTypes
                            | sort: 'name'
                        "
                                [value]="recordType"
                              >
                                {{ recordType.name }}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                          <mat-form-field class="w-50">
                            <mat-label class="primary">Site</mat-label>
                            <mat-select
                              [formControl]="timeRecord.controls['site']"
                              [compareWith]="compareOverride"
                            >
                              <mat-option value="null">
                                N/A
                              </mat-option>
                              <mat-option
                                *ngFor="
                          let site of metaData.sites
                            | sort: 'name'
                        "
                                [value]="site"
                              >
                                {{ site.name }}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                          <mat-form-field class="w-50">
                            <mat-label class="primary">Started</mat-label>
                            <input
                              matInput
                              type="time"
                              [formControl]="timeRecord.controls['started']"
                            />
                          </mat-form-field>
                          <mat-form-field class="w-50">
                            <mat-label class="primary">Finished</mat-label>
                            <input
                              matInput
                              type="time"
                              [formControl]="timeRecord.controls['finished']"
                            />
                          </mat-form-field>
                        </form>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- #endregion -->

            <!-- #region History / AuditTrail -->
            <div *ngSwitchCase="'auditTrail'">
              <app-audit-trail
                id="auditTrail"
                [auditTrail]="entity.auditTrail"
              ></app-audit-trail>
            </div>
            <!-- #endregion -->
          </div>
        </main>
        <!-- #endregion -->
        <!-- #region Footer -->
        <footer
          class="flex justify-content-end my-3"
          [ngClass]="entity.isArchived ? 'pb-4' : ''"
        >
          <div>
            <button
              mat-flat-button
              name="detailSaveButton"
              class="ml-2 px-4"
              [disabled]="form && form.invalid || !canSave()"
              (click)="save()"
            >
              Save
            </button>
            <button mat-flat-button class="ml-2 px-4" (click)="close(form)">
              Close
            </button>
          </div>
        </footer>
        <!-- #endregion -->
      </content>
    </form>
    <ng-template #loading>
      <app-loading
        class="w-100 d-flex align-items-center justify-content-center bg-modal"
      ></app-loading>
    </ng-template>
  </div>
</div>
