import {FieldType, IEditFormField} from "../../shared/components/edit-form/edit-form-model";

export const AttendeeFields: IEditFormField[] = [
  {name: "person", label: "Person", type: FieldType.Select, required: true, metaDataType: "people"},
  {name: "startedDate", label: "Started Date", type: FieldType.DateTime, required: true},
  {name: "startedTime", label: "Started Time", type: FieldType.DateTime, required: true},
  {name: "createdByName", label: "Created By", type: FieldType.String},
  {name: "finishedDate", label: "Finished Date", type: FieldType.DateTime},
  {name: "finishedTime", label: "Finished Time", type: FieldType.DateTime},
  {name: "program", label: "Program", type: FieldType.Select, metaDataType: "programs"}
];

export const AttendeeTimeRecordFields: IEditFormField[] = [
  {name: "timeRecordType", label: "Record Type", type: FieldType.Select, required: true, metaDataType: "timeRecordTypes"},
  {name: "site", label: "Site", type: FieldType.Select, metaDataType: "sites"},
  {name: "started", label: "Started", type: FieldType.DateTime, required: true},
  {name: "finished", label: "Finished", type: FieldType.DateTime},
]
