export class AppUserAuth {
  id = "";
  isAuthenticated = false;
  name = "";
  username = "";
  readOnly = false;
  isSupport = false;
  webClaims: string[] = [];
  exp = 0;
  expiresIn = 0;
  showE? = false;
  showF? = false;
  showNA? = false;
  isClient = false;
  zohoTicket = "";
  zohoDbid = "";
  sqcPhotoRequirement = "3";
  databaseName = "";
  canZohoPdf = false;
  allowIncompleteSiteQuality = false;
  allowIncompleteProgramCheck = false;
  hideIssueNaSite = false;
  mandatoryIssueRisk = false;
  issueSiteCategoryFilter: boolean = false;
  supportPin = "";
}
