<content class="stock-request-modal">
  <div
    *ngIf="stockRequest.isArchived"
    class="statusBar text-center text-light bg-primary"
  >
    <span>You are currently viewing an Archived Stock Request</span>
  </div>
  <div class="d-flex m-0 position-relative h-100">
    <div class="corner-close" (click)="close(form)">
      <i class="fal fa-times fa-2x"></i>
    </div>
    <form
      class="bg-modal px-4 d-flex w-100"
      [formGroup]="form"
      data-lpignore="true"
      autocomplete="off"
      *ngIf="loaded && form; else loading"
      (input)="check(form)"
    >
      <div class="d-flex flex-column justify-content-between w-100">
        <!-- #region Header -->
        <header class="d-flex justify-content-between primary pt-3">
          <div>
            <h2 class="m-0">
              Stock Request #{{ stockRequest.stockRequestNumber }}
            </h2>
          </div>
        </header>
        <!-- #endregion -->
        <!-- #region Main -->
        <main class="d-flex flex-grow-1 flex-column">
          <!-- #region Details -->
          <div class="mb-3">
            <div class="card p-2 d-block h-100">
              <div class="w-100">
                <mat-form-field class="w-25">
                  <mat-label class="primary"> Request Status</mat-label>
                  <mat-select
                    formControlName="stockRequestStatus"
                    [compareWith]="compareOverride"
                  >
                    <mat-option
                      *ngFor="
                      let s of data.metaData.stockItemRequestStatus
                        | sort: 'name'
                    "
                      [value]="s"
                    >{{ s.name }}
                    </mat-option
                    >
                  </mat-select>
                </mat-form-field>
                <mat-form-field class="w-25">
                  <mat-label class="primary"> Requested By</mat-label>
                  <input matInput formControlName="requestedByName"/>
                </mat-form-field>
                <mat-form-field class="w-25">
                  <mat-label class="primary"> Created Date</mat-label>
                  <input
                    matInput
                    [matDatepicker]="pickerExpiryDate"
                    formControlName="created"
                  />
                </mat-form-field>
                <mat-form-field class="w-25">
                  <mat-label class="primary"> Provided Date</mat-label>
                  <input
                    matInput
                    [matDatepicker]="pickerProvidedDate"
                    formControlName="providedDate"
                  />
                </mat-form-field>
              </div>
              <mat-form-field class="w-100">
                <mat-label class="primary"> Notes</mat-label>
                <input matInput formControlName="note"/>
              </mat-form-field>
              <mat-form-field class="w-100">
                <mat-label class="primary"> Total Cost</mat-label>
                <div class="d-flex">
                  <span class="disabled-font pe-1">£ </span>
                  <input matInput formControlName="totalCost"/>
                </div>
              </mat-form-field>
            </div>
          </div>
          <div class="h-100">
            <div class="card d-block text-center h-100">
              <app-grid
                [gridData]="stockRequest.requestedItems"
                [columns]="stockRequestColumns"
                [small]="true"
                [groupable]="false"
                [editableColumns]="
                  authService.hasClaim(claims.stockEdit)
                    ? [
                        'stockRequestStatus',
                        'quantityProvided',
                        'quantityRequested',
                        'note'
                      ]
                    : null
                "
                [igxSelectOptions]="{
                  stockRequestStatus: data.metaData.stockItemRequestStatus
                }"
                (saveEditedRow)="saveEditedRow($event); form.markAsDirty()"
              ></app-grid>
            </div>
          </div>
          <!-- #endregion -->
        </main>
        <!-- #endregion -->
        <!-- #region Footer -->
        <footer
          class="flex justify-content-end my-3"
          [ngClass]="stockRequest.isArchived ? 'pb-4' : ''"
        >
          <div>
            <button
              mat-flat-button
              name="detailSaveButton"
              class="ms-2 px-4"
              [disabled]="form.invalid || !form.dirty || !hasClaim || saving"
              (click)="save()"
            >
              <span *ngIf="saving">
                <i class="fad fa-spinner-third fa-spin"> </i>
              </span>
              <span *ngIf="!saving">Save</span>
            </button>
            <button mat-flat-button class="ms-2 px-4" (click)="close(form)">
              Close
            </button>
          </div>
        </footer>
        <!-- #endregion -->
      </div>
    </form>
    <ng-template #loading>
      <app-loading
        class="w-100 d-flex align-items-center justify-content-center bg-modal"
      ></app-loading>
    </ng-template>
  </div>
</content>
