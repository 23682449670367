import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {BaseModalComponent} from "../../shared/base/base-modal.component";
import {FieldType, IEditFormField} from "../../shared/components/edit-form/edit-form-model";
import {ConfirmModalComponent} from "../../shared/components/modals/confirm/confirm-modal.component";
import {Check} from "../shared/models/check.model";

@Component({
  selector: 'app-new-site-quality-check-modal',
  templateUrl: './new-site-quality-check-modal.component.html',
  styleUrls: ['./new-site-quality-check-modal.component.css']
})
export class NewSiteQualityCheckModalComponent extends BaseModalComponent {

  title = 'Record Site Quality Check'
  page = 'start';
  siteQualityCheck = <any>{};
  hasTasks = false;

  failedChecks = [];
  passedChecks = [];
  grades = [
    {id: 0, name: 'A'},
    {id: 1, name: 'B'},
    {id: 2, name: 'C'},
    {id: 3, name: 'D'}
  ]
  checkImages($event, check) {
    if (!check.images) {
      check.images = [];
    }
    check.images.push($event);
  }
  gradeArray = [
    'A',
    'B',
    'C',
    'D',
    'E',
    'F'
  ]

  isClient = false;
  allowIncomplete = false;
  inComplete: boolean = true;

  fields: IEditFormField[] = [
    {name: "site", label: "Site", type: FieldType.Select},
    {name: "mobileCreatedDate", label: "Recorded Date", type: FieldType.Date},
    {name: "mobileCreatedTime", label: "Recorded Time", type: FieldType.Date}
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<Component>,
  ) {
    super();
    this.metaData = data.metaData;
    this.siteQualityCheck = data.data;
    this.isClient = this.authService.securityObject.isClient;
    this.allowIncomplete = this.authService.securityObject.allowIncompleteSiteQuality;

    // Inject conditional logic for E and F Grades
    if (this.authService.securityObject.showE
      && this.authService.securityObject.showE.toString().toLowerCase() == "true") {
      this.grades.push({id: 4, name: 'E'});
    }
    if (this.authService.securityObject.showF
      && this.authService.securityObject.showF.toString().toLowerCase() == "true") {
      this.grades.push({id: 5, name: 'F'});
    }
    if (this.authService.securityObject.showNA
      && this.authService.securityObject.showNA.toString().toLowerCase() == "true") {
      this.grades.push({id: 6, name: 'NA'});
    }

    this.siteQualityCheck.mobileCreated = this.newIsoDate();
    this.siteQualityCheck.mobileCreatedDate = this.siteQualityCheck.mobileCreated.split("T")[0];
    this.siteQualityCheck.mobileCreatedTime = this.siteQualityCheck.mobileCreated.match(/([A-Z]?[^A-Z]*)/g)[1]
      .slice(1, -1).slice(0, 5);

    this.createForm(this.fields, this.siteQualityCheck).then((form) => {
      this.form = form;
      this.loaded = true;

      if (form.controls["site"].value) {
        this.getTasks(form);
      }

      this.form.controls["site"].valueChanges.subscribe((v) => {
        if (v) {
          this.getTasks(form);
        }
      });
    });
  }

  getTasks(form) {
    this.remoteService.getBy("site", "standardTasks/id", form.controls["site"].value.id)
      .subscribe((response) => {
        this.hasTasks = true;
        if (response && response.length > 0) {
          this.siteQualityCheck.checks = response;
          for (let i = 0; i < this.siteQualityCheck.checks.length; i++) {
            this.siteQualityCheck.checks[i].task = {
              id: this.siteQualityCheck.checks[i].id
            }
          }
        } else {
          this.hasTasks = false;

          this.baseService.openSnackBar(`Site has no Tasks Associated. Please Add at least one Task
              to continue.`, null, true);
        }
      })
  }

  startSiteQualityCheck() {
    this.page = 'siteQualityCheck';
    this.title = `${this.form.controls["site"].value.name} Site Quality Check`;
    this.loaded = true;
  }

  close(form) {
    this.closeModal(form, this.dialogRef);
  }

  siteQualityCheckComplete(checks) {
    this.prepChecks(checks).then(() => {
      this.siteQualityCheck.taskQualityCheckRecords = checks;
      if (this.authService.securityObject.sqcPhotoRequirement) {
        for (let i = 0; i < checks.length; i++) {
          if (checks[i] && checks[i].Rating && checks[i].Rating.id
            > parseInt(this.authService.securityObject.sqcPhotoRequirement) && checks[i].Rating.id < 6
            && checks[i].images == null) {
            this.matDialog.open(ConfirmModalComponent, {
              data: {
                type: "no-btn",
                body: [
                  `Your Site Quality Check Policy requires at least 1 Photo per Grade lower than a ${this.gradeArray[parseInt(this.authService.securityObject.sqcPhotoRequirement)]}`
                ]
              },
              panelClass: "confirm-dialog-container"
            });
            return;
          }
        }
      }

      this.page = 'adhocTasks';
    });
  }

  private save() {
    this.siteQualityCheck = {...this.siteQualityCheck, ...this.form.getRawValue()};
    this.siteQualityCheck.mobileCreated = this.siteQualityCheck.mobileCreatedDate
      ? this.formDateTime(
        this.siteQualityCheck.mobileCreatedDate,
        this.siteQualityCheck.mobileCreatedTime
      )
      : null;

    this.remoteService.add("siteQualityCheckRecord", null, {
      site: {id: this.form.controls["site"].value.id},
      isClientCheck: this.isClient,
      taskQualityCheckRecords: this.siteQualityCheck.taskQualityCheckRecords,
      created: this.siteQualityCheck.mobileCreated
    }).subscribe(() => {
      this.baseService.openSnackBar("Site Quality Check Recorded Successfully");
      this.dialogRef.close();
    }, ((error) => {
      this.baseService.openSnackBar(`Error - Unable to save Site Quality Check Record (${error.message})`,
        null, true);
    }))
  }

  private prepChecks(checks) {
    return new Promise<void>((resolve) => {
      for (let i = 0; i < checks.length; i++) {
        checks[i].Rating = checks[i].grade;
        checks[i].Task = {id: checks[i].id};
        delete checks[i].id;
      }
      resolve();
    })
  }

  protected readonly Check = Check;
}
