<div
  *ngIf="images?.length > 0; else camera"
  id="carouselIndicators"
  class="carousel slide w-100"
  data-ride="carousel"
>
  <div class="carousel-inner">
    <div *ngFor="let image of images | reverseSort: 'created'; let i = index">
      <div class="carousel-item" [class]="i == activeId ? 'active' : ''">
        <img

          [src]="image.source"
          [alt]="image.name ? image.name : 'Photo'"
        />
      </div>
    </div>
  </div>
  <a class="carousel-control-prev" *ngIf="images && images.length > 1" role="button" (click)="prev()">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="sr-only">Previous</span>
  </a>
  <a class="carousel-control-next" *ngIf="images && images.length > 1" role="button" (click)="next()">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="sr-only">Next</span>
  </a>
</div>
<ng-template #camera>
  <div class="loading h-100 d-flex">
    <div class="align-self-center mx-auto">
      <i class="fas fa-image-polaroid fa-3x"></i>
      <p>No Photos</p>
      <button
        mat-button
        class="border-p primary"
        *ngIf="showButton"
        (click)="goToPhotos.emit()"
      >
        Add Photos
      </button>
    </div>
  </div>
</ng-template>
