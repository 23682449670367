import { Pipe, PipeTransform } from "@angular/core";
@Pipe({ name: "reverseSort" })
export class ReverseSortPipe implements PipeTransform {
  transform(array, field) {
    if (!Array.isArray(array)) {
      return;
    }
    if (!field) {
      array.sort((a, b) => {
        if (a < b)
          return 1;
        if (a > b)
          return -1;
        return 0;
      });
      return array;
    }
    if (Array.isArray(field)) {
      array.sort((a, b) => {
        if (a[field[0]] < b[field[0]])
          return 1;
        if (a[field[0]] > b[field[0]])
          return -1;
        if (a[field[1]] < b[field[1]])
          return 1;
        if (a[field[1]] > b[field[1]])
          return -1;
        return 0;
      });
      return array;
    } else {
      array.sort((a, b) => {
        if (a[field] < b[field])
          return 1;
        if (a[field] > b[field])
          return -1;
        return 0;
      });
      return array;
    }
  }
}
