import {ModalType} from "../enums/modal-fields.enum";
import {IEditFormField} from "../components/edit-form/edit-form-model";
import {MetaDataTypes} from "../enums/meta-data-types.enum";

interface IModalRequest {
  // Used if the entity is existing.
  id?: string;
  // The context of the calling component or modal
  requestContext: any;
  // Enumerator which helps specify what fields and controller is used.
  modalType: ModalType;
  // Whether you want the Modal Service to save the entity or not
  autoSave?: boolean;
  // This is the hook/function which is called after the modal is saved, it is provided with the returned entity
  // (provided by a get by id) - so can be used to populate grids etc.
  afterSave?: Function;
  // For the creation of entities that should have properties from the Start - i.e. If we right click a Site
  // to add a Task, we can pass that Site Id in the payload.
  payload?: any;
}

export class ModalRequest {
  id?: string = null;
  requestContext: any; //this
  modalType: ModalType;
  autoSave?: boolean = false;
  afterSave?: Function;
  payload?: any;

  public constructor(obj: IModalRequest) {
    Object.assign(this, obj);
  }
}
