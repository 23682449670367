import { EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Component } from "@angular/core";
import { Lightbox, LightboxConfig } from "ngx-lightbox";
import { BaseComponent } from "../../base/base.component";
import { ImageService } from "../../services/image/image.service";
import { VideoModalComponent } from "../../../modals/video-modal/video-modal.component";
import { Observable } from "rxjs";
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: "app-photos",
  templateUrl: "./photos.component.html",
  styles: []
})
export class PhotosComponent extends BaseComponent implements OnInit {
  @Input()
  imageArrays = [];
  @Input()
  addPhotos = true;
  @Input()
  canDelete = true;
  @Input()
  arrayNames = [];
  @Input()
  entity?: {
    id: string;
    type: string;
  };
  @Input()
  isNew: boolean = false;
  @Output()
  addedImage = new EventEmitter();
  @Output()
  removedImage = new EventEmitter();
  @Output()
  newId = new EventEmitter();

  loaded = false;

  constructor(private lightBox: Lightbox,
    private imageService: ImageService,
    public modal: MatDialog,
    private _lightboxConfig: LightboxConfig) {
    super();
    _lightboxConfig.showZoom = true;
    _lightboxConfig.showDownloadButton = true;
  }

  ngOnInit() {
    for (let i = 0; i < this.imageArrays.length; i++) {
      const array = this.imageArrays[i];
      for (let i = 0; i < array.length; i++) {
        const image = array[i];
        image.src = image.source;
      }
    }
    this.loaded = true;
  }

  open(array: any[], index: number): void {

    if (array[index].src && (array[index].src.toUpperCase().endsWith("MOV") || array[index].src.toUpperCase().endsWith("MP4") || array[index].src.toUpperCase().endsWith("AVI"))) {
      this.openVideoModal(array[index].src).subscribe();
    }
    else {
      this.lightBox.open(array, index);
    }
  }

  // close lightbox programmatically
  close(): void {
    this.lightBox.close();
  }

  save(files) {
    if (files.length === 0) {
      return;
    }

    this.loaded = false;
    for (let i = 0; i < files.length; i++) {
      const dto = <any>{};

      if (!this.isNew) {
        dto[this.entity.type + "Id"] = this.entity.id.toString();
      } else {
        dto.id = this.newGuid();
      }

      const formData = new FormData();

      formData.append(files[i].name, files[i]);
      formData.set("imageDto", JSON.stringify(dto));

      this.imageService.addImage(formData).subscribe(
        (response: any) => {
          const image = response;
          files.length == i + 1 ? (this.loaded = true) : "";
          this.addedImage.emit(image);
          this.newId.emit(image.id);
        },
        (error) => {
          this.openSnackBar(
            "Error - Your image could not be saved at this time. \n " +
            "If this problem persists please contact PSS for advice",
            null,
            true
          );
          files.length == i + 1 ? (this.loaded = true) : "";
        }
      );
    }
  }

  openVideoModal(url: string) {
    return Observable.create((resolve) => {

      const dialogRef = this.modal.open(VideoModalComponent, {
        panelClass: [
          this.getTheme(), "edit-dialog-container"
        ],
        backdropClass: "modalBackdrop",
        data: url,
      });
      dialogRef.afterClosed().subscribe(() => resolve.complete());

    });
  }

  errorHandler(event, i) {
    this.imageArrays[0][i].error += 1;
    this.imageArrays[0][i].isHidden = true;
    if (i.error < 5) {
      setTimeout(() => {
        event.target.src += `?${Math.random()}`;
      }, 250);
    }
  }

  delete(index) {
    this.imageService
      .deleteImage(this.imageArrays[0][index])
      .subscribe((response) => {
        this.imageArrays[0].splice(index, 1);
      });
    this.removedImage.emit();
  }

  saveRotate(index, image) {
    this.imageService.rotateImage(image).subscribe((response) => {
      this.imageService.getImageById(image.id).subscribe((secondResponse) => {
        this.imageArrays[0].splice(index, 1, secondResponse);
        //this.imageArrays[0][index].src = secondResponse.src;
        this.addedImage.emit();
      });
    });
  }

  rotateClass(image) {
    if (
      image.rotation == undefined ||
      image.rotation == 0 ||
      image.rotation == 360
    ) {
      image.rotation = 0;
      return null;
    } else {
    }
    return `r${image.rotation}`;
  }

  changeThumbJpg(url) {
    if (url) {
      let newFormat =
        // url.includes("png")
        // ? url.replace("png", "jpg")
        //:
        url.includes("jpeg")
          ? url.replace("jpeg", "jpg")
          : url;
      return newFormat;
    }
  }
}
