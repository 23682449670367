import {Injectable, OnDestroy} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {BehaviorSubject, Observable, ReplaySubject, Subject} from "rxjs";
import {Section} from "../../classes/section.object";
import {ConfirmModalComponent} from "../../components/modals/confirm/confirm-modal.component";
import {RemoteService} from "../remote/remote.service";
import {BaseService} from "../../base/base.service";
import {IGridColumn} from "../../grid/grid-model";
import {
  FilteringLogic,
  FilteringStrategy,
  IForOfState,
  IgxStringFilteringOperand,
  SortingDirection
} from "@infragistics/igniteui-angular";
import {HttpClient} from "@angular/common/http";
import {FilteringExpressionsTree, GridPagingMode, NoopFilteringStrategy} from "igniteui-angular";
import {AppConfigurationService} from "../app-config/app-configuration.service";
import {debounceTime, takeUntil} from "rxjs/operators";
import {BaseFilteringStrategy} from "@infragistics/igniteui-angular/lib/data-operations/filtering-strategy";

const EMPTY_STRING = '';
const NULL_VALUE = null;

// eslint-disable-next-line no-shadow
export enum FILTER_OPERATION {
  CONTAINS = 'zcontains',
  STARTS_WITH = 'zstartswith',
  ENDS_WITH = 'zendswith',
  EQUALS = 'zeq',
  DOES_NOT_EQUAL = 'zne',
  DOES_NOT_CONTAIN = 'znotcontain',
  GREATER_THAN = 'zgt',
  LESS_THAN = 'zlt',
  LESS_THAN_EQUAL = 'zle',
  GREATER_THAN_EQUAL = 'zge',
  EMPTY = 'zempty',
  NOT_EMPTY = 'znempty',
  DATE_LESS_THAN = 'zdlt',
  DATE_GREATER_THAN = 'zdgt',
  DATE_EQUAL = 'zdeq',
  DATE_BETWEEN = 'zdbt',
  DATE_EMPTY = 'zde',
  DATE_NOT_EMPTY = 'zdne'
}

@Injectable({
  providedIn: "root"
})
export class SectionService implements OnDestroy {
  private remoteData: BehaviorSubject<any[]>;
  private chunkSize: number;
  private prevRequest: any;
  private destroy$ = new Subject<void>;
  private lengthOf: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private remoteService: RemoteService,
    private baseService: BaseService,
    private httpClient: HttpClient,
    private readonly configService: AppConfigurationService
  ) {
    this.remoteData = new BehaviorSubject([]);
  }

  onSectionChange = new Subject<any>();
  DEBOUNCE_TIME = 450;
  loadedController = "";

  subscribeToView(
    context,
    gridComponent,
    view,
    section,
    storageName,
    sortColumn,
    setContextFn,
    specialContext?,
    paramViewId?,
    filterArea?,
    sortDesc?,
  ) {
    this.clearDestroy$();

    return this.activatedRoute.paramMap.subscribe(() => {
      this.remoteService.getViews(view).subscribe((response) => {
        section.views = response;
        this.remoteService.getColumns(view).subscribe((columnResponse: IGridColumn[]) => {
          this.getGridColumns(gridComponent.gridName).then((stateColumns) => {
            let columns: IGridColumn[] = JSON.parse(JSON.stringify(gridComponent.columns));
            for (let i = 0; i < columnResponse.length; i++) {
              columnResponse[i].sortable = true;
              columnResponse[i].filterable = true;
              columnResponse[i].movable = false;
              columnResponse[i].pinned = false;
              columnResponse[i].hidden = true;
              if (stateColumns) {
                const match = stateColumns.find((f) => f.header == columnResponse[i].header);
                if (match) {
                  columnResponse[i].pinned = match.pinned;
                  columnResponse[i].hidden = match.hidden;
                  columnResponse[i].width = match.width;
                }
                if (!columnResponse[i].hidden && !section.selectedColumns
                  .find(f => f.header == columnResponse[i].header)) {
                  section.selectedColumns.push(columnResponse[i]);
                }
              }

              if (!columns.find((f) => f.header == columnResponse[i].header)) {
                columns.push(columnResponse[i]);
              }
            }
            gridComponent.columns = columns;

            // Fix for Section defaulting to its pre-set.
            for (let i = 0; i < section.selectedColumns.length; i++) {
              const match = gridComponent.grid.columns.find((f) => f.header == section.selectedColumns[i].header);
              if (match) {
                section.selectedColumns[i].hidden = match.hidden;
              }
            }

            if (paramViewId) {
              section.selectedView = section.views.find(
                (v) => v.viewId == paramViewId,
                section
              );
              this.getData(section.selectedView, section, gridComponent).then(() => {
                setContextFn(context, !!specialContext);
                section.lastUpdate = this.baseService.newIsoDate();
                context.loading = false;
                if (filterArea) {
                  gridComponent.grid.filter("areaName", filterArea, IgxStringFilteringOperand.instance()
                    .condition("contains"), true);
                }
              });

            } else {
              let l = this.baseService.getViewFromStorage(storageName);
              if (l
                && storageName != "taskRecord"
                && storageName != "inspectionRecord"
                && storageName != "programSiteRecord") {
                section.selectedView = section.views.find((v) => v.viewId == l.viewId);
              }

              this.getData(
                l ? section.selectedView : section.views[0],
                section, gridComponent
              ).then(() => {
                setContextFn(context, !!specialContext);
                context.loading = false;
                if (filterArea) {
                  gridComponent.grid.filter("areaName", filterArea, IgxStringFilteringOperand.instance()
                    .condition("contains"), true);
                }
              });

              if (!l) {
                gridComponent.grid.sort({
                  fieldName: sortColumn,
                  dir: sortDesc ? SortingDirection.Desc : SortingDirection.Asc,
                  ignoreCase: true
                });
              }
            }
          });
        });
      });
    });
  }

  getGridColumns(gridName: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const actionKey = "columns" + "-" + gridName;
      const item = JSON.parse(window.localStorage.getItem(actionKey) ?? "{}");
      resolve(item ? item["columns"] : null);
    });
  }

  getData(view, section, component) {
    return new Promise((resolve) => {
      let viewChanged = false;
      if (section.selectedView && view.viewId != section.selectedView.viewId) {
        viewChanged = true;
      }
      this.baseService.setViewToStorage(section.controller, view);
      if (view && !view.viewId) {
        resolve(false);
        return;
      }
      let selectedColumns = section.selectedColumns.reduce(
        (filtered, column) => {
          if (!column.hidden) {
            filtered.push(column.field);
          }
          return filtered;
        }, []
      );
      section.selectedView = view;
      let grid = component.grid ?? component.gridComponent.grid;

      if (section.controller == "taskRecord" || section.controller == "inspectionRecord"
        || section.controller == "inspectionCheckItemRecord"
        || section.controller == "taskSchedule") {

        if (this.loadedController == "" || this.loadedController != section.controller) {
          viewChanged = true;
          this.loadedController = section.controller;
        }

        if (viewChanged) {
          if (component.gridComponent && component.gridComponent.clearFilterSubject) {
            component.gridComponent.clearFilterSubject.next();
          }
          grid.clearFilter();
          this.clearDestroy$();
        }
        setTimeout(() => {
          grid.pagingMode = GridPagingMode.Remote;
          grid.filterStrategy = NoopFilteringStrategy.instance();
          grid.remotePaginationEnabled = true;
          const filteringExpr = grid.filteringExpressionsTree;
          const sortingExpr = grid.sortingExpressions[0];
          this.chunkSize = Math.ceil(parseInt(grid.perPage, 10));
          grid.loading = true;
          this.getCountData(section.controller, view.viewId, selectedColumns, filteringExpr)
            .subscribe((data) => {
              grid.totalRecords = data;
              this.getFilteredData(section.controller, view.viewId, selectedColumns,
                {
                  chunkSize: this.chunkSize,
                  startIndex: grid.virtualizationState.startIndex
                },
                filteringExpr,
                sortingExpr,
                (data) => {
                  section.data = data || [];
                  if (component) {
                    component.loading = false;
                  }
                  grid.loading = false;
                  if (data && data.length == 0) {
                    // note isLoading is different to loading.
                    grid.isLoading = false;
                  }
                  section.lastUpdate = this.baseService.newIsoDate();
                  resolve(true);
                });
            }, (error) => {
              grid.data = [];
              if (component) {
                component.loading = false;
              }
              grid.loading = false;
              resolve(false);
            });

          grid.filteringExpressionsTreeChange.pipe(
            debounceTime(this.DEBOUNCE_TIME),
            takeUntil(this.destroy$)
          ).subscribe(() => {
            this.processData(section.controller, view.viewId, selectedColumns, component, true);
          });
          grid.sortingExpressionsChange.pipe(
            debounceTime(this.DEBOUNCE_TIME),
            takeUntil(this.destroy$)
          ).subscribe(() => {
            this.processData(section.controller, view.viewId, selectedColumns, component);
          });
          grid.paginationComponents.first.pagingDone.pipe(
            debounceTime(this.DEBOUNCE_TIME),
            takeUntil(this.destroy$)
          ).subscribe((value) => {
            grid.virtualizationState.startIndex = value.current * this.chunkSize;
            this.processData(section.controller, view.viewId, selectedColumns, component);
          });
          grid.paginationComponents.first.perPageChange.pipe(
            debounceTime(this.DEBOUNCE_TIME),
            takeUntil(this.destroy$)
          ).subscribe((value) => {
            this.chunkSize = Math.ceil(parseInt(value, 10));
            this.processData(section.controller, view.viewId, selectedColumns, component);
          });
        }, 1000)
      } else {
        this.loadedController = "";
        this.clearDestroy$();
        grid.pagingMode = GridPagingMode.Local;
        if (grid.filterStrategy == NoopFilteringStrategy.instance()) {
          grid.clearFilter();
          grid.filterStrategy = new FilteringStrategy();
        }
        grid.remotePaginationEnabled = false;
        this.remoteService
          .getColumnData(section.controller, view.viewId, selectedColumns)
          .subscribe((response) => {
            section.data = response || [];
            if (component) {
              component.loading = false;
              grid.loading = false;
              if (response && response.length == 0) {
                // note isLoading is different to loading.
                grid.isLoading = false;
              }
            }
            section.lastUpdate = this.baseService.newIsoDate();
            resolve(true);
          });
      }
    });
  }

  private clearDestroy$(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.destroy$.unsubscribe();
    this.destroy$ = new ReplaySubject<void>();
  }

  public getCountData(controller, parameter, columns, filteringArgs?: any, sortingArgs?: any): Observable<any> {
    const payload = {Columns: columns};
    parameter = "count/" + parameter;
    return this.httpClient.post(this.buildDataUrl(controller, parameter, null, filteringArgs, sortingArgs),
      payload,
      {
        withCredentials: true
      });
  }

  public getFilteredData(controller, parameter, columns, virtualizationArgs?: IForOfState, filteringArgs?: any,
                         sortingArgs?: any, gridLoadingQuery?: (any) => void): any {
    const payload = {Columns: columns};
    return this.httpClient.post(this.buildDataUrl(controller, parameter, virtualizationArgs, filteringArgs, sortingArgs),
      payload,
      {
        withCredentials: true
      }).subscribe((data: any) => {
      this.remoteData.next(data.value);
      if (gridLoadingQuery) {
        gridLoadingQuery(data);
      }
    }, error => {
      this.remoteData.next([]);
    });
  }

  private buildDataUrl(controller, parameter, virtualizationArgs: any, filteringArgs: any, sortingArgs: any): string {
    let baseQueryString = `${this.configService.apiAddress}/${controller}/${parameter}?`;
    let scrollingQuery = EMPTY_STRING;
    let orderQuery = EMPTY_STRING;
    let filterQuery = EMPTY_STRING;
    let query = EMPTY_STRING;
    let filter = EMPTY_STRING;

    if (sortingArgs) {
      orderQuery = this.buildSortExpression(sortingArgs);
    }

    if (filteringArgs && filteringArgs.length > 0) {
      filteringArgs.forEach((columnFilter) => {
        if (filter !== EMPTY_STRING) {
          filter += ` ${FilteringLogic[columnFilter.operator].toLowerCase()} `;
        }

        filter += this.buildAdvancedFilterExpression(
          columnFilter.filteringOperands,
          columnFilter.operator);
      });

      filterQuery = `filter=${filter}`;
    }

    if (virtualizationArgs) {
      scrollingQuery = this.buildScrollExpression(virtualizationArgs);
    }
    query += (orderQuery !== EMPTY_STRING) ? `&${orderQuery}` : EMPTY_STRING;
    query += (filterQuery !== EMPTY_STRING) ? `&${filterQuery}` : EMPTY_STRING;
    query += (scrollingQuery !== EMPTY_STRING) ? `&${scrollingQuery}` : EMPTY_STRING;

    baseQueryString += query;

    return baseQueryString;
  }

  private buildAdvancedFilterExpression(operands, operator): string {
    let filterExpression = EMPTY_STRING;
    operands.forEach((operand, index) => {
      if (operand instanceof FilteringExpressionsTree) {
        if (index > 0) {
          filterExpression += ` ${FilteringLogic[operator].toLowerCase()} `;
        }
        filterExpression += this.buildAdvancedFilterExpression(
          operand.filteringOperands,
          operand.operator
        );
        return filterExpression;
      }
      const value = operand.searchVal;
      const isNumberValue = (typeof (value) === 'number') ? true : false;
      const filterValue = (isNumberValue) ? value : `'${value}'`;
      const fieldName = operand.fieldName;
      let filterString;

      if (filterExpression !== EMPTY_STRING) {
        filterExpression += ` ${FilteringLogic[operator].toLowerCase()} `;
      }

      switch (operand.condition.name) {
        case 'contains': {
          filterString = `${FILTER_OPERATION.CONTAINS}(${fieldName}, ${filterValue})`;
          break;
        }
        case 'startsWith': {
          filterString = `${FILTER_OPERATION.STARTS_WITH}(${fieldName}, ${filterValue})`;
          break;
        }
        case 'endsWith': {
          filterString = `${FILTER_OPERATION.ENDS_WITH}(${fieldName}, ${filterValue})`;
          break;
        }
        case 'equals': {
          filterString = `${fieldName} ${FILTER_OPERATION.EQUALS} ${filterValue} `;
          break;
        }
        case 'doesNotEqual': {
          filterString = `${fieldName} ${FILTER_OPERATION.DOES_NOT_EQUAL} ${filterValue} `;
          break;
        }
        case 'doesNotContain': {
          filterString = `${FILTER_OPERATION.DOES_NOT_CONTAIN}(${fieldName}, ${filterValue})`;
          break;
        }
        case 'greaterThan': {
          filterString = `${fieldName} ${FILTER_OPERATION.GREATER_THAN} ${filterValue} `;
          break;
        }
        case 'greaterThanOrEqualTo': {
          filterString = `${fieldName} ${FILTER_OPERATION.GREATER_THAN_EQUAL} ${filterValue} `;
          break;
        }
        case 'lessThan': {
          filterString = `${fieldName} ${FILTER_OPERATION.LESS_THAN} ${filterValue} `;
          break;
        }
        case 'lessThanOrEqualTo': {
          filterString = `${fieldName} ${FILTER_OPERATION.LESS_THAN_EQUAL} ${filterValue} `;
          break;
        }
        case 'empty': {
          filterString = `length(${fieldName}) ${FILTER_OPERATION.EMPTY}`;
          break;
        }
        case 'notEmpty': {
          filterString = `length(${fieldName}) ${FILTER_OPERATION.NOT_EMPTY}`;
          break;
        }
        case 'null': {
          filterString = `${fieldName} ${FILTER_OPERATION.EQUALS} ${NULL_VALUE}`;
          break;
        }
        case 'notNull': {
          filterString = `${fieldName} ${FILTER_OPERATION.DOES_NOT_EQUAL} ${NULL_VALUE}`;
          break;
        }
        case 'customFrom': {
          // Filter Value needs to be parsed to YYYY-MM-dd format (without time)
          let newFilterValue = new Date(filterValue).toISOString();
          newFilterValue = newFilterValue.split('T')[0];

          filterString = `${fieldName} ${FILTER_OPERATION.DATE_GREATER_THAN} ${newFilterValue}`;
          break;
        }
        case 'customTo': {
          // Filter Value needs to be parsed to dd/MM/yyyy format (without time)
          let newFilterValue = new Date(filterValue).toISOString();
          newFilterValue = newFilterValue.split('T')[0];
          filterString = `${fieldName} ${FILTER_OPERATION.DATE_LESS_THAN} ${newFilterValue}`;
          break;
        }
        case 'customOn': {
          // Filter Value needs to be parsed to dd/MM/yyyy format (without time)
          let newFilterValue = new Date(filterValue).toISOString();
          newFilterValue = newFilterValue.split('T')[0];
          filterString = `${fieldName} ${FILTER_OPERATION.DATE_EQUAL} ${newFilterValue}`;
          break;
        }
        case 'customBetween': {
          var dates = filterValue.split(',');
          if (dates.length < 2) {
            return;
          }
          let newFilterValue = new Date(dates[0]).toISOString();
          newFilterValue = newFilterValue.split('T')[0];
          let newFilterValue2 = new Date(dates[1]).toISOString();
          newFilterValue2 = newFilterValue2.split('T')[0];
          filterString = `${fieldName} ${FILTER_OPERATION.DATE_BETWEEN} ${newFilterValue}PLUS${newFilterValue2}`;
          break;
        }
        case 'customEmpty' : {
          filterString = `${fieldName} ${FILTER_OPERATION.DATE_EMPTY}`;
          break;
        }
        case 'customNotEmpty' : {
          filterString = `${fieldName} ${FILTER_OPERATION.DATE_NOT_EMPTY}`;
          break;
        }
      }
      filterExpression += filterString;
    });
    return filterExpression;
  }

  private buildSortExpression(sortingArgs): string {
    let sortingDirection: string;
    switch (sortingArgs.dir) {
      case SortingDirection.None: {
        sortingDirection = EMPTY_STRING;
        break;
      }
      default: {
        sortingDirection = SortingDirection[sortingArgs.dir].toLowerCase();
        break;
      }
    }

    return `orderby=${sortingArgs.fieldName} ${sortingDirection}`;
  }

  private buildScrollExpression(virtualizationArgs): string {
    let requiredChunkSize: number;
    const skip = virtualizationArgs.startIndex;
    requiredChunkSize = virtualizationArgs.chunkSize === 0 ? 11 : virtualizationArgs.chunkSize;
    const top = requiredChunkSize;
    return `skip=${skip}&top=${top}`;
  }

  public processData(controller, parameter, columns, component, isFiltering: boolean = false) {

    if (this.prevRequest) {
      this.prevRequest.unsubscribe();
    }

    let grid = component.grid ?? component.gridComponent.grid;

    if (grid && !grid.loading) {
      grid.loading = true;
      component.loading = true;
    }

    const virtualizationState = grid.virtualizationState;
    const filteringExpr = grid.filteringExpressionsTree.filteringOperands;
    const sortingExpr = grid.sortingExpressions[0];

    this.getCountData(controller, parameter, columns, filteringExpr)
      .subscribe((data) => {
        grid.totalRecords = data;
        this.prevRequest = this.getFilteredData(controller, parameter, columns,
          {
            chunkSize: this.chunkSize,
            startIndex: virtualizationState.startIndex
          },
          filteringExpr,
          sortingExpr,
          (data) => {
            grid.data = data;
            component.loading = false;
            grid.loading = false;
          });
      }, (error) => {
        grid.data = [];
        component.loading = false;
        grid.loading = false;
      });
  }

  ngOnDestroy() {
    if (this.prevRequest) {
      this.prevRequest.unsubscribe();
    }
    this.clearDestroy$();
  }

  archiveEntity(cell, context, specificMsg?) {
    return new Promise<void>((resolve) => {
      const multiple = cell.grid.selectionService.rowSelection.size > 1;
      const process = (archiving) => {
        return new Promise<void>((innerRes) => {
          const arr: { id: any }[] = [];
          cell.grid.selectionService.rowSelection.forEach((c) => arr.push(c));
          if (arr[arr.length - 1] == undefined) {
            arr.pop();
          }
          for (let i = 0; i < arr.length; i++) {
            context.sectionService.archive(context, arr[i]).then(
              () => {
                if (i + 1 === arr.length) {
                  context.openSnackBar(
                    `${multiple ? context.current.plural : context.current.singular}
                  ${!archiving ? " removed from Archive" : " Archived"}`,
                    null,
                    false
                  );

                  cell.grid.deselectAllRows(false);
                }
                if (arr.length - 1 == i) {
                  innerRes();
                }
              },
              (rej) => {
                context.openSnackBar(
                  `Error - ${!archiving ? "Cannot Update " : "Cannot Archive "} ${multiple ? context.current.plural : context.current.singular
                  } : ${rej.error ? rej.error : rej.message}`,
                  null,
                  true
                );
                if (arr.length - 1 == i) {
                  innerRes();
                }
              }
            );
          }
        });
      }

      if (context.current.selectedView.name.includes("Archived")) {
        process(false).then(() => resolve());
      } else {
        const dialogRef = context.matDialog.open(ConfirmModalComponent, {
          data: {
            type: "confirmSave",
            body: [
              specificMsg
                ? specificMsg
                : `Archived ${multiple ? context.current.plural : context.current.singular
                }  will be listed under the Archived View only`,
              `Do you wish to proceed?`
            ]
          },
          panelClass: "confirm-dialog-container"
        });
        dialogRef.afterClosed().subscribe((result) => {
          if (result) {
            process(true).then(() => resolve());
          }
        });
      }
    });
  }

  archive(context, id) {
    return new Promise((resolve, reject) =>
      context.remoteService.archive(context.current.controller, id).subscribe(
        () => {
          let currentData = context.current.data;
          let index = currentData.findIndex((el) => el.id === id);
          context.current.data.splice(index, 1);
          //Trigger change detection for grid data
          context.gridComponent.grid.data = [...currentData];
          resolve(true);
        },
        (errorResponse) => {
          reject(errorResponse.message);
        }
      )
    );
  }

  deleteEntity(cell, context) {
    return new Promise<void>((resolve) => {
      const multiple = cell.grid.selectionService.rowSelection.size > 1;
      const process = () => {
        return new Promise<void>((innerRes) => {
          const arr: { id: any }[] = [];
          cell.grid.selectionService.rowSelection.forEach((c) => arr.push(c));
          if (arr[arr.length - 1] == undefined) {
            arr.pop();
          }
          for (let i = 0; i < arr.length; i++) {
            context.sectionService.delete(context, arr[i]).then(
              (res) => {
                context.openSnackBar(
                  `${multiple ? context.current.plural : context.current.singular
                  } Deleted`,
                  null,
                  false
                );
                if (arr.length - 1 == i) {
                  innerRes();
                }
              },
              (rej) => {
                context.openSnackBar(
                  `Error - Cannot Delete ${multiple ? context.current.plural : context.current.singular
                  }: ${rej.error ? rej.error : rej.message}`,
                  null,
                  true
                );
                if (arr.length - 1 == i) {
                  innerRes();
                }
              }
            );
          }
        });
      };
      const dialogRef = context.matDialog.open(ConfirmModalComponent, {
        data: {
          type: "confirmSave",
          body: [
            `Deleting ${multiple
              ? `these ${context.current.plural}`
              : `this ${context.current.singular}`
            } is permanent and cannot be reversed`,
            `Do you wish to proceed?`
          ]
        },
        panelClass: "confirm-dialog-container"
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          process().then(() => resolve());
        }
      });
    });
  }

  delete(context, id) {
    return new Promise((resolve, reject) =>
      context.remoteService.delete(context.current.controller, id).subscribe(
        () => {
          let currentData = context.current.data;
          let index = currentData.findIndex((el) => el.id === id);
          currentData.splice(index, 1);
          //Trigger change detection for grid data
          context.gridComponent.grid.data = [...currentData];
          resolve(true);
        },
        (errorResponse) => {
          reject(errorResponse);
        }
      )
    );
  }

  syncData(current: Section, updateGrid, context?) {
    if (current.lastUpdate) {
      this.remoteService
        .getChangesAfterDate(
          current.controller,
          current.selectedView.viewId,
          current.lastUpdate,
          current.selectedColumns.map((c) => c.field)
        )
        .subscribe((res) => {
          res.forEach((row) => {
            let foundIndex = current.data.findIndex((r) => r.id === row.id);
            if (foundIndex !== -1) {
              if (row.toRemove) {
                current.data.splice(foundIndex, 1);
              } else {
                current.data[foundIndex] = row;
              }
            } else {
              current.data.unshift(row);
            }
            current.lastUpdate = this.baseService.newIsoDate();
            updateGrid;
          });
        });
    }
  }
}
