import {
  AfterViewInit,
  Component,
  EventEmitter,
  Inject,
  Output
} from "@angular/core";
import {MatDialogRef, MAT_DIALOG_DATA} from "@angular/material/dialog";
import {Claims} from "src/app/auth/models/claims";
import {BaseModalComponent} from "src/app/shared/base/base-modal.component";
import {ModalRequest} from "../../shared/classes/modal.request";
import {ModalType} from "../../shared/enums/modal-fields.enum";

@Component({
  selector: "app-task-record-modal",
  templateUrl: "./task-record-modal.component.html"
})
export class TaskRecordModalComponent
  extends BaseModalComponent
  implements AfterViewInit {
  @Output()
  onSave = new EventEmitter();

  taskRecord = <any>{};
  hasClaim = false;
  allNames = ["Task Record Photos", "Task Record Videos"];

  getNav = () => [
    {name: "Record Details", page: "details"},
    {name: "Photos", page: "photos"},
    {name: "Task Check Record", page: "taskCheckRecord", hidden: !this.data.data.taskCheckRecordId},
    {name: "Map", page: "map"},
    {name: "History", page: "auditTrail", hidden: this.isNew}
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<Component>
  ) {
    super();
    this.loaded = false;
    this.isNew = !data.data.id;
    this.nav = this.getNav();
    this.current = this.nav[0];
    this.fields = data.fields;
    this.metaData = data.metaData;
    this.taskRecord = data.data;


    if (this.isNew) {
      this.metaData.taskRecordStatuses =
        this.metaData.taskRecordStatuses.filter((c) => c.id < 3);
    }
    else{
      this.allNames = ["Task Record Photos", "Task Record Videos"];
      this.allImages = [this.taskRecord.images, this.taskRecord.videos]

    }

    const process = function (context) {
      context.createForm(context.fields, context.taskRecord).then((form) => {
        context.form = form;
        if (context.isNew) {
          context.form.controls["createdByName"].setValue(
            context.authService.securityObject.name
          );
        } else {
          context.form.controls["taskRecordStatus"].disable();
          context.form.controls["program"].disable();
        }
        context.form.controls["taskName"].disable();
        context.form.controls["createdByName"].disable();

        if (!context.form.controls["mobileCreatedDate"].value) {
          context.form.controls["mobileCreatedDate"].disable();
          context.form.controls["mobileCreatedTime"].disable();
        }

        context.loaded = true;
        //Check Claims
        !context.authService.hasClaim(Claims.taskEdit)
          ? context.form.disable()
          : context.hasClaim = true;
      });


      setTimeout(() => context.form.markAllAsTouched(), 5000);
    };

    if (this.isNew) {
      this.taskRecord.mobileCreated = this.newIsoDate();
      this.taskRecord.id = this.newGuid();
    }

    if (this.taskRecord.mobileCreated) {
      this.taskRecord.mobileCreatedDate = this.taskRecord.mobileCreated.split("T")[0];
      this.taskRecord.mobileCreatedTime = this.taskRecord.mobileCreated.match(/([A-Z]?[^A-Z]*)/g)[1]
        .slice(1, -1).slice(0, 5);
    }

    process(this);
    setTimeout(() => this.form.markAllAsTouched(), 5000);
  }

  ngAfterViewInit() {
    this.taskRecord.parsedLatitude =
      this.taskRecord.latitude !== null
        ? parseFloat(this.taskRecord.latitude)
        : null;
    this.taskRecord.parsedLongitude =
      this.taskRecord.longitude !== null
        ? parseFloat(this.taskRecord.longitude)
        : null;
  }

  openTaskCheckRecord() {
    this.openModal.next(
      new ModalRequest({
        id: this.taskRecord.taskCheckRecordId,
        requestContext: this,
        modalType: ModalType.TaskCheckRecord,
        autoSave: true,
      }));
  }
  changeSection(item) {
    if (item.page === "auditTrail") {
      let parameter = item.page !== "auditTrail" ? "id" : null;
      if (!this.taskRecord[item.page]) {
        this.loaded = false;
        this.remoteService
          .getBy(`taskRecord/${item.page}`, parameter, this.data.data.id)
          .subscribe(
            (res) => {
              this.taskRecord[item.page] = res;
              this.loaded = true;
            },
            () => {
              this.loaded = true;
            }
          );
      }
    }
    this.current = item;
  }

  checkImages($event) {
    if (!this.taskRecord.images) {
      this.taskRecord.images = [];
    }
    this.taskRecord.images.push($event);
    this.form.markAsDirty();
  }

  trackImage($event) {
    this.taskRecord.images.push({
      id: $event
    });
  }

  save() {
    this.toggleSave();
    this.taskRecord = {...this.taskRecord, ...this.form.getRawValue()};
    this.taskRecord.mobileCreated = this.taskRecord.mobileCreatedDate
      ? this.formDateTime(
        this.taskRecord.mobileCreatedDate,
        this.taskRecord.mobileCreatedTime
      )
      : null;
    this.onSave.emit(this.taskRecord);
  }

  close(form) {
    this.closeModal(form, this.dialogRef);
  }
}
