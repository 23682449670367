import { GridColumnDataType } from "@infragistics/igniteui-angular";
import { IGridColumn } from "../../shared/grid/grid-model";

export const trainingRecordColumns: IGridColumn[] = [
  {
    field: "courseName",
    header: "Course Name",
    dataType: GridColumnDataType.String
  },
  {
    field: "courseDescription",
    header: "Course Description",
    dataType: GridColumnDataType.String,
    hidden: true
  },
  {
    field: "trainerName",
    header: "Trainer Name",
    dataType: GridColumnDataType.String
  },
  {
    field: "completedDate",
    header: "Completed Date",
    dataType: GridColumnDataType.Date
  },
  {
    field: "validUntilDate",
    header: "Valid Until Date",
    dataType: GridColumnDataType.Date
  }
];

export const trainingRequirementColumns: IGridColumn[] = [
  {
    field: "courseName",
    header: "Course Name",
    dataType: GridColumnDataType.String
  },
  {
    field: "courseDescription",
    header: "Course Description",
    dataType: GridColumnDataType.String,
    hidden: true
  },
  {
    field: "mustCompleteByDate",
    header: "Must Be Complete By Date",
    dataType: GridColumnDataType.Date
  },
  {
    field: "completedDate",
    header: "Last Completed Date",
    dataType: GridColumnDataType.Date
  }
];

export const attendanceColumns: IGridColumn[] = [
  {
    field: "courseName",
    header: "Course Name",
    dataType: GridColumnDataType.String
  },
  {
    field: "courseDescription",
    header: "Course Description",
    dataType: GridColumnDataType.String,
    hidden: true
  },
  {
    field: "mustCompleteByDate",
    header: "Must Be Complete By Date",
    dataType: GridColumnDataType.Date
  }
];

export const userDatabaseColumns = [
  {
    name: "database",
    label: "Database",
    type: "select",
    required: true,
    metaDataType: "databases",
    large: true
  },
  {
    name: "role",
    label: "Web Role",
    type: "select",
    required: true,
    metaDataType: "webRoles",
    listenForChange: true
  },
  {
    name: "hasWebAccess",
    label: "Web Access",
    type: "bool",
    required: true,
    listenForChange: true,
  },
  {
    name: "hasMobileAccess",
    label: "Mobile Access",
    type: "bool",
    required: true,
  },
  { name: "save", label: "User Database", type: "save" },
];

