import {FieldType, IEditFormField} from "../../shared/components/edit-form/edit-form-model";

export const inspectionFields: IEditFormField[] = [
  {name: "name", label: "Inspection Name", type: FieldType.String, required: true},
  {name: "inspectionCategory", label: "Category", type: FieldType.Select, required: true},
  {name: "inspectionType", label: "Type", type: FieldType.Select, required: true},
  {name: "description", label: "Description", type: FieldType.String},
  {name: "site", label: "Site", type: FieldType.MultipleSelect},
  {name: "equipment", label: "Equipment", type: FieldType.MultipleSelect},
];
export const inspectionRecordFields: IEditFormField[] = [
  {name: "inspectionName", label: "Inspection Name", type: FieldType.String, required: true},
  {name: "siteName", label: "Site Inspected", type: FieldType.String, disabled: true},
  {name: "equipmentName", label: "Equipment Inspected", type: FieldType.String, disabled: true},
  {name: "inspectionStatus", label: "Inspection Status", type: FieldType.Select, required: true},
  {name: "createdByName", label: "Created By", type: FieldType.String, disabled: true},
  {name: "note", label: "Notes", type: FieldType.String},
  {name: "mobileCreatedDate", label: "Recorded Date", type: FieldType.Date, required: true},
  {name: "mobileCreatedTime", label: "Recorded Time", type: FieldType.Date, required: true}
];
export const inspectionCheckRecordFields: IEditFormField[] = [
  {name: "name", label: "Name", type: FieldType.String, required: true},
  {name: "inspectionName", label: "Inspection Name", type: FieldType.String, required: true},
  {name: "siteName", label: "Site", type: FieldType.String, disabled: true},
  {name: "equipmentName", label: "Equipment", type: FieldType.String, disabled: true},
  {name: "inspectionCheckItemResult", label: "Inspection Check Result", type: FieldType.Select, required: true},
  {name: "createdByName", label: "Created By", type: FieldType.String, disabled: true},
  {name: "note", label: "Notes", type: FieldType.String}
];

export const inspectionChecklistFields: IEditFormField[] = [
  {name: "name", label: "Checklist Name", type: FieldType.String, required: true, validatorPattern: "^.{1,100}$"},
  {name: "description", label: "Description", type: FieldType.LargeText},
  {name: "inspectionCheckItems", label: "Checks", type: FieldType.MultiDragNoOrder, selected: []}
];
export const inspectionCheckItemFields: IEditFormField[] = [
  {name: "name", label: "Check Name", type: FieldType.String, required: true},
  {name: "description", label: "Description", type: FieldType.String},
  {name: "allowDefer", label: "Allow Defer", type: FieldType.Bool},
  {name: "allowNa", label: "Allow NA", type: FieldType.Bool}
];
export const inspectionCategoryFields: IEditFormField[] = [
  {name: "name", label: "Category Name", type: FieldType.String, required: true},
  {name: "description", label: "Description", type: FieldType.String}
];
export const inspectionTypeFields: IEditFormField[] = [
  {name: "name", label: "Type Name", type: FieldType.String, required: true, validatorPattern: "^.{1,255}$"},
  {
    name: "type",
    label: "Inspection Category",
    type: FieldType.Select,
    metaDataType: "inspectionCategories",
    required: true
  },
  {
    name: "inspectionChecklist",
    label: "Inspection Checklist",
    type: FieldType.Select,
    metaDataType: "inspectionChecklists",
    required: true
  },
  {name: "description", label: "Description", type: FieldType.String},
  {
    name: "issueType",
    metaDataType: "issueTypes",
    label: "Issue Type for Automatic Failed Check Issues",
    type: FieldType.Select,
    blank: true,
    required: false,
  },
  {
    name: "riskRequired",
    label: "Risk Required for Failed Checks",
    type: FieldType.Bool,
    required: false,
    dependent: "issueType"
  },
  {name: "teams", label: "Teams", type: FieldType.MultipleSelect},
  {name: "isScheduled", label: "Scheduled", type: FieldType.Bool},
  {name: "days", label: "Days",  type: FieldType.MultipleSelect, metaDataType: "days" },
  {name: "timeFrameParam", label: "timeFrameParam", type: FieldType.Select},
  {name: "startDate", label: "Start Date", type:FieldType.Date},
  {name: "endDate", label: "End Date", type:FieldType.Date},
  {name: "timeFrameDays", label: "Time Frame Days", type:FieldType.Int},
  {name: "bufferDays", label: "Buffer Days", type:FieldType.Int},
  {
    name: "periods",
    label: "Periods",
    type: FieldType.MultipleSelect,
    metaDataType: "periods"
  }

];
