import {Regex} from "src/app/shared/helpers/regex-patterns";
import {FieldType, IEditFormField} from "../../shared/components/edit-form/edit-form-model";

export const sitesFields: IEditFormField[] = [
  {name: "name", label: "Site", type: FieldType.String, required: true},
  {name: "address", label: "Address", type: FieldType.String, validatorPattern: "^.{1,200}$"},
  {name: "postcode", label: "Postcode", type: FieldType.String, validatorPattern: Regex.postcode},
  {name: "siteCategory", label: "Category", type: FieldType.String, required: true},
  {name: "area", label: "Area", type: FieldType.String, required: true},
  {name: "ward", label: "Ward", type: FieldType.String, required: true},
  {name: "siteClient", label: "Client", type: FieldType.String},
  {name: "purchaseOrderNumber", label: "Purchase Order Number", type: FieldType.String},
  {name: "reference", label: "Reference", type: FieldType.String, validatorPattern: "^.{1,100}$"},
  {name: "latitude", label: "Latitude", type: FieldType.String, validatorPattern: Regex.latitude},
  {name: "longitude", label: "Longitude", type: FieldType.String, validatorPattern: Regex.longitude},
  {name: "what3Words", label: "What3Words", type: FieldType.String, disabled: true},
  {name: "measuredDistances", label: "Measured Distances", type: FieldType.String, disabled: true},
  {name: "teams", label: "Teams", type: FieldType.String},
  {name: "contacts", label: "Contacts", type: FieldType.String, validatorPattern: "^.{1,200}$"},
  {name: "healthAndSafetyInfo", label: "Health & Safety Info", type: FieldType.String, validatorPattern: "^.{1,200}$"},
  {name: "siteInfo", label: "Site Information", type: FieldType.String},
  {name: "hardSurface", label: "Hard Surface", type: FieldType.Decimal},
  {name: "softSurface", label: "Soft Surface", type: FieldType.Decimal},
  {name: "description", label: "Description", type: FieldType.LargeText},
  {name: "notes", label: "Notes", type: FieldType.String},
  {name: "isScheduled", label: "Scheduled", type: FieldType.Bool},
  {name: "days", label: "Days", type: FieldType.MultipleSelect, metaDataType: "days"},
  {name: "timeFrameParam", label: "timeFrameParam", type: FieldType.Select},
  {name: "startDate", label: "Start Date", type: FieldType.Date},
  {name: "endDate", label: "End Date", type: FieldType.Date},
  {name: "timeFrameDays", label: "Time Frame Days", type: FieldType.Int},
  {name: "bufferDays", label: "Buffer Days", type: FieldType.Int},
  {
    name: "periods",
    label: "Periods",
    type: FieldType.MultipleSelect,
    metaDataType: "periods"
  }
];
export const siteClientFields: IEditFormField[] = [
  {name: "name", label: "Site Client Name", type: FieldType.String, required: true},
  {name: "description", label: "Description", type: FieldType.LargeText},
  {name: "people", label: "People", type: FieldType.MultipleSelect},
  {name: "sites", label: "Sites", type: FieldType.MultipleSelect, metaDataType: "availableClientSites"}
];
export const siteCategoryFields: IEditFormField[] = [
  {name: "name", label: "Category Name", type: FieldType.String, required: true},
  {name: "description", label: "Description", type: FieldType.LargeText}
];
export const wardFields: IEditFormField[] = [
  {name: "name", label: "Ward Name", type: FieldType.String, required: true},
  {name: "area", label: "Area", type: FieldType.Select, required: true, metaDataType: "areas"},
  {name: "description", label: "Description", type: FieldType.LargeText}
];
export const areaFields: IEditFormField[] = [
  {name: "name", label: "Area Name", type: FieldType.String, required: true},
  {name: "description", label: "Description", type: FieldType.LargeText}
];
export const siteQualityCheckRecordFields: IEditFormField[] = [
  {name: "siteName", label: "Site", type: FieldType.String, required: true},
  {name: "note", label: "Notes", type: FieldType.String},
  {
    name: "createdByName",
    label: "Created By",
    type: FieldType.String,
    disabled: true
  },
  {name: "note", label: "Notes", type: FieldType.String},
  {
    name: "mobileCreatedDate",
    label: "Recorded Date",
    type: FieldType.Date,
    required: true
  },
  {
    name: "mobileCreatedTime",
    label: "Recorded Time",
    type: FieldType.Date,
    required: true
  }
]
