<div class="login-outer">
  <div class="login-middle">
    <div class="login-inner card h-auto" *ngIf="showLogin; else loading">
      <div class="card_header">
        <img
          class="mt-3 col-6 offset-3"
          src="https://pssu.azureedge.net/assets/WhiteLogo.png"
        />
      </div>
      <div class="offset-sm-2 col-sm-8">
        <form [formGroup]="loginForm">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Email</mat-label>
            <input
              matInput
              type="email"
              name="email"
              email
              formControlName="email"
              autocomplete="on"
            />
          </mat-form-field>
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Password</mat-label>
            <input
              matInput
              type="{{ showPwd ? 'text' : 'password' }}"
              name="password"
              formControlName="password"
            />
            <mat-icon matSuffix (click)="showPwd = !showPwd">{{
              showPwd ? "visibility_off" : "visibility"
            }}</mat-icon>
          </mat-form-field>
          <mat-form-field *ngIf="authService.require2Fa" appearance="outline" class="w-100">
            <mat-label>Two Factor Code</mat-label>
            <input matInput formControlName="token"  autocomplete="off">
          </mat-form-field>

          <div class="text-center">
          <button
            (click)="login()"
            [disabled]="loginForm.invalid"
            id="login-btn"
            class="col-sm-12 text-center"
            mat-flat-button
            type="submit"
          >
            Login
          </button>
          </div>
          <div class="col-sm-12 my-3 text-center" *ngIf="!isIE" (click)="msLogin()" >
            <img src="{{theme != ' light-theme' ? 'assets/ms-symbollockup_signin_light.png' : 'assets/ms-symbollockup_signin_dark.png' }}" />
          </div>
          <div class="col-sm-12 text-center my-3">
            <a
              class="col-sm-12 text-center"
              id="forgot-password"
              (click)="forgotPassword()"
            >Forgot your password?</a
            >
          </div>

        </form>
        <div *ngIf="error" class="login-error w-100">
          Invalid Login Details
          <span (click)="closeError()" class="dismissError">
            <i class="fas fa-times"></i>
          </span>
        </div>
      </div>
    </div>
    <ng-template #loading>
      <app-loading [message]="'Logging in...'"></app-loading>
    </ng-template>
  </div>
</div>
