import {ComponentType} from "@angular/cdk/portal";
import {Injectable} from "@angular/core";
import {MatDialog} from "@angular/material/dialog";
import {Observable} from "rxjs";
import {take} from "rxjs/operators";
import {RemoteService} from "../remote/remote.service";
import {ModalObject} from "../../classes/modal.object";
import {BaseService} from "../../base/base.service";
import {ModalType} from "../../enums/modal-fields.enum";
import {MetaDataTypes} from "../../enums/meta-data-types.enum";

@Injectable({
  providedIn: "root"
})
export class ModalService {
  constructor(private readonly matDialog: MatDialog,
              private readonly remoteService: RemoteService,
              private readonly baseService: BaseService) {
  }

  openHubModal(modalRequest: ModalObject, metaDataService) {
    let metaData;
    const process = function (context, data?) {
      data ??= {};
      context.openModalFromHub(
        data,
        modalRequest,
        metaData,
        context
      ).subscribe();
    }

    if (modalRequest.metaDataTypes) {
      metaDataService
        .getMetaData(modalRequest.metaDataTypes)
        .pipe(take(1))
        .subscribe((res: any) => {
          metaData = res;
          if (metaData.taskTypes) {
            metaData.taskTypes.filter(c => c.name != "Adhoc Task");
          }

          if (modalRequest.id) {
            this.remoteService
              .getBy(modalRequest.controller, "id", modalRequest.id)
              .subscribe((res) => {
                if (modalRequest.modalType == ModalType.SiteClient) {
                  if (res && res.sites && res.sites.length > 0) {
                    res.sites.forEach((site) =>
                      metaData.availableClientSites.push(site));
                  }
                }
                if (modalRequest.modalType == ModalType.EquipmentChecklist) {
                  if (res && res.equipmentTypes) {
                    for(var i = 0; i < res.equipmentTypes.length; i++) {
                      metaData.availableEquipmentTypes.push({
                        id : res.equipmentTypes[i].id,
                        name : res.equipmentTypes[i].name,
                        type : { id : res.equipmentTypes[i].type.id }
                      })
                    }
                  }
                }
                if (modalRequest.modalType == ModalType.Contractor) {
                  if (res && res.programs) {
                    for(let ii = 0; ii < res.programs.length; ii++) {
                      metaData.programs.push({
                        id : res.programs[ii].id,
                        name : res.programs[ii].name,
                        isArchived: false
                      })
                    }
                  }
                  if (res && res.people) {
                    for(let p = 0; p < res.people.length; p++) {
                      metaData.people.push({
                        id : res.people[p].id,
                        name : res.people[p].name,
                        isArchived: false
                      })
                    }
                  }
                }
                process(this, res);
              });
          } else {
            process(this, modalRequest.payload);
          }
        });
    } else {
      if (modalRequest.id) {
        this.remoteService
          .getBy(modalRequest.controller, "id", modalRequest.id)
          .subscribe((res) => {
            process(this, res);
          });
      } else {
        process(this, modalRequest.payload);
      }
    }
  }

  save(entity, context, controller, dialogRef, hook?, modalContext?): Promise<void> {
    const result = controller.replace(/([A-Z])/g, " $1");
    let title = result.charAt(0).toUpperCase() + result.slice(1);

    if (controller == "TaskGroupAssign") {
      controller = "taskGroup/Assign"
      title = "Assignment";
    }
    return new Promise((resolve, error) => {
      if (!entity.id) {
        context.remoteService
          .add(controller, null, entity)
          .subscribe((response) => {
              context.baseService.openSnackBar(
                `${title} added successfully`
              );
              dialogRef.close();
              if (hook) {
                hook(modalContext, response);
              }
            },
            (errorResponse) => {
              dialogRef.close();
              error(errorResponse);
              context.baseService.openSnackBar(
                `Error - ${errorResponse.error}`,
                null,
                true
              );
            }
          );
      } else {
        context.remoteService
          .update(controller, null, entity)
          .subscribe(
            () => {
              dialogRef.close();
              context.remoteService
                .getBy(controller, "id", entity.id)
                .subscribe((res) => {
                  context.baseService.openSnackBar(
                    `${title} updated successfully`
                  );
                  if (hook) {
                    hook(modalContext, res);
                  }
                });
            },
            (errorResponse) => {
              context.baseService.openSnackBar(
                `Error - ${errorResponse.error}`,
                null,
                true
              );
            }
          );
      }
    });
  }

  openModalFromHub(
    data,
    modal: ModalObject,
    metaData: any,
    context
  ) {
    return new Observable((res) => {
      const fields = modal.modalFields;

      let title = modal.title ? modal.title : modal.controller.replace(/([A-Z])/g, " $1");
      title = title.charAt(0).toUpperCase() + title.slice(1);

      const dialogRef = this.matDialog.open(modal.modalComponent, {
        panelClass: ["p-0", "content", "justify-content-center"],
        backdropClass: "modal-backdrop",
        data: {
          data,
          fields,
          metaData,
          title,
          message: data.isArchived
            ? `You are currently viewing an Archived ${title}`
            : modal.message
        },
        disableClose: true
      });
      dialogRef.componentInstance.onSave.subscribe((entity) => {
        //Catch all to help parent entities update their children
        entity.isModified = true;
        if (modal.autoSave) {
          this.baseService.openSnackBar("Saving...");
          if (modal.afterSave) {
            this.save(entity, context, modal.controller, dialogRef, modal.afterSave, modal.requestContext);
          } else {
            this.save(entity, context, modal.controller, dialogRef);
          }
        } else {
          modal.afterSave(modal.requestContext, entity, dialogRef)
        }
      });
    });
  }

  openModal(
    data,
    component: ComponentType<any>,
    fields?: any[],
    metaData?: {},
    context?: {},
    title?: string,
    onSave?: Function,
    afterClose?: Function,
    onNavigate?: Function,
    onGridNavigation?: Function,
    onAdd?: Function,
    message?: string,
    modalContext?: this
  ) {
    return new Observable((res) => {
      const dialogRef = this.matDialog.open(component, {
        panelClass: ["p-0", "content", "justify-content-center"],
        backdropClass: "modal-backdrop",
        data: {
          data,
          fields,
          metaData,
          title,
          message: data.isArchived
            ? `You are currently viewing an Archived ${title}`
            : ""
        },
        disableClose: true
      });
      dialogRef.componentInstance.onSave.subscribe((entity) => {
        if (afterClose) {
          onSave(entity, context, dialogRef, afterClose, modalContext);
        } else {
          onSave(entity, context, dialogRef);
        }

      });
      if (onAdd) {
        dialogRef.componentInstance.onAdd.subscribe((entity) => {
          onAdd(entity, context, dialogRef);
        });
      }
      if (afterClose) {
        dialogRef.afterClosed().subscribe(() => {
          afterClose(context);
          res.complete();
        });
      }
      if (onNavigate) {
        dialogRef.componentInstance.onNavigate.subscribe((result) =>
          onNavigate(result, dialogRef)
        );
      }
    });
  }
}
