import {
  AfterViewInit,
  Component,
  EventEmitter,
  Inject,
  Output,
  ViewChild
} from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { BaseModalComponent } from "src/app/shared/base/base-modal.component";
import { taskRecordColumns } from "./program-site-record-modal.component.columns";
import { GridComponent } from "../../shared/grid/grid.component";
import { ModalRequest } from "../../shared/classes/modal.request";
import { ModalType } from "../../shared/enums/modal-fields.enum";
import { Claims } from "src/app/auth/models/claims";

@Component({
  selector: "app-program-site-record-modal",
  templateUrl: "./program-site-record-modal.component.html"
})
export class ProgramSiteRecordModalComponent
  extends BaseModalComponent
  implements AfterViewInit
{
  @Output()
  onSave = new EventEmitter();

  programSiteRecord = <any>{};
  hasClaim = false;
  allNames = ["Program Site Record Photos"];

  getNav = () => [
    { name: "Record Details", page: "details" },
    { name: "Task Records", page: "taskRecords" },
    { name: "Photos", page: "photos" },
    { name: "Map", page: "map" },
    { name: "History", page: "auditTrail", hidden: this.isNew }
  ];

  taskRecordColumns = taskRecordColumns;

  @ViewChild(GridComponent, { static: false })
  private taskRecordsGridComponent: GridComponent;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<Component>
  ) {
    super();
    this.loaded = false;
    this.isNew = !data.data.id;
    this.nav = this.getNav();
    this.current = this.nav[0];
    this.fields = data.fields;
    this.metaData = data.metaData;
    this.programSiteRecord = data.data;

    if (this.isNew) {
      this.metaData.programSiteRecordStatuses =
        this.metaData.programSiteRecordStatuses.filter((c) => c.id < 3);
    }

    const process = function (context) {
      context
        .createForm(context.fields, context.programSiteRecord)
        .then((form) => {
          context.form = form;
          if (context.isNew) {
            context.form.controls["createdByName"].setValue(
              context.authService.securityObject.name
            );
          } else {
            context.form.controls["siteRecordStatus"].disable();
            if (context.form.controls["siteRecordStatus"].value
              && context.form.controls["siteRecordStatus"].value.id > 1) {
              context.form.controls["mobileCreatedDate"].disable();
              context.form.controls["mobileCreatedTime"].disable();
              context.form.controls["mobileCreatedDate"].clearValidators();
              context.form.controls["mobileCreatedTime"].clearValidators();
            }
            context.form.controls["program"].disable();

          }
          context.form.controls["siteName"].disable();
          context.form.controls["createdByName"].disable();
          context.loaded = true;
          //Check Claims
          !context.authService.hasClaim(Claims.programEdit)
            ? context.form.disable()
            : context.hasClaim = true;
        });
      setTimeout(() => context.form.markAllAsTouched(), 5000);
    };

    if (this.isNew) {
      this.programSiteRecord.mobileCreated = this.newIsoDate();
    }

    if (this.programSiteRecord.mobileCreated) {
      this.programSiteRecord.mobileCreatedDate =
        this.programSiteRecord.mobileCreated.split("T")[0];


      this.programSiteRecord.mobileCreatedTime =
        this.programSiteRecord.mobileCreated
          .match(/([A-Z]?[^A-Z]*)/g)[1]
          .slice(1, -1)
          .slice(0, 5);
    }

    process(this);

    setTimeout(() => this.form.markAllAsTouched(), 5000);
  }

  ngAfterViewInit() {
    this.programSiteRecord.parsedLatitude =
      this.programSiteRecord.latitude !== null
        ? parseFloat(this.programSiteRecord.latitude)
        : null;
    this.programSiteRecord.parsedLongitude =
      this.programSiteRecord.longitude !== null
        ? parseFloat(this.programSiteRecord.longitude)
        : null;
  }

  changeSection(item) {
    if (item.page === "auditTrail" || item.page === "taskRecords") {
      let parameter = item.page !== "auditTrail" ? "id" : null;
      if (!this.programSiteRecord[item.page]) {
        this.loaded = false;
        this.remoteService
          .getBy(`programSiteRecord/${item.page}`, parameter, this.data.data.id)
          .subscribe(
            (res) => {
              this.programSiteRecord[item.page] = res;
              this.loaded = true;
            },
            () => {
              this.loaded = true;
            }
          );
      }
    }
    this.current = item;
  }

  checkImages($event) {
    if (!this.programSiteRecord.images) {
      this.programSiteRecord.images = [];
    }
    this.programSiteRecord.images.push($event);
    this.form.markAsDirty();
  }

  trackImage($event) {
    this.programSiteRecord.images.push({
      id: $event
    });
  }

  save() {
    this.toggleSave();
    this.programSiteRecord = {
      ...this.programSiteRecord,
      ...this.form.getRawValue()
    };
    this.programSiteRecord.mobileCreated = this.programSiteRecord
      .mobileCreatedDate
      ? this.formDateTime(
          this.programSiteRecord.mobileCreatedDate,
          this.programSiteRecord.mobileCreatedTime
        )
      : null;
    this.onSave.emit(this.programSiteRecord);
  }

  close(form) {
    this.closeModal(form, this.dialogRef);
  }

  doubleClick(cell) {
    this.openModal.next(
      new ModalRequest({
        id: cell.id.rowID,
        requestContext: this,
        modalType: ModalType.TaskRecord,
        autoSave: false,
        afterSave: this.saveTaskRecord
      })
    );
  }

  saveTaskRecord(context, entity, dialogRef) {
    context.addOrReplace(context.programSiteRecord.taskRecords, entity);
    context.taskRecordsGridComponent.grid.data = [
      ...context.programSiteRecord.taskRecords
    ];
    dialogRef.close();
    context.form.markAsDirty();
  }
}
