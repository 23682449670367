import {
  Component,
  EventEmitter,
  Inject,
  Output,
  ViewChild
} from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { issueTaskColumns } from "./issue-modal.columns";
import { GridComponent } from "../../shared/grid/grid.component";
import { ModalRequest } from "../../shared/classes/modal.request";
import { ModalType } from "../../shared/enums/modal-fields.enum";
import { ContextItem, contextMenu } from "../../shared/classes/context.item";
import { Router } from "@angular/router";
import { BaseModalComponent } from "../../shared/base/base-modal.component";
import { debounceTime, take } from "rxjs/operators";
import { ViewCategories } from "../../shared/enums/view-categories.enum";
import { Claims } from "../../auth/models/claims";
import { Validators } from "@angular/forms";
import {FieldType} from "../../shared/components/edit-form/edit-form-model";

@Component({
  selector: "app-issues-modal",
  templateUrl: "./issue-modal.component.html",
  styles: []
})
export class IssueModalComponent
  extends BaseModalComponent {
  @Output()
  onSave = new EventEmitter();

  @Output()
  onAdd = new EventEmitter();

  @Output()
  onNavigate = new EventEmitter();

  issue = <any>{};
  fields = <any>{};
  hasClaim = false;
  allNames = ["Issue Photos"];
  contextMenuOptions = [];
  issueTaskColumns = issueTaskColumns;
  hideNASite = false;
  mandatoryRisk = true;
  filteredIssueCategories: any[] = [];
  filteredSites: any[] = [];
  filteredSiteCategories: any[] = [];
  filterSiteCategories = false;

  current = { name: "Issues Details", page: "details" };
  getNav = () => [
    { name: "Issue Details", page: "details" },
    { name: "Custom Fields", page: "customFields", hidden: !this.showCustomFields },
    { name: "Notes & Map", page: "notes" },
    {
      name: "Inspection Check",
      page: "inspectionCheckItemRecord",
      hidden:
        this.isNew || (!this.isNew && !this.issue.inspectionCheckItemRecordId)
    },
    { name: "Tasks", page: "tasks" },
    { name: "Photos", page: "photos" },
    { name: "Links & Files", page: "linksAndFiles" },
    { name: "History", page: "auditTrail", hidden: this.isNew }
  ];

  @ViewChild(GridComponent, { static: false })
  private taskGridComponent: GridComponent;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<Component>,
    private router: Router
  ) {
    super();
    this.isNew = !data.data.id;
    this.fields = data.fields;
    this.metaData = data.metaData;
    this.setContextMenu(this);
    this.issue = this.data.data;
    this.hideNASite = this.authService.securityObject.hideIssueNaSite.toString() === "true";
    this.mandatoryRisk = this.authService.securityObject.mandatoryIssueRisk.toString() === "true";
    this.filterSiteCategories = this.authService.securityObject.issueSiteCategoryFilter.toString() === "true";
    this.remoteService.getFields(ViewCategories.Issues)
      .pipe(take(1))
      .subscribe((response) => {
        this.customFields = response;
        this.showCustomFields = this.customFields.length > 0;
        this.nav = this.getNav();
        this.current = this.nav[0];
        if (this.isNew) {
          this.issue.isClosed = false;
        } else {
          this.allNames = [ "Issue Photos", "Issue Videos"]
          this.allImages = [this.issue.images, this.issue.videos];
        }

        if (this.filterSiteCategories) {
          this.fields.push({ name: "siteCategory", label: "Site Category", type: FieldType.Select });
          if (this.issue.site) {
            var metaSite = this.metaData["sites"].find((s) => s.id == this.issue.site.id);
            if (metaSite) {
              this.issue.siteCategory = metaSite.type;
            }
          }
        }

        this.issue.parsedLatitude =
          this.issue.latitude !== null ? parseFloat(this.issue.latitude) : null;
        this.issue.parsedLongitude =
          this.issue.longitude !== null ? parseFloat(this.issue.longitude) : null;

        this.createForm(this.fields, this.issue).then((form) => {
          this.form = form;
          this.form.controls["issueNumber"].disable();
          this.form.controls["closedDate"].disable();
          this.form.controls["inspectionName"].disable();
          this.form.controls["equipmentName"].disable();
          this.form.controls["siteName"].disable();
          this.form.controls["what3Words"].disable();
          this.form.controls["inspectionCheckItemRecordCreatedDate"].disable();
          this.form.controls["inspectionCheckItemRecordCreatedByName"].disable();
          this.form.controls["inspectionCheckItemResultName"].disable();
          this.form.controls["inspectionCheckItemRecordNotes"].disable();
          if (this.mandatoryRisk) {
            this.form.controls["risk"].setValidators(Validators.required);
          }
          if (this.hideNASite) {
            this.form.controls["site"].setValidators(Validators.required);
          }

          //Check Claims
          !this.authService.hasClaim(Claims.issueEdit)
            ? this.form.disable()
            : (this.hasClaim = true);

          this.form.controls["latitude"].valueChanges
            .pipe(debounceTime(500))
            .subscribe((v) => {
              this.issue.parsedLatitude = parseFloat(v);
            })


          if (this.form.controls["site"].value && this.form.controls["site"].value.type) {
            this.filteredIssueCategories = [...this.metaData["issueCategories"]
              .filter((m) => m.siteCategories.length == 0 || m.siteCategories.find((f) => f.id
              == this.form.controls["site"].value.type.id))];
          } else {
            this.filteredIssueCategories = [...this.metaData["issueCategories"]];
          }
          if (this.form.controls["issueCategory"].value && this.form.controls["issueCategory"].value.siteCategories) {
            this.filteredSites = [...this.metaData["sites"].filter((m) => m.type
              && this.form.controls["issueCategory"].value.siteCategories.find((f) => f.id == m.type.id))];

            // If the Issue Category value has Site Categories populated, then filter the Site Categories accordingly
            this.filteredSiteCategories = [...this.metaData["siteCategories"].filter((m) => this.form.controls["issueCategory"].value.siteCategories.find((f) => f.id == m.id))];

          } else {
            this.filteredSites = [...this.metaData["sites"]];
            this.filteredSiteCategories = [...this.metaData["siteCategories"]];
          }
          this.form.controls["site"].valueChanges
            .subscribe((v) => {
              if (v.type) {
                this.filteredIssueCategories = [...this.metaData["issueCategories"].filter((m) => m.siteCategories.length == 0 ||
                  m.siteCategories.find((f) => f.id == v.type.id))];
              }
            })
          this.form.controls["issueCategory"].valueChanges
            .subscribe((v) => {
              if (v && v.siteCategories && v.siteCategories.length > 0) {
                this.filteredSites = [...this.metaData["sites"].filter((m) => m.type
                  && v.siteCategories.find((f) => f.id == m.type.id))];

                // If the Issue Category value has Site Categories populated, then filter the Site Categories accordingly
                this.filteredSiteCategories = [...this.metaData["siteCategories"].filter((m) =>  v.siteCategories.find((f) => f.id == m.id))];


              } else {
                this.filteredSites = [...this.metaData["sites"]];
                this.filteredSiteCategories = [...this.metaData["siteCategories"]];
              }
            })
          this.form.controls["longitude"].valueChanges
            .pipe(debounceTime(500))
            .subscribe((v) => {
              this.issue.parsedLongitude = parseFloat(v);
            })

          this.form.controls["isClosed"].valueChanges.subscribe((v) => {
            if (v) {
              this.form.controls["closedDate"].setValue(new Date());
            }
          });
        });
        setTimeout(() => this.form.markAllAsTouched(), 5000);
      });

  }

  changeSection(item) {
    if (item.page === "auditTrail" || item.page === "tasks") {
      let parameter = item.page !== "auditTrail" ? "id" : null;
      if (!this.issue[item.page]) {
        this.loaded = false;
        this.remoteService
          .getBy(`issue/${item.page}`, parameter, this.data.data.id)
          .subscribe(
            (res) => {
              this.issue[item.page] = res;
              this.loaded = true;
            },
            () => {
              this.loaded = true;
            }
          );
      }
    }
    this.current = item;
  }

  trackImage($event) {
    this.issue.images.push({ id: $event });
  }

  addTask() {
    this.issue = { ...this.issue, ...this.form.getRawValue() };
    this.openModal.next(
      new ModalRequest({
        requestContext: this,
        modalType: ModalType.Task,
        afterSave: this.saveTask,
        payload: { issue: this.issue }
      })
    );
  }

  saveTask(context, entity, dialogRef) {
    if (!context.issue.tasks) {
      context.issue.tasks = [];
    }

    if (entity.taskType.name) {
      entity.taskTypeName = entity.taskType.name;
    }
    if (!entity.created) {
      entity.created = context.newIsoDate();
    }
    if (entity.taskPriority.name) {
      entity.taskPriorityName = entity.taskPriority.name;
    }
    if (!entity.taskStatus) {
      entity.taskStatus = "New Adhoc Task";
    }
    if (entity.programs) {
      const programNames = entity.programs.map((m) => m.name);
      entity.programNames = programNames.join(",");
    }
    context.addOrReplace(context.issue.tasks, entity);
    // If the user hasn't viewed the Task Table yet...
    if (context.taskGridComponent) {
      context.taskGridComponent.grid.data = [...context.issue.tasks];
    }
    dialogRef.close();
    context.form.controls["description"].markAsDirty();
    context.form.markAsDirty();
  }

  save() {
    this.toggleSave();
    this.issue = { ...this.issue, ...this.form.getRawValue() };
    this.onSave.emit(this.issue);
  }

  close(form) {
    this.closeModal(form, this.dialogRef);
  }

  checkDocuments($event) {
    if (!this.issue.documents) {
      this.issue.documents = [];
    }
    this.issue.documents.push($event);
    this.form.markAsDirty();
  }

  checkImages($event) {
    if (!this.issue.images) {
      this.issue.images = [];
    }
    this.issue.images.push($event);
    this.form.markAsDirty();
  }

  doubleClick(cell) {
    if (cell.row.data.isNew) {
      this.baseService.openSnackBar(
        "Error - Cannot edit new Task until Saved",
        null,
        true
      );
    }
    this.openModal.next(
      new ModalRequest({
        id: cell.id.rowID,
        requestContext: this,
        modalType: ModalType.Task,
        autoSave: false,
        afterSave: this.saveTask
      })
    );
  }

  openEntityProperties(cell, context) {
    context.doubleClick(cell);
  }

  navigateToMap() {
    this.closeModal(this.form, this.dialogRef).then(() => {
      if (this.issue.site) {
        this.router.navigate([
          `/map/issueId/${this.issue.id}/${this.issue.site.id}`
        ]);
      } else {
        this.router.navigate([`/map/issueId/${this.issue.id}`]);
      }
    });
  }

  setContextMenu(context) {
    context.contextMenuOptions.push(
      new ContextItem({
        title: "Task Properties",
        disabled: false,
        action: context.openEntityProperties,
        multipleSelect: false,
        condition: (item) => !item.isArchived,
        group: 1
      })
    );
  }
}
