import {
  AfterViewInit,
  Component,
  EventEmitter,
  Inject,
  Output,
  ViewChild
} from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { BaseModalComponent } from "src/app/shared/base/base-modal.component";
import { ModalRequest } from "../../shared/classes/modal.request";
import { ModalType } from "../../shared/enums/modal-fields.enum";
import { Router } from "@angular/router";
import { GridComponent } from "../../shared/grid/grid.component";
import { adhocTaskColumns } from "./task-quality-check-record-modal.columns";
import { Claims } from "src/app/auth/models/claims";

@Component({
  selector: "app-task-quality-check-record-modal",
  templateUrl: "./task-quality-check-record-modal.component.html"
})
export class TaskQualityCheckRecordModalComponent
  extends BaseModalComponent
  implements AfterViewInit
{
  @Output()
  onSave = new EventEmitter();

  taskQualityCheckRecord = <any>{};
  hasClaim = false;
  allNames = ["Task Check Record Photos"];

  addTaskButtons = this.authService.hasClaim(Claims.taskEdit) && [
    { name: "Add Adhoc Task", action: this.addTask }
  ];
  taskQualityCheckRecordModalColumns = adhocTaskColumns;

  getNav = () => [
    { name: "Quality Check Record Details", page: "details" },
    { name: "Adhoc Tasks", page: "adhocTasks", disabled: false },
    { name: "Photos", page: "photos" },
    // { name: "Map", page: "map" },
    { name: "History", page: "auditTrail", hidden: this.isNew }
  ];

  @ViewChild(GridComponent, { static: false })
  private taskGridComponent: GridComponent;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<Component>,
    private router: Router
  ) {
    super();
    this.loaded = false;
    this.isNew = !data.data.id;
    this.nav = this.getNav();
    this.current = this.nav[0];
    this.fields = data.fields;
    this.metaData = data.metaData;
    this.taskQualityCheckRecord = data.data;

    const process = function (context) {
      context
        .createForm(context.fields, context.taskQualityCheckRecord)
        .then((form) => {
          context.form = form;
          if (context.isNew) {
            context.form.controls["createdByName"].setValue(
              context.authService.securityObject.name
            );
          } else {
            context.form.controls["rating"].disable();
          }
          context.form.controls["taskName"].disable();
          context.form.controls["siteName"].disable();
          context.loaded = true;
          //Check Claims
          !context.authService.hasClaim(Claims.taskEdit)
            ? context.form.disable()
            : (context.hasClaim = true);
        });
      setTimeout(() => context.form.markAllAsTouched(), 5000);
    };

    if (this.isNew) {
      this.taskQualityCheckRecord.mobileCreated = this.newIsoDate();
    }

    this.taskQualityCheckRecord.mobileCreatedDate =
      this.taskQualityCheckRecord.mobileCreated.split("T")[0];
    this.taskQualityCheckRecord.mobileCreatedTime =
      this.taskQualityCheckRecord.mobileCreated
        .match(/([A-Z]?[^A-Z]*)/g)[1]
        .slice(1, -1)
        .slice(0, 5);

    process(this);

    setTimeout(() => this.form.markAllAsTouched(), 5000);
  }

  ngAfterViewInit() {
    // this.taskQualityCheckRecord.parsedLatitude =
    //   this.taskQualityCheckRecord.latitude !== null
    //     ? parseFloat(this.taskQualityCheckRecord.latitude)
    //     : null;
    // this.taskQualityCheckRecord.parsedLongitude =
    //   this.taskQualityCheckRecord.longitude !== null
    //     ? parseFloat(this.taskQualityCheckRecord.longitude)
    //     : null;
  }

  changeSection(item) {
    if (item.page === "auditTrail") {
      let parameter = item.page !== "auditTrail" ? "id" : null;
      if (!this.taskQualityCheckRecord[item.page]) {
        this.loaded = false;
        this.remoteService
          .getBy(
            `taskQualityCheckRecord/${item.page}`,
            parameter,
            this.data.data.id
          )
          .subscribe(
            (res) => {
              this.taskQualityCheckRecord[item.page] = res;
              this.loaded = true;
            },
            () => {
              this.loaded = true;
            }
          );
      }
    }
    this.current = item;
  }

  checkImages($event) {
    if (!this.taskQualityCheckRecord.images) {
      this.taskQualityCheckRecord.images = [];
    }
    this.taskQualityCheckRecord.images.push($event);
    this.form.markAsDirty();
  }

  trackImage($event) {
    this.taskQualityCheckRecord.images.push({
      id: $event
    });
  }

  save() {
    this.toggleSave();
    this.taskQualityCheckRecord = {
      ...this.taskQualityCheckRecord,
      ...this.form.getRawValue()
    };
    this.taskQualityCheckRecord.mobileCreated = this.taskQualityCheckRecord
      .mobileCreatedDate
      ? this.formDateTime(
          this.taskQualityCheckRecord.mobileCreatedDate,
          this.taskQualityCheckRecord.mobileCreatedTime
        )
      : null;
    this.onSave.emit(this.taskQualityCheckRecord);
  }

  close(form) {
    this.closeModal(form, this.dialogRef);
  }

  addTask(context) {
    context.openModal.next(
      new ModalRequest({
        requestContext: context,
        modalType: ModalType.Task,
        afterSave: context.saveTask,
        payload: { isAdhoc: true, site: context.taskQualityCheckRecord.site }
      })
    );
  }

  doubleClick(cell) {
    if (cell.row.data.isNew) {
      this.baseService.openSnackBar(
        "Error - Cannot edit new Task until Saved",
        null,
        true
      );
    }
    this.openModal.next(
      new ModalRequest({
        id: cell.id.rowID,
        requestContext: this,
        modalType: ModalType.Task,
        autoSave: false,
        afterSave: this.saveTask
      })
    );
  }

  openEntityProperties(cell, context) {
    context.doubleClick(cell);
  }

  saveTask(context, entity, dialogRef) {
    if (!context.taskQualityCheckRecord.adhocTasks) {
      context.taskQualityCheckRecord.adhocTasks = [];
    }
    if (entity.programs) {
      const programNames = entity.programs.map((m) => m.name);
      entity.programNames = programNames.join(",");
    }
    if (entity.taskType.name) {
      entity.taskTypeName = entity.taskType.name;
    }
    if (!entity.created) {
      entity.created = context.newIsoDate();
    }
    if (!entity.taskStatus) {
      entity.taskStatus = "New Adhoc Task";
    }
    context.addOrReplace(context.taskQualityCheckRecord.adhocTasks, entity);
    // If the user hasn't viewed the Task Table yet...
    if (context.taskGridComponent) {
      context.taskGridComponent.grid.data = [
        ...context.taskQualityCheckRecord.adhocTasks
      ];
    }
    dialogRef.close();
    context.form.controls["rating"].markAsDirty();
    context.form.markAsDirty();
  }

  navigateToMap() {
    this.closeModal(this.form, this.dialogRef).then(() =>
      this.router.navigate([
        `/map/taskQualityCheckRecordId/${this.taskQualityCheckRecord.id}`
      ])
    );
  }
}
