<content class="h-100">
  <header class="p-0">
    <div *ngIf="appUser.isAuthenticated && appUser.isSupport" class="border-bottom border-white">
      <mat-toolbar class="h-25 bg-transparent">
        <span class="me-3"><b>Support Panel</b></span>
        <span>|</span>
        <button (click)="supportNavigate('./support')" class="btn">
          <span>Menu</span>
        </button>
        <span>|</span>
        <button (click)="supportNavigate('./login-databases')" class="btn">
          <span>Change Database</span>
        </button>
        <span>|</span>
        <button (click)="supportNavigate('./support/user-logins')" class="btn">
          <span>Login records</span>
        </button>
        <span>|</span>
        <span class="supportPin ms-2 ps-1">Support PIN {{ appUser.supportPin }} </span>
        <button class="ms-auto btn d-flex align-items-center">
          <span>{{ appUser.name }}</span>
          <mat-icon class="ms-1">person</mat-icon>
        </button>
      </mat-toolbar>
    </div>
    <div *ngIf="isStaging">
      <mat-toolbar class="staging-bar col-sm-12">
        STAGING ENVIRONMENT
      </mat-toolbar>
    </div>
  </header>
  <main>
    <content class="h-100">
      <header>
        <div *ngIf="appUser.isAuthenticated" class="topbar-container">
          <div class="flex topbar">
            <div class="col-md-1 pssu_icon">
            </div>
            <div class="col-md-10 mid flex">
              <ng-container *ngFor="let nav of navMenu">
                <div routerLink="{{ nav.name }}" *hasClaim="nav.name + '.view'" class="no-outline nav-icon"
                  routerLinkActive="icon-active" (click)="page = '{{nav.name}}'">
                  <i class="mt-2 {{ nav.icon }}"></i>
                  <p class="nav-bar pt-1">{{ nav.title }}</p>
                </div>
              </ng-container>
            </div>
            <div class="col-sm-1 flex align-items-center justify-flex-end">
              <button mat-icon-button class="no-outline mb-3" [matMenuTriggerFor]="appMenu">
                <i class="far fa-cog fa-lg"></i>
              </button>
              <mat-menu #appMenu="matMenu">
                <button mat-menu-item (click)="router.navigate(['home'])">
                  Home
                </button>
                <button mat-menu-item (click)="openConfigure()">Configuration</button>

                <button mat-menu-item (click)="toggleTheme()">
                  Enable {{ theme != "light-theme" ? "Light" : "Dark" }} Mode
                </button>
                <button mat-menu-item [matMenuTriggerFor]="subMenu">
                  Grid Sizing
                </button>
                <button mat-menu-item (click)="reset()">
                  Reset to Default Settings
                </button>
                <button mat-menu-item (click)="logOut()">Log Out</button>
              </mat-menu>
              <mat-menu #subMenu="matMenu">
                <button *ngFor="let height of gridHeights" mat-menu-item [ngClass]="
                height.rowHeight === gridHeightSelected.rowHeight
                  ? 'border border-grey'
                  : ''
              " (click)="changeHeight(height)">
                  <i [ngClass]="height.class"></i> Size: {{ height.name }}
                </button>
              </mat-menu>
            </div>
          </div>
          <div class="db-name-container">
            <span class="db-name">{{authService.securityObject.databaseName}}</span>
          </div>
        </div>
      </header>
      <main>
        <router-outlet></router-outlet>
      </main>
      <div class="version">{{ version }}</div>
    </content>
  </main>
</content>
