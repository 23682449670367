<div class="date-filter wrapped">
  <igx-select *ngIf="grid.pagingMode != 1"
    [(ngModel)]="selectedFilter"
    (ngModelChange)="filterChange($event)"
    [displayDensity]="'compact'"
    [id]="column.header"
  >
    <igx-prefix>
      <igx-icon
        class="small-icon"
        family="imx-icons"
        name="select-all"
      ></igx-icon>
    </igx-prefix>
    <igx-select-item *ngFor="let item of operations" [value]="item">
      {{ item?.name }}
    </igx-select-item>
  </igx-select>
</div>
