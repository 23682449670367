import {FieldType, IEditFormField} from "../../shared/components/edit-form/edit-form-model";

export const personFields: IEditFormField[] = [
  {name: "firstName", label: "First Name", type: FieldType.String, required: true, validatorPattern: "^.{1,100}$"},
  {name: "lastName", label: "Last Name", type: FieldType.String, required: true, validatorPattern: "^.{1,100}$"},
  {name: "email", label: "Email", validatorPattern: "email", type: FieldType.String},
  {name: "mobile", label: "Mobile", type: FieldType.String},
  {name: "notifyTask", label: "Notify Task Complete", type: FieldType.Bool},
  {name: "notifyIssue", label: "Notify Issue Close", type: FieldType.Bool},
  {name: "notifyStock", label: "Notify Stock Request Update", type: FieldType.Bool},
  {name: "staffNo", label: "Staff No.", type: FieldType.String, validatorPattern: "^.{1,50}$"},
  {name: "jobRoles", label: "Job Roles", type: FieldType.String},
  {name: "mobileRoles", label: "Mobile Roles", type: FieldType.String},
  {name: "webRole", label: "Web Role", type: FieldType.Select},
  {name: "contractor", label: "Contractor", type: FieldType.Select},
  {name: "teams", label: "Teams", type: FieldType.String},
  {name: "siteClients", label: "Clients", type: FieldType.String},
  {name: "updated", label: "Updated", type: FieldType.Date},
  {name: "teams", label: "Teams", type: FieldType.String},
  {name: "siteClients", label: "Clients", type: FieldType.String},
  {name: "updated", label: "Updated", type: FieldType.Date},
  {name: "newPassword", label: "New Password", type: FieldType.String},
  {name: "hasWebAccess", label: "Has Web Access", type: FieldType.Bool},
  {name: "hasMobileAccess", label: "Has Mobile Access", type: FieldType.Bool},
  {name: "hasZohoAccess", label: "Has Zoho Access", type: FieldType.Bool},
  {name: "twoFactorEnabled", label: "Two Factor Enabled", type: FieldType.Bool},
  {name: "shortUsername", label: "Mobile Username", type: FieldType.String},
  {name: "newShortPassword", label: "Mobile PIN", type: FieldType.String},
];

export const teamFields: IEditFormField[] = [
  {name: "name", label: "Team Name", type: FieldType.String, required: true},
  {name: "description", label: "Description", type: FieldType.String},
  {name: "people", label: "People", type: FieldType.MultiDragNoOrder, selected: []},
  {name: "sites", label: "Sites", type: FieldType.MultiDragNoOrder, selected: []},
  {name: "inspectionTypes", label: "Inspection Types", type: FieldType.MultiDragNoOrder, selected: []},
  {name: "programs", label: "Programs", type: FieldType.MultiDragNoOrder, selected: []}
]

export const contractorFields: IEditFormField[] = [
  {name: "name", label: "Contractor Name", type: FieldType.String, required: true},
  {name: "description", label: "Description", type: FieldType.String},
  {name: "people", label: "People", type: FieldType.MultiDragNoOrder, selected: []},
  {name: "programs", label: "Programs", type: FieldType.MultiDragNoOrder, selected: []}
]

export const jobRoleFields: IEditFormField[] = [
  {name: "name", label: "Job Role Name", type: FieldType.String, required: true},
  {name: "description", label: "Description", type: FieldType.String},
  {
    name: "trainingRequirements", label: "Required Courses for Role", metaDataType: "courses",
    type: FieldType.MultiDragNoOrder,
    selected: []
  }
]

export const mobileRoleFields: IEditFormField[] = [
  {name: "name", label: "Name", type: FieldType.String, required: true},
  {name: "description", label: "Description", type: FieldType.String}
]

export const trainingRequirementFields: IEditFormField[] = [
  {name: "course", label: "Course", metaDataType: "courses", type: FieldType.Select},
  {name: "mustCompleteByDate", label: "Valid until Date", type: FieldType.Date}
];

export const trainingRecordFields: IEditFormField[] = [
  {name: "person", label: "Person", metaDataType: "person", type: FieldType.Select, required: true},
  {name: "course", label: "Course", metaDataType: "courses", type: FieldType.Select, required: true},
  {name: "trainer", label: "Trainer", metaDataType: "trainers", type: FieldType.Select, required: true},
  {name: "completedDate", label: "Completed Date", type: FieldType.Date, required: true},
  {name: "validUntilDate", label: "Valid Until", type: FieldType.Date}

];

export const courseFields: IEditFormField[] = [
  {name: "name", label: "Course Name", type: FieldType.String, required: true},
  {name: "description", label: "Description", type: FieldType.String},
  {name: "courseType", label: "Course Type", metaDataType: "courseTypes", type: FieldType.Select},
  {name: "validMonths", label: "Valid Months", type: FieldType.Int}
];

export const courseTypeFields: IEditFormField[] = [
  {name: "name", label: "Type Name", type: FieldType.String, required: true},
  {name: "description", label: "Description", type: FieldType.String}
]
