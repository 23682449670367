import {IGridColumn} from "../../shared/grid/grid-model";
import { GridColumnDataType } from "@infragistics/igniteui-angular";

export const taskRecordColumns: IGridColumn[] = [
  {
    field: "name",
    header: "Task Name",
    dataType: GridColumnDataType.String
  },
  {
    field: "created",
    header: "Recorded Date",
    dataType: GridColumnDataType.DateTime
  },
  {
    field: "siteName",
    header: "Site",
    dataType: GridColumnDataType.String
  },
  {
    field: "taskCategoryName",
    header: "Task Category",
    dataType: GridColumnDataType.String
  },
  {
    field: "taskTypeName",
    header: "Task Type",
    dataType: GridColumnDataType.String
  },
  {
    field: "taskPriorityName",
    header: "Task Priority",
    dataType: GridColumnDataType.String
  },
  {
    field: "taskRecordStatusName",
    header: "Task Record Status",
    dataType: GridColumnDataType.String
  }
];
