<div class="login-outer">
  <div class="login-middle">
    <div class="login-inner card h-auto">
      <div class="card_header">
        <h5 class="col-sm-12 text-center mb-1">Password Reset</h5>
      </div>
      <div class="col-sm-12">
        <form [formGroup]="resetForm" (ngSubmit)="submit()">
          <mat-form-field class="col-sm-12">
            <mat-label>Confirm Username</mat-label>
            <input matInput type="email" email formControlName="email">
          </mat-form-field>
          <mat-form-field class="mb-4 col-sm-12">
            <mat-label>Enter New Password</mat-label>
            <input matInput type="{{showPwd ? 'text' : 'password'}}" formControlName="password">
            <mat-icon matSuffix (click)="showPwd = !showPwd">{{showPwd ? 'visibility_off' : 'visibility'}}</mat-icon>
            <mat-hint align="start">Requires 8 Characters, including  1 Symbol and 1 Number</mat-hint>
            <mat-error *ngIf="notMatching">
              Both Passwords must match and contain 8 Characters, including 1 Symbol and 1 Number.
            </mat-error>
          </mat-form-field>
          <mat-form-field class="mb-4 col-sm-12">
            <mat-label>Repeat Password</mat-label>
            <input matInput type="{{showPwd ? 'text' : 'password'}}" formControlName="confirmPassword">
            <mat-icon matSuffix (click)="showPwd = !showPwd">{{showPwd ? 'visibility_off' : 'visibility'}}</mat-icon>
            <mat-hint align="start">Requires 8 Characters, including 1 Symbol and 1 Number</mat-hint>
            <mat-error *ngIf="notMatching">
              Both Passwords must match and contain 8 Characters, including 1 Symbol and 1 Number.
            </mat-error>
          </mat-form-field>
          <div class="col-sm-12 text-center">
            <button type="submit" [disabled]="resetForm.invalid || !resetForm.dirty" value="Submit" class="m-2 border" mat-flat-button>Submit</button>
            <button class="m-2 border" (click)="router.navigate(['login'])" mat-flat-button>Cancel</button>
        </div>
        </form>
      </div>
    </div>
  </div>
</div>
