import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import {
  Component,
  EventEmitter,
  Inject,
  Output,
  ViewChild
} from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { BaseModalComponent } from "../../shared/base/base-modal.component";
import { taskRecordColumns } from "./program-modal.component.columns";
import { ModalRequest } from "../../shared/classes/modal.request";
import { ModalType } from "../../shared/enums/modal-fields.enum";
import { GridComponent } from "../../shared/grid/grid.component"; 
import { take } from "rxjs/operators";
import { ViewCategories } from "../../shared/enums/view-categories.enum";
import { Claims } from "../../auth/models/claims";

@Component({
  selector: "app-program-modal",
  templateUrl: "./program-modal.component.html"
})
export class ProgramModalComponent extends BaseModalComponent {
  @Output()
  onSave = new EventEmitter();

  program = <any>{};
  sites = [];
  siteTasksMetaData = [];
  sitesClone;

  getNav = () => [
    { name: "Programs Details", page: "details" },
    { name: "Custom Fields", page: "customFields", hidden: !this.showCustomFields },
    { name: "Sites", page: "sites" },
    {
      name: "Tasks",
      page: "siteTasks",
      disabled: true
    },
    { name: "Task Records", page: "taskRecords", hidden: this.isNew },
    { name: "History", page: "auditTrail", hidden: this.isNew }
  ];

  programSites;

  taskRecordColumns = taskRecordColumns;

  @ViewChild(GridComponent, { static: false })
  private taskRecordsGridComponent: GridComponent;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<Component>
  ) {
    super();
    this.loaded = false;
    this.isNew = !data.data.id;

    this.remoteService.getFields(ViewCategories.Programs)
      .pipe(take(1))
      .subscribe((response) => {
        this.customFields = response;
        this.showCustomFields = this.customFields.length > 0;
        this.nav = this.getNav();
        this.current = this.nav[0];
        this.fields = data.fields;
        this.metaData = data.metaData;
        this.program = data.data;
        this.createForm(this.fields, this.program).then((form) => {
          this.form = form;
          this.loaded = true;
          this.sitesClone = JSON.parse(JSON.stringify(this.form.controls["sites"].value));
          this.form.controls["sites"].valueChanges.subscribe((v) => {
            if (v && v.length) {
              for (let i = 0; i < v.length; i++) {
                let found = this.program.sites?.find((f) => f.id == v[i].id);
                if (found) {
                  v[i].tasks = found.tasks;
                }
              }
            }
          });
          this.nav[3].disabled =
            !this.form.controls.sites.value ||
            this.form.controls.sites.value.length === 0;
          setTimeout(() => this.form.markAllAsTouched(), 5000);
          //Check Claims
          !this.authService.hasClaim(Claims.programEdit) ? this.form.disable() : "";
        });
      });


  }

  changeSection(item) {
    this.program = { ...this.program, ...this.form.getRawValue() };
    if (
      item.page === "auditTrail" ||
      item.page == "siteTasks" ||
      item.page == "taskRecords"
    ) {
      if (!this.program[item.page]) {
        let parameter = item.page !== "auditTrail" ? "id" : null;
        if (item.page == "siteTasks") {
          this.loaded = false;
          // this.program = {...this.program, ...this.form.getRawValue()};
          this.remoteService
            .add("program", "siteTasks", this.program)
            .pipe(take(1))
            .subscribe(
              (response) => {
                this.siteTasksMetaData = response;
                this.loaded = true;
              },
              () => {
                this.loaded = true;
              }
            );
        } else if (!item.initialised) {
          this.loaded = false;
          this.remoteService
            .getBy(`program/${item.page}`, parameter, this.data.data.id)
            .pipe(take(1))
            .subscribe(
              (res) => {
                this.program[item.page] = res;
                this.loaded = true;
              },
              () => {
                this.loaded = true;
              }
            );
          item.initialised = true;
        }
      }
    }
    this.current = item;
  }

  //MultiDrag
  changeSelected(field, selected) {
    field.selected = selected;
    for (let i = 0; i < this.form.controls.sites.value.length; i++) {
      const el = this.form.controls.sites.value[i];
      if (this.sitesClone) {
        let match = this.sitesClone.find(
          (b) => b.id === el.id
        );
        if (match) {
          el.order = match.order;
        }
      }
    }

    this.form.controls["sites"].value.sort((a, b) => a.order - b.order);
    this.sitesClone = JSON.parse(JSON.stringify(this.form.controls["sites"].value));
    this.nav[3].disabled = this.form.controls.sites.value.length <= 0;
    this.form.markAsDirty();
  }

  changeSelectedTask($event, field, id) {
    if ($event.isUserInput) {
      if (field) {
        let foundIndex = field.findIndex(
          (e) => e.id === $event.source.value.id
        );
        if (foundIndex === -1) {
          field.push($event.source.value);
        } else {
          field.splice(foundIndex, 1);
        }
      } else {
        field = [$event.source.value];
      }
      this.program.sites[id].tasks = field.map((s, i) => ({ ...s, order: i }));
      this.form.markAsDirty();
    }
  }

  initOption(field, option) {
    if (field?.selected) {
      let found = field.selected.find((o) => o.value.id === option.value.id);
      if (!found) {
        field.selected.push(option);
      }
    }
    if (option?.selected == false && field) {
      let found =
        field.tasks && field.tasks.find((o) => o.id === option.value.id);
      if (found) {
        option.select({ emitEvent: false });
      }
    }
    if (field.tasks) {
      field.tasks.sort((a, b) => a.order - b.order);
    }
  }

  //Order on Selection or Drag & Drop
  matSelectLoad(formControl, selected) {
    if (formControl.value || formControl.tasks?.length > 0) {
      // for (let i = 0; i < formControl.value.length; i++) {
      //   const el = formControl.value[i];
      //   el["order"] = i;
      // }
      formControl.selected = selected;
    }
  }

  handleListDrop(event: CdkDragDrop<string>, value) {
    moveItemInArray(value, event.previousIndex, event.currentIndex);
    for (let i = 0; i < value.length; i++) {
      const el = value[i];
      el["order"] = i;
    }
    this.form.markAsDirty();
  }

  removeItem(progress, site, field) {
    site.progress = progress / 15;
    if (site.progress >= 100) {
      if (field.selected && field.selected.find((e) => e.value.id === site.id)) {
        field.selected.find((e) => e.value.id === site.id).deselect();
      } else {
        let foundIndex = field.value.findIndex((e) => e.id === site.id);
        if (foundIndex !== -1) {
          field.value.splice(foundIndex, 1);
        }
      }
      this.form.markAsDirty();
    }
  }

  removeTask(progress, task, formControl, siteId) {
    task.progress = progress / 15;
    if (task.progress >= 100) {
      let foundIndex = formControl.tasks.findIndex((e) => e.id === task.id);
      let taskOption = formControl.selected.find((e) => e.value.id === task.id);
      taskOption ? taskOption.deselect() : "";
      if (foundIndex !== -1) {
        formControl.tasks.splice(foundIndex, 1);
      }
      this.program.sites[siteId].tasks = formControl.tasks.map((s, i) => ({
        ...s,
        order: i
      }));
      this.form.markAsDirty();
    }
  }

  trackImage($event) {
    this.program.images.push({
      id: $event
    });
  }

  save() {
    this.toggleSave();
    this.program = { ...this.program, ...this.form.getRawValue() };
    // Clearing circular dependency
    if (this.program.sites) {
      this.program.sites.forEach((site) => delete site.selected);
    }
    this.onSave.emit(this.program);
  }

  close(form) {
    this.closeModal(form, this.dialogRef);
  }

  checkImages($event) {
    if (!this.program.images) {
      this.program.images = [];
    }
    this.program.images.push($event);

    this.form.markAsDirty();
  }

  selectAll(selectedTasks, metaDataTasks, matSelect, id) {
    if (
      matSelect.options.some(
        (op) => op.viewValue !== "Select All" && op.selected === false
      )
    ) {
      // There is at least 1 unselected option
      // If the task is not found in the selectedTasks add it
      if (selectedTasks) {
        metaDataTasks.forEach((task) => {
          selectedTasks.find((st) => st.id === task.id)
            ? ""
            : selectedTasks.push(task);
        });
      } else {
        // If there are no selectedTasks ad all
        selectedTasks = [...metaDataTasks];
      }
      // Select All
      matSelect.options.forEach((op) => op.select());
    } else {
      // All options are selected
      // If the task is found in the selectedTasks remove it
      metaDataTasks.forEach((task) => {
        let foundIndex = selectedTasks.findIndex((st) => st.id === task.id);
        if (foundIndex || foundIndex === 0) {
          selectedTasks.splice(foundIndex, 1);
        }
      });
      // Unselect All
      matSelect.options.forEach((op) => op.deselect());
    }

    this.program.sites[id].tasks = selectedTasks.map((s, i) => ({
      ...s,
      order: i
    }));
    this.form.markAsDirty();
  }

  doubleClick(cell) {
    this.openModal.next(
      new ModalRequest({
        id: cell.id.rowID,
        requestContext: this,
        modalType: ModalType.TaskRecord,
        autoSave: false,
        afterSave: this.saveTaskRecord
      })
    );
  }

  saveTaskRecord(context, entity, dialogRef) {
    context.taskGridComponent.grid.data = [...context.program.taskRecords];
    dialogRef.close();
    context.form.markAsDirty();
  }
}
