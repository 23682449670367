import {Component, EventEmitter, Output} from "@angular/core";
import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {Observable, Subject, Subscription} from "rxjs";
import {IEditModalData} from "../components/modals/edit-modal.component";
import {ConfirmModalComponent} from "../components/modals/confirm/confirm-modal.component";
import {MatDialog} from "@angular/material/dialog";
import {MetaDataService} from "../services/meta-data/meta-data.service";
import {AuthService} from "../../auth/auth.service";
import {RemoteService} from "../services/remote/remote.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {HubEventService} from "../hub/hub-event.service";
import {InjectorService} from "../services/injector/injector.service";
import {ModalService} from "../services/modal/modal.service";
import {ModalRequest} from "../classes/modal.request";
import {BaseService} from "./base.service";

@Component({template: ""})
export class BaseModalComponent {
  protected metaDataService: MetaDataService;
  protected modalService: ModalService;
  protected authService: AuthService;
  protected matDialog: MatDialog;
  protected remoteService: RemoteService;
  protected snackBar: MatSnackBar
  protected hubEventService: HubEventService;
  protected baseService: BaseService;
  openModal: Subject<ModalRequest>;

  hasClaim = true;
  form: UntypedFormGroup;
  loaded = true;
  metaData = <any>{};
  fields = <any>{};
  isNew = false;
  nav: any = [];
  allImages: any[] = [];
  current = this.nav[0];
  saving = false;
  customFields : any[] = [];
  showCustomFields = false;

  constructor() {
    const injector = InjectorService.getInjector();
    this.baseService = injector.get(BaseService);
    this.metaDataService = injector.get(MetaDataService);
    this.authService = injector.get(AuthService);
    this.matDialog = injector.get(MatDialog);
    this.remoteService = injector.get(RemoteService);
    this.snackBar = injector.get(MatSnackBar);
    this.hubEventService = injector.get(HubEventService);
    this.modalService = injector.get(ModalService);

    this.openModal = this.hubEventService.openModal;
  }

  bools = [
    {id: true, name: "Yes"},
    {id: false, name: "No"}
  ];

  nullableBools = [
    {id: null, name: ""},
    {id: true, name: "Yes"},
    {id: false, name: "No"}
  ];

  sideNav = <any>{};
  data: any[];
  editModalData: IEditModalData = {
    fields: [],
    title: "",
    data: {},
    metaData: {}
  };
  contextMenuOptions = [];
  today = Date.now();

  singleSubscription: Subscription;
  subscriptions: Subscription;

  @Output()
  onNavigation = new EventEmitter();
  @Output()
  onSave = new Subject();
  @Output()
  onClose = new EventEmitter<UntypedFormGroup>();

  createForm(fields, data): Promise<UntypedFormGroup> {
    return new Promise((resolve) => {
      const group = {};
      fields.forEach((field) => {
        group[field.name] = new UntypedFormControl(data[field.name]);
        if (field.required) {
          group[field.name].setValidators(Validators.required);
        }
        if (field.validatorPattern) {
          group[field.name].setValidators(
            Validators.pattern(field.validatorPattern)
          );
        }
      });
      this.loaded = true;
      resolve(new UntypedFormGroup(group));
    })
  }

  newGuid() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
      const r = (Math.random() * 16) | 0,
        v = c === "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }

  closeModal(form, dialogRef) {
    return new Promise<void>((resolve) => {
      if (form && form.dirty && !this.checkControlsDirty(form)) {
        this.unsavedChanges().subscribe((result) => {
          if (!result) {
            return;
          }
          dialogRef.close();
          resolve();
        });
      } else {
        dialogRef.close();
        resolve();
      }
    });
  }

  checkControlsDirty(form) {
    const keys = Object.keys(form.controls);
    for (const key of keys) {
      if (!form.controls[key].pristine) {
        return false;
      }
    }
    return true;
  }

  formDateTime(date, time) {
    if (time.hour < 10) {
      time.hour = `0${time.hour.toString()}`;
    }
    if (time.minute < 10) {
      time.minute = `0${time.minute.toString()}`;
    }
    const formattedDate = new Date(date).toISOString().substring(0, 10);
    return formattedDate + "T" + time + "Z";
  }

  unsavedChanges() {
    return new Observable((resolve) => {
      const dialogRef = this.matDialog.open(ConfirmModalComponent, {
        data: {
          type: "confirmSave",
          body: ["Discard Changes?"]
        },
        disableClose: true,
        panelClass: "confirm-dialog-container"
      });
      dialogRef.afterClosed().subscribe((result) => {
        resolve.next(result);
        resolve.complete();
      });
    });
  }

  checkFormInvalid(form) {
    Object.keys(form.controls).forEach((key) => {
      const c = form.controls[key];
      if (c.invalid && c.errors && c.errors["pattern"]) {
        c.markAsTouched();
      }
    });
  }

  check(form) {
    if (!form.dirty) {
      form.markAsDirty();
    }
  }

  toggleSave() {
    this.saving = true;
    setTimeout(() => this.saving = false, 10000);
  }

  compareOverride(t1, t2) {
    if (t2 !== null) {
      return t1.id === t2.id;
    }
    return null;
  }

  click(event) {
    if (event.cell) {
      event.call.action(event.cell, this, event.call.extra);
    } else {
      event(this);
    }
  }

  newIsoDate() {
    const date = new Date();
    if (date.toString().includes("(British Summer Time)")) {
      date.setHours(date.getHours() + 1);
    }
    return date.toISOString();
  }

  addOrReplace(array, entity) {
    if (!array) {
      array = [];
    }
    let index = array.findIndex(
      (el) => el.id === entity.id
    );
    if (index !== -1) {
      array[index] = entity;
    } else {
      array.push(entity);
    }
  }

  info(type) {
    function openModal(context, text) {
      context.matDialog.open(ConfirmModalComponent, {
        data: {
          type: "no-btn",
          body: [
            text
          ]
        },
        panelClass: "confirm-dialog-container"
      });
      return;
    }

    switch (type) {
      case "periods":
        openModal(this, "A Period is the defined date range within which schedules will be active");
        break;
      case "time-frame":
        openModal(this, "Time Frame is the frequency (how often) the scheduled item should be undertaken by number of Days, Weeks, Months or Years");
        break;
      case "buffer":
        openModal(this, "Buffer days are used to force a gap between actioning a scheduled item to maintain the required distance between occurrences");
        break;
    }
  }
}
