<div class="h100 d-flex flex-column text-center p-4">
  <p *ngFor="let line of data.body">{{ line }}</p>
  <mat-dialog-actions
    *ngIf="data.type != 'no-btn'"
    class="mt-2 w-100 justify-content-center"
  >
    <button
      mat-flat-button
      class="col-3 bg-primary c-white"
      cdkFocusInitial
      (click)="dialogRef.close(true)"
    >
      Yes
    </button>
    <button
      *ngIf="data.type != 'error'"
      mat-flat-button
      class="ms-2 col-3 bg-primary c-white"
      (click)="dialogRef.close()"
    >
      No
    </button>
  </mat-dialog-actions>
  <mat-dialog-actions
    *ngIf="data.type == 'no-btn'"
    class="mt-2 w-100 justify-content-center"
  >
    <button
      mat-flat-button
      class="ms-2 col-3 bg-primary c-white"
      (click)="dialogRef.close()"
    >
      Ok
    </button>
  </mat-dialog-actions>
</div>
