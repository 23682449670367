<content class="inspection-modal">
  <div
    *ngIf="inspection.isArchived"
    class="statusBar text-center text-light bg-primary"
  >
    <span>You are currently viewing an Archived Inspection</span>
  </div>
  <div class="d-flex m-0 position-relative h-100">
    <div class="p-3 primary">
      <div class="text-center">
        <i class="fal fa-thumbtack fa-3x"></i>
        <H5>Inspection</H5>
      </div>
      <div class="py-5 modal-nav">
        <button
          *ngFor="let item of nav"
          mat-button
          class="btn py-2 d-block w-100"
          [ngClass]="{ 'border-p': current.page == item.page }"
          (click)="changeSection(item)"
          [disabled]="item.disabled"
          [hidden]="item.hidden"
        >
          {{ item.name }}
        </button>
      </div>
    </div>
    <div class="corner-close" (click)="close(form)">
      <i class="fal fa-times fa-2x"></i>
    </div>
    <form
      class="bg-modal px-4 d-flex w-100"
      [formGroup]="form"
      data-lpignore="true"
      autocomplete="off"
      *ngIf="loaded && form; else loading"
      (input)="check(form)"
    >
      <div class="d-flex flex-column justify-content-between w-100">
        <!-- #region Header -->
        <header class="d-flex justify-content-between primary pt-3">
          <div>
            <h2 class="m-0">
              {{ isNew ? "Add Inspection" : inspection.name }}
            </h2>
            {{ current.name }}
          </div>
        </header>
        <!-- #endregion -->
        <!-- #region Main -->
        <main class="flex-grow-1">
          <div class="h-100" [ngSwitch]="current.page">
            <!-- #region Details -->
            <div *ngSwitchCase="'details'">
              <div class="row">
                <div class="col-12 col-md-6">
                  <div class="card min-photo-height p-2 d-block">
                    <mat-form-field class="w-100">
                      <mat-label class="primary"> Inspection Name</mat-label>
                      <input matInput #name formControlName="name" />
                      <mat-error
                        *ngIf="
                          form.get('name').hasError('required') &&
                          form.get('name').touched
                        "
                      >
                        Inspection Name Required.
                      </mat-error>
                    </mat-form-field>
                    <mat-form-field class="w-100">
                      <mat-label class="primary">Category</mat-label>
                      <mat-select
                        formControlName="inspectionCategory"
                        [compareWith]="compareOverride"
                      >
                        <mat-option
                          *ngFor="
                            let category of metaData.inspectionCategories
                              | sort: 'name'
                              | archivedFilter : inspection.inspectionCategory
                          "
                          [value]="category"
                        >
                          {{ category.name }}
                        </mat-option>
                      </mat-select>
                      <mat-error
                        *ngIf="
                          form.get('inspectionCategory').hasError('required') &&
                          form.get('inspectionCategory').touched
                        "
                      >
                        Inspection Category Required.
                      </mat-error>
                    </mat-form-field>
                    <mat-form-field class="w-100">
                      <mat-label class="primary">Inspection Type</mat-label>
                      <mat-select
                        formControlName="inspectionType"
                        [compareWith]="compareOverride"
                      >
                        <mat-option
                          *ngFor="
                            let type of metaData.inspectionTypes
                              | sort: 'name'
                              | typeFilter
                                : form.controls['inspectionCategory'].value
                              | archivedFilter : inspection.inspectionType
                          "
                          [value]="type"
                        >
                          {{ type.name }}
                        </mat-option>
                      </mat-select>
                      <mat-error
                        *ngIf="
                          form.get('inspectionType').hasError('required') &&
                          form.get('inspectionType').touched
                        "
                      >
                        <span>Inspection Type Required.</span>
                      </mat-error>
                    </mat-form-field>
                    <mat-form-field class="w-100">
                      <mat-label class="primary"> Description</mat-label>
                      <textarea
                        matInput
                        class="h-100"
                        rows="4"
                        formControlName="description"
                      ></textarea>
                    </mat-form-field>
                  </div>
                </div>
                <div class="col-12 col-md-6 d-flex flex-column">
                  <div class="card p-2 d-block">
                    <mat-form-field class="w-100">
                      <mat-label class="primary">Site</mat-label>
                      <mat-select
                        formControlName="site"
                        multiple
                        [compareWith]="compareOverride"
                      >
                        <mat-option
                          *ngFor="let type of metaData.sites
                          | sort: 'name'
                          | archivedFilter : inspection.site"
                          [value]="type"
                        >
                          {{ type.name }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <mat-form-field class="w-100">
                      <mat-label class="primary">Equipment</mat-label>

                      <mat-select
                        multiple
                        formControlName="equipment"
                        [compareWith]="compareOverride"
                      >
                        <mat-option
                          *ngFor="let type of metaData.equipment
                          | sort: 'name'
                          | archivedFilter : inspection.equipment
                        "
                          [value]="type"
                        >
                          {{ type.name }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
            <!-- #endregion -->
            <div *ngSwitchCase="'customFields'">
              <app-custom-fields
                [parentForm]="form"
                [fields]="customFields"
                [entity]="inspection"
              ></app-custom-fields>
            </div>
            <!-- #region Inspection Records -->
            <div *ngSwitchCase="'inspectionRecords'" class="h-100">
              <app-grid
                #inspectionRecordGridComponent
                [gridData]="inspection.inspectionRecords"
                [columns]="inspectionRecordColumns"
                [small]="true"
                [selection]="'none'"
                [groupable]="false"
                (onDoubleClick)="doubleClick($event)"
              ></app-grid>
            </div>
            <!-- #endregion -->
            <!-- #region History / AuditTrail -->
            <div *ngSwitchCase="'auditTrail'" class="h-100">
              <app-audit-trail
                id="auditTrail"
                [auditTrail]="inspection.auditTrail"
              ></app-audit-trail>
            </div>
            <!-- #endregion -->
          </div>
        </main>
        <!-- #endregion -->
        <!-- #region Footer -->
        <footer
          class="flex justify-content-end my-3"
          [ngClass]="inspection.isArchived ? 'pb-4' : ''"
        >
          <div>
            <button
              mat-flat-button
              name="detailSaveButton"
              class="ms-2 px-4"
              [disabled]="form.invalid || !form.dirty || !hasClaim || saving"
              (click)="save()"
            >
              <span *ngIf="saving">
                <i class="fad fa-spinner-third fa-spin">
                </i>
              </span>
              <span *ngIf="!saving">Save</span>
            </button>
            <button mat-flat-button class="ms-2 px-4" (click)="close(form)">
              Close
            </button>
          </div>
        </footer>
        <!-- #endregion -->
      </div>
    </form>
    <ng-template #loading>
      <app-loading
        class="w-100 d-flex align-items-center justify-content-center bg-modal"
      ></app-loading>
    </ng-template>
  </div>
</content>
