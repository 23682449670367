import {Component, EventEmitter, Inject, OnInit, Output} from "@angular/core";
import {MatDialogRef, MAT_DIALOG_DATA} from "@angular/material/dialog";
import {BaseModalComponent} from "../../shared/base/base-modal.component";
import {Claims} from "../../auth/models/claims";

@Component({
  selector: "app-shared-document-modal",
  templateUrl: "./shared-document-modal.component.html",
  styles: []
})
export class SharedDocumentModalComponent
  extends BaseModalComponent
  implements OnInit {
  @Output()
  onSave = new EventEmitter();

  nav = [
    {name: "Document Details", page: "details", disabled: false},
    {name: "History", page: "auditTrail", disabled: false, hidden: this.isNew}
  ];
  current = this.nav[0];

  document = <any>{};
  fields = <any>{};
  hasClaim = false;
  activeFile = "";
  fileAdded = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<Component>
  ) {
    super();
    this.isNew = !data.data.id;
    this.fields = data.fields;
    this.metaData = data.metaData;
  }

  ngOnInit(): void {
    this.document = this.data.data;
    if (this.document.url) {
      this.activeFile = this.document.url + "?v=" + this.baseService.newGuid();
    }
    this.createForm(this.fields, this.document).then((form) => {
      this.form = form;
      //Check Claims
      !this.authService.hasClaim(Claims.configuration)
        ? this.form.disable()
        : (this.hasClaim = true);
    });
  }


  changeSection(item) {
    if (item.page === "auditTrail") {
      let parameter = item.page !== "auditTrail" ? "id" : null;
      if (!this.document[item.page]) {
        this.loaded = false;
        this.remoteService
          .getBy(
            `document/${item.page}`,
            parameter,
            this.data.data.id
          )
          .subscribe(
            (res) => {
              this.document[item.page] = res;
              this.loaded = true;
            },
            () => {
              this.loaded = true;
            }
          );
      }
    }
    this.current = item;
  }

  save() {
    this.document = {...this.document, ...this.form.getRawValue()};
    const formData = new FormData();

    // Removes invalid names
    this.document.name = this.document.name.replace(/[^\x00-\x7F]/g, "");
    if (this.document.file) {
      formData.append(this.document.name, this.document.file);
    }
    formData.set("documentDto", JSON.stringify(this.document));

    if (this.isNew) {
      this.remoteService.add("document/create", null, formData).subscribe(
        (response) => {
          if (response != null
            && !response.error
          ) {
            this.onSave.emit(response);
            this.closeModal(null, this.dialogRef);
            this.openSnackBar("File uploaded successfully");
          } else {
            this.openSnackBar("Error - Your document could not be saved at this time. \n " +
              "If this problem persists please contact PSS for advice", null, true);
          }
        },
        (err) => {
          this.openSnackBar(
            "Error - Unable to upload File",
            null,
            true
          );
          console.log(err);
        }
      );
    } else {
      this.remoteService.update("document", null, formData).subscribe(
        (response) => {
          this.openSnackBar("File uploaded successfully");
          this.onSave.emit(response);
          this.closeModal(null, this.dialogRef);
        },
        (err) => {
          this.openSnackBar(
            "Error - Unable to upload File",
            null,
            true
          );
          console.log(err);
        }
      );
    }
  }

  close(form) {
    this.closeModal(form, this.dialogRef);
  }

  download(url) {
    const win = window.open(url, "_blank");
    win.focus();
  }

  addFile(files) {
    if (files.length === 0) {
      return;
    }
    this.document.name = this.form.controls["name"].value ? this.form.controls["name"].value  : files[0].name;
    this.form.controls["name"].setValue(this.document.name);
    this.form.controls["url"].setValue(`Pending Save of (${files[0].name})`);
    this.document.fileName = files[0].name;
    this.document.file = files[0];
    this.document.url = null;
    this.fileAdded = true;
    this.form.markAsDirty();
  }

  openSnackBar(
    message: string,
    action?: string,
    error?: boolean,
    dismissFn?: Function
  ) {
    this.snackBar
      .open(message, action, {
        duration: 4000,
        verticalPosition: "top",
        panelClass: [
          "alert",
          "alert-heading",
          "text-white",
          "text-center",
          "d-flex",
          "justify-content-center",
          error ? "bg-danger" : "bg-success"
        ]
      })
      .afterDismissed()
      .subscribe(() => (dismissFn ? dismissFn() : ""));
  }
}
