import {
  Component,
  EventEmitter,
  Inject,
  Output,
} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {BaseModalComponent} from "src/app/shared/base/base-modal.component";
import {UntypedFormControl} from "@angular/forms";

@Component({
  selector: "app-database-modal",
  templateUrl: "./database-modal.component.html"
})
export class DatabaseModalComponent extends BaseModalComponent {
  @Output()
  onSave = new EventEmitter();

  database = <any>{};
  hasClaim = false;

  getNav = () => [
    {name: "Database Details", page: "details"},
    {name: "Section Access", page: "claims"}
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<Component>
  ) {
    super();
    this.isNew = !data.data.id;
    this.nav = this.getNav();
    this.current = this.nav[0];
    this.metaData = data.metaData;
    this.fields = data.fields;
    this.database = data.data;

    const customSort = {
      "Dashboard": 0,
      "Sites": 1,
      "Programs": 2,
      "Tasks": 3,
      "Equipment": 4,
      "People": 5,
      "Inspections": 6,
      "Stock": 7,
      "Issues": 8,
      "Map": 9,
      "Reports": 10
    };

    this.metaData.sectionClaims.sort(
      (a, b) => customSort[a.title] - customSort[b.title]
    );

    this.createForm(this.fields, this.database)
      .then((form) => {
        this.form = form;

        if (this.isNew) {
          this.database.sectionClaims = [];
        }

        for (let i = 0; i < this.metaData.sectionClaims.length; i++) {
           let match = this.database.sectionClaims.find(f => f.name == this.metaData.sectionClaims[i].name);

            form.addControl(`section-claim-${this.metaData.sectionClaims[i].name}`,
              new UntypedFormControl(match ? 1 : 0));
        }
      });
  }

  onSliderChange($event, valueType) {
    this.form.markAsDirty();
    // if ($event.value && this.form.controls[`claim-${valueType}`]) {
    //   this.form.controls[`claim-${valueType}`].setValue($event.value);
    // }
  }

  formatLabel(value: number) : string {
    switch (value) {
      case 0:
        return "Off";
      case 1:
        return "On";
    }
    return "";
  }

  changeSection(item) {
    this.current = item;
  }

  save() {
    this.database = {...this.database, ...this.form.getRawValue()};
    // iterate through the claims and build them in the API accepted format
    this.database.sectionClaims = [];
    Object.keys(this.database).forEach((key) => {
      if (key.includes("section-claim-")) {
        if (this.database[key] == true) {
          this.database.sectionClaims.push({
            name: key.substr(14),
            enabled: true
          });
        }
        delete this.database[key];
      }
    });
    this.onSave.emit(this.database);
  }

  close(form) {
    this.closeModal(form, this.dialogRef);
  }
}
