import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {AppConfigurationService} from "../app-config/app-configuration.service";
import {saveAs} from "file-saver";


@Injectable({
  providedIn: "root"
})
export class RemoteService {
  constructor(
    private httpClient: HttpClient,
    private readonly configService: AppConfigurationService
  ) {
  }

  // Returns List of views which are then used for overall Gets, I.e. View Category 5 is Sites
  getViews(category: number) {
    return this.httpClient.get<any[]>(
      this.configService.apiAddress + "/view/" + category,
      {
        withCredentials: true
      }
    );
  }

  getColumns(category: number) {
    return this.httpClient.get<any[]>(
      this.configService.apiAddress + "/view/columns/" + category,
      {
        withCredentials: true
      }
    );
  }

  getFields(category: number) {
    return this.httpClient.get<any[]>(
      this.configService.apiAddress + "/view/fields/" + category,
      {
        withCredentials: true
      }
    );
  }

  //Facilitates GET requests that are independent of the Site Tree
  getData(controller, parameter?) {
    return this.httpClient.get<any[]>(
      `${this.configService.apiAddress}/${controller}${
        parameter ? `/${parameter}` : ""
      }`,
      {
        withCredentials: true
      }
    );
  }

  /*
    Gets requests from main grid components, these requests send the requested
    columns to the API to only get the data that we need.
    Uses POST instead of GET to follow the correct HTTP principles.
    Optional Id parameter for "CONTROLLER/PARAMETER/{ID}"
  */
  getColumnData(controller, parameter, columns, id?) {
    const payload = {Columns: columns};
    return id
      ? this.httpClient.post<any[]>(
        `${this.configService.apiAddress}/${controller}/${parameter}/${id}`,
        payload,
        {
          withCredentials: true
        }
      )
      : this.httpClient.post<any[]>(
        `${this.configService.apiAddress}/${controller}/${parameter}`,
        payload,
        {
          withCredentials: true
        }
      );
  }

  //For the dashboard
  getViewsData(controller, views) {
    const payload = {Views: views};
    return this.httpClient.post<any[]>(
      `${this.configService.apiAddress}/${controller}`,
      payload,
      {
        withCredentials: true
      }
    );
  }

  //GET by parameter and id
  getBy(controller, parameter, id): any {
    if (parameter !== null) {
      return this.httpClient.get<any[]>(
        `${this.configService.apiAddress}/${controller}/${parameter}/${id}`,
        {
          withCredentials: true
        }
      );
    }
    return this.httpClient.get<any[]>(
      `${this.configService.apiAddress}/${controller}/${id}`,
      {
        withCredentials: true
      }
    );
  }

  // GET changes after date
  getChangesAfterDate(controller, viewId, date, columns?) {
    return this.httpClient.post<any[]>(
      `${this.configService.apiAddress}/${controller}/${viewId}/${date}`,
      {Columns: columns},
      {
        withCredentials: true
      }
    );
  }

  add(controller, parameter = null, payload): Observable<any> {
    return this.httpClient.post<any[]>(
      `${this.configService.apiAddress}/${controller}${
        parameter ? `/${parameter}` : ""
      }`,
      payload,
      {
        withCredentials: true
      }
    );
  }

  async asyncAdd(controller, parameter = null, payload) {
    return await this.httpClient.post<any[]>(
      `${this.configService.apiAddress}/${controller}${
        parameter ? `/${parameter}` : ""
      }`,
      payload,
      {
        withCredentials: true
      }
    ).toPromise();
  }

  update(controller, parameter = null, payload) {
    return this.httpClient.put<any[]>(
      `${this.configService.apiAddress}/${controller}${
        parameter ? `/${parameter}` : ""
      }`,
      payload,
      {
        withCredentials: true
      }
    );
  }

 async asyncUpdate(controller, parameter = null, payload) {
   return await  this.httpClient.put<any[]>(
      `${this.configService.apiAddress}/${controller}${
        parameter ? `/${parameter}` : ""
      }`,
      payload,
      {
        withCredentials: true
      }
    ).toPromise();
  }



  patch(controller, parameter = null, payload) {
    return this.httpClient.patch<any[]>(
      `${this.configService.apiAddress}/${controller}${
        parameter ? `/${parameter}` : ""
      }`,
      payload,
      {
        withCredentials: true
      }
    );
  }

  archive(controller, id) {
    return this.httpClient.delete<any[]>(
      `${this.configService.apiAddress}/${controller}/archive/${id}`,
      {
        withCredentials: true
      }
    );
  }

  delete(controller, id) {
    return this.httpClient.delete(
      `${this.configService.apiAddress}/${controller}/${id}`,
      {
        withCredentials: true
      }
    );
  }

  //MAP
  //GET coordinates from asset
  getAssetCoord(id): any {
    return this.httpClient.get<any[]>(
      `${this.configService.apiAddress}/asset/coordinates/{${id}}`,
      {
        withCredentials: true
      }
    );
  }

  postcodeToCoords(postcode): any {
    return this.httpClient.get(
      `${this.configService.apiAddress}/map/getLatLongFromPostcodeAsync/{${postcode}}`,
      {
        withCredentials: true
      }
    );
  }

  downloadPDF(controller, parameter, content?): any {
    return new Observable((resolve) => {
      var mediaType = "application/pdf";
      this.httpClient
        .post(
          `${this.configService.apiAddress}/${controller}/${parameter}`,
          content,
          {
            responseType: "blob",
            withCredentials: true
          }
        )
        .subscribe(
          (response) => {
            var blob = new Blob([response], {type: mediaType});
            saveAs(blob, "report.pdf");
          },
          (error) => {
            resolve.error(error);
            resolve.complete();
          }
        );
    });
  }
}
