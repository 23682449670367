import {
  Component,
  EventEmitter,
  Inject,
  Output,
  ViewChild
} from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { BaseModalComponent } from "src/app/shared/base/base-modal.component";
import { inspectionRecordColumns } from "./inspection-modal.columns";
import { ModalRequest } from "../../shared/classes/modal.request";
import { ModalType } from "../../shared/enums/modal-fields.enum";
import { GridComponent } from "../../shared/grid/grid.component";
import { Claims } from "src/app/auth/models/claims";
import {ViewCategories} from "../../shared/enums/view-categories.enum";
import {take} from "rxjs/operators";

@Component({
  selector: "app-inspection-modal",
  templateUrl: "./inspection-modal.component.html",
  styleUrls: ["./inspection-modal.component.css"]
})
export class InspectionModalComponent extends BaseModalComponent {
  @Output()
  onSave = new EventEmitter();

  inspectionRecordColumns = inspectionRecordColumns;
  inspection = <any>{};
  allNames = ["Photos"];

  getNav = () => [
    { name: "Inspection Details", page: "details" },
    {name: "Custom Fields", page: "customFields", hidden: !this.showCustomFields},
    { name: "Inspection Records", page: "inspectionRecords" },
    { name: "History", page: "auditTrail", hidden: this.isNew }
  ];

  @ViewChild(GridComponent, { static: false })
  private inspectionRecordGridComponent: GridComponent;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<Component>
  ) {
    super();
    this.loaded = false;
    this.isNew = !data.data.id;
    this.metaData = data.metaData;
    this.fields = data.fields;
    this.inspection = data.data;

    this.remoteService.getFields(ViewCategories.Inspections)
      .pipe(take(1))
      .subscribe((response) => {
        this.customFields = response;
        this.showCustomFields = this.customFields.length > 0;
        this.nav = this.getNav();
        this.current = this.nav[0];

        this.createForm(this.fields, this.inspection).then((form) => {
          this.form = form;
          if (!this.isNew) {
            // If the Inspection isn't new then we can't update anything that would manipulate the Inspection Records
            this.form.controls["inspectionCategory"].disable();
            this.form.controls["inspectionType"].disable();
            this.form.controls["equipment"].disable();
            this.form.controls["site"].disable();
          }
          this.loaded = true;
          setTimeout(() => this.form.markAllAsTouched(), 5000);
          //Check Claims
          !this.authService.hasClaim(Claims.inspectionEdit)
            ? this.form.disable()
            : "";
        });
      });


  }

  changeSection(item) {
    if (item.page === "inspectionRecords" || item.page === "auditTrail") {
      let parameter = item.page !== "auditTrail" ? "id" : null;
      // We only want to load each section once.
      if (!this.inspection[item.page]) {
        this.loaded = false;
        this.remoteService
          .getBy(`inspection/${item.page}`, parameter, this.data.data.id)
          .subscribe(
            (res) => {
              this.loaded = true;
              this.inspection[item.page] = res;
            },
            () => {
              this.loaded = true;
            }
          );
      }
    }
    this.current = item;
  }

  doubleClick(cell) {
    this.openModal.next(
      new ModalRequest({
        id: cell.id.rowID,
        requestContext: this,
        modalType: ModalType.InspectionRecord,
        autoSave: false,
        afterSave: this.saveInspectionRecord
      })
    );
  }

  openEntityProperties(cell, context) {
    context.doubleClick(cell);
  }

  saveInspectionRecord(context, entity, dialogRef) {
    if (!entity.created) {
      entity.created = context.newIsoDate();
    }
    context.addOrReplace(context.inspection.inspectionRecords, entity);
    context.inspectionRecordGridComponent.grid.data = [
      ...context.inspection.inspectionRecords
    ];
    dialogRef.close();
    context.form.markAsDirty();
  }

  save() {
    this.toggleSave();
    this.inspection = { ...this.inspection, ...this.form.getRawValue() };
    this.onSave.emit(this.inspection);
  }

  close(form) {
    this.closeModal(form, this.dialogRef);
  }
}
