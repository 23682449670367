import { Pipe, PipeTransform } from "@angular/core";
@Pipe({ name: "sort" })
export class SortPipe implements PipeTransform {
  transform(array, field) {
    if (!Array.isArray(array)) {
      return;
    }
    let sortedArray = [...array];
    if (!field) {
      sortedArray.sort((a, b) => {
        if (a < b || a === null) return -1;
        if (a > b) return 1;
        return 0;
      });
      return sortedArray;
    }
    if (Array.isArray(field)) {
      sortedArray.sort((a, b) => {
        if (a[field[0]] < b[field[0]]) return -1;
        if (a[field[0]] > b[field[0]]) return 1;
        if (a[field[1]] < b[field[1]]) return -1;
        if (a[field[1]] > b[field[1]]) return 1;
        return 0;
      });
      return sortedArray;
    } else {
      sortedArray.sort((a, b) => {
        if (a[field] == undefined) {
          return -1;
        } else if (typeof a[field] !== "string") {
          if (a[field] < b[field]) return -1;
          if (a[field] > b[field]) return 1;
        } else {
          if (a[field].toLowerCase() < b[field].toLowerCase()) return -1;
          if (a[field].toLowerCase() > b[field].toLowerCase()) return 1;
        }
        return 0;
      });
      return sortedArray;
    }
  }
}
