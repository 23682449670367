import { EventEmitter, Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";

@Injectable({ providedIn: "root" })
export class BaseService {
  gridHeightNotify = new EventEmitter();
  constructor(private snackBar: MatSnackBar) {}
  blankGuid = "00000000-0000-0000-0000-000000000000";

  newGuid() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
      const r = (Math.random() * 16) | 0,
        v = c === "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }

  camelize(str) {
    return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) => {
      if (+match === 0) return "";
      return index == 0 ? match.toLowerCase() : match.toUpperCase();
    });
  }

  toTitleCase(str: string) {
    return str.replace(
      /\w\S*/g,
      (txt: string) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    );
  }

  newIsoDate() {
    const date = new Date();
    if (date.toString().includes("(British Summer Time)")) {
      date.setHours(date.getHours() + 1);
    }
    return date.toISOString();
  }

  setBool(object) {
    if (!object) {
      return false;
    }
    return object;
  }

  getTheme() {
    if (localStorage.getItem("theme")) {
      return localStorage.getItem("theme").valueOf();
    }
    return "light-theme";
  }

  getViewFromStorage(gridKey) {
    let view = localStorage[`view-${gridKey}-grid`];
    if (view) {
      view = JSON.parse(view);
    }
    return view;
  }

  setViewToStorage(gridKey, view) {
    if (view) {
      view = JSON.stringify(view);
      localStorage[`view-${gridKey}-grid`] = view;
    } else {
      localStorage[`view-${gridKey}-grid`] = '';
    }
  }

  openSnackBar(
    message: string,
    action?: string,
    error?: boolean,
    dismissFn?: Function
  ) {
    this.snackBar
      .open(message, action, {
        duration: 4000,
        verticalPosition: "top",
        panelClass: [
          "alert",
          "alert-heading",
          "text-white",
          "text-center",
          "d-flex",
          "justify-content-center",
          error ? "bg-danger" : "bg-success"
        ]
      })
      .afterDismissed()
      .subscribe(() => (dismissFn ? dismissFn() : ""));
  }
}
