<div class="document-modal">
  <div class="d-flex m-0 position-relative h-100">
    <div class="p-3 primary">
      <div class="text-center">
        <i class="fal fa-file fa-3x"></i>
        <H5>Shared Document</H5>
      </div>
      <div class="py-5 modal-nav">
        <button
          *ngFor="let item of nav"
          mat-button
          class="btn py-2 d-block w-100"
          [ngClass]="{ 'border-p': current.page == item.page }"
          (click)="changeSection(item)"
          [disabled]="item.disabled"
          [hidden]="item.hidden"
        >
          {{ item.name }}
        </button>
      </div>
    </div>
    <div class="corner-close" (click)="close(form)">
      <i class="fal fa-times fa-2x"></i>
    </div>
    <form
      class="bg-modal px-4 d-flex w-100"
      [formGroup]="form"
      data-lpignore="true"
      autocomplete="off"
      *ngIf="loaded; else loading"
      (input)="check(form)"
    >
      <content class="d-flex flex-column justify-content-between w-100">
        <!-- #region Header -->
        <header class="d-flex primary pt-3">
          <div>
            <h2 class="m-0">
              Shared Document
            </h2>
            {{ current.name }}
          </div>
        </header>
        <!-- #endregion -->
        <!-- #region Main -->
        <main class="flex-grow-1">
          <div class="h-100" [ngSwitch]="current.page">
            <!-- #region Details -->
            <div *ngSwitchCase="'details'" class="h-100">
              <div class="row h-100">
                <div class="col-sm-4">
                  <div class="card w-100 h-100 p-2">
                    <mat-form-field class="w-100">
                      <mat-label class="primary">Name</mat-label>
                      <input matInput formControlName="name"/>
                    </mat-form-field>
                    <mat-form-field class="w-100">
                      <mat-label class="primary">URL</mat-label>
                      <textarea
                        matInput
                        class="h-100"
                        rows="4"
                        formControlName="url"
                      ></textarea>
                    </mat-form-field>
                    <div class="w-100 document-browse-ctn">
                        <button mat-flat-button class="w-100 mb-2 border me-1">
                          {{document.isNew ? 'Replace File' : 'Upload File'}}<input
                          class="position-absolute document-browse-btn hide-input"
                          #file
                          (change)="addFile(file.files)"
                          type="file"
                        />
                        </button>
                    </div>
                    <mat-form-field class="w-100">
                      <mat-label class="primary">Site Categories</mat-label>
                      <mat-select
                        formControlName="siteCategories"
                        multiple
                        [compareWith]="compareOverride"
                      >
                        <mat-option
                          *ngFor="let siteCategory of metaData.siteCategories
                        | sort: 'name'
                         | archivedFilter: document.siteCategories"
                          [value]="siteCategory"
                        >
                          {{ siteCategory.name }}
                        </mat-option>

                      </mat-select>
                    </mat-form-field>
                    <mat-form-field class="w-100">
                      <mat-label class="primary">Equipment Categories</mat-label>
                      <mat-select
                        formControlName="equipmentCategories"
                        multiple
                        [compareWith]="compareOverride"
                      >
                        <mat-option
                          *ngFor="let equipmentCategory of metaData.equipmentCategories
                        | sort: 'name'
                         | archivedFilter: document.equipmentCategories"
                          [value]="equipmentCategory"
                        >
                          {{ equipmentCategory.name }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <mat-form-field class="w-100">
                      <mat-label class="primary">Equipment Types</mat-label>
                      <mat-select
                        formControlName="equipmentTypes"
                        multiple
                        [compareWith]="compareOverride"
                      >
                        <mat-option
                          *ngFor="let equipmentType of metaData.equipmentTypes
                        | sort: 'name'
                         | archivedFilter: document.equipmentTypes"
                          [value]="equipmentType"
                        >
                          {{ equipmentType.name }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <mat-form-field class="w-100">
                      <mat-label class="primary">Task Categories</mat-label>
                      <mat-select
                        formControlName="taskCategories"
                        multiple
                        [compareWith]="compareOverride"
                      >
                        <mat-option
                          *ngFor="let taskCategory of metaData.taskCategories
                        | sort: 'name'
                         | archivedFilter: document.taskCategories"
                          [value]="taskCategory"
                        >
                          {{ taskCategory.name }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <mat-form-field class="w-100">
                      <mat-label class="primary">Task Types</mat-label>
                      <mat-select
                        formControlName="taskTypes"
                        multiple
                        [compareWith]="compareOverride"
                      >
                        <mat-option
                          *ngFor="let taskType of metaData.taskTypes
                        | sort: 'name'
                         | archivedFilter: document.taskTypes"
                          [value]="taskType"
                        >
                          {{ taskType.name }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <mat-form-field class="w-100">
                      <mat-label class="primary">Inspection Categories</mat-label>
                      <mat-select
                        formControlName="inspectionCategories"
                        multiple
                        [compareWith]="compareOverride"
                      >
                        <mat-option
                          *ngFor="let inspectionCategory of metaData.inspectionCategories
                        | sort: 'name'
                         | archivedFilter: document.inspectionCategories"
                          [value]="inspectionCategory"
                        >
                          {{ inspectionCategory.name }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <mat-form-field class="w-100">
                      <mat-label class="primary">Inspection Types</mat-label>
                      <mat-select
                        formControlName="inspectionTypes"
                        multiple
                        [compareWith]="compareOverride"
                      >
                        <mat-option
                          *ngFor="let inspectionType of metaData.inspectionTypes
                        | sort: 'name'
                         | archivedFilter: document.inspectionTypes"
                          [value]="inspectionType"
                        >
                          {{ inspectionType.name }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <mat-form-field class="w-100">
                      <mat-label class="primary">Stock Categories</mat-label>
                      <mat-select
                        formControlName="stockCategories"
                        multiple
                        [compareWith]="compareOverride"
                      >
                        <mat-option
                          *ngFor="let stockCategory of metaData.stockCategories
                        | sort: 'name'
                         | archivedFilter: document.stockCategories"
                          [value]="stockCategory"
                        >
                          {{ stockCategory.name }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <mat-form-field class="w-100">
                      <mat-label class="primary">Stock Types</mat-label>
                      <mat-select
                        formControlName="stockTypes"
                        multiple
                        [compareWith]="compareOverride"
                      >
                        <mat-option
                          *ngFor="let stockType of metaData.stockTypes
                        | sort: 'name'
                         | archivedFilter: document.stockTypes"
                          [value]="stockType"
                        >
                          {{ stockType.name }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
                <div class="col-sm-8">
                  <div class="card h-100 d-flex">
                    <span class="p-2">PDF Preview:</span>
                    <div class="card h-100">
                      <pdf-viewer
                        *ngIf="activeFile"
                        [src]="activeFile"
                        [original-size]="false"
                      >
                      </pdf-viewer>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- #endregion -->
            <!-- #region History / AuditTrail -->
            <div *ngSwitchCase="'auditTrail'">
              <app-audit-trail
                id="auditTrail"
                [auditTrail]="document.auditTrail"
              ></app-audit-trail>
            </div>
            <!-- #endregion -->
          </div>
        </main>
        <!-- #endregion -->
        <!-- #region Footer -->
        <footer
          class="flex justify-content-end my-3"
          [ngClass]="document.isArchived ? 'pb-4' : ''"
        >
          <div>
            <button
              mat-flat-button
              name="detailSaveButton"
              class="ms-2 px-4"
              [disabled]="form.invalid || !form.dirty || !hasClaim"
              (click)="save()"
            >
              Save
            </button>
            <button mat-flat-button class="ms-2 px-4" (click)="close(form)">
              Close
            </button>
          </div>
        </footer>
        <!-- #endregion -->
      </content>
    </form>
    <ng-template #loading>
      <app-loading
        class="w-100 d-flex align-items-center justify-content-center bg-modal"
      ></app-loading>
    </ng-template>
  </div>
</div>
