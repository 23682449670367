import { Component, OnInit, Inject } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { AuthService } from "../auth.service";
import { DOCUMENT } from "@angular/common";
import { AppUserAuth } from "../models/app-user-auth";
import { AppUser } from "../models/app-user";
import { BaseService } from "src/app/shared/base/base.service";
import { MsalService } from "@azure/msal-angular";

@Component({
  selector: "login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit {
  loginForm: UntypedFormGroup;
  loading = false;
  returnUrl: string;
  error = "";
  showPwd: boolean = false;
  user: AppUser = new AppUser();
  securityObject: AppUserAuth;
  theme = localStorage.getItem("theme")
    ? localStorage.getItem("theme").valueOf()
    : "light-theme";
  isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

  lastPassReferences = [
    "__lpform_input_idx_0",
    "__lpform_input_idx_0_numspan",
    "__lpform_input_idx_1",
    "__lpform_input_idx_1_numspan",
    "__lpform_input_idx_2",
    "__lpform_input_idx_2_numspan",
  ];

  get showLogin() {
    return !this.loading;
  }

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly route: ActivatedRoute,
    private router: Router,
    private readonly authService: AuthService,
    private readonly baseService: BaseService,
    private readonly msalService: MsalService,
    @Inject(DOCUMENT) private document: any
  ) { }

  login() {
    this.user = this.loginForm.getRawValue();
    this.authService.login(this.user).subscribe(
      () => {
        setTimeout(() => {
          if (!this.authService.databaseList) {
            // slight delay so the XSRF can set
            if (this.returnUrl) {
              this.router.navigateByUrl(this.returnUrl);
            } else {
              this.router.navigate(["dashboard"]);
            }
          }

          // Removes lastpass artifacts that remain on SPA's
          this.lastPassReferences.map((ref) => {
            const element = this.document.getElementById(ref);
            if (element) element.parentNode.removeChild(element);
          });
        });
      },
      (errorResponse) => {
        // Initialize security object to display error message
        this.securityObject = new AppUserAuth();
        this.baseService.openSnackBar(`Error - Invalid Details : ${errorResponse}.`, null, true);
      }
    );
  }

  async msLogin() {
    this.msalService.initialize().subscribe((result) => {
      this.msalService.loginPopup(
        {
          scopes: ['api://f0efb712-5b23-44c9-b9ce-a5910ffa1410/access_as_user'],
        }
      ).subscribe((response) => {
        this.msalService.instance.setActiveAccount(response.account);
        // acquire a token silently, use acquireTokenPopup or acquireTokenRedirect if fails
        this.msalService.acquireTokenSilent({
          scopes: ['api://f0efb712-5b23-44c9-b9ce-a5910ffa1410/access_as_user', 'profile']
        }).subscribe((tokenResponse) => {
          this.authService.loginWithMS(tokenResponse.accessToken).subscribe();
        });
      });
    });
  }


  forgotPassword() {
    this.user = this.loginForm.getRawValue();
    if (!this.user.email) {
      this.baseService.openSnackBar(`Please enter your username`, null, true);
    } else this.authService.requestReset(this.user);
  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      email: [this.user.email, Validators.required],
      password: [this.user.password, Validators.required],
      token: [this.user.token]
    });

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams["returnUrl"];
  }

  closeError() {
    this.error = null;
  }
}
