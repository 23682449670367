import { GridColumnDataType } from "@infragistics/igniteui-angular";
import { IGridColumn } from "../../shared/grid/grid-model";

export const inspectionRecordColumns: IGridColumn[] = [
  {
    field: "mobileCreated",
    header: "Recorded Date",
    dataType: GridColumnDataType.DateTime
  },
  {
    field: "inspectionStatusName",
    header: "Inspection Record Status",
    dataType: GridColumnDataType.String
  },
  {
    field: "numberOfPassedChecks",
    header: "Passed Checks",
    dataType: GridColumnDataType.Number
  },
  {
    field: "numberOfFailedChecks",
    header: "Failed Checks",
    dataType: GridColumnDataType.Number
  },
  {
    field: "numberOfNaChecks",
    header: "NA Checks",
    dataType: GridColumnDataType.Number
  },
  {
    field: "numberOfDeferredChecks",
    header: "Deferred Checks",
    dataType: GridColumnDataType.Number
  },
  {
    field: "createdByName",
    header: "Created By",
    dataType: GridColumnDataType.String
  }
];
