import { IgxFilteringOperand } from "igniteui-angular";

export class CustomDateFilteringOperand extends IgxFilteringOperand {
  public constructor() {
    super();
    this.operations = [
      {
        name: "customFrom",
        isUnary: false,
        iconName: "is_before",
        logic: (target: Date, searchVal: Date) => {
          if (!target) {
            return false;
          }
          return new Date(target) >= searchVal;
        }
      },
      {
        name: "customTo",
        isUnary: false,
        iconName: "is_after",
        logic: (target: Date, searchVal: Date) => {
          if (!target) {
            return false;
          }

          return new Date(target) <= searchVal;
        }
      },
      {
        name: "customBetween",
        isUnary: false,
        iconName: "is_before",
        logic: (target: Date, dateArray: Date[]) => {
          if (!target) {
            return false;
          }

          var t = new Date(target);
          t.setHours(0);
          t.setMinutes(0);

          var dateOne = new Date(dateArray[0]);
          dateOne.setHours(0);
          dateOne.setMinutes(0);

          var dateTwo = new Date(dateArray[1]);
          dateTwo.setHours(23);
          dateTwo.setMinutes(59);

          return (
            t >= dateOne
            && t <= dateTwo
          );
        }
      },
      {
        name: "customOn",
        isUnary: false,
        iconName: "is_on",
        logic: (target: Date, searchVal: Date) => {
          if (!target) {
            return false;
          }

          return (
            new Date(target).setHours(0, 0, 0, 0) ===
            new Date(searchVal).setHours(0, 0, 0, 0)
          );
        }
      },
      {
        name: "customEmpty",
        isUnary: false,
        iconName: "empty",
        logic: (target: Date, searchVal: Date) => {
          return !target;
        }
      },
      {
        name: "customNotEmpty",
        isUnary: false,
        iconName: "not_empty",
        logic: (target: Date, searchVal: Date) => {
          return !!target;
        }
      }
    ];
  }
}
