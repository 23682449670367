<span *ngIf="auditTrail && auditTrail.length > 0; else empty;">
  <div *ngFor="let auditItem of auditTrail;let index = i;" class="card my-3 p-2">
    <h5 class="card-title primary">{{ auditItem.timeStamp }} - {{auditItem.changedBy}}</h5>
    <p *ngFor="let change of auditItem.changes" class="card-text m-0">
      {{change}}
    </p>
  </div>
</span>
<ng-template #empty>
  <div class="card">
    <p class="card-text">
     {{message}}
    </p>
  </div>
</ng-template>
