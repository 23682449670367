export class Check {
  allowDefer: boolean;
  allowNa: boolean;
  id: string;
  name: boolean;
  note: string;
  images: [];
  description: boolean;
  grade: any;
  order: boolean;
  public constructor(init?: Partial<Check>) {
    Object.assign(this, init);
  }
}
