<div class="custom-field-modal">
  <div class="d-flex m-0 position-relative h-100">
    <div class="corner-close" (click)="close(form)">
      <i class="fal fa-times fa-2x"></i>
    </div>
    <form
      class="bg-modal px-4 d-flex w-100"
      [formGroup]="form"
      *ngIf="loaded; else loading"
    >
      <content class="d-flex flex-column justify-content-between w-100">
        <!-- #region Header -->
        <header class="d-flex primary pt-3">
          <div>
            <h2 class="m-0">
              Custom Field
            </h2>
          </div>
        </header>
        <!-- #endregion -->
        <!-- #region Main -->
        <main class="flex-grow-1">
          <div class="h-100">
            <div class="card p-2 d-block h-100">
              <mat-form-field class="w-100">
                <mat-label class="primary">Name</mat-label>
                <input matInput #name formControlName="name"/>
                <mat-error *ngIf="form.get('name').hasError('required') &&
                        form.get('name').touched">
                  <span>Name is Required.</span>
                </mat-error>
              </mat-form-field>
              <mat-form-field class="w-100">
                <mat-label class="primary">Data Type</mat-label>
                <mat-select
                  formControlName="dataType"
                  [compareWith]="compareOverride"
                >
                  <mat-option *ngFor="let e of metaData.customFieldDataTypes" [value]="e">
                    {{ e.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field class="w-100">
                <mat-label class="primary">Entity Type</mat-label>
                <mat-select
                  formControlName="associatedEntityType" [compareWith]="compareOverride">
                  <mat-option *ngFor="let e of metaData.customFieldEntityTypes" [value]="e">
                    {{ e.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <div *ngIf="form.controls['dataType'].value && form.controls['dataType'].value.id == 1">
                <div class="row m-0">
                  <mat-form-field class="p-0 w-75">
                    <mat-label class="primary">Options</mat-label>
                    <input matInput formControlName="optionsEntry" class="w-75"/>
                  </mat-form-field>
                  <button mat-flat-button
                          [disabled]="!form.controls.optionsEntry.value"
                          class="btn h-40px w-25 mt-2 py-2 d-block" color="primary" (click)="onAdd()">
                    Add Option
                  </button>
                </div>
                <div
                  class="d-block boundary"
                >
                  <div
                    class="d-flex border rounded m-2 p-2 justify-content-between"
                    *ngFor="let item of form.controls['options'].value; let i = index"
                  >
                    {{ item.name }}
                    <div
                      (click)="removeItem(item)">
                      <i class="fas fa-times"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
        <!-- #endregion -->
        <!-- #region Footer -->
        <footer
          class="flex justify-content-end my-3"
          [ngClass]="customField.isArchived ? 'pb-4' : ''"
        >
          <div>
            <button
              mat-flat-button
              name="detailSaveButton"
              class="ms-2 px-4"
              [disabled]="form.invalid || !form.dirty || !hasClaim"
              (click)="save()"
            >
              Save
            </button>
            <button mat-flat-button class="ms-2 px-4" (click)="close(form)">
              Close
            </button>
          </div>
        </footer>
        <!-- #endregion -->
      </content>
    </form>
    <ng-template #loading>
      <app-loading
        class="w-100 d-flex align-items-center justify-content-center bg-modal"
      ></app-loading>
    </ng-template>
  </div>
</div>
