import {Directive, AfterViewInit} from '@angular/core';
import {MatSelect} from '@angular/material/select';

@Directive({
  selector: 'mat-select'
})
export class MatSelectDirective implements AfterViewInit {

  constructor(private matSelect: MatSelect) {  }

  ngAfterViewInit() {
    // if (this.matSelect.options) {
    //   this.matSelect.options.reset(this.matSelect.options.filter((f) => {
    //     return f.value && (!f.value.isArchived || f.value.id == this.matSelect.value.id)
    //   })
    //     // , (value) => {return value.id != undefined}
    //   );
    // }
  }
}
