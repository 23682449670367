export class NavMenu {
  protected title: string;
  protected name: string;
  protected icon: string;
  public constructor(init?: Partial<NavMenu>) {
    Object.assign(this, init);
    this.name = this.title.toLowerCase();
    this.icon = `${this.title.toLowerCase()}_icon`;
  }
}
