import { IgxFilteringOperand } from "igniteui-angular";

export class CustomStringFilteringOperand extends IgxFilteringOperand {
  public constructor() {
    super();
    this.operations = [
      {
        name: "empty",
        isUnary: true,
        iconName: "empty",
        logic: (target: string) => {
          return !target;
        }
      }, {
        name: "notEmpty",
        isUnary: true,
        iconName: "not_empty",
        logic: (target: string) => {
          return target !== null && target !== undefined && target.length > 0;
        }
      }
    ].concat(this.operations);
  }
}
