import { GridColumnDataType } from "@infragistics/igniteui-angular";
import { IGridColumn } from "../../shared/grid/grid-model";

export const stockRequestsColumns: IGridColumn[] = [
  {
    field: "stockRequestNumber",
    header: "Request No.",
    dataType: GridColumnDataType.Number
  },
  {
    field: "stockName",
    header: "Stock Name",
    dataType: GridColumnDataType.String
  },
  {
    field: "stockTypeName",
    header: "Stock Type",
    dataType: GridColumnDataType.String
  },
  {
    field: "note",
    header: "Notes",
    dataType: GridColumnDataType.String
  },
  {
    field: "inStock",
    header: "Quantity In Stock",
    dataType: GridColumnDataType.Number
  },
  {
    field: "quantityRequested",
    header: "Quantity Requested",
    dataType: GridColumnDataType.Number
  },
  {
    field: "quantityProvided",
    header: "Quantity Provided",
    dataType: GridColumnDataType.Number
  },
  {
    field: "stockRequestStatusName",
    header: "Request Status",
    dataType: GridColumnDataType.String
  },
  {
    field: "providedByName",
    header: "Provided By",
    dataType: GridColumnDataType.String
  },
  {
    field: "requestedByName",
    header: "Requested By",
    dataType: GridColumnDataType.String
  },
  {
    field: "providedDate",
    header: "Date Provided",
    dataType: GridColumnDataType.Date
  },
  {
    field: "originalNote",
    header: "Original Notes",
    dataType: GridColumnDataType.String,
    hidden: true
  },
  {
    field: "stockCategoryName",
    header: "Category",
    dataType: GridColumnDataType.String
  },
  {
    field: "reference",
    header: "Reference",
    dataType: GridColumnDataType.String,
    hidden: true
  },
  {
    field: "cost",
    header: "Cost",
    dataType: GridColumnDataType.Number
  },
  {
    field: "maximumOrder",
    header: "Maximum Order Quantity",
    dataType: GridColumnDataType.Number
  },
  {
    field: "minimumStockQuantity",
    header: "Minimum Stock Quantity",
    dataType: GridColumnDataType.Number
  },
  {
    field: "stockRequestNumber",
    header: "Stock Request Number",
    dataType: GridColumnDataType.Number
  }
];
