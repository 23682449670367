import {GridColumnDataType} from "@infragistics/igniteui-angular";
import {GridColumn, IGridColumn} from "../../shared/grid/grid-model";

export const stockColumns: IGridColumn[] = [
  {
    field: "name",
    header: "Stock Name",
    dataType: GridColumnDataType.String,
    pinned: true,
    usedInDataUpdate: true
  },
  {
    field: "stockCategoryName",
    header: "Category",
    dataType: GridColumnDataType.String,
    usedInDataUpdate: true
  },
  {
    field: "stockTypeName",
    header: "Type",
    dataType: GridColumnDataType.String,
    usedInDataUpdate: true
  },
  {
    field: "stockStatus",
    header: "Stock Status",
    dataType: GridColumnDataType.String
  },
  {
    field: "quantity",
    header: "Quantity in Stock",
    dataType: GridColumnDataType.Number,
    usedInDataUpdate: true
  },
  {
    field: "minimumStockQuantity",
    header: "Minimum Stock Quantity",
    dataType: GridColumnDataType.Number,
    usedInDataUpdate: true
  },
  {
    field: "maximumOrder",
    header: "Maximum Order Quantity",
    dataType: GridColumnDataType.Number,
    hidden: true,
    usedInDataUpdate: true
  },
  {
    field: "description",
    header: "Description",
    dataType: GridColumnDataType.String,
    hidden: true,
    usedInDataUpdate: true
  },
  {
    field: "hasDocument",
    header: "Has Document(s)",
    dataType: GridColumnDataType.Boolean,
    hidden: true
  },
  {
    field: "hasImage",
    header: "Has Photo(s)",
    dataType: GridColumnDataType.Boolean,
    hidden: true
  },
  {
    field: "images",
    header: "Images",
    dataType: GridColumnDataType.String,
    hidden: true
  },
  {
    field: "cost",
    header: "Cost",
    dataType: GridColumnDataType.Currency,
    hidden: true,
    usedInDataUpdate: true
  },
  {
    field: "barcode",
    header: "Barcode",
    dataType: GridColumnDataType.String,
    hidden: true
  },
  {
    field: "reference",
    header: "Reference",
    dataType: GridColumnDataType.String,
    hidden: true,
    usedInDataUpdate: true
  },
  {
    field: "storedLocation",
    header: "Stored Location",
    dataType: GridColumnDataType.String,
    hidden: true,
    usedInDataUpdate: true
  },
  {
    field: "created",
    header: "Created Date",
    dataType: GridColumnDataType.DateTime,
    hidden: true
  },
  {
    field: "createdByName",
    header: "Created By",
    dataType: GridColumnDataType.String,
    hidden: true
  },
  {
    field: "updated",
    header: "Last Updated Date",
    dataType: GridColumnDataType.DateTime,
    hidden: true
  },
  {
    field: "updatedByName",
    header: "Last Updated By",
    dataType: GridColumnDataType.String,
    hidden: true
  },
  {
    field: "id",
    header: "Stock Guid",
    dataType: GridColumnDataType.String,
    hidden: true,
    usedInDataUpdate: true
  }
];

export const stockRequestsColumns: IGridColumn[] = [
  {
    field: "stockRequestNumber",
    header: "Request Number",
    dataType: GridColumnDataType.Number
  },
  {
    field: "stockRequestStatusName",
    header: "Request Status",
    dataType: GridColumnDataType.String
  },
  {
    field: "created",
    header: "Requested Date",
    dataType: GridColumnDataType.DateTime
  },
  {
    field: "providedDate",
    header: "Provided Date",
    dataType: GridColumnDataType.DateTime
  },
  {
    field: "requestedByName",
    header: "Requested By",
    dataType: GridColumnDataType.String
  },
  {
    field: "note",
    header: "Notes",
    dataType: GridColumnDataType.String
  },
  {
    field: "totalCost",
    header: "Total Cost",
    dataType: GridColumnDataType.Currency
  },
  {
    field: "numberOfRequestedItems",
    header: "No. Of Requested Items",
    dataType: GridColumnDataType.Number,
    hidden: true
  },
  {
    field: "updated",
    header: "Last Updated Date",
    dataType: GridColumnDataType.DateTime,
    hidden: true
  },
  {
    field: "createdByName",
    header: "Created By",
    dataType: GridColumnDataType.String,
    hidden: true
  },
  {
    field: "updatedByName",
    header: "Last Updated By",
    dataType: GridColumnDataType.String,
    hidden: true
  }
];

export const stockItemRequestsColumns: IGridColumn[] = [
  {
    field: "stockRequestNumber",
    header: "Request No.",
    dataType: GridColumnDataType.Number
  },
  {
    field: "stockName",
    header: "Stock Name",
    dataType: GridColumnDataType.String
  },
  {
    field: "stockCategoryName",
    header: "Stock Category",
    dataType: GridColumnDataType.String
  },
  {
    field: "stockTypeName",
    header: "Stock Type",
    dataType: GridColumnDataType.String
  },
  {
    field: "quantityRequested",
    header: "Quantity Requested",
    dataType: GridColumnDataType.Number
  },
  {
    field: "cost",
    header: "Cost",
    dataType: GridColumnDataType.Number
  },
  {
    field: "quantityProvided",
    header: "Quantity Provided",
    dataType: GridColumnDataType.Number
  },
  {
    field: "stockRequestStatusName",
    header: "Request Status",
    dataType: GridColumnDataType.String
  },
  {
    field: "requestedByName",
    header: "Requested By",
    dataType: GridColumnDataType.String
  },
  {
    field: "providedDate",
    header: "Supplied Date",
    dataType: GridColumnDataType.Date
  },
  {
    field: "providedByName",
    header: "Provided By",
    dataType: GridColumnDataType.String
  },
  {
    field: "note",
    header: "Notes",
    dataType: GridColumnDataType.String,
    hidden: true
  },
  {
    field: "inStock",
    header: "Quantity In Stock",
    dataType: GridColumnDataType.Number,
    hidden: true
  },
  {
    field: "originalNote",
    header: "Original Notes",
    dataType: GridColumnDataType.String,
    hidden: true
  },
  {
    field: "reference",
    header: "Reference",
    dataType: GridColumnDataType.String,
    hidden: true
  },
  {
    field: "maximumOrder",
    header: "Maximum Order Quantity",
    dataType: GridColumnDataType.Number,
    hidden: true
  },
  {
    field: "minimumStockQuantity",
    header: "Minimum Stock Quantity",
    dataType: GridColumnDataType.Number,
    hidden: true
  }
];

export const stockRequestPageColumns: IGridColumn[] = [
  {
    field: "stockRequestNumber",
    header: "Request Number",
    dataType: GridColumnDataType.Number
  },
  {
    field: "created",
    header: "Requested Date",
    dataType: GridColumnDataType.DateTime
  },
  {
    field: "providedDate",
    header: "Provided Date",
    dataType: GridColumnDataType.DateTime
  },
  {
    field: "requestedByName",
    header: "Requested By",
    dataType: GridColumnDataType.String
  },
  {
    field: "note",
    header: "Notes",
    dataType: GridColumnDataType.String
  },
  {
    field: "numberOfRequestedItems",
    header: "No. Of Requested Items",
    dataType: GridColumnDataType.Number,
    hidden: true
  },
  {
    field: "updated",
    header: "Last Updated Date",
    dataType: GridColumnDataType.DateTime,
    hidden: true
  },
  {
    field: "updatedByName",
    header: "Last Updated By",
    dataType: GridColumnDataType.String,
    hidden: true
  }
];

export const stockRequestColumns: IGridColumn[] = [
  {
    field: "stockName",
    header: "Name",
    dataType: GridColumnDataType.String
  },
  {
    field: "stockTypeName",
    header: "Type",
    dataType: GridColumnDataType.String
  },
  {
    field: "quantityRequested",
    header: "Requested",
    dataType: GridColumnDataType.Number
  },
  {
    field: "inStock",
    header: "In Stock",
    dataType: GridColumnDataType.Number
  },
  {
    field: "stockRequestStatus",
    header: "Status",
    // HACK
    dataType: GridColumnDataType.Percent
  },
  {
    field: "quantityProvided",
    header: "Provided",
    dataType: GridColumnDataType.Number
  },
  {
    field: "stockCost",
    header: "Item Cost",
    dataType: GridColumnDataType.Currency
  },
  {
    field: "note",
    header: "Notes",
    dataType: GridColumnDataType.String
  }
];

export const stockCategoryColumns: GridColumn[] = [
  {
    field: "name",
    header: "Category Name",
    dataType: GridColumnDataType.String
  },
  {
    field: "description",
    header: "Description",
    dataType: GridColumnDataType.String
  },
  {
    field: "created",
    header: "Created Date",
    dataType: GridColumnDataType.DateTime,
    hidden: true
  },
  {
    field: "createdByName",
    header: "Created By",
    dataType: GridColumnDataType.String,
    hidden: true
  },
  {
    field: "updated",
    header: "Last Updated Date",
    dataType: GridColumnDataType.DateTime,
    hidden: true
  },
  {
    field: "updatedByName",
    header: "Last Updated By",
    dataType: GridColumnDataType.String,
    hidden: true
  }
];

export const stockTypeColumns: GridColumn[] = [
  {
    field: "name",
    header: "Type Name",
    dataType: GridColumnDataType.String,
    pinned: true
  },
  {
    field: "description",
    header: "Description",
    dataType: GridColumnDataType.String
  },
  {
    field: "typeName",
    header: "Stock Category",
    dataType: GridColumnDataType.String
  },
  {
    field: "created",
    header: "Created Date",
    dataType: GridColumnDataType.DateTime,
    hidden: true
  },
  {
    field: "updated",
    header: "Last Updated Date",
    dataType: GridColumnDataType.DateTime,
    hidden: true
  },
  {
    field: "createdByName",
    header: "Created By",
    dataType: GridColumnDataType.String,
    hidden: true
  },
  {
    field: "updatedByName",
    header: "Last Updated By",
    dataType: GridColumnDataType.String,
    hidden: true
  }
];


export const stockContactColumns: IGridColumn[] = [
  {
    field: "name",
    header: "Name",
    dataType: GridColumnDataType.String
  },
  {
    field: "email",
    header: "Email",
    dataType: GridColumnDataType.String
  },
  {
    field: "mobile",
    header: "Mobile",
    dataType: GridColumnDataType.String
  },
  {
    field: "stockCategoryNames",
    header: "Stock Category Names",
    dataType: GridColumnDataType.String
  },
  {
    field: "lowStock",
    header: "Low Stock Notification",
    dataType: GridColumnDataType.Boolean
  },
  {
    field: "outOfStock",
    header: "Out Of Stock Notification",
    dataType: GridColumnDataType.Boolean
  },
  {
    field: "stockRequestCreated",
    header: "Stock Request Created Notification",
    dataType: GridColumnDataType.Boolean
  },
  {
    field: "created",
    header: "Created Date",
    dataType: GridColumnDataType.DateTime,
    hidden: true
  },
  {
    field: "createdByName",
    header: "Created By",
    dataType: GridColumnDataType.String,
    hidden: true
  },
  {
    field: "updated",
    header: "Last Updated Date",
    dataType: GridColumnDataType.DateTime,
    hidden: true
  },
  {
    field: "updatedByName",
    header: "Last Updated By",
    dataType: GridColumnDataType.String,
    hidden: true
  }
];
