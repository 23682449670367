interface IContextItem {
  title: string;

  action(): void;

  disabled: boolean;
  multipleSelect: boolean;
  multipleSelectOnly?: boolean;

  condition?(item): boolean;

  group: number;
  extra?: string;
}

export class ContextItem {
  title;
  action;
  disabled;
  multiSelect;
  condition?;
  group;
  extra?;

  constructor(obj: IContextItem) {
    Object.assign(this, obj);
  }
}

export function contextMenu(
  viewAction,
  archiveAction,
  deleteAction,
  viewClaim,
  editClaim,
  supportClaim,
  singularName,
  pluralName,
  specificArchiveMsg?
) {
  return [
    new ContextItem({
      title: `${singularName} Properties`,
      action: viewAction,
      disabled: !viewClaim,
      condition: (item) => !item.isSystem || supportClaim,
      multipleSelect: false,
      group: 1
    }),
    new ContextItem({
      title: `Archive ${singularName}`,
      action: archiveAction,
      disabled: !editClaim,
      multipleSelect: false,
      condition: (item) => !item.isArchived && ((item.canArchive !== false && !item.isSystem) || supportClaim),
      group: 1,
      extra: specificArchiveMsg
    }),
    new ContextItem({
      title: `Unarchive ${singularName}`,
      action: archiveAction,
      disabled: !editClaim,
      multipleSelect: false,
      condition: (item) => item.isArchived && ((item.canArchive !== false && !item.isSystem) || supportClaim),
      group: 1
    }),
    new ContextItem({
      title: `Delete ${singularName}`,
      action: deleteAction,
      disabled: !editClaim,
      multipleSelect: false,
      condition: (item) => item.canDelete !== false && (!item.isSystem || supportClaim),
      group: 1
    }),
    new ContextItem({
      title: `Archive ${pluralName}`,
      action: archiveAction,
      disabled: !editClaim,
      multipleSelect: true,
      multipleSelectOnly: true,
      condition: (item) => item.canArchive !== false && !item.isArchived && !item.isSystem,
      group: 1,
      extra: specificArchiveMsg
    }),
    new ContextItem({
      title: `Unarchive ${pluralName}`,
      action: archiveAction,
      disabled: !editClaim,
      multipleSelect: true,
      multipleSelectOnly: true,
      condition: (item) => item.canArchive !== false && item.isArchived && !item.isSystem,
      group: 1
    }),
    new ContextItem({
      title: `Delete ${pluralName}`,
      action: deleteAction,
      disabled: !editClaim,
      multipleSelect: true,
      multipleSelectOnly: true,
      condition: (item) => item.canDelete !== false && (!item.isSystem || supportClaim),
      group: 1
    })
  ];
}
