import { GridColumnDataType } from "@infragistics/igniteui-angular";
import { IGridColumn } from "../../shared/grid/grid-model";

export const taskModalColumns: IGridColumn[] = [
  {
    field: "mobileCreated",
    header: "Recorded Date",
    dataType: GridColumnDataType.DateTime
  },
  {
    field: "programName",
    header: "Program",
    dataType: GridColumnDataType.String
  },
  {
    field: "taskRecordStatusName",
    header: "Task Record Status",
    dataType: GridColumnDataType.String
  },
  {
    field: "hasImage",
    header: "Has Photo(s)",
    dataType: GridColumnDataType.Boolean
  }
];
