import { Component, OnInit, Inject } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { AuthService } from "../auth.service";
import { DOCUMENT } from "@angular/common";
import { Regex } from "src/app/shared/helpers/regex-patterns";
import { AppUser } from "../models/app-user";

@Component({
  selector: "reset-password",
  templateUrl: "./reset-password.component.html",
  styleUrls: ["./reset-password.component.css"]
})
export class ResetPasswordComponent implements OnInit {
  resetForm: UntypedFormGroup;
  returnUrl: string;
  showPwd: boolean = false;
  notMatching = false;
  user: AppUser = new AppUser();
  token;

  lastPassReferences = [
    "__lpform_input_idx_0",
    "__lpform_input_idx_0_numspan",
    "__lpform_input_idx_1",
    "__lpform_input_idx_1_numspan",
    "__lpform_input_idx_2",
    "__lpform_input_idx_2_numspan"
  ];

  constructor(
    public readonly router: Router,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly activatedRoute: ActivatedRoute,
    private readonly authService: AuthService,
    @Inject(DOCUMENT) private document: any
  ) {
    this.resetForm = this.formBuilder.group({
      email: [this.user.email, Validators.required],
      password: [this.user.password, Validators.required],
      confirmPassword: [this.user.password, Validators.required]
    });

    this.resetForm.controls["password"].setValidators([
      Validators.minLength(8),
      Validators.maxLength(20),
      Validators.pattern(Regex.password)
    ]);

    this.resetForm.controls["confirmPassword"].setValidators([
      Validators.minLength(8),
      Validators.maxLength(20),
      Validators.pattern(Regex.password)
    ]);

    this.resetForm.controls["password"].valueChanges.subscribe(() =>
      this.checkPasswords()
    );
    this.resetForm.controls["password"].valueChanges.subscribe(() =>
      this.checkPasswords()
    );
  }

  ngOnInit() {
    this.activatedRoute.paramMap.subscribe((s) => {
      if (s.get("token")) {
        this.token = s.get("token");
      } else {
        this.router.navigate(["login"]);
      }
    });
  }

  checkPasswords() {
    this.notMatching =
      this.resetForm.controls["confirmPassword"].value !==
      this.resetForm.controls["password"].value;
  }

  submit() {
    this.user = this.resetForm.getRawValue();
    this.user.token = this.token;
    this.authService.reset(this.user);
    // Removes lastpass artifacts that remain on SPA's
    this.lastPassReferences.map((ref) => {
      const element = this.document.getElementById(ref);
      if (element) element.parentNode.removeChild(element);
    });
  }
}
