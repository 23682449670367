import {Component, EventEmitter, Inject, Output} from "@angular/core";
import {MatDialogRef, MAT_DIALOG_DATA} from "@angular/material/dialog";
import {BaseModalComponent} from "src/app/shared/base/base-modal.component";
import {AttendeeTimeRecordFields} from "../modal-fields/attendance.fields";

@Component({
  selector: "app-attendance-modal",
  templateUrl: "./attendance-modal.component.html",
  styles: []
})
export class AttendanceModalComponent
  extends BaseModalComponent {
  @Output()
  onSave = new EventEmitter();

  nav = [
    {name: "Attendance Details", page: "details", disabled: false},
    {name: "History", page: "auditTrail", disabled: false, hidden: this.isNew}
  ];

  current = this.nav[0];
  entity = <any>{};
  loaded = false;
  timeRecords = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<Component>
  ) {
    super();
    this.isNew = !data.data.id;
    this.current = this.nav[0];
    this.fields = data.fields;
    this.metaData = data.metaData;
    this.entity = this.data.data;
    this.entity.tempDate = this.entity.created;

    if (this.isNew) {
      this.entity.started = this.newIsoDate();
      this.entity.createdByName = this.authService.securityObject.name;
    }

    if (this.entity.started) {
      this.entity.startedDate = this.entity.started.split("T")[0];
      this.entity.startedTime = this.entity.started.match(/([A-Z]?[^A-Z]*)/g)[1]
        .slice(1, -1).slice(0, 5);
    }

    if (this.entity.finished) {
      this.entity.finishedDate = this.entity.finished.split("T")[0];
      this.entity.finishedTime = this.entity.finished.match(/([A-Z]?[^A-Z]*)/g)[1]
        .slice(1, -1).slice(0, 5);
    }


    this.createForm(this.fields, this.entity).then((form) => {
      this.form = form;
      this.form.controls["createdByName"].disable();

      if (!this.isNew) {
        this.populateTimeRecords();
      }
      this.loaded = true;
    });
  }

  changeSection(item) {
    if (item.page === "auditTrail") {
      let parameter = item.page !== "auditTrail" ? "id" : null;
      if (!this.entity[item.page]) {
        this.loaded = false;
        this.remoteService
          .getBy(
            `attendance/${item.page}`,
            parameter,
            this.data.data.id
          )
          .subscribe(
            (res) => {
              this.entity[item.page] = res;
              this.loaded = true;
            },
            () => {
              this.loaded = true;
            }
          );
      }
    }
    this.current = item;
  }

  async populateTimeRecords() {
    if (this.entity.timeRecords) {
      if (this.entity.timeRecords.length > 0) {
        this.entity.timeRecords.sort((a, b) => {
          if (a.started < b.started) {
            return -1;
          }
          if (a.started > b.started) {
            return 1;
          }
          return 0;
        })

        for (var i = 0; i < this.entity.timeRecords.length; i++) {
          var record =  this.entity.timeRecords[i];

          record.started = record.started.match(/([A-Z]?[^A-Z]*)/g)[1]
              .slice(1, -1).slice(0, 5);

          if (record.finished) {
            record.finished = record.finished.match(/([A-Z]?[^A-Z]*)/g)[1]
              .slice(1, -1).slice(0, 5);
          }

          this.timeRecords.push(await this.createForm(AttendeeTimeRecordFields,
            record));
        }
      }
    }
  }

  async addTimeRecord() {
    if (this.timeRecords.length > 0) {
      this.timeRecords.sort((a, b) => {
        if (a.started < b.started) {
          return -1;
        }
        if (a.started > b.started) {
          return 1;
        }
        return 0;
      })

      this.timeRecords.push(await this.createForm(AttendeeTimeRecordFields, {
        site: null,
        started: this.timeRecords[0].controls["finished"].value ? this.timeRecords[0].controls["finished"].value : null
      }));
    } else {
      this.timeRecords.push(await this.createForm(AttendeeTimeRecordFields, {
        site: null,
        started: this.form.controls["startedTime"].value ? this.form.controls["startedTime"].value : null
      }));
    }
  }

  canSave() : boolean {
    for(let i =0; i <this.timeRecords.length; i++) {
      if (this.timeRecords[i].status !== "VALID") {
        this.timeRecords[i].markAllAsTouched();
        return false;
      }
    }
    return true;
  }

  save() {
    this.entity = {...this.entity, ...this.form.getRawValue()};

    this.entity.started = this.entity.startedDate
      ? this.formDateTime(
        this.entity.startedDate,
        this.entity.startedTime
      )
      : null;

    this.entity.finished = this.entity.finishedDate
      ? this.formDateTime(
        this.entity.finishedDate,
        this.entity.finishedTime
      )
      : null;

    /// Todo compare original
    this.entity.timeRecords = [];
    if (this.timeRecords) {
      for (let i = 0; i < this.timeRecords.length; i++) {
        let timeRecord = this.timeRecords[i].getRawValue();
        if (!timeRecord.started) {
          continue;
        }
        timeRecord.started = this.formDateTime(
          this.entity.startedDate,
          timeRecord.started
        )
        timeRecord.finished = timeRecord.finished ? this.formDateTime(
          this.entity.startedDate,
          timeRecord.finished
        ) : null;

        this.entity.timeRecords.push(timeRecord);
      }
    }

    this.onSave.emit(this.entity);
  }

  close(form) {
    this.closeModal(form, this.dialogRef);
  }
}
