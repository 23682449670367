<div *ngIf="!isStatic">
  <input list="searchOptions" placeholder="Start typing to filter..."
         [(ngModel)]="searchString" (ngModelChange)="search()"
         class="mat-flat-button map-left no-click-through search-box"/>
  <datalist id="searchOptions" *ngIf="searchString.length > 2">
    <option *ngFor="let marker of searchResults ">
      {{marker.title}}
    </option>
  </datalist>
</div>


<div *ngIf="!isStatic" class="map-right click-through">
  <!-- Layers -->
  <div>
    <div class="cardGroup mt-2 no-click-through">
      <div *ngIf="!showLayers" class="m-0">
        <button
          (click)="showLayers = true"
          title="Layers"
          class="border map-icon"
          mat-flat-button
        >
          <i class="fad fa-layer-group"></i>
        </button>
      </div>
      <mat-card appearance="outlined" *ngIf="showLayers" class="nav w-150 mt-2">
        <div class="title d-flex flex-row">
          <h5 class="m-1">Layers</h5>
          <div class="collapseButtonBox text-end shadow-none">
            <button
              class="map-icon m-0"
              (click)="showLayers = false"
              mat-flat-button
            >
              <i class="fas fa-chevron-double-right"></i>
            </button>
          </div>
        </div>
        <mat-card-content style="display: flex !important;" class="flex text-start flex-column">
          <mat-checkbox
            class="row"
            [checked]="display.taskMarkers"
            (click)="toggleLayers(2)"
          >
            Task Markers
          </mat-checkbox>
          <mat-checkbox
            class="row"
            [checked]="display.siteMarkers"
            (click)="toggleLayers(1)"
          >
            Site Markers
          </mat-checkbox>
          <mat-checkbox
            class="row"
            [checked]="display.issueMarkers"
            (click)="toggleLayers(3)"
          >
            Issue Markers
          </mat-checkbox>
          <mat-checkbox
            class="row"
            [checked]="display.siteShapes"
            (click)="toggleLayers(4)"
          >
            Site Areas
          </mat-checkbox>
          <mat-checkbox
            class="row"
            [checked]="display.taskShapes"
            (click)="toggleLayers(5)"
          >
            Task Areas
          </mat-checkbox>
        </mat-card-content>
      </mat-card>
    </div>
  </div>

  <!-- Filters -->
  <div>
      <div class="cardGroup mt-2 no-click-through">
        <div *ngIf="!showFilters" class="m-0">
          <button
            [ngClass]="{
              'secondary': filtering
            }"
            title="Filters"
            class="border map-icon"
            (click)="showFilters = true; filters.length < 1 ? addFilter() : ''"
            mat-flat-button
          >
            <i class="fas fa-filter"></i>
          </button>
        </div>
        <mat-card appearance="outlined" *ngIf="showFilters" class="nav w-500 mt-2">
          <div class="title d-flex flex-row">
            <h5 class="m-1 ms-2">Filters</h5>
            <div class="text-right">
              <button
                mat-flat-button
                class="map-icon m-0 shadow-none no-outline"
                (click)="addFilter()"
              >
                <i class="far fa-plus-circle"></i>
              </button>
            </div>
            <small class="mx-3 align-self-center ms-5"
            >Ensure applicable layers are switched on when Filtering.</small
            >
            <div class="collapseButtonBox ms-5 text-end shadow-none">
              <button
                class="map-icon m-0"
                (click)="showFilters = false"
                mat-flat-button
              >
                <i class="fas fa-chevron-double-right"></i>
              </button>
            </div>
          </div>
          <mat-card-content class="card-content">
            <div *ngFor="let filter of filters; let i = index">
              <div class="d-flex justify-content-around">
                <mat-form-field class="w-120">
                  <mat-label>Filter Type</mat-label>
                  <mat-select
                    [(ngModel)]="filters[i].filterType"
                    (ngModelChange)="updateFilterMethods(filters[i].filterType, i)"
                  >
                    <mat-option
                      *ngFor="let filterType of filterTypes | sort: 'name'"
                      [value]="filterType.id"
                    >
                      {{ filterType.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field class="w-120">
                  <mat-label>Filter Method</mat-label>
                  <mat-select
                    [(ngModel)]="filters[i].filterMethod"
                    (ngModelChange)="
                      updateFilterOptions(filters[i].filterMethod, i)
                    "
                  >
                    <mat-option
                      *ngFor="let method of filters[i].filterMethods | sort: 'name'"
                      [value]="method.filter"
                    >
                      {{ method.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field class="w-120">
                  <mat-label>Filter Options</mat-label>
                  <mat-select
                    [(ngModel)]="filters[i].filter"
                    (ngModelChange)="filterData(true)"
                  >
                    <mat-option
                      *ngFor="let filter of filters[i].filters | sort"
                      [value]="filter"
                    >
                      {{ filter }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <span class="align-self-center mb-3 px-2">{{ filter.count }}</span>
                <button mat-flat-button (click)="removeFilter(i)" class="map-icon">
                  <i class="far fa-times-circle"></i>
                </button>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
  </div>

  <!-- Plot new -->
  <div>
    <div class="cardGroup mt-2 no-click-through">
      <div *ngIf="!showPlot">
        <button
          title="Plot New..."
          *ngIf="!readOnly"
          (click)="getMarkerToPlot()"
          class="border map-icon"
          mat-flat-button
          [ngClass]="{ 'secondary': plotTool.plotting }"
        >
          <i class="fas fa-thumbtack"></i>
        </button>
      </div>
      <mat-card appearance="outlined" *ngIf="showPlot" class="nav w-150 mt-2">
        <div class="title d-flex flex-row">
          <h5 class="w-50">Plot</h5>
          <div class="text-start">
            <button
              (click)="selectTool()"
              class="map-icon m-0 shadow-none"
              mat-flat-button
            >
              <i class="fas fa-chevron-double-right"></i>
            </button>
          </div>
        </div>
        <mat-card-content class="d-flex flex-column align-items-start pb-3">
          <button
            [ngClass]="{ 'secondary': plotTool.tool == 'MARKER' }"
            class="mat-flat-button plotTool"
            (click)="selectTool('MARKER')"
          >
            <i class="me-1 fas fa-map-marker"></i>
            Marker
          </button>
          <button
            *ngIf="!plotTool.issue"
            [ngClass]="{ 'secondary': plotTool.tool == 'POLYLINE' }"
            class="mat-flat-button plotTool"
            (click)="selectTool('POLYLINE')"
          >
            <i class="me-1 fas fa-wave-triangle"></i> Path
          </button>
          <button
            *ngIf="!plotTool.issue"
            [ngClass]="{ 'secondary': plotTool.tool == 'POLYGON' }"
            class="mat-flat-button plotTool"
            (click)="selectTool('POLYGON')"
          >
            <i class="me-1 fas fa-hexagon"></i> Polygon
          </button>
          <button
            *ngIf="!plotTool.issue"
            [ngClass]="{ 'secondary': plotTool.tool == 'CIRCLE' }"
            class="mat-flat-button plotTool"
            (click)="selectTool('CIRCLE')"
          >
            <i class="me-1 fas fa-circle"></i> Circle
          </button>
        </mat-card-content>
      </mat-card>
    </div>
  </div>

  <!-- Ruler -->
  <div>

      <div class="cardGroup mt-2 no-click-through">
        <div *ngIf="!showMeasurements">
          <button
            (click)="startMeasurement()"
            title="Measuring Tool"
            class="border map-icon"
            mat-flat-button
            [ngClass]="{ 'secondary': ruler.selected }"
          >
            <i class="fas fa-ruler"></i>
          </button>
        </div>
        <mat-card appearance="outlined" *ngIf="showMeasurements" class="nav w-400 mt-2">
          <div class="title d-flex flex-row">
            <h5 class="m-1 align-self-center">Measure</h5>
            <small class="mx-3 align-self-center"
            >Click on map or plot area to measure.</small
            >
            <div class="collapseButtonBox text-end">
              <button
                class="map-icon m-0 shadow-none"
                (click)="endMeasurement()"
                mat-flat-button
              >
                <i class="fas fa-chevron-double-right"></i>
              </button>
            </div>
          </div>
          <mat-card-content class="card-content">
            <div *ngIf="measureCard.firstText" class="text-start">
              <span>{{ measureCard.firstText }}</span>
            </div>
            <div *ngIf="measureCard.secondText" class="text-start">
              <span>{{ measureCard.secondText }}</span>
            </div>
            <button
              class="shadow border px-3"
              mat-flat-button
              (click)="endMeasurement(); startMeasurement()"
            >
              Clear
            </button>
          </mat-card-content>
        </mat-card>
      </div>
  </div>

  <!-- Labels -->
  <div>
    <div class="cardGroup mt-2 no-click-through">
      <button
        mat-flat-button
        title="Show Labels"
        class="border map-icon"
        (click)="toggleLabels()"
        [ngClass]="{ 'secondary': showingAllLabels }"
      >
        <i class="fas fa-tags"></i>
      </button>
    </div>
  </div>

  <!-- Screenshot -->
  <div>
    <div class="cardGroup mt-2 no-click-through">
      <button
        (click)="takeScreenshot()"
        title="Screenshot"
        class="border map-icon"
        mat-flat-button
      >
        <i class="fas fa-image-polaroid"></i>
      </button>
      <a #downloadLink></a>
    </div>
  </div>

  <!-- Delete -->
  <div>
    <div class="cardGroup mt-2 no-click-through">
      <button
        *ngIf="plotToDelete.show"
        mat-flat-button
        title="Delete plot"
        class="border map-icon"
        (click)="deletePlot()"
      >
        <i class="fas fa-times"></i>
      </button>
    </div>
  </div>

</div>

<content class="main_component  map position-relative">
  <div #mapWindow id="map" class="position-absolute w-100 h-100"></div>
  <ng-template #loading>
    <div class="loading h-100 w-100">
      <div>
        <p class="fad fa-spinner-third fa-spin fa-3x"></p>
      </div>
    </div>
  </ng-template>
  <div *ngIf="contextmenu == true">
    <context-menu
      [x]="contextmenuX"
      [y]="contextmenuY"
      [contextMenuOptions]="contextMenuOptions"
      (onClick)="contextMenuClick($event)"
      (onClose)="contextmenu = false"
    >
    </context-menu>
  </div>
</content>
